import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer } from 'helper/GridHelper';
import { CompanyLevelPermission, DictionaryName, EwdPlaceLevelPermission, ExciseStampActionType, ExciseStampEntryClient, ExciseStampEntryHeaderDto } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { Authorized } from 'authorization/Authorized';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import ExciseStampChangeToReadyButton from '../actionButtons/ExciseStampChangeToReadyButton';
import ExciseStampChangeToEditedButton from '../actionButtons/ExciseStampChangeToEditedButton';
import ExciseStampDeleteEntryButton from '../actionButtons/ExciseStampDeleteEntryButton';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseStampRegisterId: number;
    placeContext: string;
    registerType: string;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
export default class CurrentExciseStampEntryList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "currentExciseStampEntryListGrid"
        }
    }

    refreshGrid = () => {
        this.paginatedTable.current?.refresh();
    }

    private columns: (EwdColumnProps<ExciseStampEntryHeaderDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseStampEntryHeaderDto) => (
                <>
                    {record.id && <>
                        <FadingTooltip title="Podgląd" placement="top">
                            <Link to={`/ExciseStampEntries/Preview/${record.id}`} >
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                            </Link>
                        </FadingTooltip>
                        {record.actions?.includes(ExciseStampActionType.Editing) && <Authorized placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                            <FadingTooltip title="Redaguj" placement="top">
                                <Link to={`/ExciseStampRegisters/${this.props.exciseStampRegisterId}/${this.props.registerType}/ExciseStampEntries/Edit/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<EditOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>}
                        {record.actions?.includes(ExciseStampActionType.Confirming) && <Authorized placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                            <ExciseStampChangeToReadyButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ExciseStampActionType.SwitchingToEdited) && <Authorized placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                            <ExciseStampChangeToEditedButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ExciseStampActionType.Deleting) && <Authorized placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                            <ExciseStampDeleteEntryButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                    </>}
                </>
            ),
            title: "Czynności",
            width: 140,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            width: 80,
            filter: "equals",
        },
        {
            dataIndex: "entryDate",
            key: "EntryDate",
            title: "Data wpisu",
            render: (text: any, record: ExciseStampEntryHeaderDto) => dateRenderer(record.entryDate),
            width: 90,
        },
        {
            dataIndex: "sourceIdentifier",
            key: "SourceIdentifier",
            title: "Identyfikator źródłowy",
            filter: "contains",
        },
        {
            dataIndex: "paragraphType",
            key: "ParagraphType",
            render: (text: any, record: ExciseStampEntryHeaderDto) => {
                return this.props.dictionaryStore!.getDisplay(this.props.dictionaryStore!.getExciseStampParagraphDictionaryName(this.props.registerType), record.paragraphType);
            },
            title: "Paragraf"
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (text: any, record: ExciseStampEntryHeaderDto) => {
                return this.props.dictionaryStore!.getDisplay(DictionaryName.ExciseStampEntryStatus, record.status);
            },
            title: "Status"
        },
        {
            dataIndex: "itemsQuantity",
            key: "ItemsQuantity",
            title: "Wpływ na stan banderol",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsInRegisterQuantity",
            key: "ItemsInRegisterQuantity",
            title: "Wpływ na stan banderol w siedzibie",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsOutOfRegisterQuantity",
            key: "ItemsOutOfRegisterQuantity",
            title: "Wpływ na stan banderol poza siedzibą",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsAppliedQuantity",
            key: "ItemsAppliedQuantity",
            title: "Wpływ na stan banderol naniesionych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsDamagedQuantity",
            key: "ItemsDamagedQuantity",
            title: "Wpływ na stan banderol uszkodzonych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsLostQuantity",
            key: "ItemsLostQuantity",
            title: "Wpływ na stan banderol utraconych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsDestroyedQuantity",
            key: "ItemsDestroyedQuantity",
            title: "Wpływ na stan banderol zniszczonych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseStampEntryHeaderDto>>();
    public render() {
        return (
            <>
                <PaginatedTable<ExciseStampEntryHeaderDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseStampEntryHeaderDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseStampEntryClient().getCurrent(this.props.exciseStampRegisterId, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }}
                    scroll={{ y: 'calc(100vh - 470px)' }}
                />
            </>
        )
    }
};