import React from 'react';
import { Button, Form, Modal, ModalProps, PageHeader, Table } from 'antd';
import { FCenteredRow, FInputNumber } from 'forms/FormikFormItems';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { SaveExciseRegisterInventoryStateCmd, ExciseRegisterStateClient, InventoryContainerAmountDto } from 'services/GeneratedClient';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { Moment } from 'moment';
import ExciseRegisterInventoryValidation from './ExciseRegisterInventoryValidation';

interface IProps {
    exciseRegisterId: number;
    selectedDate: Moment;
    onSave: () => void;
    isEditInventory: boolean
}

interface IState {
    visible: boolean;
    isLoading: boolean;
    form?: ExciseRegisterInventoryForm;
}

class ExciseRegisterInventoryForm {

    containers!: InventoryContainerAmountDto[]
}

export default class ExciseRegisterInventoryModal extends React.PureComponent<IProps & ModalProps, IState>{
    private validation: ExciseRegisterInventoryValidation;
    constructor(props: IProps) {
        super(props);
        this.state = {
            visible: false,
            isLoading: false,
        }
        this.validation = new ExciseRegisterInventoryValidation();
    }

    public show() {
        this.getInitialValues(this.props.exciseRegisterId)
        this.setState({
            visible: true
        })
    }

    public hide() {
        this.setState({
            visible: false
        })
    }

    private getInitialValues = (registerId: number) => {
        ajaxCatch(() =>
            new ExciseRegisterStateClient().getInventoryContainersByExciseRegister(registerId, this.props.selectedDate)
                .then((resp: InventoryContainerAmountDto[] | null) => {
                    var form = new ExciseRegisterInventoryForm()
                    form.containers = resp!;
                    this.setState({
                        isLoading: false,
                        form: form
                    })

                })
        )

    }

    public send(containers: InventoryContainerAmountDto[], actions: FormikHelpers<ExciseRegisterInventoryForm>) {
        this.setState({ isLoading: true })

        let cmd = new SaveExciseRegisterInventoryStateCmd({
            registerId: this.props.exciseRegisterId,
            date: this.props.selectedDate,
            exciseRegisterContainerStateDto: containers
        });
        ajaxByUser(this.props.isEditInventory ? "Zredagowano inwenteryzację" : "Zarejestrowano inwenteryzację", () =>
            new ExciseRegisterStateClient().saveInventoryState(cmd)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    this.props.onSave();
                }).finally(() => {
                    this.setState({ isLoading: false })
                    this.hide();
                }
                ));
    }

    private countSum(values: ExciseRegisterInventoryForm) {
        return (values.containers.filter(x => x.amount != undefined).length > 0) ?
            values.containers.filter(x => x.amount != undefined).map(x => x.amount).reduce((a, c) => {
                return a! + c! ?? 0
            }) : 0
    }
    public render() {

        return (
            <>
                {this.state.form?.containers && (
                    <>

                        <Modal {...this.props}
                            title={this.props.isEditInventory ? `REDAGOWANIE INWENTERYZACJI` : `REJESTROWANIE INWENTERYZACJI`}
                            visible={this.state.visible}
                            destroyOnClose={true}
                            onCancel={() => this.hide()}
                            footer={null}
                            width={"600px"}>
                            <Formik<ExciseRegisterInventoryForm>
                                initialValues={this.state.form}
                                enableReinitialize
                                onSubmit={(values, actions) => {
                                    this.send(values.containers, actions);
                                }}
                                validationSchema={this.validation.schema}
                            >
                                {(props: FormikProps<ExciseRegisterInventoryForm>) => (
                                    <>
                                        <FCenteredRow>
                                            <Form>

                                                <Table

                                                    dataSource={this.state.form?.containers}
                                                    rowKey={(row) => row.containerId}
                                                    size="small"
                                                    pagination={false}
                                                    style={{ width: 600 }}
                                                    columns={[
                                                        {
                                                            dataIndex: "containerId",
                                                            key: "ContainerId",
                                                            title: "Id",
                                                        },
                                                        {
                                                            dataIndex: "containerName",
                                                            key: "ContainerName",
                                                            title: "Zbiornik",
                                                        },
                                                        {
                                                            dataIndex: "amount",
                                                            key: "Amount",
                                                            title: "Ilość",
                                                            render: (text, record, i) => {
                                                                return <Field component={FInputNumber} precision={3} min={Number.MIN_SAFE_INTEGER} name={`containers.${i}.amount`} />
                                                            },
                                                        },
                                                    ]}
                                                    summary={() => (
                                                        <Table.Summary fixed>
                                                            <Table.Summary.Row>
                                                                <Table.Summary.Cell index={0} colSpan={2}>Suma</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1}>{this.countSum(props.values)?.toFixed(3)}
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                        </Table.Summary>
                                                    )}
                                                />
                                            </Form>
                                        </FCenteredRow>
                                        <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                            <Button type="primary" icon={<CloseOutlined />} size="middle" onClick={() => this.hide()}>Anuluj</Button>
                                            <Button type="primary" icon={<SaveOutlined />} size="middle" loading={this.state.isLoading} onClick={() => {
                                                props.submitForm();

                                            }}>Zapisz</Button>
                                        </FCenteredRow>
                                    </>
                                )}
                            </Formik>
                        </Modal>
                    </>
                )}
            </>
        )
    }
};

