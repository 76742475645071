import { Button, Col, Select } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { PageHeader } from "layout/PageHeader";
import PrimaryCard from "../layout/PrimaryCard";
import { DictionaryClient, EwdDictionaryValueDto } from "services/GeneratedClient";
import { FCenteredRow, FFieldLabel, FInput } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import EwdDictionaryValueValidation from "../dictionary/EwdDictionaryValueValidation";
import { inject } from "mobx-react";
import { GridStore } from "layout/table/paginated/GridStore";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
    gridStore?: GridStore;
    dictionaryId: number;
}

interface IState {
    name: string | null;
    isLoading: boolean;
    gridName?: string;
}

@inject("gridStore")
export default class EwdDictionaryValueCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/Dictionaries/${this.props.dictionaryId}`;
    private validation: EwdDictionaryValueValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            name: "",
            isLoading: false,
            gridName: this.props.gridStore?.getGridNameForLastUsedRowId(),
        };

        this.validation = new EwdDictionaryValueValidation();
    }

    componentDidMount() {
        ajaxCatch(() =>
            new DictionaryClient().getEwdDictionaryName(this.props.dictionaryId),
            (name: string | null) => {
                this.setState({ name: name })
            });
    }

    public send(values: EwdDictionaryValueDto, actions: FormikHelpers<EwdDictionaryValueDto>) {
        this.setState({ isLoading: true })
        values.dictionaryId = this.props.dictionaryId;
        ajaxByUser("Dodano wartość słownika", () =>
            new DictionaryClient().create(values)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    this.setState({ isLoading: false });
                    this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, resp.toString());
                    this.props.history.push(this.goBackUrl);
                })
                .finally(() => this.setState({ isLoading: false })));
    }

    public render() {
        return (
            <>
                <PageHeader title={`DODANIE WARTOŚCI SŁOWNIKA - ${this.state.name}`} />
                <Formik<EwdDictionaryValueDto> initialValues={new EwdDictionaryValueDto()}
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<EwdDictionaryValueDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Kod w słowniku" sm={24} />
                                    <Field component={FInput} name="code" />
                                </Col>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Wartość wyświetlana" sm={24} />
                                    <Field component={FInput} name="displayValue" />
                                </Col>
                            </FCenteredRow>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" icon={<SaveOutlined />} loading={this.state.isLoading} size="large" onClick={() => {
                                    props.submitForm();
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}