import React, { ReactNode } from "react";
import Table, { TableProps } from "../Table";
import { TableComponents } from 'rc-table/lib/interface'
import { Table as AntdTable } from 'antd';
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { showNotExpectedErrorModal } from "layout/Modals";

interface IState<T> {
    topSummaryData?: T;
    botSummaryData?: T;
    loaded: boolean;
}

export interface TableWithSummariesProps<T> extends TableProps<T> {
    gridName: string;
    topSummary?: {
        getData?: () => Promise<T | null>;
        label?: string;
    };
    botSummary?: {
        getData?: () => Promise<T | null>;
        label?: string;
    };
    rowSelection?: {
        selectedRowKeys: React.Key[];
        onChange: (selectedRowKeys: React.Key[]) => void;
    };
}

export default class TableWithSummaries<T extends object> extends React.PureComponent<TableWithSummariesProps<T>, IState<T>> {
    constructor(props: any) {
        super(props);

        this.state = { loaded: false };
    }
    private isColumnGroupType<T extends object>(x: ColumnGroupType<T> | ColumnType<T>): x is ColumnGroupType<T> {
        return (x as ColumnGroupType<T>).children !== undefined;
    }

    fetchResults() {
        if (this.props.topSummary?.getData) {
            this.props.topSummary?.getData().then((data: any) => {
                this.setState({
                    topSummaryData: data,
                    loaded: true,
                });
            })
                .catch((e: any) => {
                    showNotExpectedErrorModal(e);
                });
        }

        if (this.props.botSummary?.getData) {
            this.props.botSummary?.getData().then((data: any) => {
                this.setState({
                    botSummaryData: data,
                    loaded: true,
                });
            })
                .catch((e: any) => {
                    showNotExpectedErrorModal(e);
                });
        }
    }

    public render() {
        // !this.state.loaded && this.fetchResults();
        const components = <T extends object>(props: TableProps<T>): TableComponents<T> => {
            return {
                header: {
                    wrapper: HeaderWrapper
                }
            }
        }

        const HeaderWrapper = (hwProps: any) => {
            return React.createElement('thead', { className: "ant-table-thead" }, hwProps.children, this.props.topSummary && this.props.columns && this.state.topSummaryData && <tr className="ant-table-top-summary">
                {this.props.columns.map((col, i) => {
                    const summary = this.props.topSummary!;
                    let display: ReactNode = "";
                    if (col.key === 'actions') {
                        display = summary.label || display;
                    }
                    else {
                        if (!this.isColumnGroupType(col) && typeof col.dataIndex === 'string') {
                            const val = (this.state.topSummaryData as any)[col.dataIndex];
                            display = col.render ? col.render(val, this.state.topSummaryData!, Number.MAX_SAFE_INTEGER) : val;
                        }
                    }
                    return <th key={i}>
                        {display}
                    </th>
                })}
            </tr>)
        }
        return <Table {...this.props}
            components={(this.props.topSummary && components(this.props))}
            summary={this.props.botSummary && this.props.columns && this.state.botSummaryData ? () => <AntdTable.Summary fixed>
                <AntdTable.Summary.Row>
                    {this.props.columns!.map((col, i) => {
                        const summary = this.props.botSummary!;
                        let display: ReactNode = "";
                        if (col.key === 'actions') {
                            display = summary.label || display;
                        }
                        else {
                            if (!this.isColumnGroupType(col) && typeof col.dataIndex === 'string') {
                                const val = (this.state.botSummaryData as any)[col.dataIndex];
                                display = col.render ? col.render(val, this.state.botSummaryData!, Number.MAX_SAFE_INTEGER) : val;
                            }
                        }
                        return <AntdTable.Summary.Cell key={i} index={i}>{display}</AntdTable.Summary.Cell>
                    })}
                </AntdTable.Summary.Row>
            </AntdTable.Summary> : undefined}
        />
    }
}