import React from "react";
import { Tabs as AntdTabs, TabsProps } from "antd";
import "./tabs.less";

interface IProps extends TabsProps {
}

interface IState {
}
export default class Tabs extends React.Component<IProps, IState> {
    public render() {
        return <AntdTabs {...this.props} type={this.props.type || "card"}>
            {this.props.children}
        </AntdTabs>
    }
}

export const { TabPane } = AntdTabs;