import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { UserClient, UserDto } from 'services/GeneratedClient';
import { Link } from 'react-router-dom';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import FadingTooltip from "layout/FadingTooltip";
import { ToolOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';
import { GridStore } from 'layout/table/paginated/GridStore';
import { inject } from 'mobx-react';

interface IProps {
    gridStore?: GridStore
}

interface IState {
    gridName: string;
}

@inject("gridStore")
export default class AdminCompanyUserList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "adminCompanyUserList"
        }
    }

    componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<UserDto>[] = [
        {
            key: "actions",
            render: (text: string, record: UserDto) => (
                <>
                    <FadingTooltip title="Edytuj uprawnienia">
                        <Link to={`/Admin/UserPlacePermisions/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ToolOutlined />} />
                        </Link>
                    </FadingTooltip>
                </>
            ),
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "name",
            title: "Imię",
            filter: "contains",
        },
        {
            dataIndex: "surname",
            key: "surname",
            title: "Nazwisko",
            filter: "contains",
        },
        {
            dataIndex: "login",
            key: "login",
            title: "Login/Email",
            filter: "contains",
        },
        {
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            title: "Telefon",
        },
    ];

    private paginatedTable = createRef<PaginatedTable<UserDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`UŻYTKOWNICY`} />
                <PaginatedTable<UserDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: UserDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new UserClient().getCompanyUsers(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </>
        )
    }
}