import React from "react";
import { FSelect, IFormikInputProps } from "./FormikFormItems";
import { Select } from "antd";

interface IFBooleanSelect {
    nullable?: boolean;
    trueDisplay?: string;
    falseDisplay?: string;
}

export class FBooleanSelect extends React.Component<IFormikInputProps & IFBooleanSelect> {
    constructor(props: IFormikInputProps & IFBooleanSelect) {
        super(props);
    }
    public render() {
        return (
            <FSelect {...this.props} value={this.props.field.value?.toString()} >
                {this.props.nullable !== false &&
                    <Select.Option key={0} value={""}> </Select.Option>
                }
                <Select.Option key={1} value={"true"}>Tak</Select.Option>
                <Select.Option key={2} value={"false"}>Nie</Select.Option>
            </FSelect>
        );
    }
}