import React from "react";
import { Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import { AccountDetailsStore } from "account/AccountDetailsStore";
import { ApplicationBlockDto, CompanyClient } from "services/GeneratedClient";
import { WarningOutlined } from "@ant-design/icons";

interface IProps {
    accountDetailsStore?: AccountDetailsStore;
}

interface IState {
}

/**
 * Wyświetla stan pakietu komunikatów i smsów dla wybranej firmy.
 */
@inject("accountDetailsStore")
@observer
class CompanyLimitsLeft extends React.Component<IProps, IState> {

    isError = false;
    applicationBlockData: ApplicationBlockDto | undefined = undefined;

    constructor(props: IProps) {
        super(props);
    }

    componentDidMount() { }

    componentDidUpdate(prevProps : IProps) { }

    componentWillReceiveProps(props: IProps) {
        if (this.props.accountDetailsStore?.currentCompany) {
            this.loadData();
        }
    }

    loadData() {
        new CompanyClient().getApplicationBlock(this.props.accountDetailsStore?.currentCompany?.id ?? null)
            .then((resp)=>{
                this.applicationBlockData = resp || undefined;
                this.isError = false;
                this.forceUpdate();
            })
            .catch((resp)=>{
                this.isError = true;
                this.forceUpdate();
            });
    }

    private renderError() {
        return <>
            <Tooltip title="Błąd pobierania danych o limitach.">
                <WarningOutlined />
                <span>(?/?)</span>
            </Tooltip>
        </>;
    }

    private getTooltipText(): string {
        return `Pozostała ilość dni ważności konta: ` + this.applicationBlockData?.daysLeft + `
            Data ważności konta: ` + this.applicationBlockData?.expirationDate?.format("YYYY-MM-DD HH:mm:ss") + `

            Ilość pozostałych komunikatów do wysłania: ` + (this.applicationBlockData?.messagesLeft || "-") + `
            Ilość wysłanych komunikatów: ` + this.applicationBlockData?.messageSent + `
            Limit komunikatów do wysłania: ` + (this.applicationBlockData?.messageLimit || "-") + ``
    }

    public render() {
        if (this.isError) {
            return this.renderError()
        }

        if (!this.props.accountDetailsStore?.currentCompany || !this.applicationBlockData) {
            return <></>
        }

        return this.applicationBlockData && <>
            <Tooltip title={this.getTooltipText()} 
                    placement="bottomLeft" overlayStyle={{ whiteSpace: 'pre-line', maxWidth: '500px'}}>
                <span style={{ verticalAlign: "middle" }}>
                    <WarningOutlined />
                    <span>({this.applicationBlockData?.daysLeft}/{(this.applicationBlockData?.messagesLeft || "-")})</span>
                </span>
            </Tooltip>
        </>
    }
}

export default CompanyLimitsLeft;