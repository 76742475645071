import { Col, Select } from "antd";
import { Field } from "formik";
import React from "react";
import { FFieldLabel, FPanel } from "../../forms/FormikFormItems";
import IFormElementConfigs from "./formConfig/IFormElementConfigs";
import IFormConfigs from "./formConfig/IFormConfigs"
import { ColSize } from "antd/lib/grid";

declare type ColSpanType = number | string;

interface LabeledFieldProps {
    name?: string
    labelSm?: ColSpanType | ColSize;
    fieldSm?: ColSpanType | ColSize;
    label: string;
    children?: any;
}

/**
 * Klasa do obsługi dynamicznie wyświetlanych i walidowanych formularzy.
 * Pozwala obsłużyć ukrywanie pól formularza i indywidualną walidację.
 * 
 * Krótki przykład użycia:
 * 
 * interface IProps {
 *      isPreviewMode?: boolean;
 *      isEditMode?: boolean;
 *      isAddMode?: boolean;
 * }
 * 
 * interface IState {
 *      formConfigName?: string;
 *      schema: Yup.ObjectSchema;
 *      formConfigs: SomeClassImlementingIFormConfigsInteface;
 *      formConfig: IFormElementConfigs | null;
 * }
 * 
 * export default class SomeForm extends React.PureComponent<IProps, IState> {
 *      private df: DynamicForm;
 * 
 *      let formConfigs = new SomeClassImlementingIFormConfigsInteface(); 
 *      this.state = {
 *          formConfigs: formConfigs,
 *          schema: formConfigs.getDefaultYupSchema(),
 *          formConfig: formConfigs.getDefault(),
 *      };
 *      this.df = new DynamicForm(this.state.formConfigs, this.props.isPreviewMode);
 * 
 *      private someActionChangingForm() {
 *          this.df.setFormConfigName(someValue);
 *          this.forceUpdate();
 *      }
 *      
 *      private send(values: SomeDto, actions: FormikHelpers<SomeDto>) {
 *          this.df.removeHiddenValues(values, this.state.formConfig!);
 *      }
 * 
 *      public render() {
 *          return (
 *              ...
 *              <this.df.FPanel key='0' header={`???`} name="???">
 *                  <this.df.LabeledField label="???" name="???" fieldSm={12}>
 *                      <this.df.Field ... name="..." />
 *                  </this.df.LabeledField>
 *              </this.df.FPanel>
 *              ...
 *      }
 */
export default class DynamicForm {
    private formConfigs: IFormConfigs;
    private formConfig: IFormElementConfigs;
    private disabled: boolean;

    constructor(formConfigs: IFormConfigs, disabled = false) {
        this.formConfigs = formConfigs;
        this.formConfig = this.formConfigs.getDefault();
        this.disabled = disabled ?? false;
    }

    public setFormConfigName(formName: string | undefined) {
        if (formName) {
            this.formConfig = this.formConfigs.getForForm(formName);
        } else {
            this.formConfig = this.formConfigs.getDefault();
        }
    }

    public removeHiddenValues(values: any, formConfig: IFormElementConfigs) {
        for (let key in formConfig) {
            if (!formConfig[key].isVisible
                && values.hasOwnProperty(key)) {
                values[key] = undefined;
            }
        }
    }

    private isVisible(name: string | undefined) : boolean {
        if (!name) {
            return true;
        } else if (this.formConfig && this.formConfig[name]?.isVisible) {
            return true;
        }
        return false;
    }

    LabeledField = (props: LabeledFieldProps) => {
        if (!this.isVisible(props.name)) return <></>;
        
        const validationSchema = this.formConfig[props.name!].yupSchema;
        const isRequired = validationSchema?.describe().tests.findIndex(({ name }) => name === 'required') !== undefined;

        return <>
            <FFieldLabel sm={props.labelSm} label={props.label} optional={!isRequired} />
            <Col sm={props.fieldSm ?? 6}>
                {props.children}
            </Col></>
    }

    FPanel = ({...props}) => { 
        if (!this.isVisible(props.name)) return <></>
        return <FPanel key={props.key} header={props.header} {...props} /> 
    }
    Field = ({...props}) => { 
        if (!this.isVisible(props.name)) return <></>
        return <Field {...props} disabled={this.disabled || props.disabled} /> 
    }
    Select = ({...props}) => { 
        if (!this.isVisible(props.name)) return <></>
        return <Select {...props} disabled={this.disabled} placeholder="Wybierz" /> 
    }
}