import { UserCompanyModel, CompanyLevelPermission, EwdPlaceLevelPermission } from "../services/GeneratedClient";

export default class PermissionValidator {
    private userCompany: UserCompanyModel;

    constructor(userCompany: UserCompanyModel) {
        this.userCompany = userCompany;
    }

    public hasPlaceLevelPermissionInAnyPlace(permission: EwdPlaceLevelPermission): boolean {
        if (!this.userCompany) return false;

        if (this.userCompany.isAdmin) return true;
        if (!this.userCompany.places) return false;

        if (this.userCompany.places.find(x => x.placeLevelPermissions !== undefined && x.placeLevelPermissions.find(p => p === permission) !== undefined)) {
            return true;
        }

        return false;
    }

    public hasPlaceLevelPermission(permission: EwdPlaceLevelPermission, placeContext: string): boolean {
        if (!this.userCompany) return false;

        if (this.userCompany.places) {
            let place = this.userCompany!.places!.find(x => x.placeName == placeContext);

            if (place && place.placeLevelPermissions && place.placeLevelPermissions!.find(x => x == permission) !== undefined)
                return true;
        }

        return false;
    }

    public hasCompanyLevelPermission(permission: CompanyLevelPermission): boolean {
        if (!this.userCompany) return false;

        if (this.userCompany.companyLevelPermissions) {
            if (this.userCompany.companyLevelPermissions!.find(p => p === permission))
                return true;
        }

        return false;
    }

    public isAdmin(): boolean {
        if (!this.userCompany) return false;

        return this.userCompany.isAdmin;
    }

    public isModuleActive(): boolean {
        if (!this.userCompany) return false;

        return this.userCompany.isModuleActive;
    }

    public hasLicencePermission(permission: string): boolean {
        if (!this.userCompany) return false;

        if (this.userCompany.licencePermissions) {
            if (this.userCompany.licencePermissions!.find(p => p === permission))
                return true;
        }

        return false;
    }

    public hasAnyPlaceLevelPermission(): boolean {
        if (!this.userCompany) return false;

        if (this.userCompany.places) {
            let placeList = this.userCompany.places;
            for (let place of placeList) {
                if (place.placeLevelPermissions && place.placeLevelPermissions.length > 0) {
                    return true;
                }
            }
        };

        return false;
    }
}