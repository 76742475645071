import { Button, Col, Modal, PageHeader, Select } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CnKwaDictionaryValueClient, CnKwaDictionaryValueDto } from "services/GeneratedClient";
import CnKwaDictionaryValueValidation from "./CnKwaDictionaryValueValidation";
import { ajaxByUser, ajaxCatch } from "helper/api";
import PrimaryCard from "layout/PrimaryCard";
import { FCenteredRow, FFieldLabel, FInput } from "forms/FormikFormItems";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
    dictionaryValueId: number;
}

interface IState {
    initialValues: CnKwaDictionaryValueDto;
    isLoading: boolean;
}

export default class CnKwaDictionaryValueEdit extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/CnKwaDictionaries`;
    private validation: CnKwaDictionaryValueValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            initialValues: new CnKwaDictionaryValueDto(),
            isLoading: false,
        };

        this.validation = new CnKwaDictionaryValueValidation();
    }

    public componentDidMount() {
        ajaxCatch(() =>
            new CnKwaDictionaryValueClient().getCnKwaDictionaryValue(this.props.dictionaryValueId),
            (result: CnKwaDictionaryValueDto | null) => {
                this.setState({
                    initialValues: result!
                })
            });
    }

    public send(values: CnKwaDictionaryValueDto, actions: FormikHelpers<CnKwaDictionaryValueDto>) {
        this.setState({ isLoading: true })

        Modal.confirm({
            centered: true,
            maskClosable: true,
            title: `Aktualizacja spowoduje nadpisanie kodu wyrobu towarowego we wszystkich utworzonych już zdarzeniach akcyzowych dla kodu CN: ${values.cnCode}`,
            okText: 'Potwierdź zapis',
            cancelText: 'Anuluj',
            onOk: () => {
                ajaxByUser("Zaktualizowano wartość słownika CN->KWA", () =>
                    new CnKwaDictionaryValueClient().update(values)
                        .then((resp: any) => {
                            actions.setSubmitting(false);
                            this.props.history.push(this.goBackUrl);
                        }).finally(() => this.setState({ isLoading: false })));
            },
            onCancel: () => this.setState({ isLoading: false })
        });
    }

    public render() {
        return (
            <>
                <PageHeader title={`EDYCJA WARTOŚCI SŁOWNIKA CN->KW`} />
                <Formik<CnKwaDictionaryValueDto> initialValues={this.state.initialValues} enableReinitialize
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<CnKwaDictionaryValueDto>) => {
                        return (
                            <PrimaryCard>
                                <FCenteredRow>
                                    <Col md={6} xs={24}>
                                        <FFieldLabel label="Kod CN" sm={24} />
                                        <Field component={FInput} name="cnCode" disabled />
                                    </Col>
                                    <Col md={6} xs={24}>
                                        <FFieldLabel label="Kod KWA" sm={24} />
                                        <Field component={FInput} name="kwaCode" />
                                    </Col>
                                </FCenteredRow>
                                <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                    <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                        this.props.history.push(this.goBackUrl);
                                    }}>Anuluj</Button>
                                    <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                        props.submitForm();
                                    }}>Zapisz</Button>
                                </FCenteredRow>
                            </PrimaryCard>
                        );
                    }}
                </Formik>
            </>
        )
    }
}