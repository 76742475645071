import React from 'react';
import { Checkbox, Col, Select } from 'antd';
import { inject } from 'mobx-react';
import { RangeValue } from 'rc-picker/lib/interface';
import { DatePicker } from 'components/DatePicker';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import moment, { Moment } from 'moment';
import ExciseEnergyRegisterReportList from './ExciseEnergyRegisterReportList';
import { RegisterStore } from 'register/RegisterStore';
import { CompanyLevelPermission, EwdPlaceLevelPermission, ExciseEnergyRegisterClient, ExciseEnergyRegisterDto } from 'services/GeneratedClient';
import { ajaxCatch } from 'helper/api';
import { FFieldLabel, FRow } from 'forms/FormikFormItems';
import EwdCard from 'components/EwdCard';
import { AccountDetailsStore } from 'account/AccountDetailsStore';

interface IProps {
    registerStore?: RegisterStore;
    accountDetailsStore?: AccountDetailsStore;
}

interface IState {
    exciseEnergyRegisterId?: number;
    exciseEnergyRegisters: ExciseEnergyRegisterDto[] | null;
    dateFrom: Moment;
    dateTo: Moment;
    showDaily: boolean;
}

@inject("registerStore")
@inject("accountDetailsStore")
export default class ExciseRegisterReport extends React.PureComponent<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            dateFrom: moment().utc().startOf("month"),
            dateTo: moment().utc().endOf("month"),
            exciseEnergyRegisters: null,
            showDaily: false,
        }
    }

    onChangePlace = (value?: number) => {
        this.setState({
            exciseEnergyRegisterId: undefined
        });

        ajaxCatch(() =>
            new ExciseEnergyRegisterClient().getRegistersForPlace(value ?? null),
            (response: ExciseEnergyRegisterDto[] | null) => {
                this.setState({
                    exciseEnergyRegisters: response
                });
            });
    }

    onChangeRange = (dates: RangeValue<Moment>) => {
        if (dates) {
            this.setState({
                dateFrom: dates![0]!.startOf('day').asUtc(),
                dateTo: dates![1]!.endOf('day').asUtc(),
            });
        }
    }

    onChangeRegister = (value?: number) => {
        this.setState({
            exciseEnergyRegisterId: value
        });
    }

    onChangeShowDaily = () => {
        this.setState({
            showDaily: !this.state.showDaily
        })
    };

    public render() {
        var placeNamesWithReportPermissions = this.props.accountDetailsStore!.currentCompany!.places!
            .filter(x => x.placeLevelPermissions?.some(y => y == EwdPlaceLevelPermission.ExciseEnergyRegisterReportDisplay))
            .map(x => x.placeName!);
        var isAdmin = this.props.accountDetailsStore?.currentCompany?.isAdmin;
        var hasEwdAdminPermission =
            this.props.accountDetailsStore?.currentCompany?.companyLevelPermissions?.includes(CompanyLevelPermission.EwdAdministration);

        return (
            <>
                <PageHeaderReturn title={`RAPORT EWIDENCJI ENERGII ELEKTRYCZNEJ`} />
                <EwdCard>
                    <CenteredRow style={{ marginBottom: "10px" }}>
                        <Col md={10} xs={24}>
                            <FFieldLabel label="Miejsce" />
                            <Select showSearch optionFilterProp="children" onChange={this.onChangePlace} >
                                {this.props.registerStore?.allPlaces
                                    ?.filter(x => isAdmin || hasEwdAdminPermission || placeNamesWithReportPermissions.some(y => y == x.name))
                                    ?.map((r, index) =>
                                        <Select.Option key={index} value={r.id}>{`${r.name} (${r.streetName} ${r.streetNumber} ${r.city})`}</Select.Option>
                                    )}
                            </Select>
                        </Col>
                        <Col md={7} xs={24}>
                            <FFieldLabel label="Ewidencja" />
                            <Select showSearch optionFilterProp="children" onChange={this.onChangeRegister} value={this.state.exciseEnergyRegisterId} >
                                {this.state.exciseEnergyRegisters && this.state.exciseEnergyRegisters.map((r, index) =>
                                    <Select.Option key={index} value={r.id}>{`Id: ${r.id} - ${r.name} (CN: ${r.cnCode})`}</Select.Option>
                                )}
                            </Select>
                        </Col>
                        <Col md={7} xs={24}>
                            <FFieldLabel label="Data od/do" />
                            <DatePicker.RangePicker picker="date" onChange={this.onChangeRange} value={[moment(this.state.dateFrom).utc(), moment(this.state.dateTo).utc()]}
                                ranges={{ 'Dzisiaj': [moment().utc(), moment().utc()], 'Ten miesiąc': [moment().utc().startOf('month'), moment().utc().endOf('month')] }} />
                        </Col>
                    </CenteredRow>
                    <FRow>
                        <Col sm={6} md={6} xs={24}>
                            <Checkbox checked={this.state.showDaily} onChange={this.onChangeShowDaily}>Pokaż wpisy dzienne</Checkbox>
                        </Col>
                    </FRow>
                    <ExciseEnergyRegisterReportList
                        exciseEnergyRegisterId={this.state.exciseEnergyRegisterId}
                        dateFrom={this.state.dateFrom}
                        dateTo={this.state.dateTo}
                        showDaily={this.state.showDaily} />
                </EwdCard>
            </>
        )
    }
};