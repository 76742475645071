import React from 'react';
import { CenteredRow } from 'layout/CenteredRow';
import { MonthPicker } from 'components/DatePicker';
import moment, { Moment } from 'moment';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import CompletedExciseStampEntryList from './CompletedExciseStampEntryList';
import ExciseStampRegisterBasicDetails from 'exciseStamps/exciseStampRegister/details/ExciseStampRegisterBasicDetails';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Authorized } from 'authorization/Authorized';
import { EwdPlaceLevelPermission } from 'services/GeneratedClient';
import CorrectAsNewNavigationButton from './buttons/CorrectAsNewNavigationButton';

interface IProps {
    exciseStampRegisterId: number;
    registerType: string;
    placeContext: string;
}

interface IState {
    dateFrom: Moment;
    dateTo: Moment;
}

export default class CompletedExciseStampEntry extends React.PureComponent<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            dateFrom: moment().startOf("month").asUtc(),
            dateTo: moment().endOf("month").asUtc()
        }
    }

    onChange = (value: Moment | null, dateString: string) => {
        if (value) {
            this.setState({
                dateFrom: moment(value).startOf("month").asUtc(),
                dateTo: moment(value).endOf("month").asUtc()
            })
        }
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`REJESTR WPISÓW EWIDENCJI BANDEROL - ZAMKNIĘTE`} goBack={"/ExciseStampRegisters"} />
                <ExciseStampRegisterBasicDetails exciseStampRegisterId={this.props.exciseStampRegisterId} />
                <CenteredRow><MonthPicker onChange={this.onChange} value={this.state.dateFrom} /></CenteredRow>
                <CompletedExciseStampEntryList
                    exciseStampRegisterId={this.props.exciseStampRegisterId}
                    registerType={this.props.registerType}
                    dateFrom={this.state.dateFrom}
                    dateTo={this.state.dateTo}
                />
                <BottomActionButtons>
                    <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryCorrecting} placeContext={this.props.placeContext}>
                        <CorrectAsNewNavigationButton exciseStampRegisterId={this.props.exciseStampRegisterId} registerType={this.props.registerType} />
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};