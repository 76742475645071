import { Modal } from "antd";
import React from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormChangeStore } from "./FormChangeStore";
import { baseAppUrl } from "helper/api";

interface ILinkWithConfirmationProps extends RouteComponentProps<any> {
    to: string;
    actionOnClick?: () => void;
    formChangeStore?: FormChangeStore;
}

interface IState { }

@inject("formChangeStore")
@observer
class LinkWithConfirmationComponent extends React.PureComponent<ILinkWithConfirmationProps, IState> {
    constructor(props: any) {
        super(props);
    }

    private proceed() {
        this.props.formChangeStore?.setChangeDetectionStatus(false);
        if (this.props.actionOnClick) {
            this.props.actionOnClick();
        }
        else {
            this.props.history.push(this.props.to);
        }
    };

    private onLinkClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        if (this.props.formChangeStore?.formChangeDetected) {
            Modal.confirm({
                centered: true,
                title: "Uwaga!",
                content: "Na formularzu wykryto niezapisane zmiany. Czy na pewno chcesz kontynuować?",
                okText: "Tak",
                onOk: () => this.proceed(),
                cancelText: "Nie",
            });
        }
        else {
            this.proceed();
        }
    };

    public render() {
        return (
            <a href={`${baseAppUrl}${this.props.to}`} onClick={event => this.onLinkClick(event)}>
                {this.props.children}
            </a>
        );
    }
}

export const LinkWithConfirmation = withRouter(LinkWithConfirmationComponent);