import React from "react";
import { Tooltip as AntdTooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";

interface IProps {

}

interface IState {

}

export default class Tooltip extends React.Component<IProps & TooltipProps, IState> {
    public render() {
        return <AntdTooltip placement="right" {...this.props} mouseLeaveDelay={0.0} mouseEnterDelay={0.7}>
            {this.props.children}
        </AntdTooltip>
    }
}