import React from 'react';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import { Button } from 'antd';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Link } from 'react-router-dom';
import { Authorized } from 'authorization/Authorized';
import { EwdPlaceLevelPermission } from 'services/GeneratedClient';
import CurrentExciseStampEntryList from './CurrentExciseStampEntryList';
import ExciseStampRegisterBasicDetails from 'exciseStamps/exciseStampRegister/details/ExciseStampRegisterBasicDetails';

interface IProps {
    exciseStampRegisterId: number;
    placeContext: string;
    registerType: string;
}

export default class CurrentExciseStampEntry extends React.PureComponent<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`REJESTR WPISÓW EWIDENCJI BANDEROL - OTWARTE`} goBack={"/ExciseStampRegisters"} />
                <ExciseStampRegisterBasicDetails exciseStampRegisterId={this.props.exciseStampRegisterId} />
                <CurrentExciseStampEntryList registerType={this.props.registerType} exciseStampRegisterId={this.props.exciseStampRegisterId} placeContext={this.props.placeContext} />
                <BottomActionButtons>
                    <Authorized requiresSubscription placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                        <Link to={`/ExciseStampRegisters/${this.props.exciseStampRegisterId}/${this.props.registerType}/ExciseStampEntries/Add`}>
                            <Button>Dodaj zdarzenie</Button>
                        </Link>
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};