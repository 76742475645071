import React from "react";
import { ImportDataType, ImportFileExtension } from "../services/GeneratedClient";
import ImportFile from "./ImportFile";


export default class CsvImportEnergies extends React.Component<{}> {

    constructor(props: any) {
        super(props);
    }
    public render() {
        return (
            <>
                <ImportFile
                    extension={ImportFileExtension.CSV}
                    dataType={ImportDataType.ExciseEnergy}
                />

            </>
        );
    }
}
