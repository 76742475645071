import { SwaggerException } from "../services/GeneratedClient";
import { showNotExpectedErrorModal } from "../layout/Modals";
import { notification } from "antd";
import { useEffect, useState } from "react";

declare global {
    interface Navigator {
        msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

export const baseAppUrl = (
    document.getElementsByTagName("base")[0] || {}
).href.slice(0, -1);

export const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

export function ajaxByUser<T>(successMsg: string, request: () => Promise<T>, success?: () => void) {
    return request()
        .then(() => {
            notification.success({
                placement: "bottomRight",
                message: successMsg,
            });
        })
        .then(() => { if (success) success(); })
        .catch((resp: SwaggerException) => {
            Promise.reject();
            showNotExpectedErrorModal(resp);
        });
}

export function ajaxByUserWithResponse<T>(successMsg: string, request: () => Promise<T>, success?: (arg0: T) => void, onError?: (resp: SwaggerException) => void) {
    return request()
        .then((arg0: T) => { if (success) success(arg0); return arg0; })
        .then((arg0: T) => {
            notification.success({
                placement: "bottomRight",
                message: successMsg,
            });
            return arg0;
        })
        .catch((resp: SwaggerException) => {
            onError && onError(resp);
            showNotExpectedErrorModal(resp);
        });
}


export function ajaxCatch<T>(request: () => Promise<T>, success?: (arg0: T) => void, onError?: (resp: SwaggerException) => void) {
    return request()
        .then((arg0: T) => { if (success) success(arg0); return arg0; })
        .catch((resp: SwaggerException) => {
            onError && onError(resp);
            showNotExpectedErrorModal(resp);
        });
}


export function ajaxCatchErrorPage<T>(request: () => Promise<T>, history: any, success?: (arg0: T) => void) {
    return request()
        .then((arg0: T) => { if (success) success(arg0); return arg0; })
        .catch((resp: SwaggerException) => {
            history.replace(`/ErrorPage/${resp.status}`);
        });
}

export function useAjaxCatch<T>(request?: () => Promise<T>, success?: (arg0: T) => void, deps?: any[]) {
    let [exception, setException] = useState<SwaggerException | null>(null);

    useEffect(() => {
        if (request) {
            request()
                .then((arg0: T) => { if (success) success(arg0); return arg0; })
                .catch((resp: SwaggerException) => {
                    setException(resp);
                });
        }
    }, deps)

    return exception;
}

export function downloadFile(file: Blob, fileName: string) {
    const fileURL = URL.createObjectURL(file);

    if (window.navigator.msSaveOrOpenBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveOrOpenBlob(file, fileName);
    }
    else {
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    setTimeout(() => {
        //Na potrzeby IE11
        window.URL.revokeObjectURL(fileURL);
    }, 300)
};