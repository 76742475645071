import React, { PropsWithChildren } from 'react';
import { Row, Col, Card as AntCard } from 'antd';
import { CardProps } from 'antd/lib/card';

export const DetailCell: React.FC<PropsWithChildren<{ label: string, value?: string, span?: number }>> = ({ label, value, span = 12, children }) =>
    <>
        <Col>
            {children}</Col>
        <Col span={span}>
            <b>{label}:</b> {value}
        </Col>
    </>

export const cardColor = '#ecf2f5';

export const Card = (props: CardProps) =>
    <AntCard size="small" className="dd-details-card" headStyle={{ background: cardColor }} {...props}>
        {props.children}
    </AntCard>

export const RowWithBottomMargin: React.FC<PropsWithChildren<{ margin?: number }>> = ({ margin = 7, children }) =>
    <Row style={{ marginBottom: margin }}>{children}</Row>