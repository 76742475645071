import { SearchOutlined } from '@ant-design/icons';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuditLogAreaName } from 'services/GeneratedClient';

interface IPros {
    entityId?: number,
    auditLogAreaName: AuditLogAreaName
    gridName?: string,
    recordId?: number
}

interface IState {
    previewLink: string,
}

export default class ExciseStampChangeToEditedButton extends React.PureComponent<IPros, IState> {
    constructor(props: IPros) {
        super(props);

        this.state = {
            previewLink: ""
        }
    }

    componentDidMount() {
        if(this.props.entityId != undefined)
            this.resolveEntityPreviewLink(this.props.auditLogAreaName);
    }

    private resolveEntityPreviewLink(areaName: AuditLogAreaName) {
        switch(areaName) {
            case AuditLogAreaName.ExciseRegister:
                this.setState({previewLink: `/ExciseEntries/Preview/${this.props.entityId}`});
                break;
            case AuditLogAreaName.ExciseStampRegister:
                this.setState({previewLink: `/ExciseStampEntries/Preview/${this.props.entityId}`});
                break;
            case AuditLogAreaName.ExciseEnergyRegister:
                this.setState({previewLink: `/ExciseEnergies/Entries/Preview/${this.props.entityId}`});
                break;
            default:
                this.setState({previewLink: ''});
                break;
        }
    }

    public render() {
        return <>
            {this.props.entityId && this.state.previewLink && <FadingTooltip title="Podgląd" placement="top">
                <Link to={this.state.previewLink}>
                    <TableButton gridName={this.props.gridName} recordId={this.props.recordId} icon={<SearchOutlined />} />
                </Link>
            </FadingTooltip>}
        </>
    }
}