import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer } from 'helper/GridHelper';
import { ActionType, CompanyLevelPermission, CorrectiveExciseEntryDto, EwdPlaceLevelPermission, ExciseEntryClient, ExciseEntryDto, ExciseRegisterStateClient } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from "layout/FadingTooltip";
import { DiffOutlined, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { Moment } from 'moment';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { Authorized } from 'authorization/Authorized';
import { GridStore } from 'layout/table/paginated/GridStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
    exciseRegisterId: number;
    isIncome: boolean;
    isExpenditure: boolean;
    isTransshipment: boolean;
    dateFrom: Moment;
    dateTo: Moment;
}

interface IState {
    gridName: string;
    expandedRowKeys?: React.Key[]
}

interface ExciseEntryColumnProps {
    income: boolean;
    expenditure: boolean;
    transshipment: boolean;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class CompletedExciseEntryList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: this.getGridName(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment),
        }
    }

    componentDidMount() {
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    getGridName = (isIncome: boolean, isExpenditure: boolean, isTransshipment: boolean) => `completedExcise${isIncome ? "Income" : ""}${isExpenditure ? "Expenditure" : ""}${isTransshipment ? "Transshipment" : ""}EntriesList`

    expandableConfig(): ExpandableConfig<ExciseEntryDto> {
        return {
            rowExpandable: (record: ExciseEntryDto) => record.correctedEntries?.length !== 0,
            expandedRowRender: (record: ExciseEntryDto) => {
                const columns: (ExciseEntryColumnProps & EwdColumnProps<CorrectiveExciseEntryDto>)[] = [
                    {
                        key: "actions",
                        render: (text: string, record: CorrectiveExciseEntryDto) => (
                            <>
                                {record.id && <>
                                    <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay}
                                        placeContext={record.placeContext}>
                                        <FadingTooltip title="Podgląd" placement="top">
                                            <Link to={`/ExciseEntries/Preview/${record.id}`} >
                                                <TableButton gridName={gridName} recordId={record.id} icon={<SearchOutlined />} />
                                            </Link>
                                        </FadingTooltip>
                                    </Authorized>
                                </>}
                            </>
                        ),
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "id",
                        key: "Id",
                        title: "Id",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "date",
                        key: "Date",
                        title: "Data",
                        render: (text: any, record: CorrectiveExciseEntryDto) => dateRenderer(record.date),
                        income: true,
                        expenditure: true,
                        transshipment: true,
                        width: 90,
                    },
                    {
                        dataIndex: "correctionOrdinalNumber",
                        key: "CorrectionOrdinalNumber",
                        title: "Lp. Korekty",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "supplierName",
                        key: "SupplierName",
                        title: "Dostawca",
                        income: true,
                        expenditure: false,
                        transshipment: false,
                    },
                    {
                        dataIndex: "recipientName",
                        key: "RecipientName",
                        title: "Odbiorca",
                        income: false,
                        expenditure: true,
                        transshipment: false,
                    },
                    {
                        dataIndex: "ownerName",
                        key: "OwnerName",
                        title: "Właściciel",
                        income: false,
                        expenditure: false,
                        transshipment: true,
                    },
                    {
                        dataIndex: "documentNumber",
                        key: "DocumentNumber",
                        title: "Nr dokumentu",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "productNumber",
                        key: "ProductNumber",
                        title: "Nr pozycji dokumentu",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "invoiceNumber",
                        key: "InvoiceNumber",
                        title: "Nr faktury",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "exciseProductCode",
                        key: "ExciseProductCode",
                        title: "Kod wyrobu akcyzowego",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "cnCode",
                        key: "CnCode",
                        title: "Kod CN",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "productName",
                        key: "ProductName",
                        title: "Nazwa towaru",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "incomeInExciseUnit",
                        key: "IncomeInExciseUnit",
                        title: "Przychód w jednostce rozliczenia akcyzy",
                        income: true,
                        expenditure: false,
                        transshipment: false,
                    },
                    {
                        dataIndex: "incomeInInventoryUnit",
                        key: "IncomeInInventoryUnit",
                        title: "Przychód w jednostce technicznej",
                        income: true,
                        expenditure: false,
                        transshipment: false,
                    },
                    {
                        dataIndex: "expenditureInExciseUnit",
                        key: "ExpenditureInExciseUnit",
                        title: "Rozchód w jednostce rozliczenia akcyzy",
                        income: false,
                        expenditure: true,
                        transshipment: false,
                    },
                    {
                        dataIndex: "expenditureInInventoryUnit",
                        key: "ExpenditureInInventoryUnit",
                        title: "Rozchód w jednostce technicznej",
                        income: false,
                        expenditure: true,
                        transshipment: false,
                    },
                    {
                        dataIndex: "amountTransshipmentInExciseUnit",
                        key: "AmountTransshipmentInExciseUnit",
                        title: "Ilość przeładowana w jednostce rozliczenia akcyzy",
                        income: false,
                        expenditure: false,
                        transshipment: true,
                    },
                    {
                        dataIndex: "amountTransshipmentInTechnicalUnit",
                        key: "AmountTransshipmentInTechnicalUnit",
                        title: "Ilość przeładowana w jednostce technicznej",
                        income: false,
                        expenditure: false,
                        transshipment: true,
                    },
                    {
                        dataIndex: "unitConverter",
                        key: "UnitConverter",
                        title: "Przelicznik jednostek",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                    {
                        dataIndex: "storedAmount",
                        key: "StoredAmount",
                        title: "Ilość magazynowana (stan po Zdarzeniu)",
                        income: true,
                        expenditure: true,
                        transshipment: false,
                    },
                    {
                        dataIndex: "methodOfConsumptionOfGoods",
                        key: "MethodOfConsumptionOfGoods",
                        title: "Sposób zużycia wyrobów akcyzowych",
                        income: false,
                        expenditure: true,
                        transshipment: false,
                    },
                    {
                        dataIndex: "methodOfProcessingOfGoods",
                        key: "MethodOfProcessingOfGoods",
                        title: "Sposób przetworzenia",
                        income: false,
                        expenditure: true,
                        transshipment: false,
                    },
                    {
                        dataIndex: "exciseAmount",
                        key: "ExciseAmount",
                        title: "Kwota akcyzy",
                        income: true,
                        expenditure: true,
                        transshipment: false,
                    },
                    {
                        dataIndex: "correctionReason",
                        key: "CorrectionReason",
                        title: "Przyczyna korekty",
                        income: true,
                        expenditure: true,
                        transshipment: true,
                    },
                ]
                const data: CorrectiveExciseEntryDto[] | undefined = record.correctedEntries;
                const gridName = `corrective_${this.getGridName(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment)}`;
                return <PaginatedTable<CorrectiveExciseEntryDto>
                    gridName={gridName}
                    pagination={false}
                    columns={columns.filter(x => (this.props.isIncome && x.income) || (this.props.isExpenditure && x.expenditure) || (this.props.isTransshipment && x.transshipment))}
                    columnsSelector={true}
                    dataSource={data}
                    getRowKey={(r: CorrectiveExciseEntryDto) => r.id!.toString()}
                    getPagedResult={() => new Promise((resolve, reject) => { resolve({ results: data }) })}
                />
            },
            expandedRowKeys: this.state.expandedRowKeys,
            expandIconColumnIndex: -1
        }
    };
    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        const gridName = this.getGridName(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment);
        if (gridName != prevState.gridName) {
            this.setState({ gridName });
        }
        if (prevProps.dateFrom !== this.props.dateFrom || prevProps.dateTo !== this.props.dateTo) {
            this.paginatedTable.current?.refresh();
        }
    }

    private columns: (ExciseEntryColumnProps & EwdColumnProps<ExciseEntryDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseEntryDto) => (
                <>
                    {record.id && <>
                        <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay}
                            placeContext={record.placeContext}>
                            <FadingTooltip title="Podgląd" placement="top">
                                <Link to={`/ExciseEntries/Preview/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized></>}
                    {record.actions?.includes(ActionType.Correcting) && !record.isSupplyEntry &&
                        <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting} placeContext={record.placeContext}>
                            <FadingTooltip title="Korekta" placement="top">
                                <Link to={`/ExciseRegisters/${this.props.exciseRegisterId}/ExciseEntries/Correct/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<DiffOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>}
                    {record.correctedEntries?.length !== 0 && <>
                        {this.state.expandedRowKeys && this.state.expandedRowKeys.includes(record.id!.toString())
                            ? <FadingTooltip title="Ukryj korekty">
                                <TableButton type="default" icon={<DownOutlined />} onClick={() => {
                                    const remove = this.state.expandedRowKeys!.indexOf(record.id!.toString());
                                    this.setState({
                                        expandedRowKeys: (this.state.expandedRowKeys || []).filter((_, i) => i !== remove)
                                    })
                                }}
                                />
                            </FadingTooltip>
                            : <FadingTooltip title="Pokaż korekty">
                                <TableButton type="default" icon={<RightOutlined />} onClick={() => {
                                    this.setState({
                                        expandedRowKeys: [...(this.state.expandedRowKeys || []), record.id!.toString()]
                                    })
                                }}
                                />
                            </FadingTooltip>
                        }
                    </>}
                </>
            ),
            title: "Czynności",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "equals",
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (text: any, record: ExciseEntryDto) => dateRenderer(record.date),
            income: true,
            expenditure: true,
            transshipment: true,
            width: 90,
            filter: "dateRange",
        },
        {
            dataIndex: "ordinalNumber",
            key: "OrdinalNumber",
            title: "Lp.",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "equals",
        },
        {
            dataIndex: "correctionOrdinalNumber",
            key: "CorrectionOrdinalNumber",
            title: "Lp. Korekty",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "equals",
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            title: "Paragraf",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "supplierName",
            key: "SupplierName",
            title: "Dostawca",
            income: true,
            expenditure: false,
            transshipment: false,
            filter: "contains",
        },
        {
            dataIndex: "recipientName",
            key: "RecipientName",
            title: "Odbiorca",
            income: false,
            expenditure: true,
            transshipment: false,
            filter: "contains",
        },
        {
            dataIndex: "ownerName",
            key: "OwnerName",
            title: "Właściciel",
            income: false,
            expenditure: false,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "documentNumber",
            key: "DocumentNumber",
            title: "Nr dokumentu",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "productNumber",
            key: "ProductNumber",
            title: "Nr pozycji dokumentu",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "equals",
        },
        {
            dataIndex: "invoiceNumber",
            key: "InvoiceNumber",
            title: "Nr faktury",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "exciseProductCode",
            key: "ExciseProductCode",
            title: "Kod wyrobu akcyzowego",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "productName",
            key: "ProductName",
            title: "Nazwa towaru",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
            income: true,
            expenditure: false,
            transshipment: false,
        },
        {
            dataIndex: "incomeInInventoryUnit",
            key: "IncomeInInventoryUnit",
            title: "Przychód w jednostce technicznej",
            income: true,
            expenditure: false,
            transshipment: false,
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "expenditureInInventoryUnit",
            key: "ExpenditureInInventoryUnit",
            title: "Rozchód w jednostce technicznej",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "amountTransshipmentInExciseUnit",
            key: "AmountTransshipmentInExciseUnit",
            title: "Ilość przeładowana w jednostce rozliczenia akcyzy",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "amountTransshipmentInTechnicalUnit",
            key: "AmountTransshipmentInTechnicalUnit",
            title: "Ilość przeładowana w jednostce technicznej",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "unitConverter",
            key: "UnitConverter",
            title: "Przelicznik jednostek",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "storedAmount",
            key: "StoredAmount",
            title: "Ilość magazynowana (stan po Zdarzeniu)",
            income: true,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "methodOfConsumptionOfGoods",
            key: "MethodOfConsumptionOfGoods",
            title: "Sposób zużycia wyrobów akcyzowych",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "methodOfProcessingOfGoods",
            key: "MethodOfProcessingOfGoods",
            title: "Sposób przetworzenia",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "exciseAmount",
            key: "ExciseAmount",
            title: "Kwota akcyzy",
            income: true,
            expenditure: true,
            transshipment: false,
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseEntryDto>>();
    public render() {
        return (
            <>
                <PaginatedTable<ExciseEntryDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns.filter(x => (this.props.isIncome && x.income) || (this.props.isExpenditure && x.expenditure) || (this.props.isTransshipment && x.transshipment))}
                    columnsSelector={true}
                    expandable={this.expandableConfig()}
                    // klaskows 29.03.22 Do weryfikacji, czy potrzebne, jeśli nie, to do usunięcia (razem z zapytaniami), jeśli tak, to do poprawy
                    // topSummary={this.props.isIncome && this.props.isExpenditure && this.props.isTransshipment
                    //     ? { label: "Wiersz otwarcia", getData: () => { return new ExciseRegisterStateClient().getInitialState(this.props.exciseRegisterId, this.props.dateFrom) } }
                    //     : undefined}
                    // botSummary={this.props.isIncome && this.props.isExpenditure && this.props.isTransshipment
                    //     ? { label: "Podsumowanie", getData: () => { return new ExciseRegisterStateClient().getSummarizingState(this.props.exciseRegisterId, this.props.dateFrom, this.props.dateTo) } }
                    //     : undefined}
                    getRowKey={(r: ExciseEntryDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseEntryClient().getClosed(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment, this.props.exciseRegisterId, this.props.dateFrom, this.props.dateTo, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }}
                    scroll={{ y: 'calc(100vh - 550px)' }}
                />
            </>
        )
    }
};
