import { IFormConfig } from "./IFormConfig";
import { Ua138h_u5_1_1_FormConfig } from "./paragraphs/Ua138h_u5_1_1_FormConfig";
import { Ua138h_u5_1_2_FormConfig } from "./paragraphs/Ua138h_u5_1_2_FormConfig";
import { Ua138h_u5_1_3_FormConfig } from "./paragraphs/Ua138h_u5_1_3_FormConfig";
import { Ua138h_u5_1_4_FormConfig } from "./paragraphs/Ua138h_u5_1_4_FormConfig";
import { Ua138h_u5_2_FormConfig } from "./paragraphs/Ua138h_u5_2_FormConfig";
import { Ua138h_u5_3_FormConfig } from "./paragraphs/Ua138h_u5_3_FormConfig";
import { Ua138h_u5_4_1_FormConfig } from "./paragraphs/Ua138h_u5_4_1_FormConfig";
import { Ua138h_u5_4_2_FormConfig } from "./paragraphs/Ua138h_u5_4_2_FormConfig";
import { Ua138h_u5_5_FormConfig } from "./paragraphs/Ua138h_u5_5_FormConfig";
import { Ua138h_u5_6_FormConfig } from "./paragraphs/Ua138h_u5_6_FormConfig";
import { Ua138h_u5_7_FormConfig } from "./paragraphs/Ua138h_u5_7_FormConfig";
import IFormElementConfigs from "./IFormElementConfigs";
import DefaultFormConfig from "./paragraphs/DefaultFormConfig";
import IFormConfigs from "./IFormConfigs";
import * as Yup from "yup";
import { Moment } from "moment";

/**
 * Lista wszystkich konfiguracji formularza edycji wpisu ewidencji energetycznej.
 * Lista jest zależna od wybranego paragrafu.
 * W przypadku tworzenia innego formularza na tej podstawie część metod chyba trzeba wynieść do klasy bazowej.
 */

 interface IExciseEnergyEntryFormValidationOptionalProps
 {
    firstClosedDay: Moment | undefined,
    lastClosedDay: Moment | undefined
 }

export default class ExciseEnergyEntryFormConfigs implements IFormConfigs
{
    private configs: IFormConfig = {};

    constructor() 
    {
        this.configs["UA138h.U5.1.1"] = new Ua138h_u5_1_1_FormConfig().get();
        this.configs["UA138h.U5.1.2"] = new Ua138h_u5_1_2_FormConfig().get();
        this.configs["UA138h.U5.1.3"] = new Ua138h_u5_1_3_FormConfig().get();
        this.configs["UA138h.U5.1.4"] = new Ua138h_u5_1_4_FormConfig().get();
        this.configs["UA138h.U5.2"] = new Ua138h_u5_2_FormConfig().get();
        this.configs["UA138h.U5.3"] = new Ua138h_u5_3_FormConfig().get();
        this.configs["UA138h.U5.4.1"] = new Ua138h_u5_4_1_FormConfig().get();
        this.configs["UA138h.U5.4.2"] = new Ua138h_u5_4_2_FormConfig().get();
        this.configs["UA138h.U5.5"] = new Ua138h_u5_5_FormConfig().get();
        this.configs["UA138h.U5.6"] = new Ua138h_u5_6_FormConfig().get();
        this.configs["UA138h.U5.7"] = new Ua138h_u5_7_FormConfig().get();
    }

    public getDefault(): IFormElementConfigs {
        return new DefaultFormConfig().get();
    }

    public getForForm(paragraph: string): IFormElementConfigs
    {
        return this.configs[paragraph] ?? this.getDefault();
    }

    public getDefaultYupSchema(): Yup.ObjectSchema<object> {
        let configs = this.getDefault();
        return this.convertConfigsToYupSchema(configs);
    }

    public getYupSchema(paragraph: string | undefined, optionalDateProps?: IExciseEnergyEntryFormValidationOptionalProps): Yup.ObjectSchema<object> {
        if (!paragraph) {
            return this.getDefaultYupSchema();
        }
        let configs = this.getForForm(paragraph);
        configs["date"].setYupSchemaDate(optionalDateProps);
        return this.convertConfigsToYupSchema(configs);
    }

    private convertConfigsToYupSchema(configs: IFormElementConfigs): Yup.ObjectSchema<object> {
        var fields:any = {};
        for (let key in configs) {
            if (configs[key].yupSchema) {
                fields[key] = configs[key].yupSchema;
            }
        }

        return Yup.object().shape({ ...fields });
    }
}