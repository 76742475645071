import React, { createRef } from 'react';
import { Checkbox, Col, Collapse, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { RangeValue } from 'rc-picker/lib/interface';
import { DatePicker } from 'components/DatePicker';
import Tabs, { TabPane } from 'layout/tabs/Tabs'
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import moment, { Moment } from 'moment';
import ExciseRegisterReportList from './ExciseRegisterReportList';
import { RegisterStore } from 'register/RegisterStore';
import { CompanyLevelPermission, EwdPlaceLevelPermission, ExciseRegisterClient, ExciseRegisterDto, GridSettingsDto } from 'services/GeneratedClient';
import { ajaxCatch } from 'helper/api';
import { FFieldLabel, FRow } from 'forms/FormikFormItems';
import EwdCard from 'components/EwdCard';
import { AccountDetailsStore } from 'account/AccountDetailsStore';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import IReportTemplateData from './exciseRegisterReportTemplates/IReportTemplateData';
import ExciseRegisterReportTemplatePanel from './exciseRegisterReportTemplates/ExciseRegisterReportTemplatePanel';
import getExciseRegisterReportGridName from './utils/getExciseRegisterReportGridName';
import getExciseRegisterReportColumns from './utils/getExciseRegisterReportColumns';
import { CenteredSpin } from 'layout/CenteredSpin';
import { ITemplateComponent } from './exciseRegisterReportTemplates/ITemplateComponent';

interface IProps {
    registerStore?: RegisterStore;
    accountDetailsStore?: AccountDetailsStore;
    dictionaryStore?: DictionaryStore;
}

interface IState extends ITemplateComponent {
    exciseRegisterId?: number;
    exciseRegisters: ExciseRegisterDto[] | null;
    dateFrom: Moment;
    dateTo: Moment;
    showContainers: boolean;
    hasRegisterControlOfNegativeStates: boolean;
    selectedReportType: string;
}

type OptionValue = 'all' | 'income' | 'expenditure';

interface IOption {
    label: string;
    isIncome?: boolean;
    isExpenditure?: boolean;
    value: OptionValue;
}

@inject("registerStore")
@inject("accountDetailsStore")
@inject("dictionaryStore")
@observer
export default class ExciseRegisterReport extends React.PureComponent<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            dateFrom: moment().utc().startOf("month"),
            dateTo: moment().utc().endOf("month"),
            exciseRegisters: null,
            showContainers: false,
            hasRegisterControlOfNegativeStates: true,
            selectedReportType: "all",
            applyingTemplate: false,
            templateApplied: false,
        }
    }

    private options: (IOption)[] = [
        { label: 'Przychód', value: 'income', isIncome: true },
        { label: 'Rozchód', value: 'expenditure', isExpenditure: true },
        { label: 'Wszystko', value: 'all', isIncome: true, isExpenditure: true }
    ]

    onChangePlace = (value?: number) => {
        this.setState({
            exciseRegisterId: undefined,
            showContainers: false,
            selectedReportType: "all",
            templateApplied: false,
        });

        ajaxCatch(() =>
            new ExciseRegisterClient().getRegistersForPlace(value ?? null),
            (response: ExciseRegisterDto[] | null) => {
                this.setState({
                    exciseRegisters: response
                });
            });
    }

    onChangeRange = (dates: RangeValue<Moment>) => {
        if (dates) {
            this.setState({
                dateFrom: dates![0]!.startOf('day').asUtc(),
                dateTo: dates![1]!.endOf('day').asUtc(),
            });
        }
    }

    onChangeRegister = (value?: number) => {
        this.setState({
            exciseRegisterId: value,
            showContainers: false,
            selectedReportType: "all",
            templateApplied: false,
        });
        this.hasControlOfNegativeStates(value);
    }

    onChangeContainer = () => {
        this.setState({
            showContainers: !this.state.showContainers
        })
    };

    onTabChange = (key: string) => {
        this.setState({
            selectedReportType: key,
            templateApplied: false,
        });
    };

    hasControlOfNegativeStates = (registerId?: number) => {
        registerId && ajaxCatch(() =>
            new ExciseRegisterClient().hasRegisterControlOfNegativeStates(registerId),
            (response: boolean) => {
                this.setState({
                    hasRegisterControlOfNegativeStates: response
                });
            });
    }

    getVisibleColumnKeys = () => {
        if (!this.props.accountDetailsStore || !this.props.accountDetailsStore.gridSettings) {
            return [];
        }

        const selectedOption = this.options.filter(opt => opt.value == this.state.selectedReportType)[0];
        const gridName = getExciseRegisterReportGridName(selectedOption.isIncome ?? false, selectedOption.isExpenditure ?? false, this.state.templateApplied);
        const gridSetting = this.props.accountDetailsStore.gridSettings[gridName];

        return gridSetting?.visibleColumnKeys ?? getExciseRegisterReportColumns({
            hasControlOfNegativeStates: this.state.hasRegisterControlOfNegativeStates
        }).map(column => column.key!.toString());
    };

    applyReportTemplate = (reportTemplateData: IReportTemplateData) => {
        if (!this.props.accountDetailsStore || !this.props.accountDetailsStore.gridSettings) {
            return;
        }

        this.setState({ applyingTemplate: true });

        this.modifyGrid(reportTemplateData)
            .finally(() => this.setState({
                selectedReportType: reportTemplateData.reportType.toLowerCase(),
                showContainers: reportTemplateData.includeContainers,
                applyingTemplate: false,
                templateApplied: true,
            }));
    };

    modifyGrid = (reportTemplateData: IReportTemplateData) => new Promise<void>((resolve, _) => {
        const selectedOption = this.options.filter(opt => opt.value == reportTemplateData.reportType.toLowerCase())[0];
        const gridName = getExciseRegisterReportGridName(selectedOption.isIncome ?? false, selectedOption.isExpenditure ?? false, true);
        this.props.accountDetailsStore!.forceVisibleColumnsChange(gridName, reportTemplateData.visibleColumnKeys!);
        resolve();
    });


    public render() {
        var placeNamesWithReportPermissions = this.props.accountDetailsStore!.currentCompany!.places!
            .filter(x => x.placeLevelPermissions?.some(y => y == EwdPlaceLevelPermission.ExciseRegisterReportDisplay))
            .map(x => x.placeName!);
        var isAdmin = this.props.accountDetailsStore?.currentCompany?.isAdmin;
        var hasEwdAdminPermission =
            this.props.accountDetailsStore?.currentCompany?.companyLevelPermissions?.includes(CompanyLevelPermission.EwdAdministration);

        return (
            <>
                <PageHeaderReturn title={`RAPORT EWIDENCJI AKCYZOWEJ`} />
                <EwdCard>
                    <CenteredRow style={{ marginBottom: "10px" }}>
                        <Col md={10} xs={24}>
                            <FFieldLabel label="Miejsce" />
                            <Select showSearch optionFilterProp="children" onChange={this.onChangePlace} >
                                {this.props.registerStore?.allPlaces
                                    ?.filter(x => isAdmin || hasEwdAdminPermission || placeNamesWithReportPermissions.some(y => y == x.name))
                                    ?.map((r, index) =>
                                        <Select.Option key={index} value={r.id}>{`${r.name} (${r.streetName} ${r.streetNumber} ${r.city})`}</Select.Option>
                                    )}
                            </Select>
                        </Col>
                        <Col md={7} xs={24}>
                            <FFieldLabel label="Ewidencja" />
                            <Select showSearch optionFilterProp="children" onChange={this.onChangeRegister} value={this.state.exciseRegisterId} >
                                {this.state.exciseRegisters && this.state.exciseRegisters.map((r, index) =>
                                    <Select.Option key={index} value={r.id}>{`Id: ${r.id} - ${r.name} (CN: ${r.cnCode})`}</Select.Option>
                                )}
                            </Select>
                        </Col>
                        <Col md={7} xs={24}>
                            <FFieldLabel label="Data od/do" />
                            <DatePicker.RangePicker picker="date" onChange={this.onChangeRange} value={[moment(this.state.dateFrom).utc(), moment(this.state.dateTo).utc()]}
                                ranges={{ 'Dzisiaj': [moment().utc(), moment().utc()], 'Ten miesiąc': [moment().utc().startOf('month'), moment().utc().endOf('month')] }} />
                        </Col>
                    </CenteredRow>
                    <ExciseRegisterReportTemplatePanel
                        registerId={this.state.exciseRegisterId}
                        includeContainers={this.state.showContainers}
                        reportType={this.state.selectedReportType}
                        applyingTemplate={this.state.applyingTemplate}
                        getVisibleColumnKeys={this.getVisibleColumnKeys}
                        applyReportTemplate={this.applyReportTemplate} />
                    <FRow>
                        <Col sm={6} md={6} xs={24}>
                            <Checkbox checked={this.state.showContainers} onChange={this.onChangeContainer}>Podział na zbiorniki</Checkbox>
                        </Col>
                    </FRow>
                    {this.state.applyingTemplate ? <CenteredSpin /> : <Tabs type="card"
                        defaultActiveKey={this.state.selectedReportType}
                        activeKey={this.state.selectedReportType}
                        onTabClick={this.onTabChange}>
                        {this.options.map(option => {
                            return <TabPane tab={option.label} key={option.value} >
                                <ExciseRegisterReportList
                                    exciseRegisterId={this.state.exciseRegisterId}
                                    isIncome={option.isIncome || false}
                                    isExpenditure={option.isExpenditure || false}
                                    dateFrom={this.state.dateFrom}
                                    dateTo={this.state.dateTo}
                                    showContainers={this.state.showContainers}
                                    hasControlOfNegativeStates={this.state.hasRegisterControlOfNegativeStates}
                                    templateApplied={this.state.templateApplied} />
                            </TabPane>
                        })}
                    </Tabs>}
                </EwdCard>
            </>
        )
    }
};