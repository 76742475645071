import React from "react";

export default class UnathorizedView extends React.Component {
    public render() {
        return (
            <div>
                <div className="row">
                    <div className="page-title">Brak uprawnień</div>
                </div>
                <div className="row">
                    <span>Nie posiadasz wystarczających uprawnień</span>
                </div>
            </div>
        );
    }
}
