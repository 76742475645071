import { ImportMessageType, } from '../../services/GeneratedClient';

export const mappingsInfo = new Map<ImportMessageType, string>([
	[ImportMessageType.Error, 'Dane nie zostały zaimportowane'],
	[ImportMessageType.Success, 'Import zakończony pomyślnie'],
	[ImportMessageType.Warning, 'Dane zostały zaimportowane z komunikatami'],
	[ImportMessageType.InProgress, 'Dane w trakcie przetwarzania']
]);

export const map = (value: ImportMessageType): string =>
	mappingsInfo.get(value) ?? '';