import { AccountDetailsStore } from "account/AccountDetailsStore";
import { AlertsStore } from "alerts/AlertsStore";
import { Alert, Button, Collapse } from "antd";
import { Authorized } from "authorization/Authorized";
import { dateToString } from "helper/GridHelper";
import { GridStore } from "layout/table/paginated/GridStore";
import { RegisterStore } from "register/RegisterStore";
import { inject, observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { EwdPlaceLevelPermission } from "services/GeneratedClient";
import PermissionValidator from "../authorization/PermissionValidator";

const { Panel } = Collapse;

interface IProps {
    accountDetailsStore?: AccountDetailsStore;
    alertsStore?: AlertsStore;
    gridStore?: GridStore;
    registerStore?: RegisterStore;
}

interface IState {
    loaded: boolean;
}

@inject("accountDetailsStore")
@inject("alertsStore")
@inject("gridStore")
@inject("registerStore")
@observer
class AlertsComponent extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loaded: false
        };
    }

    public componentDidMount() {
        this.fetchAlerts()
    }

    componentDidUpdate() {
        if (this.props.accountDetailsStore!.currentCompany?.id !== this.props.registerStore!.companyId) {
            if (this.state.loaded) {
                this.setState({ loaded: false });
                this.fetchAlerts()
            }
        }
    }

    private fetchAlerts() {
        this.props.registerStore!.setCompany(this.props.accountDetailsStore?.currentCompany?.id);
        let userCompany = this.props.accountDetailsStore!.currentCompany;
        let permissionValidator = new PermissionValidator(userCompany!);

        if (permissionValidator.hasPlaceLevelPermissionInAnyPlace(EwdPlaceLevelPermission.ExciseRegisterDayClosing)) {
            this.props.alertsStore?.fetchAndFilterCloseDayReminder();
        }
        if (permissionValidator.hasPlaceLevelPermissionInAnyPlace(EwdPlaceLevelPermission.ExciseStampRegisterDayClosing)) {
            this.props.alertsStore?.fetchAndFilterCloseDayStampReminder();
        }
        if (permissionValidator.hasPlaceLevelPermissionInAnyPlace(EwdPlaceLevelPermission.ExciseEnergyRegisterDayClosing)) {
            this.props.alertsStore?.fetchAndFilterCloseDayEnergyReminder();
        }

        this.props.alertsStore?.fetchAndFilterAnnouncements();

        this.setState({
            loaded: true
        });
    }

    setCloseDayReminderAlert(alertId: number, alertDate: Moment) {
        const alertKey = `${alertId}_${dateToString(alertDate)}`;
        let closedAlerts = sessionStorage.getItem('closeDayAlerts');
        if (closedAlerts) {
            closedAlerts += `;${alertKey}`
            sessionStorage.setItem('closeDayAlerts', closedAlerts)
        } else {
            sessionStorage.setItem('closeDayAlerts', alertKey)
        }

        const data = this.props.alertsStore!.closeDayAlerts!.filter(a => a.exciseRegisterId !== alertId)
        this.props.alertsStore?.setCloseDayReminder(data);
    }

    setCloseDayStampReminderAlert(alertId: number, alertDate: Moment) {
        const alertKey = `${alertId}_${dateToString(alertDate)}`;
        let closedStampAlerts = sessionStorage.getItem('closeDayStampAlerts');
        if (closedStampAlerts) {
            closedStampAlerts += `;${alertKey}`
            sessionStorage.setItem('closeDayStampAlerts', closedStampAlerts)
        } else {
            sessionStorage.setItem('closeDayStampAlerts', alertKey)
        }

        const data = this.props.alertsStore!.closeDayStampAlerts!.filter(a => a.exciseRegisterId !== alertId)
        this.props.alertsStore?.setCloseDayStampReminder(data);
    }

    setCloseDayEnergyReminderAlert(alertId: number, alertDate: Moment) {
        const alertKey = `${alertId}_${dateToString(alertDate)}`;
        let closedEnergyAlerts = sessionStorage.getItem('closeDayEnergyAlerts');
        if (closedEnergyAlerts) {
            closedEnergyAlerts += `;${alertKey}`
            sessionStorage.setItem('closeDayEnergyAlerts', closedEnergyAlerts)
        } else {
            sessionStorage.setItem('closeDayEnergyAlerts', alertKey)
        }

        const data = this.props.alertsStore!.closeDayEnergyAlerts!.filter(a => a.exciseRegisterId !== alertId)
        this.props.alertsStore?.setCloseDayEnergyReminder(data);
    }

    setCloseOtherAlerts(alertKey: number) {
        let closedAlerts = sessionStorage.getItem('ewd-announcements');
        if (closedAlerts) {
            closedAlerts += `;${alertKey}`
            sessionStorage.setItem('ewd-announcements', closedAlerts)
        } else {
            sessionStorage.setItem('ewd-announcements', alertKey.toString())
        }

        const data = this.props.alertsStore!.announcements!.filter(a => a.id !== alertKey)
        this.props.alertsStore?.setAnnouncements(data);
    }

    getFirstUnclosedDate = (lastClosedDate: Moment) => {
        return lastClosedDate.clone().add(1, 'days').format();
    }

    setIsReloading = () => {
        this.props.gridStore?.saveIsReloading(true);
    }

    render() {
        return <>
            {((this.props.alertsStore?.closeDayAlerts && this.props.alertsStore?.closeDayAlerts.length > 0) ||
                (this.props.alertsStore?.announcements && this.props.alertsStore?.announcements.length > 0) ||
                (this.props.alertsStore?.closeDayStampAlerts && this.props.alertsStore?.closeDayStampAlerts.length > 0) ||
                (this.props.alertsStore?.closeDayEnergyAlerts && this.props.alertsStore?.closeDayEnergyAlerts.length > 0) ||
                (this.props.accountDetailsStore?.currentCompany && !this.props.accountDetailsStore.currentCompany.isModuleActive)) &&
                <Collapse defaultActiveKey={['0']} className="alerts-container">
                    <Panel key='0' header={`Powiadomienia`} >
                        {this.props.accountDetailsStore?.currentCompany && !this.props.accountDetailsStore.currentCompany.isModuleActive &&
                            <Alert key="moduleNotActive" type="error" showIcon
                                description={`Firmie o nazwie ${this.props.accountDetailsStore?.currentCompany!.name} zakończył się okres użytkowania lub wyczerpał się limit komunikatów.`}
                                message="Firma została dezaktywowana"
                            />
                        }
                        {this.props.alertsStore?.closeDayAlerts?.map((o, i) => (
                            <Authorized
                                key={`reminder_${o.exciseRegisterId}`}
                                placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterDayClosing}
                                placeContext={o.exciseRegisterPlaceName}
                            >
                                <Alert closable onClose={() => { this.setCloseDayReminderAlert(o.exciseRegisterId, o.lastClosedDay) }} type="warning" showIcon
                                    message={`Przypomnienie o konieczności zamknięcia dnia dla ewidencji akcyzowej o Id rejestru ${o.exciseRegisterId}, kod CN ${o.cnCode}, ostatni zamknięty dzień ${dateToString(o.lastClosedDay)}.`}
                                    action={
                                        <Link to={{
                                            pathname: `/ExciseRegisters/Details/${o.exciseRegisterId}`,
                                            state: { firstUnclosedDate: this.getFirstUnclosedDate(o.lastClosedDay) }
                                        }}>
                                            <Button size="small" type="link" onClick={this.setIsReloading}>
                                                ZOBACZ
                                            </Button>
                                        </Link>
                                    }
                                />
                            </Authorized>
                        ))}
                        {this.props.alertsStore?.closeDayStampAlerts?.map((o, i) => (
                            <Authorized
                                key={`reminder_${o.exciseRegisterId}`}
                                placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterDayClosing}
                                placeContext={o.exciseRegisterPlaceName}
                            >
                                <Alert closable onClose={() => { this.setCloseDayStampReminderAlert(o.exciseRegisterId, o.lastClosedDay) }} type="warning" showIcon
                                    message={`Przypomnienie o konieczności zamknięcia dnia dla ewidencji banderol o Id rejestru ${o.exciseRegisterId}, rodzaj ${o.exciseStampType}, ostatni zamknięty dzień ${dateToString(o.lastClosedDay)}.`}
                                    action={
                                        <Link to={{
                                            pathname: `/ExciseStampRegisters/Details/${o.exciseRegisterId}`,
                                            state: { firstUnclosedDate: this.getFirstUnclosedDate(o.lastClosedDay) }
                                        }}>
                                            <Button size="small" type="link" onClick={this.setIsReloading}>
                                                ZOBACZ
                                            </Button>
                                        </Link>
                                    }
                                />
                            </Authorized>
                        ))}
                        {this.props.alertsStore?.closeDayEnergyAlerts?.map((o, i) => (
                            <Authorized
                                key={`reminder_${o.exciseRegisterId}`}
                                placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterDayClosing}
                                placeContext={o.exciseRegisterPlaceName}
                            >
                                <Alert closable onClose={() => { this.setCloseDayEnergyReminderAlert(o.exciseRegisterId, o.lastClosedDay) }} type="warning" showIcon
                                    message={`Przypomnienie o konieczności zamknięcia dnia dla ewidencji energii elektrycznej o Id rejestru ${o.exciseRegisterId}, kod CN ${o.cnCode}, ostatni zamknięty dzień ${dateToString(o.lastClosedDay)}.`}
                                    action={
                                        <Link to={{
                                            pathname: `/ExciseEnergies/Registers/Details/${o.exciseRegisterId}`,
                                            state: { firstUnclosedDate: this.getFirstUnclosedDate(o.lastClosedDay) }
                                        }}>
                                            <Button size="small" type="link" onClick={this.setIsReloading}>
                                                ZOBACZ
                                            </Button>
                                        </Link>
                                    }
                                />
                            </Authorized>
                        ))}
                        {this.props.alertsStore?.announcements?.map((announcement, _) => (
                            <Alert
                                className="announcement"
                                closable={!announcement.isError}
                                key={`announcement_${announcement.id}`}
                                type={announcement.isError ? "error" : "warning"}
                                showIcon
                                message={announcement.content}
                                onClose={() => { this.setCloseOtherAlerts(announcement.id) }}
                            />
                        ))}
                    </Panel>
                </Collapse>}
        </>
    }
}

export default AlertsComponent;
