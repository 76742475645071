import { Button, Col, notification } from "antd";
import { inject, observer } from "mobx-react";
import React, { createRef } from "react";
import { RouteComponentProps } from "react-router";
import PrimaryCard from "../layout/PrimaryCard";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { ActionType, AssignEntryToRegisterCmd, AssigningResult, DictionaryName, EwdPlaceLevelPermission, EwdRegisterName, ExciseEntryClient, ExciseEntryDto } from "services/GeneratedClient";
import { ajaxCatch } from "helper/api";
import { RegulationStore } from "regulations/RegulationStore";
import { CenteredRow } from "layout/CenteredRow";
import Descriptions from "layout/Descriptions";
import { booleanRenderer, dateRenderer, yesNoBooleanValueProvider } from "helper/GridHelper";
import Page from "layout/Page";
import { ContainerOutlined } from "@ant-design/icons";
import AssignRegisterModal from "./AssignRegisterModal";
import BottomActionButtons from "layout/ActionButtons/BottomActionButtons";
import { Authorized } from "authorization/Authorized";
import { RegisterStore } from "register/RegisterStore";
import { showErrorModal } from "layout/Modals";
import ExciseRegisterBasicDetails from "exciseRegisters/ExciseRegisterBasicDetails";

interface IProps extends RouteComponentProps<any> {
    dictionaryStore?: DictionaryStore;
    regulationStore?: RegulationStore;
    exciseEntryId: number;
    registerStore?: RegisterStore;
    isAwaiting?: boolean;
}

interface IState {
    isLoading: boolean;
    exciseEntryCnCode: string;
}

@inject("dictionaryStore")
@inject("regulationStore")
@inject("registerStore")
@observer
export default class ExciseEntryDetails extends React.PureComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            exciseEntryCnCode: '',
            isLoading: true
        }
    }

    private assignRegisterModal = createRef<AssignRegisterModal>();

    private assignRegister = (registerId: number) => {
        const cmd = new AssignEntryToRegisterCmd();
        cmd.exciseEntryId = this.props.exciseEntryId;
        cmd.registerId = registerId;
        ajaxCatch(() =>
            new ExciseEntryClient().assignRegister(cmd)
                .then((response: AssigningResult | null) => {
                    let msg = "Przypisano zdarzenie do ewidencji" + (response!.assignedAsExpired ? " jako przeterminowane i zwrócono do poczekalni" : "");
                    notification.success({
                        placement: "bottomRight",
                        message: msg,
                    });
                    this.props.history.goBack();
                }));
    }

    private addCorrection = (exciseEntry: ExciseEntryDto) => {
        if (!exciseEntry.registerIdForExpiredEntry) {
            showErrorModal(`Nie można przypisać zdarzenia z powodu braku pasującej ewidencji akcyzowej.`);
            return;
        }
        this.props.history.push(`/ExciseRegisters/${exciseEntry.registerIdForExpiredEntry}/ExciseEntries/AssignAsAdditiveCorrection/${exciseEntry.id}`)
    }

    public render() {
        return (
            <>
                <Page<ExciseEntryDto>
                    title="PODGLĄD ZDARZENIA"
                    goBack={this.props.history.goBack}
                    ajaxCall={() => this.props.isAwaiting
                        ? new ExciseEntryClient().getAwaitingExciseEntry(this.props.exciseEntryId).then(result => {
                            this.setState({ exciseEntryCnCode: result?.cnCode!, isLoading: false })
                            return result;
                        })
                        : new ExciseEntryClient().get(this.props.exciseEntryId).then(result => {
                            this.setState({ exciseEntryCnCode: result?.cnCode!, isLoading: false })
                            return result;
                        })
                    }>
                    {(exciseEntry) => {
                        return (<PrimaryCard>
                            <CenteredRow>
                                <Col xl={24} xs={24}>
                                    {
                                        exciseEntry.registerIdForExpiredEntry &&
                                        <ExciseRegisterBasicDetails exciseRegisterId={exciseEntry.registerIdForExpiredEntry} />
                                    }
                                    <Descriptions title="Informacje ogólne">
                                        <Descriptions.Item label='Paragraf'><>{exciseEntry.paragraphOfRegulation && `${this.props.regulationStore?.getDisplay(exciseEntry.paragraphOfRegulation, EwdRegisterName.ExciseRegister)}`}</></Descriptions.Item>
                                        <Descriptions.Item label="Kierunek zdarzenia"><>{this.props.dictionaryStore?.getDisplay(DictionaryName.ExciseEntryDirection, exciseEntry.direction)}</></Descriptions.Item>
                                        <Descriptions.Item label="Id systemowy"><>{exciseEntry.id}</></Descriptions.Item>
                                        <Descriptions.Item label="Identyfikator źródłowy"><>{exciseEntry.sourceIdentifier}</></Descriptions.Item>
                                        {
                                            exciseEntry.ordinalNumber &&
                                            <Descriptions.Item label="Lp."><>{exciseEntry.ordinalNumber}</></Descriptions.Item>
                                        }
                                    </Descriptions>
                                    {
                                        (exciseEntry.isSupplyEntry || exciseEntry.correctionOrdinalNumber) &&
                                        <Descriptions title="Informacje o korekcie">
                                            <Descriptions.Item label="Powód korekty"><>{exciseEntry.correctionReason}</></Descriptions.Item>
                                            <Descriptions.Item label="Lp. korekty"><>{exciseEntry.correctionOrdinalNumber}</></Descriptions.Item>
                                            <Descriptions.Item label="Edytor"><>{exciseEntry.editorUserName}</></Descriptions.Item>
                                        </Descriptions>
                                    }
                                    {
                                        !exciseEntry.isSupplyEntry &&
                                        <>
                                            <Descriptions title='Wyrób'>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.productName}</></Descriptions.Item>
                                                <Descriptions.Item label="Kod towarowy"><>{exciseEntry.productCode}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod wyrobu akcyzowego"><>{this.props.dictionaryStore?.getDisplay(DictionaryName.ExciseProduct, exciseEntry.exciseProductCode)}</></Descriptions.Item>
                                                <Descriptions.Item label="Kod CN wyrobu"><>{this.props.dictionaryStore?.getDisplay(DictionaryName.CnCode, exciseEntry.cnCode)}</></Descriptions.Item>

                                                <Descriptions.Item label="Informacja o znakach akcyzy"><>{booleanRenderer(exciseEntry.fiscalMark, yesNoBooleanValueProvider)}</></Descriptions.Item>
                                                <Descriptions.Item label="Informacja o oznakowaniu i barwieniu"><>{booleanRenderer(exciseEntry.notColouredAndMarkedFuelOils, yesNoBooleanValueProvider)}</></Descriptions.Item>

                                                <Descriptions.Item label="Przychód w jednostce rozliczenia akcyzy"><>{exciseEntry.incomeInExciseUnit}</></Descriptions.Item>
                                                <Descriptions.Item label="Rozchód w jednostce rozliczenia akcyzy"><>{exciseEntry.expenditureInExciseUnit}</></Descriptions.Item>

                                                <Descriptions.Item label="Przychód w jednostce alternatywnej"><>{exciseEntry.incomeInInventoryUnit}</></Descriptions.Item>
                                                <Descriptions.Item label="Rozchód w jednostce alternatywnej"><>{exciseEntry.expenditureInInventoryUnit}</></Descriptions.Item>

                                                <Descriptions.Item label="Przelicznik jednostek"><>{exciseEntry.unitConverter}</></Descriptions.Item>
                                                <Descriptions.Item label="Kod Nomenklatury Scalonej (CN) suszu tytoniowego otrzymanego w wyniku przetwarzania"><>{exciseEntry.cnCodeOfDriedTabacco}</></Descriptions.Item>

                                                <Descriptions.Item label="Masa netto"><>{exciseEntry.netWeight}</></Descriptions.Item>
                                                <Descriptions.Item label="Zawartość alkoholu"><>{exciseEntry.alcoholicStrength}</></Descriptions.Item>

                                                <Descriptions.Item label="Stopień Plato"><>{exciseEntry.degreePlato}</></Descriptions.Item>
                                                <Descriptions.Item label="Rodzaj paliwa"><>{this.props.dictionaryStore?.getDisplay(DictionaryName.FuelTypeType, exciseEntry.fuelType)}</></Descriptions.Item>

                                                <Descriptions.Item label="Maksymalna cena detaliczna za 20 szt. lub za kilogram"><>{exciseEntry.maxRetailPrice}</></Descriptions.Item>
                                                <Descriptions.Item label="Wielkość producenta"><>{exciseEntry.sizeOfProducer}</></Descriptions.Item>

                                                <Descriptions.Item label="Biokomponenty oraz paliwo spełniają wymagania jakościowe"><>{booleanRenderer(exciseEntry.biofuelContentQuality, yesNoBooleanValueProvider)}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Informacje dodatkowe">
                                                <Descriptions.Item label="Data"><>{dateRenderer(exciseEntry.date)}</></Descriptions.Item>
                                                <Descriptions.Item label="Data wystawienia dokumentu"><>{dateRenderer(exciseEntry.documentDate)}</></Descriptions.Item>

                                                <Descriptions.Item label="Numer dokumentu dostawy lub przewozowego lub zgłoszenia"><>{exciseEntry.documentNumber}</></Descriptions.Item>
                                                <Descriptions.Item label="Numer pozycji dokumentu dostawy lub przewozowego lub zgłoszenia"><>{exciseEntry.productNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Data faktury"><>{dateRenderer(exciseEntry.invoiceDate)}</></Descriptions.Item>
                                                <Descriptions.Item label="Numer faktury"><>{exciseEntry.invoiceNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kwota akcyzy"><>{exciseEntry.exciseAmount}</></Descriptions.Item>
                                                <Descriptions.Item label="Kwota akcyzy paliwowej"><>{exciseEntry.fuelExciseAmount}</></Descriptions.Item>

                                                <Descriptions.Item label="Numer Zbiornika wydającego"><>{this.props.registerStore?.getContainerDisplayById(exciseEntry.containerIdFrom)}</></Descriptions.Item>
                                                <Descriptions.Item label="Numer Zbiornika przyjmującego"><>{this.props.registerStore?.getContainerDisplayById(exciseEntry.containerIdTo)}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejsce w składzie"><>{exciseEntry.taxWarehousePlace}</></Descriptions.Item>

                                                <Descriptions.Item label="Sposób nabycia"><>{exciseEntry.methodOfAcquisition}</></Descriptions.Item>
                                                <Descriptions.Item label="Sposób wyprowadzenia"><>{exciseEntry.methodOfDerivation}</></Descriptions.Item>

                                                <Descriptions.Item label="Sposób zużycia wyrobów akcyzowych"><>{exciseEntry.methodOfConsumptionOfGoods}</></Descriptions.Item>
                                                <Descriptions.Item label="Sposób przetworzenia"><>{exciseEntry.methodOfProcessingOfGoods}</></Descriptions.Item>

                                                <Descriptions.Item label="Ilość przeładowana w jednostce rozliczenia akcyzy"><>{exciseEntry.amountTransshipmentInExciseUnit}</></Descriptions.Item>
                                                <Descriptions.Item label="Ilość przeładowana w jednostce technicznej"><>{exciseEntry.amountTransshipmentInTechnicalUnit}</></Descriptions.Item>

                                                <Descriptions.Item label="Przeładowano z"><>{exciseEntry.transshippedFrom}</></Descriptions.Item>
                                                <Descriptions.Item label="Przeładowano na"><>{exciseEntry.transshippedTo}</></Descriptions.Item>

                                                <Descriptions.Item label="Numer koncesji na obrót paliwami ciekłymi z zagranicą"><>{exciseEntry.concessionNumberForFuels}</></Descriptions.Item>
                                                <Descriptions.Item label="Typ koncesji"><>{exciseEntry.concessionType}</></Descriptions.Item>

                                                <Descriptions.Item label="Data wydania koncesji"><>{dateRenderer(exciseEntry.concessionIssueDate)}</></Descriptions.Item>
                                                <Descriptions.Item label="Data ważności koncesji"><>{dateRenderer(exciseEntry.concessionExpiryDate)}</></Descriptions.Item>

                                                <Descriptions.Item label="Data sprzedaży"><>{dateRenderer(exciseEntry.saleDate)}</></Descriptions.Item>
                                                <Descriptions.Item label="Data wydania wyrobów węglowych finalnemu nabywcy"><>{dateRenderer(exciseEntry.finalCarbonicBuyerIssueDate)}</></Descriptions.Item>

                                                <Descriptions.Item label="Sposób użycia"><>{exciseEntry.useMethod}</></Descriptions.Item>
                                                <Descriptions.Item label="Adres miejsca wykonania czynności"><>{exciseEntry.activitiesPlaceAddress}</></Descriptions.Item>

                                                <Descriptions.Item label="Status wyrobów niezwolnionych"><>{exciseEntry.notExemptProductStatus}</></Descriptions.Item>
                                                <Descriptions.Item label="Opis przeznaczenia"><>{exciseEntry.useDescription}</></Descriptions.Item>

                                                <Descriptions.Item label="Typ dokumentu lokalnego logistycznego" span={2}>{exciseEntry.typeOfLocalDocument}</Descriptions.Item>
                                                <Descriptions.Item label="Numer lokalnego dokumentu logistycznego">{exciseEntry.localDocumentNumber}</Descriptions.Item>
                                                <Descriptions.Item label="Numer pozycji na dokumencie lokalnym">{exciseEntry.localProductNumber}</Descriptions.Item>

                                                <Descriptions.Item label="Numer zgłoszenia celnego">{exciseEntry.customsDeclarationNumber}</Descriptions.Item>
                                                <Descriptions.Item label="Data zgłoszenia celnego"><>{dateRenderer(exciseEntry.customsDeclarationDate)}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejsce (urząd) importu">{exciseEntry.customsOffice}</Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Odbiorca">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.recipientIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.recipientName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.recipientStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.recipientStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.recipientPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.recipientCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Dostawca">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.supplierIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.supplierName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.supplierStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.supplierStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.supplierPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.supplierCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Przewoźnik">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.transporterIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.transporterName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.transporterStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.transporterStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.transporterPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.transporterCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Spedytor">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.transportArrangerIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.transportArrangerName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.transportArrangerStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.transportArrangerStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.transportArrangerPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.transportArrangerCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Właściciel">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.ownerIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.ownerName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.ownerStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.ownerStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.ownerPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość">{exciseEntry.ownerCity}</Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Magazynujący tytoń poza składem">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.storerOfTobaccoIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.storerOfTobaccoName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.storerOfTobaccoStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.storerOfTobaccoStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.storerOfTobaccoPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.storerOfTobaccoCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Finalny nabywca">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.finalBuyerIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.finalBuyerName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.finalBuyerStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.finalBuyerStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.finalBuyerPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.finalBuyerCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Importer">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.importerIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.importerName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.importerStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.importerStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.importerPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.importerCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Sprzedawca">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.sellerIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.sellerName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.sellerStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.sellerStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.sellerPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.sellerCity}</></Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Nabywca">
                                                <Descriptions.Item label="Identyfikator (np. NIP)"><>{exciseEntry.buyerIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa"><>{exciseEntry.buyerName}</></Descriptions.Item>

                                                <Descriptions.Item label="Ulica"><>{exciseEntry.buyerStreetName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nr domu"><>{exciseEntry.buyerStreetNumber}</></Descriptions.Item>

                                                <Descriptions.Item label="Kod pocztowy"><>{exciseEntry.buyerPostcode}</></Descriptions.Item>
                                                <Descriptions.Item label="Miejscowość"><>{exciseEntry.buyerCity}</></Descriptions.Item>
                                            </Descriptions>
                                        </>
                                    }
                                </Col>
                            </CenteredRow>
                            <BottomActionButtons>
                                <>
                                    <Authorized requiresSubscription placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterEntryAwaitingDisplayAndAssigning}>
                                        {exciseEntry.actions?.includes(ActionType.Assigning) && <>
                                            <AssignRegisterModal exciseEntryCnCode={this.state.exciseEntryCnCode} ref={this.assignRegisterModal} onSelect={this.assignRegister} />
                                            <Button loading={this.state.isLoading} type="primary" icon={<ContainerOutlined />} onClick={() => this.assignRegisterModal.current?.show()}>
                                                {exciseEntry.registerIdForExpiredEntry ? "Przypisz do innej ewidencji" : "Przypisz"}
                                            </Button>
                                        </>}
                                        {exciseEntry.registerIdForExpiredEntry &&
                                            <Authorized requiresSubscription placeContext={exciseEntry.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting}>
                                                <Button loading={this.state.isLoading} type="primary" icon={<ContainerOutlined />} onClick={() => this.addCorrection(exciseEntry)}>
                                                    Zarejestruj jako korektę dodającą w zamkniętym dniu
                                                </Button>
                                            </Authorized>}
                                    </Authorized>
                                </>
                            </BottomActionButtons>
                        </PrimaryCard>)
                    }}
                </Page>
            </>
        )
    }
}
