import React, { createRef } from 'react';
import { dateRenderer, EwdColumnProps } from 'helper/GridHelper';
import { DictionaryName, EwdPlaceLevelPermission, ExciseRegisterClient, ExciseRegisterDto } from 'services/GeneratedClient';
import { Link } from 'react-router-dom';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from "layout/FadingTooltip";
import { ReadOutlined, ReconciliationOutlined, SearchOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';
import { Authorized } from 'authorization/Authorized';
import { GridStore } from 'layout/table/paginated/GridStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class ExciseRegisterList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "exciseRegisterList"
        }
    }

    public componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<ExciseRegisterDto>[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseRegisterDto) => (
                <>
                    <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay} placeContext={record.placeName}>
                        <FadingTooltip title="Zdarzenia ewidencji">
                            <Link to={`/ExciseRegisters/${record.id}/Entries/Completed`}>
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ReadOutlined />} />
                            </Link>
                        </FadingTooltip>
                    </Authorized>
                    {!record.closeDate && <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay} placeContext={record.placeName}>
                        <FadingTooltip title="Zdarzenia bieżące">
                            <Link to={`/ExciseRegisters/${record.id}/Entries/Current`}>
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ReconciliationOutlined />} />
                            </Link>
                        </FadingTooltip>
                    </Authorized>}
                    <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterDisplay} placeContext={record.placeName}>
                        <FadingTooltip title="Szczegóły ewidencji">
                            <Link to={`/ExciseRegisters/Details/${record.id}`}>
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                            </Link>
                        </FadingTooltip>
                    </Authorized>
                </>
            ),
            title: "Czynności",
            width: 110,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            sorter: true,
            filter: "contains",
        },
        {
            dataIndex: "placeName",
            key: "Place.Name",
            title: "Miejsce",
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN wyrobu",
            filter: "contains",
        },
        {
            dataIndex: "exciseUnitOfMeasure",
            key: "ExciseUnitOfMeasure",
            render: (text: number) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, text),
            title: "Jednostka miary akcyzowej",
        },
        {
            dataIndex: "inventoryUnitOfMeasure",
            key: "InventoryUnitOfMeasure",
            render: (text: number) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, text),
            title: "Jednostka miary techniczna",
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (status: string) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.ExciseRegisterStatus, status),
            title: "Status",
        },
        {
            dataIndex: "closeDate",
            key: "CloseDate",
            title: "Data zamknięcia",
            render: (text: any, record: ExciseRegisterDto) => dateRenderer(record.closeDate),
            filter: "dateRange",
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseRegisterDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`EWIDENCJE AKCYZOWE`} />
                <PaginatedTable<ExciseRegisterDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseRegisterDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseRegisterClient().getAll(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </>
        )
    }
};