import React, { ReactNode } from 'react';
import { Button, Modal, ModalProps, Table } from 'antd';

interface IProps {
    headerName: ReactNode;
    containersState: ContainerState[] | undefined;
}

interface IState {
    visible: boolean;
}

export class ContainerState {
    containerName!: string;
    amount!: number;
    constructor(containerName: string, amount: number) {
        this.containerName = containerName;
        this.amount = amount;
    }
}

export default class ExciseRegisterContainersStateModal extends React.PureComponent<IProps & ModalProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            visible: false,
        }
    }

    public show() {
        this.setState({
            visible: true
        })
    }

    public hide() {
        this.setState({
            visible: false
        })
    }

    public render() {
        return (
            <Modal {...this.props}
                title={this.props.headerName}
                visible={this.state.visible}
                onCancel={() => this.hide()}
                footer={[
                    <Button key="back" onClick={() => this.hide()}>
                        Zamknij
                    </Button>
                ]}
                width={"600px"}>
                <Table

                    dataSource={this.props.containersState}
                    rowKey={(row) => row.containerName}
                    size="small"
                    pagination={false}
                    style={{ width: 600 }}
                    columns={[
                        {
                            dataIndex: "containerName",
                            key: "ContainerName",
                            title: "Zbiornik",
                        },
                        {
                            dataIndex: "amount",
                            key: "Amount",
                            title: "Ilość",
                        },
                    ]}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Suma</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{(this.props.containersState?.map(x => x.amount).reduce((a, c) => (a! + c! ?? 0)).toFixed(3))}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </Modal>
        )
    }
};