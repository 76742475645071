import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { ExciseRegisterClient, ExciseRegisterDto } from 'services/GeneratedClient';
import { Button, Modal, ModalProps } from 'antd';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from "layout/FadingTooltip";
import { LoginOutlined, SearchOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseEntryCnCode: string;
    onSelect?: (registerId: number) => any;
}

interface IState {
    visible: boolean;
}

@inject("dictionaryStore")
export default class AssignRegisterModal extends React.PureComponent<IProps & ModalProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            visible: false,
        }
    }

    public show() {
        this.setState({
            visible: true
        })
    }

    public hide() {
        this.setState({
            visible: false
        })
    }

    private columns: EwdColumnProps<ExciseRegisterDto>[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseRegisterDto) => (
                <>
                    {record.id && <>
                        <FadingTooltip title="Wybierz" placement="top">
                            <TableButton icon={<LoginOutlined />} onClick={() => this.props.onSelect && this.props.onSelect(record.id)} />
                        </FadingTooltip></>}
                </>
            ),
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            filter: "contains",
        },
        {
            dataIndex: "placeName",
            key: "Place.Name",
            title: "Miejsce",
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN wyrobu",
            filter: "contains",
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseRegisterDto>>();

    public render() {
        return (
            <Modal {...this.props}
                title="Wybierz ewidencję"
                visible={this.state.visible}
                onCancel={() => this.hide()}
                footer={[
                    <Button key="back" onClick={() => this.hide()}>
                        Anuluj
                    </Button>
                ]}
                width={"750px"}>
                <PaginatedTable<ExciseRegisterDto>
                    ref={this.paginatedTable}
                    gridName="assignExciseRegister"
                    columns={this.columns}
                    getRowKey={(r: ExciseRegisterDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseRegisterClient().getAssignExciseRegisters(this.props.exciseEntryCnCode, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </Modal>
        )
    }
};
