import { SearchOutlined } from '@ant-design/icons';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuditLogAreaName } from 'services/GeneratedClient';

interface IPros {
    registerId?: number,
    auditLogAreaName: AuditLogAreaName,
    gridName?: string,
    recordId?: number
}

interface IState {
    previewLink: string,
}

export default class ExciseStampChangeToEditedButton extends React.PureComponent<IPros, IState> {
    constructor(props: IPros) {
        super(props);

        this.state = {
            previewLink: ""
        }
    }

    componentDidMount() {
        if(this.props.registerId != undefined)
            this.resolveRegisterPreviewLink(this.props.auditLogAreaName);
    }

    private resolveRegisterPreviewLink(areaName: AuditLogAreaName) {
        switch(areaName) {
            case AuditLogAreaName.ExciseRegister:
                this.setState({previewLink: `/ExciseRegisters/Details/${this.props.registerId}`});
                break;
            case AuditLogAreaName.ExciseStampRegister:
                this.setState({previewLink: `/ExciseStampRegisters/Details/${this.props.registerId}`});
                break;
            case AuditLogAreaName.ExciseEnergyRegister:
                this.setState({previewLink: `/ExciseEnergies/Registers/Details/${this.props.registerId}`})
                break;
            default:
                this.setState({previewLink: ''})
        }
    }

    public render() {
        return <>
            {this.props.registerId && this.state.previewLink && <FadingTooltip title="Podgląd" placement="top">
                <Link to={this.state.previewLink}>
                    <TableButton gridName={this.props.gridName} recordId={this.props.recordId} icon={<SearchOutlined />} />
                </Link>
            </FadingTooltip>}
        </>
    }
}