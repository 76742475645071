import { Select, Button, Input } from "antd";
import * as React from "react";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { inject, observer } from "mobx-react";
import { GridStore } from "../GridStore";
import { CenteredRow } from "layout/CenteredRow";
import { EwdColumnProps } from "helper/GridHelper";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

interface IProps<T> {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;

    column: EwdColumnProps<T>;
    handleSearch: () => void;
    gridName: string;
}

class State {
    value?: string[];
}

@inject("dictionaryStore")
@inject("gridStore")
@observer
export class GridFilterDictionarySelect<T> extends React.Component<IProps<T>, State> {
    constructor(props: IProps<T>) {
        super(props);

        let savedValues = this.props.gridStore!.getColumnFilter(this.props.gridName, this.props.column.key!);
        if (savedValues) savedValues = savedValues.filter((a: any) => a !== undefined);

        this.state = {
            value: savedValues
        }
    }


    public render() {
        const dict = this.props.dictionaryStore!.getDictionary(this.props.column.filterDictionary!);
        const dictValues = dict && dict.values;

        return <>
            <CenteredRow>
                <Button type="primary" icon={<SearchOutlined />} size="small" onClick={() => this.search()} disabled={!this.state.value}>
                    Wyszukaj
                </Button>
                <Button size="small" style={{ marginLeft: 4 }} icon={<DeleteOutlined />} onClick={() => this.clear()}>
                    Wyczyść
                </Button>
            </CenteredRow>

            <Select<string[]>
                ref={input => this.inputRef = input}
                mode="multiple"
                size="small"
                placeholder="Wybierz"
                style={{ width: 300, marginTop: 8, display: 'block' }}
                showSearch
                value={this.state.value}
                onChange={(val: string[]) => this.setState({ value: val })}
                optionFilterProp="children">
                {dictValues && dictValues.map(x =>
                    <Select.Option key={x.value} value={x.value!}>{x.displayValue}</Select.Option>
                )}
            </Select>
        </>
    }

    private inputRef: any;

    componentDidMount() {
    }

    componentDidUpdate() {
        var x = window.scrollX, y = window.scrollY;
        this.inputRef.focus();
        window.scrollTo(x, y);
    }

    private clear() {
        this.setState({ value: undefined });
        this.props.gridStore!.clearColumnFilter(this.props.gridName, this.props.column.key!)

        this.props.handleSearch();
    }

    private search() {
        this.props.gridStore!.saveColumnFilter(this.props.gridName, this.props.column.key!, this.state.value);
        this.props.handleSearch();
    }
}