import React, { PropsWithChildren } from 'react'
import { Row, Col, CardProps, Card as AntCard } from 'antd';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { DictionaryName, ExciseEnergyRegisterDto } from 'services/GeneratedClient';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import { PlaceDetails } from 'places/PlaceDetails';


const ExciseEnergyRegisterInformation = ({ exciseEnergyRegister, dictionaryStore }: { exciseEnergyRegister: ExciseEnergyRegisterDto, dictionaryStore: DictionaryStore }) =>
    <>
        <RowWithBottomMargin>
            <Col span={24}>
                <Card title="Szczegóły rejestru ewidencji">
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Nazwa"}
                            value={exciseEnergyRegister.name} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Kod CN"}
                            value={exciseEnergyRegister.cnCode} />
                    </RowWithBottomMargin>
                </Card>
            </Col>
        </RowWithBottomMargin>
        <RowWithBottomMargin>
            <Col span={24}>
                <PlaceDetails placeId={exciseEnergyRegister.placeId} horizontal />
            </Col>
        </RowWithBottomMargin>
    </>


export default ExciseEnergyRegisterInformation;