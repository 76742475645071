 import { ClientBase } from "../services/ClientBase";
 import { baseAppUrl } from "../helper/api";
import { ImportDataType, ImportFileExtension } from "services/GeneratedClient";

export class FileUploadClient extends ClientBase {
    public uploadFiles(file: any, dataType: ImportDataType, fileExtension: ImportFileExtension): Promise<any> | null {
        
            const formData = new FormData();
        formData.append('file',file);
        formData.append('dataType',dataType.toString());
        formData.append('extension',fileExtension.toString());

        let url = baseAppUrl + "/api/Import/ImportFile";

        let options = <RequestInit>{
            body: formData,
            method: "POST",
            headers: {}
        };
        return this.transformOptions(options).then((transformedOptions) => {
            return fetch(url, transformedOptions)
                .then((resp => {
                    if (resp.ok) {
                        return resp.json()
                    } else {
                        throw Error(resp.statusText);
                    }
                }));
        });
        
    }
}
