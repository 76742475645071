import { Button, Col, Select } from "antd";
import { inject, observer } from "mobx-react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { PageHeader } from "../layout/PageHeader";
import PrimaryCard from "../layout/PrimaryCard";
import { ContainerClient, ContainerDto, CreateContainerCmd } from "../services/GeneratedClient";
import { FCenteredRow, FFieldLabel, FInput, FCheckbox } from "../forms/FormikFormItems";
import { ajaxByUser } from "../helper/api";
import ContainerValidation from "./ContainerValidation";
import { RegisterStore } from "register/RegisterStore";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
    placeId: number;
    registerStore?: RegisterStore;
}

interface IState {
    isLoading: boolean;
}

@inject("dictionaryStore")
@inject("registerStore")
@observer
export default class ContainerCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/Containers/${this.props.placeId}`;
    private validation: ContainerValidation;
    
    constructor(props: any) {
        super(props);
        
        this.validation = new ContainerValidation();
        this.state = {
            isLoading: false
        };
    }

    public send(values: ContainerDto, actions: FormikHelpers<ContainerDto>) {
        values.placeId = this.props.placeId;
        this.setState({ isLoading: true })
        let cmd = new CreateContainerCmd({
            containerDto: values
        });
        ajaxByUser("Dodano nowy zbiornik", () =>
            new ContainerClient().create(cmd)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    this.props.registerStore?.refreshRegisters();
                    this.props.history.push(this.goBackUrl);
                })).finally(() => this.setState({ isLoading: false }));
    }

    public render() {
        return (
            <>
                <PageHeader title={`TWORZENIE NOWEGO ZBIORNIKA`} />
                <Formik<ContainerDto> initialValues={new ContainerDto()}
                    onSubmit={(values, actions) => {
                        this.setState({ isLoading: true })
                        this.send(values, actions)
                    }}
                    validationSchema={this.validation.schema}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}>
                    {(props: FormikProps<ContainerDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Nazwa zbiornika" sm={24} />
                                    <Field component={FInput} name="name" />
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col md={9} xs={24}>
                                    <FFieldLabel oneRow label="Czy aktywny" sm={6} />
                                    <Field component={FCheckbox} name="isActive" />
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" icon={<SaveOutlined />} loading={this.state.isLoading} size="large" onClick={() => {
                                    props.submitForm();
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}