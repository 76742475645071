import React, { useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd';
import { useDictionaryStore } from '../dictionary/dictionaryStore';
import { DictionaryName, ExciseRegisterClient, ExciseRegisterDto } from '../services/GeneratedClient';
import { Card, DetailCell, RowWithBottomMargin } from '../components/StateDetailsComponents';
import { ajaxCatch } from 'helper/api';
import { CenteredSpin } from 'layout/CenteredSpin';

interface Props {
    exciseRegisterId: number
}

const ExciseRegisterBasicDetails = (props: Props) => {
    var dictionaryStore = useDictionaryStore();
    const [exciseRegister, setExciseRegister] = useState<ExciseRegisterDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        setIsLoading(true);
        fetchExciseRegister();
    }, [props.exciseRegisterId]);

    function fetchExciseRegister() {
        ajaxCatch(() =>
            new ExciseRegisterClient().get(props.exciseRegisterId),
            (result: ExciseRegisterDto | null) => {
                setExciseRegister(result!)
            }).finally(() => setIsLoading(false));
    }

    return (
        <RowWithBottomMargin>
            <Col span={24}>
                <Card className='m-0' title="Dane ewidencji akcyzowej">
                    {isLoading ? <CenteredSpin size="large" /> :
                        <>
                            <Row>
                                <DetailCell
                                    label={"Id ewidencji"}
                                    value={exciseRegister?.id.toString()} />
                                <DetailCell
                                    label={"Status"}
                                    value={dictionaryStore.getDisplay(DictionaryName.ExciseRegisterStatus, exciseRegister?.status)} />
                            </Row>
                            <Row>
                                <DetailCell
                                    label={"Nazwa ewidencji"}
                                    value={exciseRegister?.name} />
                                <DetailCell
                                    label={"Nazwa miejsca"}
                                    value={exciseRegister?.placeName} />
                            </Row>
                            <Divider className='divider-thin' />
                            <Row>
                                <DetailCell
                                    label={"Kod CN wyrobu"}
                                    value={dictionaryStore?.getDisplay(DictionaryName.CnCode, exciseRegister?.cnCode)}
                                    span={24} />
                            </Row>
                        </>}
                </Card>
            </Col>
        </RowWithBottomMargin>
    )
};

export default ExciseRegisterBasicDetails;
