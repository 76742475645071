import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { CompanyLevelPermission, ExciseEnergyRegisterAdminListDto, ExciseEnergyRegisterClient } from 'services/GeneratedClient';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, SearchOutlined, ToolOutlined } from '@ant-design/icons';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Button, Popconfirm } from 'antd';
import { Authorized } from 'authorization/Authorized';
import { GridStore } from 'layout/table/paginated/GridStore';
import { ajaxByUser } from 'helper/api';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class ExciseEnergyRegisterAdminList extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "exciseEnergyRegisterAdminGrid"
        }
    }

    public componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<ExciseEnergyRegisterAdminListDto>[] = [
        {
            key: "actions",
            width: 120,
            render: (text: string, record: ExciseEnergyRegisterAdminListDto) => (
                <>
                    <FadingTooltip title="Szczegóły ewidencji">
                        <Link to={`/ExciseEnergies/Registers/Details/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                        </Link>
                    </FadingTooltip>
                    {record.canEdit && <Authorized companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                        <FadingTooltip title="Redaguj">
                            <Link to={`/Admin/ExciseEnergies/Registers/Edit/${record.id}`} >
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<EditOutlined />} />
                            </Link>
                        </FadingTooltip>
                    </Authorized>}
                    {
                        record.canDelete &&
                        <Authorized companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                            <FadingTooltip title={"Usuń pusty rejestr"}>
                                <Popconfirm placement="bottomRight" title={`Czy na pewno chcesz usunąć rejestr energii o identyfikatorze ${record.id}? Operacji nie można wycofać.`} okText="Tak" cancelText="Nie"
                                    onConfirm={() => {
                                        ajaxByUser(`Poprawnie usunięto rejestr energii o identyfikatorze ${record.id}`, () =>
                                            new ExciseEnergyRegisterClient().deleteEmptyExciseEnergyRegister(record.id!).then(() => {
                                                this.refresh();
                                            }));
                                    }}>
                                    <TableButton icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </FadingTooltip>
                        </Authorized>
                    }
                </>
            )
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            sorter: true,
            filter: "contains",
        },
        {
            dataIndex: "placeName",
            key: "PlaceName",
            title: "Miejsce",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
        },
    ];

    private refresh() {
        this.paginatedTable.current?.refresh();
    }

    private paginatedTable = createRef<PaginatedTable<ExciseEnergyRegisterAdminListDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`EWIDENCJE ENERGII ELEKTRYCZNEJ`} />
                <PaginatedTable<ExciseEnergyRegisterAdminListDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseEnergyRegisterAdminListDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseEnergyRegisterClient().getAdminList(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
                <BottomActionButtons>
                    <Authorized requiresSubscription>
                        <Link to="/Admin/ExciseEnergies/Registers/Add">
                            <Button>Dodaj ewidencję</Button>
                        </Link>
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};