import React from 'react';
import { Modal } from 'antd';
import { GlobalUserManager } from 'authentication/UserManager';
import IdleTimer from 'react-idle-timer';

interface IProps {
    idleTimeout?: number;
}

export default class AutoLogoutOnIdleComponent extends React.Component<IProps, {}> {
    idleTimer?: IdleTimer;
    logoutTimer?: NodeJS.Timeout;
    logoutModal: any;

    constructor(props: IProps) {
        super(props)
    }

    render() {
        return (
            this.props.idleTimeout ? <IdleTimer
                ref={ref => { this.idleTimer = ref || undefined }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                timeout={1000 * this.props.idleTimeout} /> : null
        )
    }

    private onAction = () => {
    }

    private onActive = () => {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
        }

        if (this.logoutModal) {
            this.logoutModal.destroy();
        }
    }

    private onIdle = () => {
        this.logoutModal = Modal.warn({
            centered: true,
            maskClosable: true,
            okText: 'Pozostań zalogowany',
            onOk: () => {
                this.logoutTimer && clearTimeout(this.logoutTimer);
            },
            title: <p>Za chwilę zostaniesz automatycznie wylogowany.</p>
        });

        this.logoutTimer = global.setTimeout(() => {
            GlobalUserManager.UserManager().signoutRedirect();
        }, 15000);
    }
}