import * as Yup from 'yup';

export default class ExciseRegisterValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        return Yup.object().shape({
            name: Yup.string().required(),
            placeId: Yup.number().required(),
            cnCode: Yup.string().required()
                .matches(/^[0-9]+$/, "Kod CN musi składać się tylko z cyfr.")
                .max(8, 'Kod CN może mieć maksymalnie 8 znaków.'),
            exciseUnitOfMeasure: Yup.number().required(),
            inventoryUnitOfMeasure: Yup.number().required()
        });
    }
}