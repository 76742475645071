import { CloseOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, ColProps, notification, Popconfirm } from "antd";
import { Authorized } from "authorization/Authorized";
import { IPlaceContextProps } from "authorization/interfaces/IPlaceContextProps";
import { DictionaryStore } from "dictionary/dictionaryStore";
import ExciseStampRegisterBasicDetails from "exciseStamps/exciseStampRegister/details/ExciseStampRegisterBasicDetails";
import { Field, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { FCenteredRow, FDatePicker, FDictionarySelectWithTooltip, FInput, FInputNumber, FPanel, FRow, IFFieldLabelProps, OptionalFieldLabel as FormikLabel } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import { showErrorListModal, showFormIsInvalidModal } from "layout/Modals";
import { PageHeader } from "layout/PageHeader";
import PrimaryCard from "layout/PrimaryCard";
import { inject } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { RouteComponentProps } from "react-router";
import { RegulationStore } from "regulations/RegulationStore";
import { ConfirmExciseStampEntryCmd, CorrectAsNewExciseStampEntryCmd, CorrectExciseStampEntryCmd, CorrectExciseStampEntryResponseDto, CorrectionAsNewInitialDataDto, CreateExciseStampEntryCmd, EwdPlaceLevelPermission, EwdRegisterName, ExciseStampEntryClient, ExciseStampEntryFormDto, SaveExciseStampEntryDraftCmd } from "services/GeneratedClient";
import * as Yup from 'yup';
import ExciseStampEntryValidation from "./ExciseStampEntryValidation";
import { FormChangeStore } from "formChange/FormChangeStore";
import { LinkWithConfirmation } from "formChange/LinkWithConfirmation";

interface IProps extends RouteComponentProps<any>, IPlaceContextProps {
    dictionaryStore?: DictionaryStore;
    regulationStore?: RegulationStore;
    formChangeStore?: FormChangeStore;
    exciseStampRegisterId: number;
    placeContext: string;
    exciseStampEntryId?: number;
    registerParagraphType: string;
    isCorrecting?: boolean;
    isCorrectingAsNew?: boolean;
}

interface IState {
    initialValues: ExciseStampEntryFormDto;
    schema: Yup.ObjectSchema;
    isEditing: boolean;
    entryParagraph?: string;
    isLoading: boolean;
    isCorrecting: boolean;
    isCorrectingAsNew: boolean;
    lastClosedDay?: Moment;
    initialStateDay?: Moment;
}

@inject("regulationStore")
@inject("dictionaryStore")
@inject("formChangeStore")
export default class ExciseStampEntryForm extends React.PureComponent<IProps, IState> {
    private goBackUrl: string;
    private validation: ExciseStampEntryValidation;

    constructor(props: any) {
        super(props);

        this.goBackUrl = (props.isCorrecting || props.isCorrectingAsNew) ? `/ExciseStampRegisters/${this.props.exciseStampRegisterId}/${this.props.registerParagraphType}/Entries/Completed`
            : `/ExciseStampRegisters/${this.props.exciseStampRegisterId}/${this.props.registerParagraphType}/Entries/Current`;
        this.validation = new ExciseStampEntryValidation();

        this.state = {
            initialValues: new ExciseStampEntryFormDto(),
            schema: this.validation.schema,
            isLoading: false,
            isEditing: this.props.exciseStampEntryId ? true : false,
            isCorrecting: this.props.isCorrecting ?? false,
            isCorrectingAsNew: this.props.isCorrectingAsNew ?? false,
        };
    }

    public componentDidMount() {
        if (this.state.isEditing)
            this.fetchExciseStampEntry();
        else if (this.state.isCorrectingAsNew)
            this.fetchCorrectionAsNewInitialData();
    }

    private fetchExciseStampEntry() {
        ajaxCatch(() =>
            new ExciseStampEntryClient().getExciseStampEntryForm(this.props.exciseStampEntryId!),
            (result: ExciseStampEntryFormDto | null) => {
                this.setState({
                    initialValues: result!,
                    entryParagraph: result!.paragraphType,
                    schema: result!.paragraphType
                        ? this.validation.getValidationSchema(
                            this.props.regulationStore?.getByParagraph(result!.paragraphType, EwdRegisterName.ExciseStampRegister),
                            {
                                isCorrection: this.props.isCorrecting,
                            })
                        : this.validation.schema,
                })
            });
    }

    private fetchCorrectionAsNewInitialData() {
        ajaxCatch(() =>
            new ExciseStampEntryClient().getCorrectionAsNewInitialData(this.props.exciseStampRegisterId),
            (result: CorrectionAsNewInitialDataDto | null) => {
                let initialValues = this.state.initialValues;
                initialValues.nextCorrectionOrdinalNumber = result?.nextCorrectionOrdinalNumber!;
                initialValues.ordinalNumber = result?.nextOrdinalNumber!;
                initialValues.editorUserName = result?.editorUserName
                this.setState({
                    initialValues: initialValues,
                    initialStateDay: result?.firstClosedDay,
                    lastClosedDay: result?.lastClosedDay,
                });
            });
    }

    send(values: ExciseStampEntryFormDto, actions: FormikHelpers<ExciseStampEntryFormDto>) {
        this.setState({ isLoading: true });
        if (!this.state.initialValues.id) {
            let cmd = new CreateExciseStampEntryCmd({
                exciseStampEntryDto: values,
                registerId: this.props.exciseStampRegisterId,
                editorId: undefined,
            });
            ajaxByUser("Dodano zdarzenie", () =>
                new ExciseStampEntryClient().create(cmd)
                    .then((resp: any) => {
                        actions.setSubmitting(false);
                        this.props.history.push(this.goBackUrl);
                    }).finally(() => this.setState({ isLoading: false })));
        } else {
            let cmd = new ConfirmExciseStampEntryCmd({
                exciseStampEntryFormDto: values,
                registerId: this.props.exciseStampRegisterId,
                editorId: undefined,
            });
            ajaxByUser("Zredagowano zdarzenie", () =>
                new ExciseStampEntryClient().confirm(cmd)
                    .then((resp: any) => {
                        actions.setSubmitting(false);
                        this.props.history.push(this.goBackUrl);
                    }).finally(() => this.setState({ isLoading: false })));
        }
    }

    saveToEditState(values: ExciseStampEntryFormDto) {
        let cmd = new SaveExciseStampEntryDraftCmd({
            exciseStampEntryDto: values,
            registerId: this.props.exciseStampRegisterId,
            editorId: undefined,
        });
        ajaxByUser("Zapisano zdarzenie", () =>
            new ExciseStampEntryClient().saveDraft(cmd)
                .then((resp: any) => {
                    this.props.history.push(this.goBackUrl);
                }));
    }

    public correct(values: ExciseStampEntryFormDto, actions: FormikHelpers<ExciseStampEntryFormDto>) {
        let cmd = new CorrectExciseStampEntryCmd({
            exciseStampEntryFormDto: values,
            registerId: this.props.exciseStampRegisterId,
            editorId: undefined
        });
        ajaxCatch(() =>
            new ExciseStampEntryClient().correct(cmd)
                .then((result: CorrectExciseStampEntryResponseDto | null) => {
                    if (result && result.errors && result.errors.length > 0) {
                        actions.setSubmitting(false);
                        showErrorListModal("Problem w trakcie dokonywania korekty", result.errors);
                    } else {
                        actions.setSubmitting(false);
                        this.props.history.push(this.goBackUrl);
                        notification.success({
                            message: "Zapisano korektę zdarzenia",
                            placement: "bottomRight",
                        });
                    }
                }));
    }

    correctAsNew(values: ExciseStampEntryFormDto, actions: FormikHelpers<ExciseStampEntryFormDto>) {
        let cmd = new CorrectAsNewExciseStampEntryCmd({
            exciseStampEntryFormDto: values,
            registerId: this.props.exciseStampRegisterId,
            editorId: undefined,
        });
        ajaxCatch(() => new ExciseStampEntryClient().correctAsNew(cmd)
            .then((result: CorrectExciseStampEntryResponseDto | null) => {
                if (result && result.errors && result.errors.length > 0) {
                    actions.setSubmitting(false);
                    showErrorListModal("Problem w trakcie dodawania zdarzenia korygującego", result.errors);
                } else {
                    actions.setSubmitting(false);
                    this.props.history.push(this.goBackUrl);
                    notification.success({
                        message: "Zapisano zdarzenie korygujące",
                        placement: "bottomRight",
                    });
                }
            }));
    }

    checkIfFieldShouldBeDisabled = (fieldName: string) => {
        let fieldsAlwaysDisabled = ["paragraphType", "entryDate"];
        let nonCorrectableFields = ["paragraphType", "entryDate"];
        if (this.state.isEditing && fieldsAlwaysDisabled.some(a => a === fieldName)) {
            return true;
        }

        if (this.state.isCorrecting) {
            if (nonCorrectableFields.some(a => a == fieldName)) {
                return true;
            }
        }

        if (this.state.entryParagraph && this.props.regulationStore && !fieldsAlwaysDisabled.includes(fieldName) &&
            !this.props.regulationStore.getByParagraph(this.state.entryParagraph, EwdRegisterName.ExciseStampRegister).requiredFields?.includes(fieldName))
            return true;

        return false;
    }

    private getPageHeaderTitle(): string {
        if (this.state.isCorrecting) {
            return "KOREKTA WPISU EWIDENCJI BANDEROL";
        }
        else if (this.state.isEditing) {
            return "REDAGOWANIE WPISU EWIDENCJI BANDEROL";
        }
        else if (this.state.isCorrectingAsNew) {
            return "TWORZENIE WPISU EWIDENCJI BANDEROL, KORYGUJĄCEGO";
        }
        else {
            return "TWORZENIE WPISU EWIDENCJI BANDEROL";
        }
    }

    public validateAndSubmitForm = (props: FormikProps<ExciseStampEntryFormDto>) => {
        props.setFormikState({
            isSubmitting: true,
            values: props.values,
            errors: props.errors,
            touched: props.touched,
            isValidating: props.isValidating,
            submitCount: props.submitCount + 1
        });

        props.validateForm(props.values)
            .then((errors: FormikErrors<ExciseStampEntryFormDto>) => {
                if (Object.keys(errors).length) {
                    props.setSubmitting(false);
                    console.log("Błędy walidacji klienckiej: ", errors, "wartości", props.values);
                    showFormIsInvalidModal();
                } else {
                    props.submitForm();
                }
            })
    }

    FFieldLabel = (props: IFFieldLabelProps & ColProps & { name: string }) => <FormikLabel {...props}
        optional={props.optional !== undefined
            ? props.optional
            : this.state.entryParagraph && this.props.regulationStore
                ? !this.props.regulationStore.getByParagraph(this.state.entryParagraph, EwdRegisterName.ExciseStampRegister).requiredFields?.includes(props.name)
                : false} />

    public render() {
        return (
            <>
                <PageHeader title={this.getPageHeaderTitle()} />
                <ExciseStampRegisterBasicDetails exciseStampRegisterId={this.props.exciseStampRegisterId} />
                <Formik<ExciseStampEntryFormDto> initialValues={this.state.initialValues} enableReinitialize
                    onSubmit={(values, actions) => {
                        if (this.state.isCorrecting) {
                            this.correct(values, actions);
                        }
                        else if (this.state.isCorrectingAsNew) {
                            this.correctAsNew(values, actions);
                        }
                        else {
                            this.send(values, actions);
                        }
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.state.schema}>
                    {(props: FormikProps<ExciseStampEntryFormDto>) => {
                        this.props.formChangeStore?.setChangeDetectionStatus(props.dirty);
                        return <PrimaryCard>
                            <Collapse defaultActiveKey={['0', '1', '2']}>
                                <FPanel key='0' header={`Informacje ogólne`}>
                                    <FRow>
                                        <this.FFieldLabel sm={6} label="Paragraf" name="paragraphType" optional={false} />
                                        <Col sm={6}>
                                            <Field component={FDictionarySelectWithTooltip} disabled={this.checkIfFieldShouldBeDisabled("paragraphType")}
                                                dictionaryName={this.props.dictionaryStore?.getExciseStampParagraphDictionaryName(this.props.registerParagraphType)}
                                                name="paragraphType"
                                                onChange={(v: any, o: any) => {
                                                    props.values.paragraphType = v;
                                                    this.setState({
                                                        initialValues: props.values,
                                                        entryParagraph: v,
                                                        schema: this.validation.getValidationSchema(
                                                            this.props.regulationStore?.getByParagraph(v, EwdRegisterName.ExciseStampRegister),
                                                            {
                                                                isCorrection: this.state.isCorrectingAsNew,
                                                                initialStateDay: this.state.initialStateDay,
                                                                lastClosedDay: this.state.lastClosedDay,
                                                            }),
                                                    });
                                                }} />
                                        </Col>
                                        <this.FFieldLabel sm={6} label="Data zdarzenia" name="entryDate" optional={false} />
                                        <Col sm={6}>
                                            <Field component={FDatePicker} name="entryDate" optional={false} disabled={this.checkIfFieldShouldBeDisabled("entryDate")} />
                                        </Col>
                                    </FRow>
                                    {props.values.paragraphType && <>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Numer dokumentu źródłowego" name="sourceIdentifier" optional={false} />
                                            <Col sm={6}>
                                                <Field component={FInput} optional={false} name="sourceIdentifier" disabled={false} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Identyfikacja partii" name="batchIdentifier" optional={false} />
                                            <Col sm={6}>
                                                <Field component={FInput} name="batchIdentifier" disabled={false} />
                                            </Col>
                                        </FRow>
                                        {
                                            (this.state.isCorrecting || this.state.isCorrectingAsNew) && this.state.initialValues.ordinalNumber &&
                                            <FRow>
                                                <this.FFieldLabel sm={6} label="Lp." name="ordinalNumber" optional={false} />
                                                <Col sm={6}>
                                                    <Field component={FInput} name="ordinalNumber" disabled={true} />
                                                </Col>
                                            </FRow>
                                        }
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Identyfikacja wysyłki" name="sendIdentifier" />
                                            <Col sm={6}>
                                                <Field component={FInput} name="sendIdentifier" disabled={this.checkIfFieldShouldBeDisabled("sendIdentifier")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Rok produkcji" name="productionYear" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="productionYear" disabled={this.checkIfFieldShouldBeDisabled("productionYear")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Dane identyfikacyjne miejsca, z którego otrzymano banderole podatkowe" name="obtainedPlaceDetails" />
                                            <Col sm={18}>
                                                <Field component={FInput} name="obtainedPlaceDetails" disabled={this.checkIfFieldShouldBeDisabled("obtainedPlaceDetails")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Nazwa organu lub wytwórcy znaków akcyzy, który wydał banderole podatkowe" name="issuingAuthorityName" />
                                            <Col sm={18}>
                                                <Field component={FInput} name="issuingAuthorityName" disabled={this.checkIfFieldShouldBeDisabled("issuingAuthorityName")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Nazwa i adres podmiotu zagranicznego" name="foreignEntityDetails" />
                                            <Col sm={18}>
                                                <Field component={FInput} name="foreignEntityDetails" disabled={this.checkIfFieldShouldBeDisabled("foreignEntityDetails")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Zakres numerów ewidencyjnych od" name="scopeNumberFrom" />
                                            <Col sm={6}>
                                                <Field component={FInput} name="scopeNumberFrom" disabled={this.checkIfFieldShouldBeDisabled("scopeNumberFrom")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Zakres numerów ewidencyjnych do" name="scopeNumberTo" />
                                            <Col sm={6}>
                                                <Field component={FInput} name="scopeNumberTo" disabled={this.checkIfFieldShouldBeDisabled("scopeNumberTo")} />
                                            </Col>
                                        </FRow>
                                    </>}
                                </FPanel>
                                {props.values.paragraphType && <>
                                    {(this.state.isCorrecting || this.state.isCorrectingAsNew) &&
                                        <FPanel key='1' header={`Informacje o korekcie`}>
                                            <FRow>
                                                <this.FFieldLabel sm={6} label="Powód korekty" name="correctionReason" optional={false} />
                                                <Col sm={6}>
                                                    <Field component={FInput} name="correctionReason" disabled={false} />
                                                </Col>
                                                <this.FFieldLabel sm={6} label="Lp. korekty" name="nextCorrectionOrdinalNumber" optional={false} />
                                                <Col sm={6}>
                                                    <Field component={FInput} name="nextCorrectionOrdinalNumber" disabled={true} />
                                                </Col>
                                            </FRow>
                                            <FRow>
                                                <this.FFieldLabel label="Edytor" name="editorUserName" sm={6} optional={false} />
                                                <Col sm={9}>
                                                    <Field component={FInput} disabled={true} name="editorUserName" />
                                                </Col>
                                            </FRow>
                                        </FPanel>
                                    }
                                    <FPanel key='2' header={`Wartości liczbowe pozycji ewidencji banderol`}>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba banderol wydanych" name="issuedQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="issuedQuantity" disabled={this.checkIfFieldShouldBeDisabled("issuedQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych wydanych do oznaczania" name="issedForMarkQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="issedForMarkQuantity" disabled={this.checkIfFieldShouldBeDisabled("issedForMarkQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych przekazanych" name="transferredQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="transferredQuantity" disabled={this.checkIfFieldShouldBeDisabled("transferredQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba zwróconych banderol podatkowych" name="returnedQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="returnedQuantity" disabled={this.checkIfFieldShouldBeDisabled("returnedQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba wysłanych banderol podatkowych" name="sentQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="sentQuantity" disabled={this.checkIfFieldShouldBeDisabled("sentQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba otrzymanych banderol podatkowych" name="receivedQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="receivedQuantity" disabled={this.checkIfFieldShouldBeDisabled("receivedQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba otrzymanych banderol podatkowych do procesu oznaczania" name="receivedForMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="receivedForMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("receivedForMarkingQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba banderol wydanych za banderole podatkowe zwrócone niewykorzystane i nieuszkodzone" name="issuedForReplacedQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="issuedForReplacedQuantity" disabled={this.checkIfFieldShouldBeDisabled("issuedForReplacedQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba banderol wydanych w ramach dopuszczalnej normy strat" name="issuedForAcceptedLossQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="issuedForAcceptedLossQuantity" disabled={this.checkIfFieldShouldBeDisabled("issuedForAcceptedLossQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych naniesionych na opakowania jednostkowe wyrobów akcyzowych" name="appliedQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="appliedQuantity" disabled={this.checkIfFieldShouldBeDisabled("appliedQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych powracających" name="otherQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="otherQuantity" disabled={this.checkIfFieldShouldBeDisabled("otherQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych uszkodzonych w procesie oznaczania wyrobów akcyzowych" name="damagedInMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="damagedInMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("damagedInMarkingQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych uszkodzonych poza procesem oznaczania wyrobów akcyzowych" name="damagedOutMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="damagedOutMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("damagedOutMarkingQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba zwróconych banderol podatkowych uszkodzonych w procesie oznaczania" name="returnedDamagedInMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="returnedDamagedInMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("returnedDamagedInMarkingQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba zwróconych banderol podatkowych uszkodzonych poza procesem oznaczania" name="returnedDamagedOutMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="returnedDamagedOutMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("returnedDamagedOutMarkingQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych utraconych w procesie oznaczania wyrobów akcyzowych" name="lostInMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="lostInMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("lostInMarkingQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych utraconych poza procesem oznaczania wyrobów akcyzowych" name="lostOutMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="lostOutMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("lostOutMarkingQuantity")} />
                                            </Col>
                                        </FRow>
                                        <FRow>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych zniszczonych w procesie oznaczania wyrobów akcyzowych" name="destroyedInMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="destroyedInMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("destroyedInMarkingQuantity")} />
                                            </Col>
                                            <this.FFieldLabel sm={6} label="Liczba banderol podatkowych zniszczonych poza procesem oznaczania wyrobów akcyzowych" name="destroyedOutMarkingQuantity" />
                                            <Col sm={6}>
                                                <Field component={FInputNumber} precision={0} name="destroyedOutMarkingQuantity" disabled={this.checkIfFieldShouldBeDisabled("destroyedOutMarkingQuantity")} />
                                            </Col>
                                        </FRow>
                                    </FPanel>
                                </>}
                            </Collapse>
                            {props.values.paragraphType && <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <LinkWithConfirmation to="" actionOnClick={() => this.props.history.push(this.goBackUrl)} >
                                    <Button icon={<CloseOutlined />} size="large" >Anuluj</Button>
                                </LinkWithConfirmation>
                                <Popconfirm title="Czy na pewno chcesz wyczyścić formularz?" okText="Tak" cancelText="Nie" onConfirm={() => props.resetForm()}>
                                    <Button type="default" icon={<DeleteOutlined />} size="large" >Wyczyść</Button>
                                </Popconfirm>
                                {!(this.state.isCorrecting || this.state.isCorrectingAsNew) &&
                                    <Authorized requiresSubscription placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                                        <Button type="primary" icon={<SaveOutlined />} size="large" onClick={() => {
                                            this.saveToEditState(props.values);
                                        }}>Zapisz</Button>
                                    </Authorized>}
                                <Authorized requiresSubscription placeContext={this.props.placeContext}
                                    placeLevelPermission={(this.state.isCorrecting || this.state.isCorrectingAsNew)
                                        ? EwdPlaceLevelPermission.ExciseStampRegisterEntryCorrecting
                                        : EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                                    <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                        this.validateAndSubmitForm(props);
                                    }}>{(this.state.isCorrecting || this.state.isCorrectingAsNew) ? "Zapisz korektę" : "Zapisz ze statusem \"Gotowe\""}</Button>
                                </Authorized>
                            </FCenteredRow>}
                        </PrimaryCard>
                    }}
                </Formik>
            </>
        )
    }
}