import { RegulationStore } from "regulations/RegulationStore";
import { DictionaryName } from "../services/GeneratedClient";
import { DictionaryStore } from "./dictionaryStore";

export const filterCNCodeDictionary = (dictionaryStore: DictionaryStore, exciseProductCode: string | undefined) => {
    if (exciseProductCode) {
        let correspondences = dictionaryStore.getDictionaryValues(DictionaryName.CnCode)
            .filter(cn => dictionaryStore.getDictionaryValues(DictionaryName.CorrespondenceCnCodeExciseProduct)
                .some(y => y.value === exciseProductCode && y.description === cn.value));

        let polishCorrespondences = dictionaryStore.getDictionaryValues(DictionaryName.PolishCnCode)
            .filter(cn => dictionaryStore.getDictionaryValues(DictionaryName.PolishCorrespondenceCnCodeExciseProduct)
                .some(y => y.value === exciseProductCode && y.description === cn.value));

        return correspondences.concat(polishCorrespondences);
    }

    return dictionaryStore.concatDictionaries(DictionaryName.CnCode, DictionaryName.PolishCnCode)
};

export const filterExciseProductCodeDictionary = (dictionaryStore: DictionaryStore, cnCode: string | undefined) => {
    let fullDictionary = dictionaryStore.concatDictionaries(DictionaryName.ExciseProduct, DictionaryName.PolishExciseProduct);
    if (cnCode) {
        let cnCodes = dictionaryStore.getDictionaryValues(DictionaryName.CorrespondenceCnCodeExciseProduct)
            .filter(cn => cn.description && cn.description.startsWith(cnCode));
        let polishCnCodes = dictionaryStore.getDictionaryValues(DictionaryName.PolishCorrespondenceCnCodeExciseProduct)
            .filter(cn => cn.description && cn.description.startsWith(cnCode));
        let allCnCodes = cnCodes.concat(polishCnCodes).map(x => x.value)

        let exciseProducts = dictionaryStore.getDictionaryValues(DictionaryName.ExciseProduct)
        let polishExciseProducts = dictionaryStore.getDictionaryValues(DictionaryName.PolishExciseProduct)
        let allExciseProducts = exciseProducts.concat(polishExciseProducts)

        let allExciseProductsFiltered = allExciseProducts.filter(x => x.value && allCnCodes.includes(x.value));

        return allExciseProductsFiltered.length > 0 ? allExciseProductsFiltered : fullDictionary;
    }
    return fullDictionary;
};

export const filterRegulationsWithDefinedDirection = (dictionaryStore: DictionaryStore, regulationStore: RegulationStore, allowedParagraphs: string[], entryDirection: string | undefined) => {
    let regulations = regulationStore.regulations;
    if (entryDirection) {
        regulations = regulations!.filter(reg => entryDirection && reg.direction == entryDirection);
    }

    let regulationsDict = dictionaryStore.getDictionaryValues(DictionaryName.RegulationExciseEntry)
        .filter(reg => regulations!.find(r => r.paragraph === reg.value)?.direction);

    if (allowedParagraphs.length > 0) {
        regulationsDict = regulationsDict.filter(reg => allowedParagraphs.includes(reg.value ? reg.value : ''));
    }

    return regulationsDict;
};