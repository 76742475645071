import IFormElementConfigs from "../IFormElementConfigs";
import FormElementConfig from "../FormElementConfig";
import { yupToFormErrors } from "formik";
import * as Yup from 'yup';

export default class DefaultFormConfig
{
    private configs: IFormElementConfigs = {};

    constructor() 
    {
        this.configs["panelGeneral"] = new FormElementConfig({isVisible: true});
        this.configs["paragraphOfRegulation"] = new FormElementConfig({isVisible: true, yupSchema: Yup.string().required()});
    }

    public get(): IFormElementConfigs
    {
        return this.configs;
    }
}