import { dateToString } from 'helper/GridHelper';
import { Moment } from 'moment';
import * as Yup from 'yup';

interface IFormElementConfigProps
{
    isVisible?: boolean | undefined;
    yupSchema?: Yup.Schema<any> | undefined;
}

interface IExciseEnergyEntryFormValidationOptionalProps
{
    firstClosedDay: Moment | undefined,
    lastClosedDay: Moment | undefined
}

/**
 * Określa kongiurację jednego elementu formularza.
 */
export default class FormElementConfig implements IFormElementConfigProps
{
    isVisible: boolean | undefined;
    yupSchema: Yup.Schema<any> | undefined;

    constructor(props: IFormElementConfigProps) 
    {
        this.isVisible = props.isVisible;
        this.yupSchema = props.yupSchema;
    }

    public setYupSchemaDate(optionalDateParameters: IExciseEnergyEntryFormValidationOptionalProps | undefined) {
        if(optionalDateParameters?.lastClosedDay && optionalDateParameters?.firstClosedDay)
            this.yupSchema = this.getEntryDateValidationSchema(optionalDateParameters);
    }

    private getEntryDateValidationSchema(optionalDateParameters: IExciseEnergyEntryFormValidationOptionalProps | undefined) {
        const firstClosedDay = optionalDateParameters?.firstClosedDay;
        const lastClosedDay = optionalDateParameters!.lastClosedDay;
        const lastClosedDayLeft = optionalDateParameters!.lastClosedDay!.clone().add(1, 'days').subtract(1, 'seconds');
        const rv = Yup.date().required()
            .min(firstClosedDay!.toDate(), `Podana data zdarzenia przypada wcześniej niż\npierwszy zamknięty dzień.\nMinimalna data zdarzenia korygującego: ${dateToString(firstClosedDay)}`)
            .max(lastClosedDayLeft!.toDate(), `Podany dzień zdarzenia nie jest zamknięty.\nMaksymalna data zdarzenia korygującego: ${dateToString(lastClosedDay)}`);
        return rv;
    }
}
