import React, { createRef } from 'react';
import Tabs, { TabPane } from 'layout/tabs/Tabs';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import { Button, Col, Divider, notification, Popconfirm, Row } from 'antd';
import { Card } from 'components/StateDetailsComponents';
import { DatePicker } from 'components/DatePicker';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { CenteredRow } from 'layout/CenteredRow';
import moment, { Moment } from 'moment';
import DailyExciseEntryList from '../../exciseEntries/DailyExciseEntryList';
import { CloseDaysForExciseRegisterCmd, CloseDaysResult, CloseDayStateResult, EwdPlaceLevelPermission, ExciseRegisterClient, ExciseRegisterDto, ExciseRegisterStateClient, ExciseRegisterStateDetailsDto, ExciseRegisterStateDto } from 'services/GeneratedClient';
import ExciseRegisterInformation from 'exciseRegisters/details/components/ExciseRegisterInformation';
import ExciseRegisterStateInformation from 'exciseRegisters/details/components/ExciseRegisterStateInformation';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { ajaxCatch } from 'helper/api';
import { showErrorListModal } from 'layout/Modals';
import { Authorized } from 'authorization/Authorized';
import { AlertsStore } from 'alerts/AlertsStore';
import ExciseRegisterInventoryModal from './components/ExciseRegisterInventoryModal';
import { RouteComponentProps } from 'react-router';
import { GridStore } from 'layout/table/paginated/GridStore';
import { dateToString } from 'helper/GridHelper';
import { RegisterDetailsStore } from 'registerDetails/RegisterDetailsStore';

interface IProps extends RouteComponentProps<any> {
    exciseRegisterId: number;
    dictionaryStore?: DictionaryStore;
    alertsStore?: AlertsStore;
    gridStore?: GridStore;
    registerDetailsStore?: RegisterDetailsStore;
}

interface IState {
    selectedDate: Moment;
    exciseRegister: ExciseRegisterDto | null;
    exciseRegisterState: ExciseRegisterStateDto | null;
    isPossibleToCloseDay: boolean;
    isPossibleCreateInventory: boolean;
    isPossibleEditInventory: boolean;
    closingDays: boolean;
    exciseRegisterStateDetails: ExciseRegisterStateDetailsDto | null;
}

type OptionValue = 'all' | 'income' | 'expenditure' | 'transshipment';

interface IOption {
    label: string;
    isIncome?: boolean;
    isExpenditure?: boolean;
    isTransshipment?: boolean;
    value: OptionValue;
}

@inject("dictionaryStore")
@inject("alertsStore")
@inject("gridStore")
@inject("registerDetailsStore")
export default class ExciseRegisterDetails extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedDate: moment().asUtc(),
            exciseRegister: null,
            exciseRegisterState: null,
            isPossibleToCloseDay: false,
            isPossibleCreateInventory: true,
            isPossibleEditInventory: false,
            closingDays: false,
            exciseRegisterStateDetails: null,
        }
    }
    private exciseRegisterInventoryModal = createRef<ExciseRegisterInventoryModal>();

    private options: (IOption)[] = [
        { label: 'Przychód', value: 'income', isIncome: true },
        { label: 'Rozchód', value: 'expenditure', isExpenditure: true },
        { label: 'Przeładunek', value: 'transshipment', isTransshipment: true },
        { label: 'Wszystko', value: 'all', isIncome: true, isExpenditure: true, isTransshipment: true }
    ]

    componentDidMount() {
        this.getExciseRegister();
        this.getExciseRegisterStateDetails();
        this.props.registerDetailsStore?.checkSelectedRegister(`ExciseRegister${this.props.exciseRegisterId}`);
        this.setState({ selectedDate: this.getDate() }, () => this.getCloseDayState());
    }

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if (prevState.selectedDate !== this.state.selectedDate) {
            this.getCloseDayState();
            this.props.registerDetailsStore?.saveSelectedDate(this.state.selectedDate);
        }
        if (prevProps.exciseRegisterId !== this.props.exciseRegisterId) {
            this.getExciseRegister();
            this.getExciseRegisterStateDetails();
            this.setState({ selectedDate: this.getDate() }, () => this.getCloseDayState());
        }
        if (this.props.gridStore?.getIsReloading()) {
            this.setState({ selectedDate: this.getDate() });
        }
    }

    getDate() {
        const date = (this.props.location.state?.firstUnclosedDate && this.props.gridStore?.getIsReloading())
            ? moment(this.props.location.state.firstUnclosedDate).asDate()
            : (this.props.registerDetailsStore?.getSelectedDate() ?? this.state.selectedDate);
        this.props.gridStore?.saveIsReloading(false);
        return date;
    }

    getExciseRegister = () => {
        ajaxCatch(() =>
            new ExciseRegisterClient().get(this.props.exciseRegisterId),
            (result: ExciseRegisterDto | null) => {
                this.setState({
                    exciseRegister: result,
                    selectedDate: result?.closeDate == undefined ? this.state.selectedDate : result?.closeDate.clone().asUtc(),
                    closingDays: result?.closingDaysInProgress ?? false,
                })
            });
    }

    getExciseRegisterStateDetails = () => {
        ajaxCatch(() =>
            new ExciseRegisterStateClient().getExciseRegisterStateDetails(this.props.exciseRegisterId),
            (result: ExciseRegisterStateDetailsDto | null) => {
                this.setState({
                    exciseRegisterStateDetails: result
                })
            });
    }

    getCloseDayState = () => {
        ajaxCatch(() =>
            new ExciseRegisterStateClient().getCloseDayState(this.props.exciseRegisterId, this.state.selectedDate.asDate().asUtc()),
            (result: CloseDayStateResult | null) => {
                if (result) {
                    this.setState({
                        exciseRegisterState: result.exciseRegisterState ?? null,
                        isPossibleToCloseDay: result.isPossibleToCloseDay,
                        isPossibleCreateInventory: result.isPossibleCreateInventory,
                        isPossibleEditInventory: result.isPossibleEditInventory
                    })
                }
            });
    }

    closeDays = () => {
        this.setState({ closingDays: true });

        let cmd = new CloseDaysForExciseRegisterCmd({
            exciseRegisterId: this.props.exciseRegisterId,
            toDate: this.state.selectedDate.asUtc(),
            editorId: undefined,
        })

        ajaxCatch(() => new ExciseRegisterStateClient().closeDays(cmd),
            (result: CloseDaysResult | null) => {
                if (result && result.errors && result.errors.length > 0) {
                    showErrorListModal(result.message!, result.errors);
                } else {
                    notification.success({
                        placement: "bottomRight",
                        message: result?.message!,
                    });
                    this.getCloseDayState();
                    this.getExciseRegisterStateDetails();
                    this.props.alertsStore?.fetchAndFilterCloseDayReminder();
                    this.refresh();
                }
            }).finally(() => { this.setState({ closingDays: false }) });
    }

    onSaveInventory = () => {
        this.getCloseDayState();
        this.getExciseRegisterStateDetails();
    }

    onChange = (value: Moment | null, dateString: string) => {
        if (value) {
            this.setState({
                selectedDate: moment(value).asDate()
            });
        }
    }

    setDayToPrevious = () => {
        this.setState({
            selectedDate: moment(this.state.selectedDate.add(-1, 'days'))
        })
    }

    setDayToNext = () => {
        this.setState({
            selectedDate: moment(this.state.selectedDate.add(1, 'days'))
        });
    }

    isDisabledDate = (date: Moment) => {
        var previousDisabled = this.state.exciseRegisterStateDetails ? this.state.exciseRegisterStateDetails?.firstClosedDate.asDate() > date.asDate() : false;
        var nextDisabled = this.state.exciseRegister?.closeDate ? this.state.exciseRegister?.closeDate!.asDate() < date.asDate() : false;
        return previousDisabled || nextDisabled;
    }

    isPreviousDayDisabled = () => {
        return this.state.closingDays || dateToString(this.state.selectedDate) === dateToString(this.state.exciseRegisterStateDetails?.firstClosedDate)
    }

    isNextDayDisabled = () => {
        return this.state.closingDays || dateToString(this.state.selectedDate) === dateToString(this.state.exciseRegister?.closeDate);
    }

    isEditInventory = () => {
        return this.state.isPossibleEditInventory;
    }

    goToExciseRegisters() {
        this.props.gridStore?.saveLastUsedRowId("exciseRegisterList", this.props.exciseRegisterId.toString());
        return "/ExciseRegisters";
    }

    refresh() {
        this.dailyExciseEntryList.current?.refreshGrid();
    }

    getNumberOfDaysToClose = (): number => {
        let dateFrom = this.state.exciseRegisterStateDetails?.lastClosedDate.asUtc().asDate();
        let dateTo = this.state.selectedDate.asUtc().asDate();
        let numberOfDays = dateTo.diff(dateFrom, "days");
        return numberOfDays;
    }

    getCloseDaysPopconfirmMessage = () => {
        let dateFrom = dateToString(this.state.exciseRegisterStateDetails?.lastClosedDate.clone().add(1, "day"));
        let dateTo = dateToString(this.state.selectedDate);
        return <>
            <span>Zamykany dzień nie jest pierwszym otwartym.</span> <br />
            <span>System sprawdzi, czy w danym okresie znajdują się zdarzenia redagowane.</span> <br />
            <span>Czy na pewno chcesz zamknąć dni od {dateFrom} do {dateTo} włącznie?</span>
        </>;
    }

    getStateSummaryText = () => {
        return this.state.closingDays ? "Zamykanie dni w trakcie" : "Brak zainicjalizowanego stanu początkowego!"
    }

    dailyExciseEntryList = createRef<DailyExciseEntryList>();

    public render() {
        return (
            <>
                <PageHeaderReturn title={`SZCZEGÓŁY EWIDENCJI AKCYZOWEJ`} goBack={this.props.location.state?.firstUnclosedDate ? this.goToExciseRegisters() : this.props.history.goBack} />
                {this.state.exciseRegister && this.props.dictionaryStore &&
                    <ExciseRegisterInformation exciseRegister={this.state.exciseRegister} dictionaryStore={this.props.dictionaryStore} />}
                {(this.state.exciseRegister && this.state.exciseRegisterStateDetails) ?
                    <ExciseRegisterStateInformation
                        exciseRegisterState={this.state.exciseRegisterState}
                        exciseRegisterStateDetails={this.state.exciseRegisterStateDetails}
                        IsPossibleToCloseDay={this.state.isPossibleToCloseDay}
                        hasRegisterControlOfNegativeStates={this.state.exciseRegister.controlOfNegativeStates}
                    />
                    : <Row>
                        <Col span={12}>
                            <Card title="Podsumowanie dnia">
                                <p>{this.getStateSummaryText()}</p>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Podsumowanie całkowite">
                                <p>{this.getStateSummaryText()}</p>
                            </Card>
                        </Col>
                    </Row>
                }
                <Divider />
                <CenteredRow>
                    <Button onClick={this.setDayToPrevious} disabled={this.isPreviousDayDisabled()}>{'<'}</Button>
                    <DatePicker onChange={this.onChange} value={moment(this.state.selectedDate.asDate())} disabledDate={this.isDisabledDate} format={"YYYY-MM-DD"} disabled={this.state.closingDays} />
                    <Button onClick={this.setDayToNext} disabled={this.isNextDayDisabled()}>{'>'}</Button>
                </CenteredRow>
                <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay}
                    placeContext={this.state.exciseRegister?.placeName}>
                    <Tabs type="card" defaultActiveKey={"all"}>
                        {this.options.map(option => {
                            return <TabPane tab={option.label} key={option.value} >
                                <DailyExciseEntryList exciseRegisterId={this.props.exciseRegisterId}
                                    isIncome={option.isIncome || false}
                                    isExpenditure={option.isExpenditure || false}
                                    isTransshipment={option.isTransshipment || false}
                                    date={this.state.selectedDate}
                                    ref={this.dailyExciseEntryList}
                                />
                            </TabPane>
                        })}
                    </Tabs>
                </Authorized>
                <BottomActionButtons>
                    <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterDayClosing} placeContext={this.state.exciseRegister?.placeName}>
                        <Popconfirm placement="top"
                            disabled={this.getNumberOfDaysToClose() < 2 || this.state.closingDays}
                            title={this.getCloseDaysPopconfirmMessage()} okText="Tak" cancelText="Nie"
                            onConfirm={this.closeDays}>
                            <Button type="primary"
                                size={'large'}
                                disabled={!this.state.isPossibleToCloseDay || this.state.closingDays}
                                loading={this.state.closingDays}
                                onClick={() => { this.getNumberOfDaysToClose() < 2 && this.closeDays() }}>
                                {this.getNumberOfDaysToClose() > 1 ? "Zamknij dni do wskazanej daty" : "Zamknij dzień"}
                            </Button>
                        </Popconfirm>
                    </Authorized>
                    {this.state.exciseRegister?.controlOfNegativeStates &&
                        <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterInventoryCreate} placeContext={this.state.exciseRegister?.placeName}>
                            {
                                <>
                                    <ExciseRegisterInventoryModal
                                        exciseRegisterId={this.props.exciseRegisterId}
                                        selectedDate={this.state.selectedDate.asDate().asUtc()}
                                        ref={this.exciseRegisterInventoryModal}
                                        onSave={() => this.onSaveInventory()}
                                        isEditInventory={this.isEditInventory()}
                                    />

                                    {
                                        (<Button type="primary" size={'large'}
                                            onClick={() => this.exciseRegisterInventoryModal.current?.show()}
                                            disabled={this.state.closingDays || (!this.state.isPossibleCreateInventory && !(this.state.isPossibleToCloseDay && this.isEditInventory()))}
                                        >
                                            {
                                                (this.isEditInventory()) ?
                                                    'Redaguj inwentaryzację' : 'Rejestruj inwentaryzację'
                                            }
                                        </Button>)
                                    }
                                </>
                            }
                        </Authorized>}
                </BottomActionButtons>
            </>
        )
    }
};