import { AuditOutlined } from '@ant-design/icons';
import ConfirmActionTableButton from 'layout/ActionButtons/ConfirmActionTableButton';
import React from 'react';
import {ExciseStampActionType, ExciseStampEntryClient } from 'services/GeneratedClient';

interface ITableButtonProps {
    recordId: number;
    onSuccess?: () => void;
    onFailure?: () => void;
}

interface IState {
    loading: boolean;
}

export default class ExciseStampChangeToReadyButton extends React.PureComponent<ITableButtonProps, IState> {
    constructor(props: ITableButtonProps) {
        super(props);

        this.state = {
            loading: false
        }
    }

    request = () => {
        return new ExciseStampEntryClient().changeEntryStatus(this.props.recordId, ExciseStampActionType.Confirming );
    }


    public render() {
        return <ConfirmActionTableButton icon={<AuditOutlined />}
                                         question="Czy na pewno chcesz zmienić status zdarzenia na Gotowe?"
                                         confirmMessage="Zmieniono status wpisu."
                                         tooltip="Zmień na Gotowe"
                                         request={this.request}
                                         onSuccess={this.props.onSuccess}
                                         onFailure={this.props.onFailure}
        />
    }
}
