import * as GeneratedClient from "./GeneratedClient";
import { baseAppUrl } from "../helper/api";
import { GlobalUserManager } from "../authentication/UserManager";
import { User } from "oidc-client";

export class ClientBase {
    protected transformOptions(options: any) {
        options.headers!["Pragma"] = "no-cache";
        options.headers!["Cache-Control"] = "no-cache";

        let um = GlobalUserManager.UserManager();
        if (um) {
            let promise = um.getUser().then((user: User | null) => {
                if (user) {
                    const token = user.access_token;
                    options.headers!["Authorization"] = [`Bearer ${token}`];
                }

                return Promise.resolve(options);

            });
            return promise;
        }

        return Promise.resolve(options);
    }

    protected getBaseUrl(arg1: string, base?: string) {
        return baseAppUrl;
    }

    protected transformResult(url: string, response: Response, processor: (response: Response) => any) {
        // return response.json().then(json => {
        //     if (!response.ok) {
        //         if (response.status !== 500) {
        //             showWarning(json);
        //         } else if (response.status === 500) {
        //             showNotExpectedErrorModal();
        //         }

        //         throw new Error(json);
        //     }

        //     return json;
        // })

        return processor(response);
    }
}
