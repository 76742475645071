import { UserManager, WebStorageStateStore } from "oidc-client";
import urljoin from "url-join";
import { baseAppUrl } from "../helper/api";

export class GlobalUserManager {
    private static _userManager: UserManager | undefined = undefined;

    public static Init(authorityUrl: string): void {
        GlobalUserManager._userManager = createUserManagerWithSettings(authorityUrl);
    }

    public static UserManager = (): UserManager => {
        return GlobalUserManager._userManager!;
    }
}

function createUserManagerWithSettings(authorityUrl: string): UserManager {
    var config = {
        authority: authorityUrl,
        client_id: "ewdfront",
        redirect_uri: urljoin(baseAppUrl, "/authCallback"),
        response_type: "code",
        scope: "openid profile eWDapi",
        post_logout_redirect_uri: baseAppUrl.replace(/\$/, ""),
        loadUserInfo: true,
        automaticSilentRenew: true,
        accessTokenExpiringNotificationTime: 120,
        silent_redirect_uri: urljoin(baseAppUrl, "/silentRenew"),
        userStore: new WebStorageStateStore({ store: window.localStorage })
    };

    return new UserManager(config);
}
