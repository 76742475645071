import { Select, Button, Input } from "antd";
import * as React from "react";
import { inject, observer } from "mobx-react";
import { GridStore } from "../GridStore";
import { CenteredRow } from "layout/CenteredRow";
import { CustomSelectValue, EwdColumnProps } from "helper/GridHelper";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

interface IProps<T> {
    gridStore?: GridStore;

    column: EwdColumnProps<T>;
    handleSearch: () => void;
    gridName: string;
    filterCustomSelectValues?: CustomSelectValue[];
}

class State {
    value?: string[];
}

@inject("gridStore")
@observer
export class GridFilterCustomSelect<T> extends React.Component<IProps<T>, State> {
    constructor(props: IProps<T>) {
        super(props);

        let savedValues = this.props.gridStore!.getColumnFilter(this.props.gridName, this.props.column.key!);
        if (savedValues) savedValues = savedValues.filter((a: any) => a !== undefined);

        this.state = {
            value: savedValues
        }
    }

    public render() {
        return <>
            <CenteredRow>
                <Button type="primary" icon={<SearchOutlined />} size="small" onClick={() => this.search()} disabled={!this.state.value}>
                    Wyszukaj
                </Button>
                <Button size="small" style={{ marginLeft: 4 }} icon={<DeleteOutlined/>} onClick={() => this.clear()}>
                    Wyczyść
                </Button>
            </CenteredRow>

            <Select<string[]>
                ref={input => this.inputRef = input}
                mode="multiple"
                size="small"
                placeholder="Wybierz"
                style={{ width: 300, marginTop: 8, display: 'block' }}
                showSearch
                value={this.state.value}
                onChange={(val: string[]) => this.setState({ value: val })}
                optionFilterProp="children">
                {this.props.filterCustomSelectValues && this.props.filterCustomSelectValues.map(x =>
                    <Select.Option key={x.code} value={x.code}>{x.displayName}</Select.Option>)
                }
            </Select>
        </>
    }

    private inputRef: any;

    componentDidMount() {
    }

    componentDidUpdate() {
        var x = window.scrollX, y = window.scrollY;
        this.inputRef.focus();
        window.scrollTo(x, y);
    }

    private clear() {
        this.setState({ value: undefined });
        this.props.gridStore!.clearColumnFilter(this.props.gridName, this.props.column.key!)

        this.props.handleSearch();
    }

    private search() {
        this.props.gridStore!.saveColumnFilter(this.props.gridName, this.props.column.key!, this.state.value);
        this.props.handleSearch();
    }
}