import { filterType } from "helper/GridHelper";
import { Moment } from "moment";

export default class SieveQueryBuilder {
    protected query: string = "";

    public default(key: string, value: string | string[] | any, filter: filterType): void {
        if (key === undefined || key === "" || value === undefined) return;
        if (Array.isArray(value) && value.length === 0) return;

        switch (filter) {
            case 'contains':
                return this.contains(key, value);
            case 'equals':
                return this.equals(key, value);
            case 'dictionary':
                return this.containsAnyElement(key, value);
            case 'dateRange':
                return this.dateBetween(key, value);
            default:
                throw `Typ filtrowania ${filter} nie jest domyślnie obsługiwany`
        }
    }

    public contains(key: string, value: string): void {
        if (value === undefined || value === "") return;

        this.query += key + "@=" + value + ",";
    }

    public equals(key: string, value: string): void {
        if (value === undefined || value === "") return;

        this.query += key + "==" + value + ",";
    }

    public containsAnyElement(key: string, values: string | string[]): void {
        if (values === undefined || values === null || values.length == 0) return;
        if (values instanceof Array) {
            this.query += key + "==" + values.join("|") + ",";
        } else {
            this.query += key + "==" + values + ",";
        }
    }

    public dateBetween(key: string, values: Moment[]): void {
        if (values === undefined || values.length == 0) return;

        if (values.length > 0 && values[0] != null) {
            this.query += key + ">=" + values[0].startOf('day').asUtc().format() + ",";
        }

        if (values.length > 1 && values[1] != null) {
            this.query += key + "<=" + values[1].endOf('day').asUtc().format() + ",";
        }
    }

    public build(): string {
        this.query = this.query.slice(0, -1);
        return this.query;
    }

}