import React, { createRef } from 'react';
import { EwdColumnProps } from '../helper/GridHelper';
import { ContainerDto, ContainerClient, PlaceClient, PlaceDto } from '../services/GeneratedClient';
import { Button, Popconfirm } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';
import PaginatedTable from '../layout/table/paginated/PaginatedTable';
import { SieveModel } from '../layout/table/paginated/SieveModel';
import BottomActionButtons from '../layout/ActionButtons/BottomActionButtons';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { ajaxByUser, ajaxCatch } from '../helper/api';
import FadingTooltip from '../layout/FadingTooltip';
import TableButton from '../layout/table/tableButton/TableButton';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';

interface IProps extends RouteComponentProps<any> {
    placeId: number;
}
interface IState {
    gridName: string;
    placeName: string;
}

export default class ContainersList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "containerList",
            placeName: ""
        }
    }
    private getPlace = () => {
        ajaxCatch(() =>
            new PlaceClient().getById(this.props.placeId),
            (result: PlaceDto | null) => {
                this.setState({ placeName: result?.name! })
            });

    }


    private columns: EwdColumnProps<ContainerDto>[] = [
        {
            key: "actions",
            render: (text: string, record: ContainerDto) => (

                record.isEditable && (
                    <FadingTooltip title={record.isActive ? "Dezaktywuj" : "Aktywuj"}>
                        <Popconfirm placement="bottomRight" title={<text>Czy na pewno chcesz {record.isActive ? "dezaktywować" : "aktywować"} zbiornik {record.name}?</text>} okText="Tak" cancelText="Nie"
                            onConfirm={() => {
                                ajaxByUser(record.isActive ? "Dezaktywowano zbiornik" : "Aktywowano zbiornik", () =>
                                    new ContainerClient().switchIsActive(record.id!).then(() => {
                                        this.refresh();
                                    }));
                            }}>
                            <TableButton icon={record.isActive ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                        </Popconfirm>
                    </FadingTooltip>
                )
            ),
            width: 40,
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            filter: "contains",
        },
        {
            dataIndex: "isActive",
            key: "IsActive",
            title: "Czy aktywne",
            render: (text: any, record: ContainerDto) => record.isActive ? "TAK" : "NIE",
        },
    ];

    private refresh() {
        this.paginatedTable.current?.refresh();
    }

    private paginatedTable = createRef<PaginatedTable<ContainerDto>>();

    public render() {
        this.getPlace()
        return (
            <>
                <PageHeaderReturn title={`ZBIORNIKI MIEJSCA - ${this.state.placeName}`} goBack={`/Admin/Places`} />
                <PaginatedTable<ContainerDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ContainerDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ContainerClient().getAll(this.props.placeId, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
                <BottomActionButtons>
                    <Link to={`/Admin/Containers/Add/${this.props.placeId}`}>
                        <Button>Dodaj zbiornik</Button>
                    </Link>
                </BottomActionButtons>
            </>
        )
    }
};