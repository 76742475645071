import { Button, Col } from "antd";
import { inject, observer } from "mobx-react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { PageHeader } from "layout/PageHeader";
import { CreateExciseStampRegisterStateCmd, ExciseStampRegisterStateClient, ExciseStampRegisterStateDto } from "services/GeneratedClient";
import { FCenteredRow, FDatePicker, FFieldLabel, FInputNumber } from "forms/FormikFormItems";
import { ajaxByUser } from "helper/api";
import ExciseStampRegisterStateValidation from "./ExciseStampRegisterStateValidation";
import PrimaryCard from "layout/PrimaryCard";

interface IProps extends RouteComponentProps<any> {
    exciseStampRegisterId: number;
}

interface IState {
    isLoading: boolean;
}

@inject("dictionaryStore")
@inject("registerStore")
@observer
export default class ExciseStampRegisterStateCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/ExciseStampRegisters`;
    private validation: ExciseStampRegisterStateValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false
        };

        this.validation = new ExciseStampRegisterStateValidation();
    }

    public send(values: ExciseStampRegisterStateDto, actions: FormikHelpers<ExciseStampRegisterStateDto>) {
        this.setState({ isLoading: true });
        values.registerId = this.props.exciseStampRegisterId;
        values.date = values.date.asUtc();
        let cmd = new CreateExciseStampRegisterStateCmd({
            exciseStampRegisterStateDto: values
        });
        ajaxByUser("Zainicjalizowano stan początkowy", () =>
            new ExciseStampRegisterStateClient().createInitialState(cmd)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    this.props.history.push(this.goBackUrl);
                }).finally(() => this.setState({ isLoading: false })));
    }

    public render() {
        return (
            <>
                <PageHeader title={`INICJALIZOWANIE STANU POCZĄTKOWEGO EWIDENCJI BANDEROL`} />
                <Formik<ExciseStampRegisterStateDto> initialValues={new ExciseStampRegisterStateDto()}
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<ExciseStampRegisterStateDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Data"  sm={24} />
                                    <Field component={FDatePicker} name="date" />
                                </Col>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Stan banderol" sm={24} />
                                    <Field component={FInputNumber} precision={0} name="stampItemsQuantity" />
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Stan banderol w siedzibie" sm={24} />
                                    <Field component={FInputNumber} precision={0} name="stampItemsInRegisterQuantity" />
                                </Col>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Stan banderol poza siedzibą" sm={24} />
                                    <Field component={FInputNumber} precision={0} name="stampItemsOutOfRegisterQuantity" />
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Stan banderol naniesionych" sm={24} />
                                    <Field component={FInputNumber} precision={0} name="stampItemsAppliedQuantity" />
                                </Col>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Stan banderol uszkodzonych" sm={24} />
                                    <Field component={FInputNumber} precision={0} name="stampItemsDamagedQuantity" />
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Stan banderol utraconych" sm={24} />
                                    <Field component={FInputNumber} precision={0} name="stampItemsLostQuantity" />
                                </Col>
                                <Col xl={6} sm={10}>
                                    <FFieldLabel label="Stan banderol zniszczonych" sm={24} />
                                    <Field component={FInputNumber} precision={0} name="stampItemsDestroyedQuantity" />
                                </Col>
                            </FCenteredRow>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                    props.submitForm();
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}