import React from "react";
import { Input, Button } from "antd";
import { inject, observer } from "mobx-react";
import { GridStore } from "../GridStore";
import {default as Tooltip} from "layout/Tooltip";
import { FilterOutlined } from "@ant-design/icons";
import TableButton from "layout/table/tableButton/TableButton";

interface IProps<T> {
    handleSearch: () => void;
    gridStore?: GridStore;
    gridName: string;
    anyFilters: boolean;
}

interface IState {
}

@inject("gridStore")
@observer
export default class GridClearFilters<T> extends React.Component<IProps<T>, IState> {
    constructor(props: IProps<T>) {
        super(props);

        this.state = {
        }
    }

    private searchInput: Input | null = null;

    public select() {
        this.searchInput && this.searchInput.select();
    }

    public render() {
        return <Tooltip title="Zresetuj filtry/sortowanie" placement="topRight">
            <TableButton type="ghost" icon={<FilterOutlined />} className={`grid-filter-${this.props.anyFilters? "" : "in"}active`} onClick={() => this.clear()} />
        </Tooltip>
    }

    private clear() {
        this.props.gridStore!.resetGrid(this.props.gridName);
        this.props.handleSearch();
    }
}