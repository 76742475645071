import React, { createRef } from 'react';
import { dateRenderer, dateToString, EwdColumnProps } from 'helper/GridHelper';
import { AdminExciseRegisterDto, CompanyLevelPermission, DictionaryName, ExciseRegisterClient } from 'services/GeneratedClient';
import { Link } from 'react-router-dom';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from "layout/FadingTooltip";
import { AppstoreAddOutlined, CloseOutlined, DeleteOutlined, FilePptOutlined, MinusSquareOutlined, PlusSquareOutlined, SearchOutlined, ToolOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Button, Popconfirm } from 'antd';
import { GridStore } from 'layout/table/paginated/GridStore';
import { ajaxByUser } from 'helper/api';
import { Authorized } from 'authorization/Authorized';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class AdminExciseRegisterList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "adminExciseRegisterList"
        }
    }

    public componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<AdminExciseRegisterDto>[] = [
        {
            key: "actions",
            render: (text: string, record: AdminExciseRegisterDto) => (
                <>
                    <FadingTooltip title="Szczegóły ewidencji">
                        <Link to={`/ExciseRegisters/Details/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                        </Link>
                    </FadingTooltip>
                    {!record.isStateInitiated && <FadingTooltip title="Inicjalizuj stan początkowy">
                        <Link to={`/Admin/ExciseRegisters/CreateInitialState/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ToolOutlined />} />
                        </Link>
                    </FadingTooltip>}
                    {!record.closeDate && <FadingTooltip title="Przypisanie zbiorników">
                        <Link to={`/Admin/ExciseRegisters/ExciseRegisterContainers/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<AppstoreAddOutlined />} />
                        </Link>
                    </FadingTooltip>}
                    {!record.closeDate && <FadingTooltip title="Konfiguracja Paragrafów">
                        <Link to={`/Admin/ExciseRegisters/ExciseRegisterParagraphConfiguration/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<FilePptOutlined />} />
                        </Link>
                    </FadingTooltip>}
                    {
                        record.canDelete &&
                        <Authorized companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                            <FadingTooltip title={"Usuń pusty rejestr"}>
                                <Popconfirm placement="bottomRight" title={`Czy na pewno chcesz usunąć rejestr akcyzowy o identyfikatorze ${record.id}? Operacji nie można wycofać.`} okText="Tak" cancelText="Nie"
                                    onConfirm={() => {
                                        ajaxByUser(`Poprawnie usunięto rejestr akcyzowy o identyfikatorze ${record.id}`, () =>
                                            new ExciseRegisterClient().deleteEmptyExciseRegister(record.id!).then(() => {
                                                this.refresh();
                                            }));
                                    }}>
                                    <TableButton icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </FadingTooltip>
                        </Authorized>
                    }
                    {
                        record.canClose &&
                        <Authorized companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                            <FadingTooltip title={"Zamknij rejestr"}>
                                <Popconfirm placement="bottomRight" title={`Czy na pewno chcesz zamknąć rejestr akcyzowy o identyfikatorze ${record.id} z datą ${dateToString(record.lastCloseDayDate)}? Operacji nie można wycofać.`} okText="Tak" cancelText="Nie"
                                    onConfirm={() => {
                                        ajaxByUser(`Poprawnie zamknięto rejestr akcyzowy o identyfikatorze ${record.id}`, () =>
                                            new ExciseRegisterClient().closeExciseRegister(record.id!, record.lastCloseDayDate!).then(() => {
                                                this.refresh();
                                            }));
                                    }}>
                                    <TableButton icon={<CloseOutlined />} />
                                </Popconfirm>
                            </FadingTooltip>
                        </Authorized>
                    }
                    {!record.isStateInitiated &&
                        <Authorized companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                            <FadingTooltip title={`${record.controlOfNegativeStates ? "Wyłącz" : "Włącz"} kontrolę stanów ujemnych`}>
                                <Popconfirm placement="bottomRight"
                                    title={<>Czy na pewno chcesz {record.controlOfNegativeStates ? "wyłączyć " : "włączyć "}
                                        kontrolę stanów ujemnych dla rejestru akcyzowego o id {record.id}?<br />
                                        Kontroli nie można ponownie {record.controlOfNegativeStates ? "włączyć" : "wyłączyć"} po zainicjalizowaniu stanu.</>}
                                    okText="Tak"
                                    cancelText="Nie"
                                    onConfirm={() => {
                                        ajaxByUser(`Poprawnie ${record.controlOfNegativeStates ? "wyłączono" : "włączono"} 
                                        kontrolę stanów ujemnych dla rejestru akcyzowego o identyfikatorze ${record.id}`, () =>
                                            new ExciseRegisterClient().switchControlOfNegativeStates(record.id!).then(() => {
                                                this.refresh();
                                            }));
                                    }}>
                                    <TableButton icon={record.controlOfNegativeStates ? <PlusSquareOutlined /> : <MinusSquareOutlined />} />
                                </Popconfirm>
                            </FadingTooltip>
                        </Authorized>
                    }
                </>
            ),
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            sorter: true,
            filter: "contains",
        },
        {
            dataIndex: "placeName",
            key: "Place.Name",
            title: "Miejsce",
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN wyrobu",
            filter: "contains",
        },
        {
            dataIndex: "exciseUnitOfMeasure",
            key: "ExciseUnitOfMeasure",
            render: (text: number) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, text),
            title: "Jednostka miary akcyzowej",
        },
        {
            dataIndex: "inventoryUnitOfMeasure",
            key: "InventoryUnitOfMeasure",
            render: (text: number) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, text),
            title: "Jednostka miary techniczna",
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (status: string) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.ExciseRegisterStatus, status),
            title: "Status",
        },
        {
            dataIndex: "closeDate",
            key: "CloseDate",
            title: "Data zamknięcia",
            render: (text: any, record: AdminExciseRegisterDto) => dateRenderer(record.closeDate),
            filter: "dateRange",
        },
        {
            dataIndex: "controlOfNegativeStates",
            key: "ControlOfNegativeStates",
            title: "Ewidencja dla PPW",
            width: 70,
            render: (text: any, record: AdminExciseRegisterDto) => record.controlOfNegativeStates ? "NIE" : "TAK",
        },
    ];

    private refresh() {
        this.paginatedTable.current?.refresh();
    }

    private paginatedTable = createRef<PaginatedTable<AdminExciseRegisterDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`EWIDENCJE AKCYZOWE`} />
                <PaginatedTable<AdminExciseRegisterDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: AdminExciseRegisterDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseRegisterClient().getAdminList(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
                <BottomActionButtons>
                    <Authorized requiresSubscription>
                        <Link to="/Admin/ExciseRegisters/Add">
                            <Button>Dodaj ewidencję</Button>
                        </Link>
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};