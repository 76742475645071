import { Button, Card, PageHeader, Transfer } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router";
import { ExciseRegisterClient, ExciseRegisterContainerDto, SaveExciseRegisterContainerCmd } from "../../services/GeneratedClient";
import { ajaxByUser, ajaxCatch } from "../../helper/api";
import { CenteredRow } from "layout/CenteredRow";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import "./exciseRegisterContainers.less";
import { RegisterStore } from "register/RegisterStore";
import { inject } from "mobx-react";

interface IProps extends RouteComponentProps<any> {
    exciseRegisterId: number;
    registerStore?: RegisterStore;
}
interface IState {
    isLoading: boolean
    availableContainers: any[],
    selectedContainers: string[] | undefined,
}
@inject("registerStore")
export default class ExciseRegisterContainers extends React.PureComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            availableContainers: [],
            selectedContainers: [],
            isLoading: false
        };
    }
    public componentDidMount() {
        this.getInitialValues(this.props.exciseRegisterId)
    }
    private readonly goBackUrl = `/Admin/ExciseRegisters`;

    private getInitialValues = (registerId: number) => {
        ajaxCatch(() =>
            new ExciseRegisterClient().getExciseRegisterContainer(registerId)
                .then((resp: ExciseRegisterContainerDto | null) => {
                    this.setState({
                        isLoading: false,
                        availableContainers: resp!.availableContainers!.map(x => ({
                            key: x.id.toString(),
                            title: x.name,
                            disabled: !x.isEditable
                        })
                        ),
                        selectedContainers: resp!.selectedContainers!.map(x => x.id.toString())
                    })
                }));
    }
    handleChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
        this.setState({ selectedContainers: nextTargetKeys });

    };

    handleSelectChange = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
        this.setState({ selectedContainers: [...sourceSelectedKeys, ...targetSelectedKeys] });
    };

    public save() {
        this.setState({ isLoading: true })
        let cmd = new SaveExciseRegisterContainerCmd({
            exciseRegisterId: this.props.exciseRegisterId,
            containersIds: this.state.selectedContainers?.map(x => Number(x))
        });
        ajaxByUser("Zaktualizowano przypisanie zbiorników", () =>
            new ExciseRegisterClient().saveExciseRegisterContainers(cmd)
                .then((resp: any) => {
                    this.props.registerStore?.refreshRegisters();
                    this.props.history.push(this.goBackUrl);
                }).finally(() => this.setState({ isLoading: false })));
    }

    public render() {
        return (
            <>
                <PageHeader title={`Przypisanie zbiorników do ewidencji`} />
                <Card>
                    <Transfer
                        style={{ "justifyContent": "center" }}
                        dataSource={this.state.availableContainers}
                        showSearch
                        targetKeys={this.state.selectedContainers}
                        onChange={this.handleChange}
                        render={item => item.title}
                        titles={['Do przypisania', 'Przypisane']}
                        selectAllLabels={[
                            ({ selectedCount, totalCount }) => (
                                <span>
                                    {selectedCount} z {totalCount}
                                </span>
                            ), ({ selectedCount, totalCount }) => (
                                <span>
                                    {selectedCount} z {totalCount}
                                </span>
                            )
                        ]}
                        listStyle={{
                            width: 400,
                            height: 600,
                        }}
                        locale={{
                            searchPlaceholder: "Wyszukaj",
                        }}
                    />
                    <CenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                        <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                            this.props.history.push(this.goBackUrl);
                        }}>Anuluj</Button>
                        <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                            this.save();
                        }}>Zapisz</Button>
                    </CenteredRow>
                </Card>
            </>
        )
    }
}


