import { ColumnProps } from "antd/lib/table";
import React, { Key } from "react";
import { Tree, Popover, Button } from 'antd';
import { inject, observer } from "mobx-react";
import { AccountDetailsStore } from "account/accountDetailsStore";
import ColumnHelper from "./ColumnHelper";
import { GridSettingsDto } from "services/GeneratedClient";
import Tooltip from "layout/Tooltip";
import { ColumnWidthOutlined } from "@ant-design/icons";
import TableButton from "../tableButton/TableButton";
import { DataNode } from "antd/lib/tree";

interface IProps {
    allColumns: ColumnProps<any>[];
    accountDetailsStore?: AccountDetailsStore;
    gridSettings?: GridSettingsDto;
    gridName: string;
}
interface IState {
    visible: boolean;
    checkedKeys: Key[];
}

@inject("accountDetailsStore")
@observer
export default class ColumnSelector extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            visible: false,
            checkedKeys: ColumnHelper.getVisibleColumnsKeys(this.props.gridSettings, this.props.allColumns)
        }
    }


    componentDidUpdate(prevProps: any) {
        if (prevProps.gridName !== this.props.gridName) {
            this.setState(prevState => ({ checkedKeys: [...ColumnHelper.getVisibleColumnsKeys(this.props.gridSettings, this.props.allColumns)] }))
        }
    }

    public render() {
        const columns = this.props.allColumns.filter(a => a.key !== "actions");
        const treeData = columns.map(column => ({
            selectable: false,
            disableCheckbox: column.key != null ? this.state.checkedKeys.length <= 1 && this.state.checkedKeys.includes(column.key.toString()) : false,
            title: column.title as React.ReactNode,
            key: column.key != null ? column.key!.toString() : "",
        } as DataNode));

        return (
            <Popover
                placement="left"
                content={<Tree
                    blockNode
                    checkable
                    virtual={false}
                    height={500}
                    treeData={treeData}
                    checkedKeys={ColumnHelper.getVisibleColumnsKeys(this.props.gridSettings, columns)}
                    onCheck={(checked: { checked: Key[]; halfChecked: Key[]; } | Key[]) => {
                        const checkedKeys = Array.isArray(checked) ? checked : checked.checked;
                        this.setState({ checkedKeys: checkedKeys });
                        this.props.accountDetailsStore!.saveVisibleColumns(this.props.gridName, checkedKeys);
                    }} />}
                trigger="click"
                title={
                    <span style={{ display: "flex", justifyContent: "space-between", marginTop: "4px", marginBottom: "4px" }}>
                        Widoczne kolumny
                        <Button type="primary" size="small" onClick={() => {
                            const checkedKeys = this.props.allColumns.map(column => column.key!);
                            this.setState({ checkedKeys: checkedKeys });
                            this.props.accountDetailsStore!.saveVisibleColumns(this.props.gridName, checkedKeys);
                        }}>
                            Zaznacz wszystko
                        </Button>
                    </span>}
                visible={this.state.visible}
                onVisibleChange={(v) => {
                    this.setState({ visible: v });
                }} >
                <Tooltip title="Widoczność kolumn" placement="topRight">
                    <TableButton icon={<ColumnWidthOutlined />} onClick={() => this.setState({ visible: true })} />
                </Tooltip>
            </Popover >
        )
    }
}