import React from "react";
import { RegulationClient, RegulationSchemaDto } from "../services/GeneratedClient";
import { ajaxCatch } from "../helper/api";
import { inject, observer } from "mobx-react";
import { RegulationStore } from "./RegulationStore";

interface IProps {
    regulationStore?: RegulationStore;
}

interface IState {
    regulationsLoaded: boolean;
}

@inject("regulationStore")
@observer
export default class RegulationProvider extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            regulationsLoaded: false
        };
    }

    public componentDidMount() {
        this.fetchRegulations()
    }

    private fetchRegulations() {
        ajaxCatch(() =>
            new RegulationClient().getValidationsSchemasPerParagraph(),
            (regulations: RegulationSchemaDto[] | null) => {
                this.props.regulationStore!.setRegulations(regulations!);
                this.setState({
                    regulationsLoaded: true
                })
            });
    }

    public render() {
        if (this.state.regulationsLoaded && this.props.children) return this.props.children;
        return null;
    }
}