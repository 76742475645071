import React from 'react';
import { Table as AntdTable } from 'antd';
import { TableProps as AntdTableProps } from 'antd/lib/table';
export interface TableProps<T> extends AntdTableProps<T> {}

const Table = <T extends object>(props: TableProps<T>) =>
    <AntdTable
        size={"small"}
        rowClassName={(record, index) => {
            if (index % 2 === 1)
                return "row-alternate";
            return "";
        }}
        {...props}
    />

export default Table;