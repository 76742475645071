import React from 'react';
import { Button, Card, PageHeader, Row, Space, Transfer } from "antd";
import { useEffect, useState } from "react";
import { DictionaryName, ExciseRegisterClient, SaveExciseRegisterRegulationConfigurationCmd } from "services/GeneratedClient";
import { useRegulationStore } from '../../regulations/RegulationStore'
import PrimaryCard from 'layout/PrimaryCard';
import { useDictionaryStore } from 'dictionary/dictionaryStore';
import { SaveOutlined } from '@ant-design/icons';
import { FCenteredRow } from 'forms/FormikFormItems';
import { ajaxByUser } from 'helper/api';
import { Link } from 'react-router-dom';

interface IProps {
    exciseRegisterId: number
}


const ExciseRegisterParagraphConfiguration = (props: IProps) => {
    var regulationStore = useRegulationStore();
    var dictionaryStore = useDictionaryStore();

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [initialData, setInitialData] = useState(loadInitialData)

    useEffect(() => {
        regulationStore.fetchExciseRegisterParagraphConfiguration(props.exciseRegisterId).then((result: string[] | null) => {
            setTargetKeys(result != null ? result! : [])
        })
    }, [props.exciseRegisterId]);

    function loadInitialData() {
        let regulations = regulationStore.regulations;
        return dictionaryStore.getDictionaryValues(DictionaryName.RegulationExciseEntry)
            .filter(reg => regulations!.find(r => r.paragraph === reg.value)?.direction)
            .map(item => ({
                key: item.value,
                title: item.displayValue,
                code: item.value
            }))
    }

    function onChange(nextTargetKeys: string[]) {
        setTargetKeys(nextTargetKeys);
    };

    function onSelectChange(sourceSelectedKeys: string[], targetSelectedKeys: string[]) {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    function save () {
        ajaxByUser("Zapisano konfigurację paragrafów.", 
            () => new ExciseRegisterClient().saveExciseRegisterRegulationConfiguration(
                new SaveExciseRegisterRegulationConfigurationCmd({ exciseRegisterId: props.exciseRegisterId, paragraphList: targetKeys }) 
            ));
    }

    return (
        <>
            <PageHeader title={`KONFIGURACJA PARAGRAFÓW`} />
            <Card>
                <Transfer
                    style={{ "justifyContent": "center" }}
                    dataSource={initialData}
                    titles={['Do wybrania', 'Wybrane']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    render={(item: any) => item.title}
                    listStyle={{
                        width: 400,
                        height: 600,
                    }}
                />
                <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                    <Link to={'/Admin/ExciseRegisters'}>
                            <Button danger size="large" type="primary">Anuluj</Button>
                    </Link>
                    <Button type="primary" icon={<SaveOutlined />} size="large" onClick={save}>Zapisz</Button>
                </FCenteredRow>
            </Card>
        </>
    )
};

export default ExciseRegisterParagraphConfiguration;