import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { DictionaryName, EwdPlaceLevelPermission, ExciseStampRegisterClient, ExciseStampRegisterDto, } from 'services/GeneratedClient';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { BellOutlined, ReadOutlined, ReconciliationOutlined, SearchOutlined } from '@ant-design/icons';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { Authorized } from 'authorization/Authorized';
import { GridStore } from 'layout/table/paginated/GridStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class ExciseStampRegisterList extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "exciseStampRegisterGrid"
        }
    }

    componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<ExciseStampRegisterDto>[] = [
        {
            key: "actions",
            width: 120,
            render: (text: string, record: ExciseStampRegisterDto) => (
                <>
                    <Authorized placeContext={record.placeName} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryDisplay}>
	                    <FadingTooltip title="Zamknięte pozycje zdarzeń">
	                        <Link to={`/ExciseStampRegisters/${record.id}/${record.exciseStampRegisterType}/Entries/Completed`}>
	                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ReadOutlined />} />
	                        </Link>
	                    </FadingTooltip>
	                    <FadingTooltip title="Pozycje Rejestru Zdarzeń">
	                        <Link to={`/ExciseStampRegisters/${record.id}/${record.exciseStampRegisterType}/Entries/Current`}>
	                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ReconciliationOutlined />} />
	                        </Link>
	                    </FadingTooltip>
                    </Authorized>
                    <FadingTooltip title="Szczegóły ewidencji">
                        <Link to={`/ExciseStampRegisters/Details/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                        </Link>
                    </FadingTooltip>
                </>
            )
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "exciseStampRegisterType",
            key: "ExciseStampRegisterType",
            title: "Typ",
            render: (text: string) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.ExciseStampRegisterType, text),
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            sorter: true,
            filter: "contains",
        },
        {
            dataIndex: "placeName",
            key: "PlaceName",
            title: "Miejsce",
        },
        {
            dataIndex: "exciseStampType",
            key: "ExciseStampType",
            title: "Rodzaj",
            filter: "contains"
        },
        {
            dataIndex: "ownerDetails",
            key: "OwnerDetails",
            title: "Dane właściciela",
            filter: "contains",
        }
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseStampRegisterDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`EWIDENCJE BANDEROL`} />
                <PaginatedTable<ExciseStampRegisterDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseStampRegisterDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseStampRegisterClient().getAll(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </>
        )
    }
};