import { DeleteOutlined } from '@ant-design/icons';
import ConfirmActionTableButton from 'layout/ActionButtons/ConfirmActionTableButton';
import React from 'react';
import { ExciseEntryClient } from 'services/GeneratedClient';

interface ITableButtonProps {
    recordId: number;
    isAwaiting?: boolean;
    onSuccess?: () => void;
    onFailure?: () => void;
}

interface IState {
    loading: boolean;
}

export default class DeleteEntryButton extends React.PureComponent<ITableButtonProps, IState> {
    constructor(props: ITableButtonProps) {
        super(props);

        this.state = {
            loading: false
        }
    }

    request = () => {
        return this.props.isAwaiting
            ? new ExciseEntryClient().deleteAwaitingEntry(this.props.recordId)
            : new ExciseEntryClient().deleteEntry(this.props.recordId);
    }


    public render() {
        return <ConfirmActionTableButton icon={<DeleteOutlined />}
                                         question="Czy na pewno chcesz usunąć zdarzenie?"
                                         confirmMessage="Usunięto zdarzenie"
                                         tooltip="Usuń"
                                         request={this.request}
                                         onSuccess={this.props.onSuccess}
                                         onFailure={this.props.onFailure}
        />
    }
}
