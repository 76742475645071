import { PrinterOutlined } from "@ant-design/icons";
import { PageHeader } from 'layout/PageHeader';
import BottomActionButtons from "layout/ActionButtons/BottomActionButtons";
import React, { createRef } from "react";
import { RouteComponentProps } from "react-router";
import { Button, Checkbox, notification } from "antd";
import { DatePicker } from "components/DatePicker";
import { FLabel } from "forms/FormikFormItems";
import { CreateDailyReportsPdfRequest, DailyReportsClient, DictionaryName, ExciseRegisterDto } from "services/GeneratedClient";
import moment, { Moment } from 'moment';
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { inject } from "mobx-react";
import { dateRenderer, EwdColumnProps } from 'helper/GridHelper';
import { DictionaryStore } from "dictionary/dictionaryStore";
import { showNotExpectedErrorModal } from "layout/Modals";
import EwdCard from "components/EwdCard";
import { CenteredRow } from "layout/CenteredRow";
import { SieveModel } from "layout/table/paginated/SieveModel";
import PaginatedTable from "layout/table/paginated/PaginatedTable";
import { GridStore } from "layout/table/paginated/GridStore";
import { downloadFile } from "helper/api";
import { CenteredSpin } from "layout/CenteredSpin";
import { AccountDetailsStore } from "account/AccountDetailsStore";

interface IProps extends RouteComponentProps<any> {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
    accountDetailsStore?: AccountDetailsStore;
}

interface IState {
    loading: boolean;
    generating: boolean;
    reportDate: Moment | null;
    includeClosedRegisters: boolean;
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
@inject("accountDetailsStore")
export default class DailyReports extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            generating: false,
            reportDate: moment().add(-1, "days"),
            includeClosedRegisters: false,
            gridName: "exciseRegisterList",
        };
    }

    private columns: EwdColumnProps<ExciseRegisterDto>[] = [
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            filter: "contains",
        },
        {
            dataIndex: "placeName",
            key: "Place.Name",
            title: "Miejsce",
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN wyrobu",
            filter: "contains",
        },
        {
            dataIndex: "exciseUnitOfMeasure",
            key: "ExciseUnitOfMeasure",
            render: (text: number) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, text),
            title: "Jednostka miary akcyzowej",
        },
        {
            dataIndex: "inventoryUnitOfMeasure",
            key: "InventoryUnitOfMeasure",
            render: (text: number) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, text),
            title: "Jednostka miary techniczna",
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (status: string) =>
                this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.ExciseRegisterStatus, status),
            title: "Status",
        },
        {
            dataIndex: "closeDate",
            key: "CloseDate",
            title: "Data zamknięcia",
            render: (text: any, record: ExciseRegisterDto) => dateRenderer(record.closeDate),
            filter: "dateRange",
        },
    ];

    private onReportDateChange = async (date: Moment | null) => {
        await this.setState({ reportDate: date!.startOf("day").asUtc() });
        this.paginatedTable.current?.refresh();
    }

    private onIncludeClosedRegistersCheckboxCheck = async (props: CheckboxChangeEvent) => {
        await this.setState({ includeClosedRegisters: props.target.checked });
        this.paginatedTable.current?.refresh();
    }

    private sendCreateDailyReportsQuery = () => {
        let keys = this.paginatedTable.current?.getSelectedRowKeys().map(x => +x.valueOf());
        if (keys!.length == 0) {
            notification.error({
                message: "Nie wybrano żadnej ewidencji.",
                placement: "bottomRight",
            });
            return;
        }

        let request = new CreateDailyReportsPdfRequest({
            reportDate: this.state.reportDate!,
            exciseRegisterIds: keys!,
            ianaTimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        this.setState({ generating: true });
        new DailyReportsClient().createDailyReportsPdf(request)
            .then(response => {
                downloadFile(response.data, response.fileName!);
            })
            .catch(error => {
                showNotExpectedErrorModal(error);
            })
            .finally(() => {
                this.setState({ generating: false });
            });
    }

    private paginatedTable = createRef<PaginatedTable<ExciseRegisterDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`WYDRUKI JEDNODNIOWE`} />

                <EwdCard>
                    {this.state.generating ? this.generationSpin() : this.searchSection()}
                </EwdCard>

                <BottomActionButtons style={{ marginTop: "10px" }}>
                    <Button type="primary" disabled={this.state.loading} loading={this.state.generating} icon={<PrinterOutlined />} onClick={this.sendCreateDailyReportsQuery}>
                        Wydrukuj do pliku PDF
                    </Button>
                </BottomActionButtons>
            </>
        );
    }

    public searchSection() {
        return <>
            <CenteredRow style={{ marginBottom: "10px" }}>
                <FLabel label="Dzień raportowany" />
                <DatePicker allowClear={false} onChange={this.onReportDateChange} value={this.state.reportDate} />
                <FLabel label="Uwzględnij zamnięte ewidencje" />
                <Checkbox checked={this.state.includeClosedRegisters} onChange={this.onIncludeClosedRegistersCheckboxCheck} />
            </CenteredRow>

            <PageHeader title={`WYBÓR EWIDENCJI DO WYDRUKU`} />
            <PaginatedTable<ExciseRegisterDto>
                ref={this.paginatedTable}
                selectable={true}
                gridName={this.state.gridName}
                columns={this.columns}
                columnsSelector={true}
                getRowKey={(r: ExciseRegisterDto) => r.id!.toString()}
                getPagedResult={(sieve: SieveModel) => {
                    return new DailyReportsClient().getExciseRegistersForDailyReports(
                        this.state.reportDate!.asUtc(),
                        this.state.includeClosedRegisters,
                        sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                }} />
        </>
    }

    public generationSpin() {
        return <>
            <CenteredSpin size="large">Trwa generowanie wydruków, proszę czekać...</CenteredSpin>
        </>
    }

};