import React, { createRef } from 'react';
import { EwdColumnProps, booleanRenderer, dateRenderer, dateTimeRenderer, yesNoBooleanValueProvider } from 'helper/GridHelper';
import { ReportClient, SieveModel as ClientSieveModel, ExportColumn, CreateReportRequest, ExciseEnergyRegisterReportDto, CsvReportClient } from 'services/GeneratedClient';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { Moment } from 'moment';
import { GeneratePdfButton } from '../../components/GeneratePdfButton';
import { GenerateCSVButton } from 'components/GenerateCSVButton';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseEnergyRegisterId?: number;
    dateFrom?: Moment;
    dateTo?: Moment;
    showDaily: boolean;
}

interface IState {
    gridName: string;
    pageHeader: string;
    sieveModelUp?: SieveModel | undefined;
}

@inject("dictionaryStore")
export default class ExciseEnergyRegisterReportList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "ExciseEnergyRegisterReportGrid",
            pageHeader: "RAPORT EWIDENCJI ENERGII ELEKTRYCZNEJ"
        }
    }

    private columns: (EwdColumnProps<ExciseEnergyRegisterReportDto>)[] = [
        {
            key: "actions"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id"
        },
        {
            dataIndex: "ordinalNumbers",
            key: "OrdinalNumbers",
            title: "Lp. | Lp. korekty"
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            title: "Paragraf"
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (text: any, record: ExciseEnergyRegisterReportDto) => dateRenderer(record.date),
            width: 90,
        },
        {
            dataIndex: "actionDate",
            key: "ActionDate",
            title: "Data wprowadzenia / edycji",
            render: (text: any, record: ExciseEnergyRegisterReportDto) => dateTimeRenderer(record.actionDate),
            width: 90,
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
        },
        {
            dataIndex: "exciseAmount",
            key: "ExciseAmount",
            title: "Kwota akcyzy",
        },
        {
            dataIndex: "exciseDuty",
            key: "ExciseDuty",
            title: "Czy opodatkowana?",
            render: (text: any, record: ExciseEnergyRegisterReportDto) => booleanRenderer(record.exciseDuty, yesNoBooleanValueProvider),
        },
        {
            dataIndex: "exciseExemptionReason",
            key: "ExciseExemptionReason",
            title: "Powód zwolnienia",
        },
        {
            dataIndex: "invoiceNumber",
            key: "InvoiceNumber",
            title: "Nr faktury",
        },
        {
            dataIndex: "supplierIdentifier",
            key: "SupplierIdentifier",
            title: "Nr dostawcy",
        },
        {
            dataIndex: "supplierName",
            key: "SupplierName",
            title: "Nazwa dostawcy",
        },
        {
            dataIndex: "supplierAddress",
            key: "SupplierAddress",
            title: "Adres dostawcy",
        },
        {
            dataIndex: "recipientIdentifier",
            key: "RecipientIdentifier",
            title: "Nr odbiorcy",
        },
        {
            dataIndex: "recipientName",
            key: "RecipientName",
            title: "Nazwa odbiorcy",
        },
        {
            dataIndex: "recipientAddress",
            key: "RecipientAddress",
            title: "Adres odbiorcy",
        },
        {
            dataIndex: "comment",
            key: "Comment",
            title: "Komentarz",
        },
        {
            dataIndex: "editorUserMail",
            key: "EditorUserMail",
            title: "Email użytkownika dodającego / edytującego",
        },
    ];

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.dateFrom !== this.props.dateFrom ||
            prevProps.dateTo !== this.props.dateTo ||
            prevProps.exciseEnergyRegisterId !== this.props.exciseEnergyRegisterId) {
            this.paginatedTable.current?.refresh();
        }
    }

    private getExportColumns(): ExportColumn[] {
        let visibleColumns: ExportColumn[] = this.paginatedTable.current?.getVisibleColumnsWithFilters().map(item =>
            new ExportColumn({
                key: item.key?.toString(),
                title: item.title?.toString(),
            }))!;
        return visibleColumns;
    }

    private getClientSieveModel(): ClientSieveModel {
        let { filters, sorts, page } = this.state.sieveModelUp!;
        return new ClientSieveModel({ filters: filters, sorts: sorts, page: page, pageSize: 1 })
    }

    private createReportRequest(): CreateReportRequest {
        let parameters = new CreateReportRequest();
        parameters.sieveModel = this.getClientSieveModel();
        parameters.columns = this.getExportColumns();
        return parameters;
    }

    private paginatedTable = createRef<PaginatedTable<ExciseEnergyRegisterReportDto>>();

    public render() {
        return (
            <>
                <PageHeader title={this.state.pageHeader}
                    right={
                        this.props.exciseEnergyRegisterId
                        &&
                        <>
                            <GenerateCSVButton getFile={() => {
                                let { totalRowCount } = this.state.sieveModelUp!;
                                return new CsvReportClient().createExciseEnergyRegisterReportCSV(
                                    this.createReportRequest(),
                                    this.props.exciseEnergyRegisterId ?? null,
                                    this.props.dateFrom!,
                                    this.props.dateTo!,
                                    totalRowCount!,
                                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                                )
                            }} />
                            <GeneratePdfButton getFile={() => {
                                return new ReportClient().createExciseEnergyRegisterReportPdf(
                                    this.createReportRequest(),
                                    this.props.exciseEnergyRegisterId ?? null,
                                    this.props.dateFrom!,
                                    this.props.dateTo!,
                                    this.state.pageHeader,
                                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    this.props.showDaily)
                            }} />
                        </>
                    }
                />
                <PaginatedTable<ExciseEnergyRegisterReportDto>
                    noScroll={false}
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseEnergyRegisterReportDto) => r.id ? r.id!.toString() : r.registerId!.toString() + ' ' + r.date.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        this.setState({ sieveModelUp: sieve });
                        return new ReportClient().getExciseEnergyRegisterReport(this.props.exciseEnergyRegisterId ?? null,
                            this.props.dateFrom!, this.props.dateTo!, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }}
                    scroll={{ y: 'calc(100vh - 590px)' }} />
            </>
        )
    }
};
