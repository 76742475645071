import { directions } from 'helper/FieldsDictionary';
import { dateToString } from 'helper/GridHelper';
import { Moment } from 'moment';
import { ExciseEntryFormDto, RegulationSchemaDto } from 'services/GeneratedClient';
import * as Yup from 'yup';
import { isUnitConverterRequired } from './ExciseEntryUtils';

interface IExciseEntryFormValidationOptionalProps {
    isCorrection?: boolean;
    isPlaceExists?: boolean;
    lastClosedDay?: Moment;
    initialStateDay?: Moment;
    exciseRegisterCloseDate?: Moment;
}

export default class ExciseEntryFormValidation {
    private regulation?: RegulationSchemaDto;
    public schema = this.getValidationSchema();

    public getValidationSchema(regulation?: RegulationSchemaDto, optionalParameters?: IExciseEntryFormValidationOptionalProps) {
        this.regulation = regulation;
        return Yup.object<ExciseEntryFormDto>().shape({
            correctionReason: this.valid('correctionReason', optionalParameters),
            paragraphOfRegulation: Yup.string().required(),
            direction: Yup.string().required(),
            productName: this.valid('productName'),
            productCode: this.valid('productCode'),
            exciseProductCode: this.valid('exciseProductCode'),
            cnCode: this.valid('cnCode'),
            fiscalMark: this.valid('fiscalMark'),
            notColouredAndMarkedFuelOils: this.valid('notColouredAndMarkedFuelOils'),
            incomeInExciseUnit: this.valid('incomeInExciseUnit'),
            expenditureInExciseUnit: this.valid('expenditureInExciseUnit'),
            incomeInInventoryUnit: this.valid('incomeInInventoryUnit'),
            expenditureInInventoryUnit: this.valid('expenditureInInventoryUnit'),
            unitConverter: this.valid('unitConverter'),
            cnCodeOfDriedTabacco: this.valid('cnCodeOfDriedTabacco'),
            date: this.valid('date', optionalParameters),
            documentDate: this.valid('documentDate'),
            documentNumber: this.valid('documentNumber'),
            productNumber: this.valid('productNumber'),
            invoiceDate: this.valid('invoiceDate'),
            invoiceNumber: this.valid('invoiceNumber'),
            exciseAmount: this.valid('exciseAmount'),
            taxWarehousePlace: this.valid('taxWarehousePlace'),
            methodOfAcquisition: this.valid('methodOfAcquisition'),
            methodOfDerivation: this.valid('methodOfDerivation'),
            methodOfConsumptionOfGoods: this.valid('methodOfConsumptionOfGoods'),
            methodOfProcessingOfGoods: this.valid('methodOfProcessingOfGoods'),
            amountTransshipmentInExciseUnit: this.valid('amountTransshipmentInExciseUnit'),
            amountTransshipmentInTechnicalUnit: this.valid('amountTransshipmentInTechnicalUnit'),
            transshippedFrom: this.valid('transshippedFrom'),
            transshippedTo: this.valid('transshippedTo'),
            recipientIdentifier: this.valid('recipientIdentifier'),
            recipientName: this.valid('recipientName'),
            recipientStreetName: this.valid('recipientStreetName'),
            recipientStreetNumber: this.valid('recipientStreetNumber'),
            recipientPostcode: this.valid('recipientPostcode'),
            recipientCity: this.valid('recipientCity'),
            supplierIdentifier: this.valid('supplierIdentifier'),
            supplierName: this.valid('supplierName'),
            supplierStreetName: this.valid('supplierStreetName'),
            supplierStreetNumber: this.valid('supplierStreetNumber'),
            supplierPostcode: this.valid('supplierPostcode'),
            supplierCity: this.valid('supplierCity'),
            transporterIdentifier: this.valid('transporterIdentifier'),
            transporterName: this.valid('transporterName'),
            transporterStreetName: this.valid('transporterStreetName'),
            transporterStreetNumber: this.valid('transporterStreetNumber'),
            transporterPostcode: this.valid('transporterPostcode'),
            transporterCity: this.valid('transporterCity'),
            transportArrangerIdentifier: this.valid('transportArrangerIdentifier'),
            transportArrangerName: this.valid('transportArrangerName'),
            transportArrangerStreetName: this.valid('transportArrangerStreetName'),
            transportArrangerStreetNumber: this.valid('transportArrangerStreetNumber'),
            transportArrangerPostcode: this.valid('transportArrangerPostcode'),
            transportArrangerCity: this.valid('transportArrangerCity'),
            ownerIdentifier: this.valid('ownerIdentifier'),
            ownerName: this.valid('ownerName'),
            ownerStreetName: this.valid('ownerStreetName'),
            ownerStreetNumber: this.valid('ownerStreetNumber'),
            ownerPostcode: this.valid('ownerPostcode'),
            ownerCity: this.valid('ownerCity'),
            placeId: this.valid('placeId'),
            fuelExciseAmount: this.valid('fuelExciseAmount'),
            concessionNumberForFuels: this.valid('concessionNumberForFuels'),
            concessionType: this.valid('concessionType'),
            concessionIssueDate: this.valid('concessionIssueDate'),
            concessionExpiryDate: this.valid('concessionExpiryDate'),
            storerOfTobaccoIdentifier: this.valid('storerOfTobaccoIdentifier'),
            storerOfTobaccoName: this.valid('storerOfTobaccoName'),
            storerOfTobaccoStreetName: this.valid('storerOfTobaccoStreetName'),
            storerOfTobaccoStreetNumber: this.valid('storerOfTobaccoStreetNumber'),
            storerOfTobaccoPostcode: this.valid('storerOfTobaccoPostcode'),
            storerOfTobaccoCity: this.valid('storerOfTobaccoCity'),
            saleDate: this.valid('saleDate'),
            useMethod: this.valid('useMethod'),
            activitiesPlaceAddress: this.valid('activitiesPlaceAddress'),
            finalBuyerIdentifier: this.valid('finalBuyerIdentifier'),
            finalBuyerName: this.valid('finalBuyerName'),
            finalBuyerStreetName: this.valid('finalBuyerStreetName'),
            finalBuyerStreetNumber: this.valid('finalBuyerStreetNumber'),
            finalBuyerPostcode: this.valid('finalBuyerPostcode'),
            finalBuyerCity: this.valid('finalBuyerCity'),
            importerIdentifier: this.valid('importerIdentifier'),
            importerName: this.valid('importerName'),
            importerStreetName: this.valid('importerStreetName'),
            importerStreetNumber: this.valid('importerStreetNumber'),
            importerPostcode: this.valid('importerPostcode'),
            importerCity: this.valid('importerCity'),
            sellerIdentifier: this.valid('sellerIdentifier'),
            sellerName: this.valid('sellerName'),
            sellerStreetName: this.valid('sellerStreetName'),
            sellerStreetNumber: this.valid('sellerStreetNumber'),
            sellerPostcode: this.valid('sellerPostcode'),
            sellerCity: this.valid('sellerCity'),
            buyerIdentifier: this.valid('buyerIdentifier'),
            buyerName: this.valid('buyerName'),
            buyerStreetName: this.valid('buyerStreetName'),
            buyerStreetNumber: this.valid('buyerStreetNumber'),
            buyerPostcode: this.valid('buyerPostcode'),
            buyerCity: this.valid('buyerCity'),
            notExemptProductStatus: this.valid('notExemptProductStatus'),
            useDescription: this.valid('useDescription'),
            containerIdFrom: this.valid('containerIdFrom', optionalParameters),
            containerIdTo: this.valid('containerIdTo', optionalParameters),
            typeOfLocalDocument: this.valid('typeOfLocalDocument'),
            localDocumentNumber: this.valid('localDocumentNumber'),
            localProductNumber: this.valid('localProductNumber'),
            calorificValue: this.valid('calorificValue'),
            customsDeclarationNumber: this.valid('customsDeclarationNumber'),
            customsDeclarationDate: this.valid('customsDeclarationDate'),
            customsOffice: this.valid('customsOffice'),
        });
    }

    private valid(name: string, optionalParameters?: IExciseEntryFormValidationOptionalProps) {
        let rv = {} as any;
        switch (name) {
            case 'productName':
                rv = Yup.string();
                break;
            case 'productCode':
                rv = Yup.string();
                break;
            case 'exciseProductCode':
                rv = Yup.string();
                break;
            case 'cnCode':
                rv = Yup.string()
                    .matches(/^[0-9]+$/, "Kod CN musi składać się tylko z cyfr.")
                    .max(8, 'Kod CN może mieć maksymalnie 8 znaków.');
                break;
            case 'fiscalMark':
                rv = Yup.string();
                break;
            case 'notColouredAndMarkedFuelOils':
                rv = Yup.string();
                break;
            case 'incomeInExciseUnit':
                rv = Yup.number();
                break;
            case 'expenditureInExciseUnit':
                rv = Yup.number();
                break;
            case 'incomeInInventoryUnit':
                rv = Yup.number();
                break;
            case 'expenditureInInventoryUnit':
                rv = Yup.number();
                break;
            case 'unitConverter':
                rv = Yup.number().when(['cnCode', 'paragraphOfRegulation'], {
                    is: (cnCode?: string, paragraphOfRegulation?: string) =>
                        isUnitConverterRequired(paragraphOfRegulation, cnCode),
                    then: Yup.number().required(),
                });
                break;
            case 'cnCodeOfDriedTabacco':
                rv = Yup.string();
                break;
            case 'date':
                rv = this.getEntryDateValidationSchema(optionalParameters);
                break;
            case 'documentDate':
                rv = Yup.date();
                break;
            case 'documentNumber':
                rv = Yup.string();
                break;
            case 'productNumber':
                rv = Yup.string();
                break;
            case 'invoiceDate':
                rv = Yup.date();
                break;
            case 'invoiceNumber':
                rv = Yup.string();
                break;
            case 'exciseAmount':
                rv = Yup.number();
                break;
            case 'taxWarehousePlace':
                rv = Yup.string();
                break;
            case 'methodOfAcquisition':
                rv = Yup.string();
                break;
            case 'methodOfDerivation':
                rv = Yup.string();
                break;
            case 'methodOfConsumptionOfGoods':
                rv = Yup.string();
                break;
            case 'methodOfProcessingOfGoods':
                rv = Yup.string();
                break;
            case 'amountTransshipmentInExciseUnit':
                rv = Yup.number();
                break;
            case 'amountTransshipmentInTechnicalUnit':
                rv = Yup.number();
                break;
            case 'transshippedFrom':
                rv = Yup.string();
                break;
            case 'transshippedTo':
                rv = Yup.string();
                break;
            case 'recipientIdentifier':
                rv = Yup.string();
                break;
            case 'recipientName':
                rv = Yup.string();
                break;
            case 'recipientStreetName':
                rv = Yup.string();
                break;
            case 'recipientStreetNumber':
                rv = Yup.string();
                break;
            case 'recipientPostcode':
                rv = Yup.string();
                break;
            case 'recipientCity':
                rv = Yup.string();
                break;
            case 'supplierIdentifier':
                rv = Yup.string();
                break;
            case 'supplierName':
                rv = Yup.string();
                break;
            case 'supplierStreetName':
                rv = Yup.string();
                break;
            case 'supplierStreetNumber':
                rv = Yup.string();
                break;
            case 'supplierPostcode':
                rv = Yup.string();
                break;
            case 'supplierCity':
                rv = Yup.string();
                break;
            case 'transporterIdentifier':
                rv = Yup.string();
                break;
            case 'transporterName':
                rv = Yup.string();
                break;
            case 'transporterStreetName':
                rv = Yup.string();
                break;
            case 'transporterStreetNumber':
                rv = Yup.string();
                break;
            case 'transporterPostcode':
                rv = Yup.string();
                break;
            case 'transporterCity':
                rv = Yup.string();
                break;
            case 'transportArrangerIdentifier':
                rv = Yup.string();
                break;
            case 'transportArrangerName':
                rv = Yup.string();
                break;
            case 'transportArrangerStreetName':
                rv = Yup.string();
                break;
            case 'transportArrangerStreetNumber':
                rv = Yup.string();
                break;
            case 'transportArrangerPostcode':
                rv = Yup.string();
                break;
            case 'transportArrangerCity':
                rv = Yup.string();
                break;
            case 'ownerIdentifier':
                rv = Yup.string();
                break;
            case 'ownerName':
                rv = Yup.string();
                break;
            case 'ownerStreetName':
                rv = Yup.string();
                break;
            case 'ownerStreetNumber':
                rv = Yup.string();
                break;
            case 'ownerPostcode':
                rv = Yup.string();
                break;
            case 'ownerCity':
                rv = Yup.string();
                break;
            case 'placeId':
                rv = Yup.number();
                break;
            case 'fuelExciseAmount':
                rv = Yup.number();
                break;
            case 'concessionNumberForFuels':
                rv = Yup.string();
                break;
            case 'concessionType':
                rv = Yup.string();
                break;
            case 'concessionIssueDate':
                rv = Yup.date();
                break;
            case 'concessionExpiryDate':
                rv = Yup.date();
                break;
            case 'storerOfTobaccoIdentifier':
                rv = Yup.string();
                break;
            case 'storerOfTobaccoName':
                rv = Yup.string();
                break;
            case 'storerOfTobaccoStreetName':
                rv = Yup.string();
                break;
            case 'storerOfTobaccoStreetNumber':
                rv = Yup.string();
                break;
            case 'storerOfTobaccoPostcode':
                rv = Yup.string();
                break;
            case 'storerOfTobaccoCity':
                rv = Yup.string();
                break;
            case 'saleDate':
                rv = Yup.date();
                break;
            case 'useMethod':
                rv = Yup.string();
                break;
            case 'activitiesPlaceAddress':
                rv = Yup.string();
                break;
            case 'finalBuyerIdentifier':
                rv = Yup.string();
                break;
            case 'finalBuyerName':
                rv = Yup.string();
                break;
            case 'finalBuyerStreetName':
                rv = Yup.string();
                break;
            case 'finalBuyerStreetNumber':
                rv = Yup.string();
                break;
            case 'finalBuyerPostcode':
                rv = Yup.string();
                break;
            case 'finalBuyerCity':
                rv = Yup.string();
                break;
            case 'importerIdentifier':
                rv = Yup.string();
                break;
            case 'importerName':
                rv = Yup.string();
                break;
            case 'importerStreetName':
                rv = Yup.string();
                break;
            case 'importerStreetNumber':
                rv = Yup.string();
                break;
            case 'importerPostcode':
                rv = Yup.string();
                break;
            case 'importerCity':
                rv = Yup.string();
                break;
            case 'sellerIdentifier':
                rv = Yup.string();
                break;
            case 'sellerName':
                rv = Yup.string();
                break;
            case 'sellerStreetName':
                rv = Yup.string();
                break;
            case 'sellerStreetNumber':
                rv = Yup.string();
                break;
            case 'sellerPostcode':
                rv = Yup.string();
                break;
            case 'sellerCity':
                rv = Yup.string();
                break;
            case 'buyerIdentifier':
                rv = Yup.string();
                break;
            case 'buyerName':
                rv = Yup.string();
                break;
            case 'buyerStreetName':
                rv = Yup.string();
                break;
            case 'buyerStreetNumber':
                rv = Yup.string();
                break;
            case 'buyerPostcode':
                rv = Yup.string();
                break;
            case 'buyerCity':
                rv = Yup.string();
                break;
            case 'notExemptProductStatus':
                rv = Yup.string();
                break;
            case 'useDescription':
                rv = Yup.string();
                break;
            case 'correctionReason':
                rv = optionalParameters?.isCorrection ? Yup.string().required() : Yup.string();
                break;
            case 'containerIdFrom':
                rv = Yup.number()
                    .when(["direction"], {
                        is: (direction: string) => {
                            return direction && (direction == directions.outgoing || direction == directions.transshipment);
                        },
                        then: Yup.number().required()
                    })
                break;
            case 'containerIdTo':
                rv = Yup.number()
                    .when(["direction"], {
                        is: (direction: string) => {
                            return direction && (direction == directions.incoming || direction == directions.transshipment);
                        },
                        then: Yup.number().required()
                    })
                break;
            case 'typeOfLocalDocument':
                rv = Yup.string();
                break;
            case 'localDocumentNumber':
                rv = Yup.string();
                break;
            case 'localProductNumber':
                rv = Yup.string();
                break;
            case 'calorificValue':
                rv = Yup.number();
                break;
            case 'customsDeclarationNumber':
                rv = Yup.string();
                break;
            case 'customsDeclarationDate':
                rv = Yup.date();
                break;
            case 'customsOffice':
                rv = Yup.string();
                break;
        }

        if (!this.regulation || !this.regulation.requiredFields?.includes(name)) {
            return rv;
        }

        return rv.required();
    }

    private getEntryDateValidationSchema(optionalParameters: IExciseEntryFormValidationOptionalProps | undefined) {
        if (optionalParameters?.lastClosedDay && optionalParameters?.initialStateDay) {
            var registerCloseDate = optionalParameters?.exciseRegisterCloseDate
            var initialStateDay = optionalParameters?.initialStateDay;
            var lastClosedDay = optionalParameters.lastClosedDay;
            var lastClosedDayLeft = optionalParameters.lastClosedDay!.clone().add(1, 'days').subtract(1, 'seconds');

            if (registerCloseDate !== undefined) {
                return Yup.date()
                    .min(initialStateDay!.toDate(), `Podany dzień zdarzenia przypada wcześniej niż data\ninicjalizacji stanu magazynowego.\nMinimalna data zdarzenia korektującego to: ${dateToString(initialStateDay)}`)
                    .max(registerCloseDate!.toDate(), `Podany dzień zdarzenia jest po dacie zamknięcia ewidencji.\nMaksymalna data zdarzenia korektującego to: ${dateToString(registerCloseDate)}`);
            }

            return Yup.date()
                .min(initialStateDay!.toDate(), `Podany dzień zdarzenia przypada wcześniej niż data\ninicjalizacji stanu magazynowego.\nMinimalna data zdarzenia korektującego to: ${dateToString(initialStateDay)}`)
                .max(lastClosedDayLeft!.toDate(), `Podany dzień zdarzenia nie jest zamknięty.\nMaksymalna data zdarzenia korektującego to: ${dateToString(lastClosedDay)}`);
        }

        return Yup.date();
    }
}