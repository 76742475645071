import * as moment from 'moment';

declare module 'moment' {
  export interface Moment {
    asUtc(): Moment;
    asDate(): Moment;
  }
}

(moment.fn as any).asUtc = function () {
  const _self = this as moment.Moment;
  return _self.utcOffset(0, true);
};

(moment.fn as any).asDate = function () {
  const _self = this as moment.Moment;
  return _self.startOf('day');
};