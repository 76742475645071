import React from 'react'
import { RowProps as AntdRowProps } from "antd/lib/row";
import { CenteredRow } from 'layout/CenteredRow'
import "./BottomActionButtons.less";

interface IProps extends AntdRowProps {    
}

const BottomActionButtons = (props : IProps) => {
    return (
        <CenteredRow {...props} className="bottom-action-buttons" />
    )
}

export default BottomActionButtons;