import { Col } from "antd";
import { inject } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import PrimaryCard from "../../layout/PrimaryCard";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { EwdRegisterName, ExciseStampEntryClient, ExciseStampEntryFormDto } from "services/GeneratedClient";
import { RegulationStore } from "regulations/RegulationStore";
import { CenteredRow } from "layout/CenteredRow";
import Descriptions from "layout/Descriptions";
import Page from "layout/Page";
import { dateRenderer } from "helper/GridHelper";


interface IProps extends RouteComponentProps<any> {
    dictionaryStore?: DictionaryStore;
    regulationStore?: RegulationStore;
    exciseEntryId: number;
}

@inject("dictionaryStore")
@inject("regulationStore")
export default class ExciseStampEntryFormDetails extends React.PureComponent<IProps, {}> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <>
                <Page<ExciseStampEntryFormDto>
                    title="PODGLĄD WPISU"
                    goBack={this.props.history.goBack}
                    ajaxCall={() => new ExciseStampEntryClient().getExciseStampEntry(this.props.exciseEntryId)}>
                    {(exciseStampEntry) => {
                        return (<PrimaryCard>
                            <CenteredRow>
                                <Col xl={24} xs={24}>
                                    <Descriptions title="Informacje ogólne">
                                        <Descriptions.Item label='Paragraf'><>{(exciseStampEntry.paragraphType && !exciseStampEntry.isSupplyEntry) 
                                            ? `${this.props.regulationStore?.getDisplay(exciseStampEntry.paragraphType, EwdRegisterName.ExciseStampRegister)}`
                                            : exciseStampEntry.paragraphType}</>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Id systemowy"><>{exciseStampEntry.id}</></Descriptions.Item>
                                        {
                                            exciseStampEntry.ordinalNumber &&
                                            <Descriptions.Item label="Lp."><>{exciseStampEntry.ordinalNumber}</></Descriptions.Item>
                                        }
                                        {
                                            !exciseStampEntry.isSupplyEntry &&
                                            <>
                                                <Descriptions.Item label="Data wpisu"><>{dateRenderer(exciseStampEntry.entryDate)}</></Descriptions.Item>
                                                <Descriptions.Item label="Numer dokumentu źródłowego"><>{exciseStampEntry.sourceIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Identyfikacja partii"><>{exciseStampEntry.batchIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Identyfikacja wysyłki"><>{exciseStampEntry.sendIdentifier}</></Descriptions.Item>
                                                <Descriptions.Item label="Rok produkcji"><>{exciseStampEntry.productionYear}</></Descriptions.Item>
                                                <Descriptions.Item label="Dane identyfikacyjne miejsca, z którego otrzymano banderole podatkowe"><>{exciseStampEntry.obtainedPlaceDetails}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa organu lub wytwórcy znaków akcyzy, który wydał banderole podatkowe"><>{exciseStampEntry.issuingAuthorityName}</></Descriptions.Item>
                                                <Descriptions.Item label="Nazwa i adres podmiotu zagranicznego"><>{exciseStampEntry.foreignEntityDetails}</></Descriptions.Item>
                                                <Descriptions.Item label="Zakres numerów ewidencyjnych od"><>{exciseStampEntry.scopeNumberFrom}</></Descriptions.Item>
                                                <Descriptions.Item label="Zakres numerów ewidencyjnych do"><>{exciseStampEntry.scopeNumberTo}</></Descriptions.Item>
                                            </>
                                        }
                                        
                                    </Descriptions>
                                    {
                                        (exciseStampEntry.isSupplyEntry || exciseStampEntry.correctionOrdinalNumber) &&
                                        <Descriptions title="Informacje o korekcie">
                                            <Descriptions.Item label="Powód korekty"><>{exciseStampEntry.correctionReason}</></Descriptions.Item>
                                            <Descriptions.Item label="Lp. korekty"><>{exciseStampEntry.correctionOrdinalNumber}</></Descriptions.Item>
                                            <Descriptions.Item label="Edytor"><>{exciseStampEntry.editorUserName}</></Descriptions.Item>
                                        </Descriptions>
                                    }
                                    {
                                        !exciseStampEntry.isSupplyEntry &&
                                        <Descriptions title="Wartości liczbowe wpisu">
                                            <Descriptions.Item label="Liczba banderol wydanych"><>{exciseStampEntry.issuedQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych wydanych do oznaczania"><>{exciseStampEntry.issedForMarkQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych przekazanych"><>{exciseStampEntry.transferredQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba zwróconych banderol podatkowych"><>{exciseStampEntry.returnedQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba wysłanych banderol podatkowych"><>{exciseStampEntry.sentQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba otrzymanych banderol podatkowych"><>{exciseStampEntry.receivedQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba otrzymanych banderol podatkowych do procesu oznaczania"><>{exciseStampEntry.receivedForMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol wydanych za banderole podatkowe zwrócone niewykorzystane i nieuszkodzone"><>{exciseStampEntry.issuedForReplacedQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol wydanych w ramach dopuszczalnej normy strat"><>{exciseStampEntry.issuedForAcceptedLossQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych naniesionych na opakowania jednostkowe wyrobów akcyzowych"><>{exciseStampEntry.appliedQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych powracających"><>{exciseStampEntry.otherQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych uszkodzonych w procesie oznaczania wyrobów akcyzowych"><>{exciseStampEntry.damagedInMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych uszkodzonych poza procesem oznaczania wyrobów akcyzowych"><>{exciseStampEntry.damagedOutMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba zwróconych banderol podatkowych uszkodzonych w procesie oznaczania"><>{exciseStampEntry.returnedDamagedInMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba zwróconych banderol podatkowych uszkodzonych poza procesem oznaczania"><>{exciseStampEntry.returnedDamagedOutMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych utraconych w procesie oznaczania wyrobów akcyzowych"><>{exciseStampEntry.lostInMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych utraconych poza procesem oznaczania wyrobów akcyzowych"><>{exciseStampEntry.lostOutMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych zniszczonych w procesie oznaczania wyrobów akcyzowych"><>{exciseStampEntry.destroyedInMarkingQuantity}</></Descriptions.Item>
                                            <Descriptions.Item label="Liczba banderol podatkowych zniszczonych poza procesem oznaczania wyrobów akcyzowych"><>{exciseStampEntry.destroyedOutMarkingQuantity}</></Descriptions.Item>
                                        </Descriptions>
                                    }
                                </Col>
                            </CenteredRow>
                        </PrimaryCard>)
                    }}
                </Page>
            </>
        )
    }
}