import { Button, Col, Collapse, Modal, notification, Popconfirm } from "antd";
import { DeleteOutlined, HighlightOutlined, SaveOutlined } from '@ant-design/icons';
import { booleanRenderer, dateTimeRenderer, EwdColumnProps, yesNoBooleanValueProvider } from "helper/GridHelper";
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { DictionaryName, ExciseRegisterReportTemplateClient, ExciseRegisterReportTemplateDto, SaveExciseRegisterReportTemplateCommand } from "services/GeneratedClient";
import React, { createRef, useEffect, useRef, useState } from 'react';
import { DictionaryStore, useDictionaryStore } from "dictionary/dictionaryStore";
import FadingTooltip from "layout/FadingTooltip";
import TableButton from "layout/table/tableButton/TableButton";
import BottomActionButtons from "layout/ActionButtons/BottomActionButtons";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
import { FCenteredRow, FFieldLabel, FInput } from "forms/FormikFormItems";
import { CenteredRow } from "layout/CenteredRow";
import "./ExciseRegisterReportStyles.less"
import IReportTemplateData from "./IReportTemplateData";
import { ajaxCatch } from "helper/api";

const { Panel } = Collapse;

interface IFormValues {
    templateName?: string;
}

interface IProps {
    registerId?: number;
    includeContainers: boolean,
    reportType: string,
    applyingTemplate: boolean,
    getVisibleColumnKeys: () => string[];
    applyReportTemplate: (reportTemplateData: IReportTemplateData) => void;
}

const ExciseRegisterReportTemplatePanel = (props: IProps) => {
    const gridName = "exciseRegisterReportTemplates";
    const saveTemplateText = "Zapisz aktualną konfigurację jako szablon";
    const paginatedTable = createRef<PaginatedTable<ExciseRegisterReportTemplateDto>>();
    const [loading, setLoading] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const dictionaryStore = useDictionaryStore();

    useEffect(() => {
        if (!loading) {
            paginatedTable.current?.refresh();
        }
    }, [loading, props.registerId]);

    const formik = useFormik<IFormValues>({
        initialValues: { templateName: undefined },
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: Yup.object().shape({ templateName: Yup.string().required() }),
        onSubmit: (values) => {
            const command = new SaveExciseRegisterReportTemplateCommand({
                exciseRegisterId: props.registerId!,
                templateName: values.templateName!,
                includeContainers: props.includeContainers!,
                reportType: props.reportType!.charAt(0).toUpperCase() + props.reportType!.slice(1),
                visibleColumnKeys: props.getVisibleColumnKeys().filter(key => key !== "actions"),
            });
            setLoading(true);
            ajaxCatch(() => new ExciseRegisterReportTemplateClient().saveExciseRegisterReportTemplates(command),
                () => {
                    notification.success({
                        message: `Zapisano szablon \"${values.templateName!}\"`,
                        placement: "bottomRight",
                    });
                }).finally(() => {
                    setLoading(false);
                    setModalOpened(false);
                });
        },
    });

    const deleteReportTemplate = (template: ExciseRegisterReportTemplateDto) => {
        setLoading(true);
        new ExciseRegisterReportTemplateClient().deleteExciseRegisterReportTemplates(template.id)
            .then(() => {
                notification.success({
                    message: `Usunięto szablon \"${template.templateName!}\"`,
                    placement: "bottomRight",
                });
            }).catch(() => {
                notification.error({
                    message: `Nie udało się usunąc szablonu \"${template.templateName!}\"`,
                    placement: "bottomRight",
                });
            }).finally(() => {
                setLoading(false);
            });
    };

    const applyReportTemplate = (template: ExciseRegisterReportTemplateDto) => {
        props.applyReportTemplate({
            includeContainers: template.includeContainers!,
            reportType: template.reportType!,
            visibleColumnKeys: template.visibleColumnKeys!,
        });
        notification.success({
            message: `Wczytano szablon \"${template.templateName!}\"`,
            placement: "bottomRight",
        });
    };

    const onSaveReportTemplateButtonClick = () => {
        formik.resetForm();
        setModalOpened(true);
    };

    const columns: EwdColumnProps<ExciseRegisterReportTemplateDto>[] = [
        {
            key: "actions",
            render: (_, record: ExciseRegisterReportTemplateDto) => (
                <>
                    <FadingTooltip title={"Usuń szablon"}>
                        <Popconfirm placement="bottomRight" title={`Czy na pewno usunąć szablon \"${record.templateName}\"?`} okText="Tak" cancelText="Nie"
                            onConfirm={() => { deleteReportTemplate(record) }}>
                            <TableButton
                                icon={<DeleteOutlined />}
                                loading={loading || props.applyingTemplate}
                                disabled={loading || props.applyingTemplate}
                            />
                        </Popconfirm>
                    </FadingTooltip>
                    <FadingTooltip title={"Użyj szablonu"}>
                        <TableButton
                            icon={<HighlightOutlined />}
                            loading={loading || props.applyingTemplate}
                            disabled={loading || props.applyingTemplate}
                            onClick={() => { applyReportTemplate(record) }} />
                    </FadingTooltip>
                </>
            ),
            title: "Czynności",
            width: 110,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "templateName",
            key: "TemplateName",
            title: "Nazwa szablonu",
            filter: "contains",
        },
        {
            dataIndex: "dateCreated",
            key: "DateCreated",
            title: "Data utworzenia",
            render: (value) => dateTimeRenderer(value),
            sorter: true,
        },
        {
            dataIndex: "includeContainers",
            key: "IncludeContainers",
            title: "Podział na zbiorniki",
            render: (value) => booleanRenderer(value, yesNoBooleanValueProvider),
        },
        {
            dataIndex: "reportType",
            key: "ReportType",
            title: "Typ raportu",
            render: (value) => { return dictionaryStore.getDisplay(DictionaryName.ExciseRegisterReportType, value) },
        },
    ];

    return (
        <>
            <Modal
                title={saveTemplateText}
                visible={modalOpened}
                centered={true}
                okButtonProps={{ disabled: loading, loading: loading }}
                cancelButtonProps={{ disabled: loading }}
                onCancel={() => setModalOpened(false)}
                onOk={formik.submitForm}>
                <FormikProvider value={formik}>
                    <FCenteredRow>
                        <FFieldLabel label="Nazwa szablonu" sm={8} />
                        <Col sm={16}>
                            <Field component={FInput} name="templateName" placeholder="Wpisz nazwę szablonu" />
                        </Col>
                    </FCenteredRow>
                </FormikProvider>
            </Modal>
            <Collapse className="report-templates-container">
                <Panel key='0' header={`Szablony raportów`}>
                    {props.registerId ? <>
                        <PaginatedTable<ExciseRegisterReportTemplateDto>
                            ref={paginatedTable}
                            loading={loading}
                            gridName={gridName}
                            columns={columns}
                            columnsSelector={true}
                            getRowKey={(r: ExciseRegisterReportTemplateDto) => r.id.toString()}
                            getPagedResult={(sieve: SieveModel) => {
                                return new ExciseRegisterReportTemplateClient().getExciseRegisterReportTemplates(props.registerId!, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                            }} />
                        <BottomActionButtons>
                            <Button type="primary"
                                disabled={loading}
                                loading={loading}
                                icon={<SaveOutlined />}
                                onClick={onSaveReportTemplateButtonClick}>
                                {saveTemplateText}
                            </Button>
                        </BottomActionButtons>
                    </> : <CenteredRow>
                        <span className="report-register-not-selected">
                            Wybierz rejestr ewidencji
                        </span>
                    </CenteredRow>}
                </Panel>
            </Collapse>
        </>
    );
};

export default ExciseRegisterReportTemplatePanel;