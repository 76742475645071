import React, { useState, ReactElement } from 'react';
import { Skeleton } from 'antd';
import { useAjaxCatch } from 'helper/api';
import { getErrorPage } from 'error/ErrorPages';
import { PageHeaderReturn } from './PageHeaderReturn';

interface Props<T> {
    title: string,
    goBack?: string | (() => any);
    children: (data: T) => ReactElement,
    ajaxCall?: () => Promise<T | null>
}

const Page = <T extends object>({ title, goBack, ajaxCall, children }: Props<T>) => {
    let [data, setData] = useState<T | null>(null);

    let error = useAjaxCatch(
        ajaxCall,
        data => setData(data),
        []
    );

    let view = <Skeleton active />;
    if (error) {
        view = getErrorPage(error.status)
    }

    if ((data && data !== null) || !ajaxCall) {
        view = children(data!);
    }

    return (
        <>
            <PageHeaderReturn title={title} goBack={goBack} />
            {view}
        </>
    )
}

export default Page;