import React, { createRef, Key } from 'react'
import { SieveModel, AntPagination, mapAntSorterToSieve, mapFiltersToSieveFilters } from './SieveModel';
import { showNotExpectedErrorModal } from "../../Modals";
import { EwdColumnProps } from 'helper/GridHelper';
import { inject, observer } from 'mobx-react';
import { GridStore } from './GridStore';
import { SorterResult } from 'antd/lib/table/interface';
import { ColumnExtender } from './ColumnExtender';
import ColumnHelper from './ColumnHelper';
import { AccountDetailsStore } from 'account/AccountDetailsStore';
import TableWithSummaries, { TableWithSummariesProps } from './TableWithSummaries';
import { ColumnProps } from 'antd/lib/table/Column';

export interface IPaginatedTableState<T> {
    results?: T[] | undefined;
    loaded?: boolean;
    selectedRowKeys: React.Key[];
}

export interface IPaginatedTableProps<T> {
    getPagedResult: (sieve: SieveModel) => Promise<any>;
    getRowKey: (record: T) => string | number;
    gridName: string;
    accountDetailsStore?: AccountDetailsStore;
    columnsSelector?: boolean;

    columns: EwdColumnProps<T>[];
    gridStore?: GridStore;
    noSpinnerOnRefresh?: boolean;
    noScroll?: boolean;
    selectable?: boolean;
    loading?: boolean;
}

@inject("gridStore")
@inject("accountDetailsStore")
@observer
export default class PaginatedTable<T extends object> extends React.PureComponent<TableWithSummariesProps<T> & IPaginatedTableProps<T>, IPaginatedTableState<T>> {
    private columnExtender: ColumnExtender<T>;
    private visibleColumnsWithFilters: ColumnProps<T>[];

    constructor(props: any) {
        super(props);

        this.state = {
            selectedRowKeys: [],
        };
        this.columnExtender = new ColumnExtender<T>(this.props.gridName, this.props.columnsSelector);
        this.visibleColumnsWithFilters = [];
    }

    public getVisibleColumnsWithFilters() {
        return this.visibleColumnsWithFilters;
    }

    public componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.gridName !== this.props.gridName) {
            this.columnExtender = new ColumnExtender<T>(this.props.gridName, this.props.columnsSelector);
            this.refresh();
        }
    }

    public refresh() {
        this.setState({
            loaded: false,
            selectedRowKeys: [],
        });

        this.tableRef.current?.fetchResults();
        let sieve = new SieveModel();

        let savedFilter = this.props.gridStore!.getGridFilters(this.props.gridName);
        let savedAntPagination = this.props.gridStore!.getGridPagination(this.props.gridName);
        let savedAntSorter = this.props.gridStore!.getGridSorter(this.props.gridName);

        if (savedFilter)
            sieve.filters = mapFiltersToSieveFilters(savedFilter, this.props.columns);

        if (savedAntPagination) {
            sieve.page = savedAntPagination.current;
            sieve.pageSize = savedAntPagination.pageSize;
        }

        if (savedAntSorter) {
            sieve.sorts = mapAntSorterToSieve(savedAntSorter);
        }


        this.fetchSieveResults(sieve);
    }

    private handleAntTableChange = (antPagination: AntPagination, antFilters: Partial<Record<keyof T, string[]>>, antSorter: SorterResult<T>) => {
        this.setState({ loaded: false });
        let sieve = new SieveModel();

        let savedFilters = this.props.gridStore!.getGridFilters(this.props.gridName);

        if (savedFilters)
            sieve.filters = mapFiltersToSieveFilters(savedFilters, this.props.columns);

        sieve.page = antPagination.current;
        sieve.pageSize = antPagination.pageSize;
        sieve.sorts = mapAntSorterToSieve(antSorter);

        this.props.gridStore!.saveGridSorter(this.props.gridName, antSorter);

        this.fetchSieveResults(sieve);
    };

    private fetchSieveResults(sieve: SieveModel) {
        this.props.getPagedResult(sieve).then((data: any) => {
            let antPagination: AntPagination = {
                current: data!.currentPage,
                pageSize: data!.pageSize,
                total: data!.rowCount
            };

            sieve.totalRowCount = antPagination.total;

            this.props.gridStore!.saveGridPagination(this.props.gridName, antPagination);

            this.setState({
                results: data!.results,
                loaded: true,
            });
        })
            .catch((e: any) => {
                showNotExpectedErrorModal(e);
                this.setState({ loaded: true });
            });
    }
    private isFunction = (arg: any): arg is Function => typeof arg === 'function'
    private getRowClassName = (record: T, index: number, indent: number): string => {
        return this.isFunction(this.props.rowClassName) ? (this.props.rowClassName as Function)(record, index, indent) : this.props.rowClassName as string;
    }

    public getSelectedRowKeys() {
        return this.state.selectedRowKeys;
    }

    onSelectChange = (newlySelectedRowKeys: React.Key[]) => {
        this.setState({ selectedRowKeys: newlySelectedRowKeys });
    };

    private tableRef = createRef<TableWithSummaries<T>>();
    public render() {
        let antSorter = (this.props.gridStore! as GridStore).getGridSorter(this.props.gridName);
        let columnsWithFilters = this.columnExtender.applyFiltersAndSorter(this.props.columns,
            antSorter,
            () => {
                this.props.gridStore!.clearGridPagination(this.props.gridName);
                this.refresh();
            },
            (columnKey: Key) => {
                return this.props.gridStore!.getColumnFilter(this.props.gridName, columnKey) !== undefined;
            });

        let gridSettings = (this.props.accountDetailsStore! as AccountDetailsStore).gridSettings![this.props.gridName];

        this.columnExtender.applyActionButtons(gridSettings, columnsWithFilters, this.props.gridStore!.getGridFilters(this.props.gridName) !== undefined, () => {
            this.refresh();
        });

        this.visibleColumnsWithFilters = columnsWithFilters;
        if (gridSettings !== undefined) {
            this.visibleColumnsWithFilters = ColumnHelper.getVisibleColumns(gridSettings, columnsWithFilters);
        }

        var scroll = this.props.scroll || { scrollToFirstRowOnChange: true };
        if (this.props.noScroll) {
            scroll.scrollToFirstRowOnChange = false;
        }
        scroll.x = 120 * this.visibleColumnsWithFilters.length;

        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (<TableWithSummaries {...this.props} loading={this.props.loading || ((!this.props.noSpinnerOnRefresh || this.state.results === undefined) && !this.state.loaded)}
            ref={this.tableRef}
            columns={this.visibleColumnsWithFilters}
            scroll={scroll}
            pagination={(this.props.pagination === undefined || this.props.pagination) && this.props.gridStore!.getGridPagination(this.props.gridName)}
            rowKey={(r: any) => this.props.getRowKey(r).toString()}
            onChange={this.handleAntTableChange as any}
            dataSource={this.state.results}
            rowClassName={(record, index, indent) => {
                const objectId = Object.entries(record).find(i => i[0] === 'id' && i[1]);

                return `
                    ${(index % 2 === 1) ? "row-alternate" : ""}
                    ${objectId ? (objectId?.[1].toString() === this.props.gridStore?.getLastUsedRowId(this.props.gridName) ? "row-last-used" : "") : ""}
                    ${this.props.rowClassName ? this.getRowClassName(record, index, indent) : ""}
                `;
            }}
            rowSelection={this.props.selectable ? rowSelection : undefined}
        />
        )
    }
}
