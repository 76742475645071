import React from 'react';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import CurrentExciseEnergyEntryList from './CurrentExciseEnergyEntryList';
import { Button } from 'antd';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Link } from 'react-router-dom';
import { Authorized } from 'authorization/Authorized';
import { EwdPlaceLevelPermission } from 'services/GeneratedClient';
import { IPlaceContextProps } from 'authorization/interfaces/IPlaceContextProps';
import ExciseEnergyRegisterBasicDetails from 'exciseEnergies/registers/ExciseEnergyRegisterBasicDetails';

interface IProps extends IPlaceContextProps {
    exciseEnergyRegisterId: number
}

export default class CurrentExciseEnergyEntry extends React.PureComponent<IProps, {}>{

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`REJESTR WPISÓW EWIDENCJI ENERGII ELEKTRYCZNEJ - OTWARTE`} goBack={"/ExciseEnergies/Registers"} />
                <ExciseEnergyRegisterBasicDetails exciseEnergyRegisterId={this.props.exciseEnergyRegisterId} />
                <CurrentExciseEnergyEntryList exciseEnergyRegisterId={this.props.exciseEnergyRegisterId} />
                <BottomActionButtons>
                    <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}
                        placeContext={this.props.placeContext}>
                        <Link to={`/ExciseEnergies/Registers/${this.props.exciseEnergyRegisterId}/Entries/Add`}>
                            <Button>Dodaj zdarzenie</Button>
                        </Link>
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};