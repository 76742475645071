import React from 'react';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import CompletedExciseEnergyEntryList from './CompletedExciseEnergyEntryList';
import ExciseEnergyRegisterBasicDetails from 'exciseEnergies/registers/ExciseEnergyRegisterBasicDetails';
import { CenteredRow } from 'layout/CenteredRow';
import { MonthPicker } from 'components/DatePicker';
import moment, { Moment } from 'moment';
import { Divider } from 'antd';
import { IPlaceContextProps } from 'authorization/interfaces/IPlaceContextProps';
import CorrectAsNewNavigationButton from './buttons/CorrectAsNewNavigationButton'
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Authorized } from 'authorization/Authorized';
import { EwdPlaceLevelPermission } from 'services/GeneratedClient';

interface IProps extends IPlaceContextProps {
    exciseEnergyRegisterId: number;
    placeContext: string;
}

interface IState {
    dateFrom: Moment;
    dateTo: Moment;
}

export default class CompletedExciseEnergyEntry extends React.PureComponent<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            dateFrom: moment().startOf("month").asUtc(),
            dateTo: moment().endOf("month").asUtc()
        }
    }

    onChange = (value: Moment | null, dateString: string) => {
        if (value) {
            this.setState({
                dateFrom: moment(value).startOf("month").asUtc(),
                dateTo: moment(value).endOf("month").asUtc()
            })
        }
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`REJESTR WPISÓW EWIDENCJI ENERGII ELEKTRYCZNEJ - ZAMKNIĘTE`} goBack={"/ExciseEnergies/Registers"} />
                <ExciseEnergyRegisterBasicDetails exciseEnergyRegisterId={this.props.exciseEnergyRegisterId} />
                <Divider />
                <CenteredRow><MonthPicker onChange={this.onChange} value={this.state.dateFrom} /></CenteredRow>
                <CompletedExciseEnergyEntryList
                    exciseEnergyRegisterId={this.props.exciseEnergyRegisterId}
                    dateFrom={this.state.dateFrom}
                    dateTo={this.state.dateTo}
                />
                <BottomActionButtons>
                    <Authorized
                        requiresSubscription
                        placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryCorrecting}
                        placeContext={this.props.placeContext}>
                        <CorrectAsNewNavigationButton exciseEnergyRegisterId={this.props.exciseEnergyRegisterId} />
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};