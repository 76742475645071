import React from "react";
import { PageHeader, IPageHeaderProps } from "./PageHeader";
import { Button as AntdButton, ButtonProps } from "antd";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

export interface IPageHeaderReturnProps extends IPageHeaderProps {
    title: string;
    goBack?: string | (() => any);
}

export class PageHeaderReturn extends React.PureComponent<IPageHeaderReturnProps> {
    constructor(props: IPageHeaderReturnProps) {
        super(props);
    };

    render() {
        const Button = (props: ButtonProps) => <AntdButton type={"default"} icon={<LeftOutlined />} {...props} />
        const ReturnButton = (props: ButtonProps) =>
            <>
                {this.props.goBack && <>
                    {typeof this.props.goBack === 'string'
                        ? <Link to={this.props.goBack!}>
                            <Button {...props} />
                        </Link>
                        : <Button {...props} onClick={this.props.goBack} />}
                </>}
            </>

        return (
            <PageHeader {...this.props} right={<ReturnButton>Powrót</ReturnButton>} />
        );
    }
}