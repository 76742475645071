import { tuple } from 'antd/lib/_util/type';
import { inject } from 'mobx-react';
import * as Yup from 'yup';

export default class ExciseEnergyRegisterCreateValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        const cnCode = "27160000";
        return Yup.object().shape({
            name: Yup.string().required().min(1).max(255),
            placeId: Yup.string().required(),
            cnCode: Yup.string().required().oneOf([cnCode], `Pole musi mieć wartość: ${cnCode}`)
        });
    }
}