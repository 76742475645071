import { Button, Collapse, notification, Popconfirm, Select } from "antd";
import { inject, observer } from "mobx-react";
import { CalculatorOutlined, CloseOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import React, { } from "react";
import { RouteComponentProps } from "react-router";
import PrimaryCard from "layout/PrimaryCard";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { RegisterStore } from "register/RegisterStore";
import { DictionaryName, ExciseEnergyEntryClient, ExciseEnergyEntryFormDto, DictionaryClient, EwdDictionaryDto, EwdPlaceLevelPermission, SaveExciseEnergyEntryCmd, ExciseEnergyRegisterClient, CorrectExciseEnergyEntryCmd, EwdRegisterName, CalculateEnergyExciseAmountCmd } from "services/GeneratedClient";
import { FCenteredRow, FRow, FInput, FInputNumber, FDatePicker, FDictionarySelectWithTooltip, FSelect, FDictionarySelect } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import { RegulationStore } from "regulations/RegulationStore";
import * as Yup from 'yup';
import { showFormIsInvalidModal } from "layout/Modals";
import { PageHeader } from "layout/PageHeader";
import { Authorized } from "authorization/Authorized";
import { SelectValue } from "antd/lib/select";
import { IPlaceContextProps } from "authorization/interfaces/IPlaceContextProps";
import ExciseEnergyEntryFormConfigs from "./formConfig/ExciseEnergyEntryFormConfigs";
import IFormElementConfigs from "./formConfig/IFormElementConfigs";
import DynamicForm from "./DynamicForm"
import ExciseEnergyRegisterBasicDetails from "exciseEnergies/registers/ExciseEnergyRegisterBasicDetails";
import { Moment } from "moment";
import { directions } from "helper/FieldsDictionary";
import { FormChangeStore } from "formChange/FormChangeStore";
import { LinkWithConfirmation } from "formChange/LinkWithConfirmation";
import { FBooleanSelect } from "forms/FBooleanSelect";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any>, IPlaceContextProps {
    dictionaryStore?: DictionaryStore;
    registerStore?: RegisterStore;
    regulationStore?: RegulationStore;
    formChangeStore?: FormChangeStore;
    exciseEnergyRegisterId?: number;
    exciseEnergyEntryId?: number;
    isPreviewMode?: boolean;
    isEditMode?: boolean;
    isAddMode?: boolean;
    isCorrectMode?: boolean;
    isCorrectAsNewMode?: boolean;
}

interface IState {
    initialValues: ExciseEnergyEntryFormDto;
    paragraph?: string;
    schema: Yup.ObjectSchema;
    formConfigs: ExciseEnergyEntryFormConfigs;
    formConfig: IFormElementConfigs | null;
    ewdDictionaries: EwdDictionaryDto[] | null;
    isLoading: boolean;
    isWaitingForResult: boolean;
    optionalDateProps?: IOptionalDateProps;
}

interface IOptionalDateProps {
    firstClosedDay: Moment | undefined,
    lastClosedDay: Moment | undefined
}

@inject("dictionaryStore")
@inject("registerStore")
@inject("regulationStore")
@inject("formChangeStore")
@observer
export default class ExciseEnergyEntryForm extends React.PureComponent<IProps, IState> {
    private df: DynamicForm;

    constructor(props: any) {
        super(props);

        let formConfigs = new ExciseEnergyEntryFormConfigs();
        this.state = {
            initialValues: new ExciseEnergyEntryFormDto(),
            formConfigs: formConfigs,
            schema: formConfigs.getDefaultYupSchema(),
            ewdDictionaries: null,
            isLoading: false,
            isWaitingForResult: false,
            formConfig: formConfigs.getDefault(),
        };

        this.df = new DynamicForm(this.state.formConfigs, this.props.isPreviewMode);
    }

    public componentDidMount() {
        this.initForm();
        this.fetchEwdDictionaries();
    }

    private initForm() {
        if (this.props.isPreviewMode) {
            ajaxCatch(() =>
                new ExciseEnergyEntryClient().getExciseEnergyEntryForPreview(this.props.exciseEnergyEntryId!),
                (result: ExciseEnergyEntryFormDto | null) => {
                    this.setState({
                        initialValues: result!,
                        paragraph: result?.paragraphOfRegulation,
                    });
                    this.df.setFormConfigName(this.state.initialValues.paragraphOfRegulation);
                });
        }
        else if (this.props.isEditMode) {
            ajaxCatch(() =>
                new ExciseEnergyEntryClient().getExciseEnergyEntryForEdit(this.props.exciseEnergyEntryId!),
                (result: ExciseEnergyEntryFormDto | null) => {
                    this.setState({
                        initialValues: result!,
                        paragraph: result?.paragraphOfRegulation,
                        schema: this.state.formConfigs.getYupSchema(result?.paragraphOfRegulation),
                    });
                    this.df.setFormConfigName(this.state.initialValues.paragraphOfRegulation);
                });
        }
        else if (this.props.isCorrectMode) {
            ajaxCatch(() =>
                new ExciseEnergyEntryClient().getExciseEnergyEntryForCorrect(this.props.exciseEnergyEntryId!),
                (result: ExciseEnergyEntryFormDto | null) => {
                    result!.isCorrective = true;
                    result!.correctionReason = "";
                    this.setState({
                        initialValues: result!,
                        paragraph: result?.paragraphOfRegulation,
                        schema: this.state.formConfigs.getYupSchema(result?.paragraphOfRegulation),
                    });
                    this.df.setFormConfigName(this.state.initialValues.paragraphOfRegulation);
                });
        }
        else if (this.props.isAddMode || this.props.isCorrectAsNewMode) {
            new ExciseEnergyRegisterClient().get(this.props.exciseEnergyRegisterId!).then((data) => {
                this.setState({
                    initialValues: new ExciseEnergyEntryFormDto({
                        ...this.state.initialValues,
                        registerId: this.props.exciseEnergyRegisterId!,
                        cnCode: data?.cnCode,
                        productName: "Energia elektryczna"
                    })
                })
            });
        }
        if (this.props.isCorrectAsNewMode) {
            new ExciseEnergyEntryClient().getCorrectionAsNewInitialData(this.props.exciseEnergyRegisterId!).then((data) => {
                this.setState({
                    initialValues: new ExciseEnergyEntryFormDto({
                        ...this.state.initialValues,
                        nextCorrectionOrdinalNumber: data?.nextCorrectionOrdinalNumber!,
                        correctionReason: "",
                        isCorrective: true,
                        editorUserName: data?.editorUserName
                    }),
                    optionalDateProps: {
                        firstClosedDay: data?.firstClosedDay,
                        lastClosedDay: data?.lastClosedDay,
                    }
                })
            });
        }
    }

    private fetchEwdDictionaries() {
        ajaxCatch(() =>
            new DictionaryClient().getEwdDictionariesAndValues(),
            (dictionaries: EwdDictionaryDto[] | null) => {
                this.setState({
                    ewdDictionaries: dictionaries
                })
            });
    }

    public send(values: ExciseEnergyEntryFormDto, actions: FormikHelpers<ExciseEnergyEntryFormDto>) {
        this.df.removeHiddenValues(values, this.state.formConfig!);
        if (this.props.isCorrectMode) {
            let cmd = new CorrectExciseEnergyEntryCmd({
                exciseEnergyEntryFormDto: values,
                registerId: this.getEnergyRegisterId(),
                editorId: undefined,
            });
            ajaxCatch(() =>
                new ExciseEnergyEntryClient().correct(cmd)
                    .then((resp: any) => {
                        actions.setSubmitting(false);
                        this.props.history.goBack();
                        notification.success({
                            message: "Zapisano korektę zdarzenia",
                            placement: "bottomRight",
                        });
                    }).finally(() => this.setState({ isLoading: false })));
        }
        else if (this.props.isCorrectAsNewMode) {
            let cmd = new CorrectExciseEnergyEntryCmd({
                exciseEnergyEntryFormDto: values,
                registerId: this.getEnergyRegisterId(),
                editorId: undefined,
            });
            ajaxByUser("Zapisano zdarzenie korygujące", () =>
                new ExciseEnergyEntryClient().correctAsNew(cmd)
                    .then((resp: any) => {
                        actions.setSubmitting(false);
                        this.props.history.goBack();
                    }).finally(() => this.setState({ isLoading: false })));
        }
        else {
            let cmd = new SaveExciseEnergyEntryCmd({
                exciseEnergyEntryFormDto: values,
                exciseEnergyRegisterId: this.getEnergyRegisterId(),
                editorId: undefined,
            });
            ajaxByUser("Zapisano zdarzenie", () =>
                new ExciseEnergyEntryClient().save(cmd)
                    .then((resp: any) => {
                        actions.setSubmitting(false);
                        this.props.history.goBack();
                    }).finally(() => this.setState({ isLoading: false })));
        }
    }

    private calculateExciseAmount(values: ExciseEnergyEntryFormDto) {
        let quantity;
        const direction = this.props.regulationStore!
            .getByParagraph(values.paragraphOfRegulation!, EwdRegisterName.ExciseEnergyRegister).direction;
        if (direction === directions.incoming)
            quantity = values.incomeInExciseUnit;
        else
            quantity = values.expenditureInExciseUnit;

        let cmd = new CalculateEnergyExciseAmountCmd({
            amountRate: values.amountRate,
            quantityInExciseUnit: quantity,
            direction: direction,
        });

        return ajaxCatch(() =>
            new ExciseEnergyEntryClient().calculateEnergyExciseAmount(cmd)
        );
    }

    private getEnergyRegisterId(): number {
        return this.props.exciseEnergyRegisterId ?? this.state.initialValues.registerId;
    }

    private getPageHeaderTitle(): string {
        if (this.props.isPreviewMode) {
            return "PODGLĄD WPISU EWIDENCJI ENERGII ELEKTRYCZNEJ";
        } else if (this.props.isEditMode) {
            return "REDAGOWANIE WPISU EWIDENCJI ENERGII ELEKTRYCZNEJ";
        } else if (this.props.isCorrectMode) {
            return "KOREKTA WPISU EWIDENCJI ENERGII ELEKTRYCZNEJ";
        } else if (this.props.isCorrectAsNewMode) {
            return "TWORZENIE WPISU EWIDENCJI ENERGII ELEKTRYCZNEJ, KORYGUJĄCEGO"
        }
        return "TWORZENIE WPISU EWIDENCJI ENERGII ELEKTRYCZNEJ";
    }

    public render() {
        return (
            <>
                <PageHeader title={this.getPageHeaderTitle()} />
                {this.getEnergyRegisterId() && <ExciseEnergyRegisterBasicDetails exciseEnergyRegisterId={this.getEnergyRegisterId()} />}
                <Formik<ExciseEnergyEntryFormDto> initialValues={this.state.initialValues} enableReinitialize
                    onSubmit={(values, actions) => {
                        this.setState({ isLoading: true });
                        this.send(values, actions);
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.state.schema}>
                    {(props: FormikProps<ExciseEnergyEntryFormDto>) => {
                        this.props.formChangeStore?.setChangeDetectionStatus(props.dirty);
                        return <PrimaryCard>
                            <Collapse defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                <this.df.FPanel key='0' header={`Informacje ogólne`} name="panelGeneral">
                                    <FRow>
                                        <this.df.LabeledField label="Paragraf" name="paragraphOfRegulation" fieldSm={12}>
                                            <this.df.Field component={FDictionarySelectWithTooltip}
                                                dictionaryName={DictionaryName.RegulationExciseEnergyUa138h}
                                                name="paragraphOfRegulation"
                                                disabled={this.props.isCorrectMode}
                                                onChange={(v: any, o: any) => {
                                                    let propsValues = props.values;
                                                    propsValues.paragraphOfRegulation = v;
                                                    this.setState({
                                                        initialValues: propsValues,
                                                        paragraph: v,
                                                        formConfig: this.state.formConfigs.getForForm(v),
                                                        schema: this.state.formConfigs.getYupSchema(v, this.state.optionalDateProps),
                                                    });
                                                    this.df.setFormConfigName(v);
                                                }} />
                                        </this.df.LabeledField>
                                    </FRow>
                                    {!this.props.isCorrectAsNewMode && !this.props.isAddMode &&
                                        <>
                                            <FRow>
                                                <this.df.LabeledField label="Id systemowy" name="id">
                                                    <this.df.Field component={FInput} disabled={true} name="id" />
                                                </this.df.LabeledField>
                                                <this.df.LabeledField label="Lp." name="ordinalNumber">
                                                    <this.df.Field component={FInput} disabled={true} name="ordinalNumber" />
                                                </this.df.LabeledField>
                                            </FRow>
                                            <FRow>
                                                <this.df.LabeledField label="Identyfikator źródłowy" name="sourceIdentifier">
                                                    <this.df.Field component={FInput} disabled={true} name="sourceIdentifier" />
                                                </this.df.LabeledField>
                                            </FRow>
                                        </>}
                                </this.df.FPanel>

                                {(this.props.isCorrectMode || this.props.isCorrectAsNewMode ||
                                    (this.props.isPreviewMode && props.initialValues.correctionReason)) &&
                                    <this.df.FPanel key={5} header={"Informacje o korekcie"} name="panelCorrectionInformations">
                                        <FRow>
                                            <this.df.LabeledField label="Lp. korekty"
                                                name={this.props.isCorrectMode || this.props.isCorrectAsNewMode ? "nextCorrectionOrdinalNumber" : "correctionOrdinalNumber"}
                                            >
                                                <this.df.Field component={FInput} disabled={true}
                                                    name={this.props.isCorrectMode || this.props.isCorrectAsNewMode ? "nextCorrectionOrdinalNumber" : "correctionOrdinalNumber"} />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Powód korekty" name="correctionReason" fieldSm={9}>
                                                <this.df.Field component={FInput} name="correctionReason" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Edytor" name="editorUserName" fieldSm={9}>
                                                <this.df.Field component={FInput} disabled={true} name="editorUserName" />
                                            </this.df.LabeledField>
                                        </FRow>
                                    </this.df.FPanel>}

                                {this.state.initialValues.correctionOrdinalNumber != 0 &&
                                    <this.df.FPanel key='1' header={`Wyrób`} name="panelProduct">
                                        <FRow>
                                            <this.df.LabeledField label="Nazwa" name="productName">
                                                <this.df.Field component={FInput} name="productName" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Kod CN wyrobu" name="cnCode">
                                                <this.df.Field name="cnCode" disabled={true} component={FInput} />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Powód zwolnienia" name="exciseExemptionReason">
                                                <this.df.Field component={FDictionarySelectWithTooltip}
                                                    name="exciseExemptionReason"
                                                    dictionaryName={DictionaryName.ExciseExemptionReason} />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Czy opodatkowano?" name="exciseDuty">
                                                <this.df.Field component={FBooleanSelect} isBoolean name="exciseDuty" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Przychód w jednostce rozliczenia akcyzy" name="incomeInExciseUnit">
                                                <this.df.Field component={FInputNumber} precision={3} name="incomeInExciseUnit" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Rozchód w jednostce rozliczenia akcyzy" name="expenditureInExciseUnit">
                                                <this.df.Field component={FInputNumber} precision={3} name="expenditureInExciseUnit" />
                                            </this.df.LabeledField>
                                        </FRow>
                                    </this.df.FPanel>}

                                {this.state.initialValues.correctionOrdinalNumber != 0 &&
                                    <this.df.FPanel key={2} header={"Informacje dodatkowe"} name="panelAdditionalInformations">
                                        <FRow>
                                            <this.df.LabeledField label="Data" name="date">
                                                <this.df.Field sm={6} component={FDatePicker} name="date" disabled={this.props.isCorrectMode} />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Data wystawienia dokumentu" name="documentDate">
                                                <this.df.Field sm={6} component={FDatePicker} name="documentDate" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Numer dokumentu dostawy lub przewozowego lub zgłoszenia" name="documentNumber">
                                                <this.df.Field component={FInput} name="documentNumber" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Numer pozycji dokumentu dostawy lub przewozowego lub zgłoszenia" name="productNumber">
                                                <this.df.Field component={FInput} name="productNumber" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Data faktury" name="invoiceDate">
                                                <this.df.Field sm={6} component={FDatePicker} name="invoiceDate" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Numer faktury" name="invoiceNumber">
                                                <this.df.Field component={FInput} name="invoiceNumber" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        {!this.props.isPreviewMode &&
                                            <FRow>
                                                <this.df.LabeledField label="Stawka do wyliczenia akcyzy" name="amountRate">
                                                    <this.df.Select
                                                        optionFilterProp="children"
                                                        onSelect={(v: any, o: any) => {
                                                            let values = props.values;
                                                            values.amountRate = v;
                                                            this.setState({
                                                                initialValues: values,
                                                            });
                                                        }}>
                                                        {this.props.registerStore && this.props.registerStore.exciseEnergyRates?.filter(x =>
                                                            x.validFrom <= props.values.date && (x.validTo ? props.values.date <= x.validTo : true))
                                                            .map(x => <Option key={x.id!} value={x.amountRate}>{x.amountRate.toPrecision()} zł</Option>)}
                                                    </this.df.Select>
                                                </this.df.LabeledField>
                                                <Button
                                                    type="default"
                                                    size="large"
                                                    icon={<CalculatorOutlined />}
                                                    loading={this.state.isWaitingForResult}
                                                    onClick={() => {
                                                        let values = props.values;
                                                        this.setState({ isWaitingForResult: true });
                                                        this.calculateExciseAmount(values).then((resp) => {
                                                            props.setFieldValue("exciseAmount", resp);
                                                            props.setFieldTouched("exciseAmount");
                                                        }).finally(() => this.setState({ isWaitingForResult: false }));
                                                    }}>
                                                    Wylicz kwotę akcyzy
                                                </Button>
                                            </FRow>}
                                        <FRow>
                                            <this.df.LabeledField label="Kwota akcyzy" name="exciseAmount">
                                                <this.df.Field component={FInputNumber} name="exciseAmount" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Data sprzedaży" name="saleDate">
                                                <this.df.Field component={FDatePicker} name="saleDate" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Za okres od" name="forPeriodFrom">
                                                <this.df.Field component={FDatePicker} name="forPeriodFrom" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Za okres do" name="forPeriodTo">
                                                <this.df.Field component={FDatePicker} name="forPeriodTo" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Rodzaj dokumentu logistycznego wewnętrznego" name="typeOfLocalDocument">
                                                <this.df.Field component={FInput} name="typeOfLocalDocument" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Numer dokumentu logistycznego wewnętrznego" name="localDocumentNumber">
                                                <this.df.Field component={FInput} name="localDocumentNumber" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Komentarz" name="comment" fieldSm={9}>
                                                <this.df.Field component={FInput} name="comment" />
                                            </this.df.LabeledField>
                                        </FRow>
                                    </this.df.FPanel>}

                                {this.state.initialValues.correctionOrdinalNumber != 0 &&
                                    <this.df.FPanel key={3} header={"Dostawca"} name="panelSupplier">
                                        <FRow>
                                            <this.df.LabeledField label="Wyszukaj kontrahenta z listy" name="supplier" fieldSm={18}>
                                                <this.df.Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "supplier")}>
                                                    {this.props.registerStore!.traders.map(x =>
                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                </this.df.Select>
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Identyfikator (np. NIP)" name="supplierIdentifier">
                                                <this.df.Field component={FInput} name="supplierIdentifier" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Nazwa" name="supplierName">
                                                <this.df.Field component={FInput} name="supplierName" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Ulica" name="supplierStreetName">
                                                <this.df.Field component={FInput} name="supplierStreetName" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Nr domu" name="supplierStreetNumber">
                                                <this.df.Field component={FInput} name="supplierStreetNumber" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Kod pocztowy" name="supplierPostcode">
                                                <this.df.Field component={FInput} name="supplierPostcode" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Miejscowość" name="supplierCity">
                                                <this.df.Field component={FInput} name="supplierCity" />
                                            </this.df.LabeledField>
                                        </FRow>
                                    </this.df.FPanel>}

                                {this.state.initialValues.correctionOrdinalNumber != 0 &&
                                    <this.df.FPanel key={4} header={"Odbiorca"} name="panelReceipient">
                                        <FRow>
                                            <this.df.LabeledField label="Wyszukaj kontrahenta z listy" name="recipient" fieldSm={18}>
                                                <this.df.Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "recipient")}>
                                                    {this.props.registerStore!.traders.map(x =>
                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                </this.df.Select>
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Identyfikator (np. NIP)" name="recipientIdentifier">
                                                <this.df.Field component={FInput} name="recipientIdentifier" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Nazwa" name="recipientName">
                                                <this.df.Field component={FInput} name="recipientName" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Ulica" name="recipientStreetName">
                                                <this.df.Field component={FInput} name="recipientStreetName" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Nr domu" name="recipientStreetNumber">
                                                <this.df.Field component={FInput} name="recipientStreetNumber" />
                                            </this.df.LabeledField>
                                        </FRow>
                                        <FRow>
                                            <this.df.LabeledField label="Kod pocztowy" name="recipientPostcode">
                                                <this.df.Field component={FInput} name="recipientPostcode" />
                                            </this.df.LabeledField>
                                            <this.df.LabeledField label="Miejscowość" name="recipientCity">
                                                <this.df.Field component={FInput} name="recipientCity" />
                                            </this.df.LabeledField>
                                        </FRow>
                                    </this.df.FPanel>}
                            </Collapse>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <LinkWithConfirmation to="" actionOnClick={this.props.history.goBack}>
                                    <Button icon={<CloseOutlined />} size="large">Anuluj</Button>
                                </LinkWithConfirmation>
                                <Popconfirm title="Czy na pewno chcesz wyczyścić formularz?" okText="Tak" cancelText="Nie" onConfirm={() => props.resetForm()}>
                                    <Button type="default" icon={<DeleteOutlined />} size="large" >Wyczyść</Button>
                                </Popconfirm>
                                {!this.props.isPreviewMode &&
                                    <Authorized requiresSubscription placeContext={this.props.placeContext}
                                        placeLevelPermission={this.props.isCorrectMode || this.props.isCorrectAsNewMode
                                            ? EwdPlaceLevelPermission.ExciseEnergyRegisterEntryCorrecting
                                            : EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}>
                                        <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                            this.validateAndSubmitForm(props);
                                        }}>{`Zapisz${this.props.isCorrectMode || this.props.isCorrectAsNewMode ? " korektę" : ""}`}</Button>
                                    </Authorized>}
                            </FCenteredRow>
                        </PrimaryCard>
                    }}
                </Formik>
            </>
        )
    }

    validateAndSubmitForm = (props: FormikProps<ExciseEnergyEntryFormDto>) => {
        props.setFormikState({
            isSubmitting: true,
            values: props.values,
            errors: props.errors,
            touched: props.touched,
            isValidating: props.isValidating,
            submitCount: props.submitCount + 1
        });

        props.validateForm(props.values)
            .then((errors: FormikErrors<ExciseEnergyEntryFormDto>) => {
                if (Object.keys(errors).length) {
                    props.setSubmitting(false);
                    console.log("Błędy walidacji klienckiej: ", errors, "wartości", props.values);
                    showFormIsInvalidModal();
                } else {
                    props.submitForm();
                }
            })
    }

    setFormSubject = (props: FormikProps<ExciseEnergyEntryFormDto>, subject: string) => (id: SelectValue): void => {
        var trader = this.props.registerStore!.traders.filter(x => x.id == id)[0];

        props.setFieldValue(subject + `Identifier`, trader.vatNumber);
        props.setFieldValue(subject + `Name`, trader.name);
        props.setFieldValue(subject + `StreetName`, trader.street);
        props.setFieldValue(subject + `StreetNumber`, trader.streetNumber);
        props.setFieldValue(subject + `Postcode`, trader.postalCode);
        props.setFieldValue(subject + `City`, trader.city);
    }
}

