import { dateToString } from "helper/GridHelper";
import { Moment } from "moment";
import { ExciseStampEntryFormDto, RegulationSchemaDto } from "services/GeneratedClient";
import * as Yup from 'yup';

interface IExciseStampEntryFormValidationOptionalProps{
    isCorrection? : boolean;  
    lastClosedDay?: Moment;
    initialStateDay?: Moment;
}

export default class ExciseStampEntryValidation {
    private regulation?: RegulationSchemaDto;
    public schema = this.getValidationSchema();

    public getValidationSchema(regulation?: RegulationSchemaDto, optionalParameters?: IExciseStampEntryFormValidationOptionalProps) {
        this.regulation = regulation;
        return Yup.object<ExciseStampEntryFormDto>().shape({
            correctionReason: optionalParameters?.isCorrection ? Yup.string().required() : Yup.string(),
            //Dane podstawowe - stałe
            paragraphType: Yup.string().required(),
            sourceIdentifier: Yup.string().required(),
            batchIdentifier: Yup.string().required(),
            entryDate: this.valid("entryDate", Date.name, optionalParameters),
            //Dane podstawowe - zmienne
            sendIdentifier: this.valid("sendIdentifier", String.name),
            productionYear: this.valid("productionYear", String.name),
            obtainedPlaceDetails: this.valid("obtainedPlaceDetails", String.name),
            issuingAuthorityName: this.valid("issuingAuthorityName", String.name),
            foreignEntityDetails: this.valid("foreignEntityDetails", String.name),
            scopeNumberFrom: this.valid("scopeNumberFrom", String.name),
            scopeNumberTo: this.valid("scopeNumberTo", String.name),
            //Wartości liczbowe banderol
            issuedQuantity: this.valid("issuedQuantity", Number.name),
            issedForMarkQuantity: this.valid("issedForMarkQuantity", Number.name),
            transferredQuantity: this.valid("transferredQuantity", Number.name),
            receivedQuantity: this.valid("receivedQuantity", Number.name),
            receivedForMarkingQuantity: this.valid("receivedForMarkingQuantity", Number.name),
            returnedQuantity: this.valid("returnedQuantity", Number.name),
            sentQuantity: this.valid("sentQuantity", Number.name),
            issuedForReplacedQuantity: this.valid("issuedForReplacedQuantity", Number.name),
            issuedForAcceptedLossQuantity: this.valid("issuedForAcceptedLossQuantity", Number.name),
            appliedQuantity: this.valid("appliedQuantity", Number.name),
            damagedInMarkingQuantity: this.valid("damagedInMarkingQuantity", Number.name),
            returnedDamagedInMarkingQuantity: this.valid("returnedDamagedInMarkingQuantity", Number.name),
            damagedOutMarkingQuantity: this.valid("damagedOutMarkingQuantity", Number.name),
            returnedDamagedOutMarkingQuantity: this.valid("returnedDamagedOutMarkingQuantity", Number.name),
            lostInMarkingQuantity: this.valid("lostInMarkingQuantity", Number.name),
            lostOutMarkingQuantity: this.valid("lostOutMarkingQuantity", Number.name),
            destroyedInMarkingQuantity: this.valid("destroyedInMarkingQuantity", Number.name),
            destroyedOutMarkingQuantity: this.valid("destroyedOutMarkingQuantity", Number.name),
            otherQuantity: this.valid("otherQuantity", Number.name)
        });
    }

    private valid(name: string, type: string, optionalParameters?: IExciseStampEntryFormValidationOptionalProps | undefined) {
        let rv = {} as any;
        switch (type) {
            case String.name:
                rv = Yup.string();
                break;
            case Number.name:
                rv = Yup.number();
                break;
            case Date.name:
                rv = Yup.date();
                if (name === "entryDate") {
                    rv = this.getEntryDateValidationSchema(optionalParameters);
                }
                break;
        }

        if (!this.regulation || !this.regulation.requiredFields?.includes(name)) {
            return rv;
        }

        return rv.required();
    }

    private getEntryDateValidationSchema(optionalParameters: IExciseStampEntryFormValidationOptionalProps | undefined) {
        if (optionalParameters?.lastClosedDay && optionalParameters?.initialStateDay) {
            var initialStateDay = optionalParameters?.initialStateDay;
            var lastClosedDay = optionalParameters.lastClosedDay;
            var lastClosedDayLeft = optionalParameters.lastClosedDay!.clone().add(1, 'days').subtract(1, 'seconds');

            return Yup.date().required()
                .min(initialStateDay!.toDate(), `Podany dzień zdarzenia przypada wcześniej niż data\ninicjalizacji stanu magazynowego.\nMinimalna data zdarzenia korygującego to: ${dateToString(initialStateDay)}`)
                .max(lastClosedDayLeft!.toDate(), `Podany dzień zdarzenia nie jest zamknięty.\nMaksymalna data zdarzenia korygującego to: ${dateToString(lastClosedDay)}`);
        }

        return Yup.date().required();
    }
}
