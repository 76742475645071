import * as React from "react";
import { Route, withRouter, RouteComponentProps, Switch, Redirect } from "react-router";
import { inject, observer } from "mobx-react";
import { AccountDetailsStore } from "account/AccountDetailsStore";
import Authenticated from "authentication/Authenticated";
import { Authorized } from "authorization/Authorized";
import DictionaryProvider from "./dictionary/DictionaryProvider"
import { ErrorPage } from "error/ErrorPages";
import ExciseRegisterCreate from "exciseRegisters/ExciseRegisterCreate";
import ExciseRegisterList from "exciseRegisters/ExciseRegisterList";
import AdminExciseRegisterList from "exciseRegisters/admin/AdminExciseRegisterList";
import ExciseRegisterStateCreate from "exciseRegisters/admin/ExciseRegisterStateCreate";
import PlaceCreate from "places/PlaceCreate";
import PlaceList from "places/PlaceList";
import AppLayout from "layout/Layout";
import RegisterProvider from "./register/RegisterProvider";
import { PublicSettingsDto, CompanyLevelPermission, EwdPlaceLevelPermission } from "services/GeneratedClient";
import CompletedExciseEntry from "exciseEntries/completed/CompletedExciseEntry";
import CurrentExciseEntry from "exciseEntries/current/CurrentExciseEntry";
import ExciseStampRegisterList from "exciseStamps/exciseStampRegister/ExciseStampRegisterList";
import ExciseEntryForm from "exciseEntries/ExciseEntryForm";
import RegulationProvider from "regulations/RegulationProvider";
import EwdDictionaryList from "dictionary/EwdDictionaryList";
import EwdDictionaryValueList from "dictionary/EwdDictionaryValueList";
import EwdDictionaryValueCreate from "dictionary/EwdDictionaryValueCreate";
import EwdDictionaryValueEdit from "dictionary/EwdDictionaryValueEdit";
import ExciseStampRegisterCreate from "exciseStamps/exciseStampRegister/ExciseStampRegisterCreate";
import AwaitingExciseEntry from "exciseEntries/awaiting/AwaitingExciseEntryList";
import ExciseEntryDetails from "exciseEntries/ExciseEntryDetails";
import ExciseRegisterDetails from "exciseRegisters/details/ExciseRegisterDetails";
import ExciseRegisterParagraphConfiguration from "exciseRegisters/admin/ExciseRegisterParagraphConfiguration";
import ExciseRegisterReport from "reports/exciseRegisterReport/ExciseRegisterReport";
import AuditLogList from "auditLog/AuditLogList"
import CompletedExciseStampEntry from "exciseStamps/exciseStampEntries/completed/CompletedExciseStampEntry";
import ExciseStampEntryForm from "exciseStamps/exciseStampEntries/ExciseStampEntryForm";
import CurrentExciseStampEntry from "exciseStamps/exciseStampEntries/current/CurrentExciseStampEntry";
import ExciseStampEntryFormDetails from "exciseStamps/exciseStampEntries/ExciseStampEntryFormDetails";
import AdminCompanyUserList from "userPlacePermisssions/AdminCompanyUserList";
import AdminUserPlacePermissionsForm from "userPlacePermisssions/AdminUserPlacePermissionsForm";
import PlaceContext from "authorization/PlaceContext";
import ExciseStampRegisterDetails from "exciseStamps/exciseStampRegister/details/ExciseStampRegisterDetails";
import ExciseStampRegisterAdminList from "exciseStamps/exciseStampRegister/admin/ExciseStampRegisterAdminList";
import ExciseStampRegisterStateCreate from "exciseStamps/exciseStampRegister/admin/ExciseStampRegisterStateCreate";
import ExciseEnergyRegisterAdminList from "exciseEnergies/registers/admin/ExciseEnergyRegisterAdminList";
import ExciseEnergyRegisterCreate from "exciseEnergies/registers/admin/ExciseEnergyRegisterCreate";
import ExciseEnergyRegisterList from "exciseEnergies/registers/ExciseEnergyRegisterList";
import ExciseEnergyRegisterDetails from "exciseEnergies/registers/details/ExciseEnergyRegisterDetails";
import CurrentExciseEnergyEntry from "exciseEnergies/entries/current/CurrentExciseEnergyEntry";
import CompletedExciseEnergyEntry from "exciseEnergies/entries/completed/CompletedExciseEnergyEntry";
import ExciseEnergyEntryForm from "exciseEnergies/entries/ExciseEnergyEntryForm";
import CsvImport from "imports/CsvImport"
import XlsxImport from "imports/XlsxImport"
import CsvImportEnergies from "imports/CsvImportEnergies"
import XlsxImportEnergies from "imports/XlsxImportEnergies"
import ExciseStampRegisterReport from "reports/exciseStampRegisterReport/ExciseStampRegisterReport";
import ContainersList from "containers/ContainersList";
import ContainerCreate from "containers/ContainerCreate";
import AlertsComponent from "components/AlertsComponent"
import ExciseRegisterContainers from "exciseRegisters/exciseRegisterContainers/ExciseRegisterContainers";
import AutoLogoutWhenUserLoggedInUsingAnotherClient from "components/Account/AutoLogoutWhenUserLoggedInUsingAnotherClientComponent";
import AutoLogoutOnIdle from "components/Account/AutoLogoutOnIdleComponent";
import CnKwaDictionaryValueList from "cnKwaDictionaryValue/CnKwaDictionaryValueList";
import CnKwaDictionaryValueCreate from "cnKwaDictionaryValue/CnKwaDictionaryValueCreate";
import CnKwaDictionaryValueEdit from "cnKwaDictionaryValue/CnKwaDictionaryValueEdit";
import 'utils/MomentExtension';
import ExciseEnergyRegisterReport from "reports/exciseEnergyRegisterReport/ExciseEnergyRegisterReport";
import DailyReports from "reports/dailyReports/DailyReports";

interface IProps {
    accountDetailsStore?: AccountDetailsStore;
}

@inject("accountDetailsStore")
@observer
class App extends React.Component<RouteComponentProps & IProps & { settings: PublicSettingsDto }> {
    public render() {
        return <Authenticated>
            <AutoLogoutWhenUserLoggedInUsingAnotherClient />
            <AutoLogoutOnIdle idleTimeout={this.props.settings.idleTimeout} />
            <AppLayout platformUrl={this.props.settings.dashboardUrl} >
                {this.props.accountDetailsStore!.currentCompany &&
                    <RegisterProvider>
                        <DictionaryProvider>
                            <Route component={AlertsComponent} />
                            <Route path="/ErrorPage/:statusCode" exact render={props => <ErrorPage statusCode={props.match.params.statusCode} />} />

                            <Switch>
                                <Route exact={true} path="/" render={() =>
                                    <>
                                        <Authorized placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseEnergyRegisterDisplay}><Redirect to="/ExciseEnergies/Registers" /></Authorized>
                                        <Authorized placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseStampRegisterDisplay}><Redirect to="/ExciseStampRegisters" /></Authorized>
                                        <Authorized placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterDisplay}><Redirect to="/ExciseRegisters" /></Authorized>
                                    </>
                                } />
                                <Route exact={true} path="/ExciseRegisters" render={props =>
                                    <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterDisplay}><ExciseRegisterList /></Authorized>} />
                                <Route path="/ExciseRegisters/Details/:registerId" render={props =>
                                    <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {placeContext => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterDisplay}>
                                            <ExciseRegisterDetails exciseRegisterId={props.match.params.registerId} {...props} />
                                        </Authorized>}
                                    </PlaceContext>} />
                                {/* Excise Register Entry -routes*/}
                                <Route path="/ExciseEntries/Preview/:entryId">
                                    <Route render={props => <PlaceContext exciseEntryId={props.match.params.entryId}>
                                        {placeContext => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay}>
                                            <RegulationProvider>
                                                <ExciseEntryDetails {...props} exciseEntryId={props.match.params.entryId} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseRegisters/:registerId/Entries/Completed">
                                    <Route render={props => <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {placeContext => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay}>
                                            <CompletedExciseEntry exciseRegisterId={props.match.params.registerId} placeContext={placeContext} />
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseRegisters/:registerId/Entries/Current">
                                    <Route render={props => <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay}>
                                            <CurrentExciseEntry placeContext={placeContext} placeId={placeId} exciseRegisterId={props.match.params.registerId} />
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseRegisters/:registerId/ExciseEntries/Add">
                                    <Route render={props => <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                                            <RegulationProvider>
                                                <ExciseEntryForm placeContext={placeContext} placeId={placeId} {...props} exciseRegisterId={props.match.params.registerId} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseRegisters/:registerId/ExciseEntries/Edit/:exciseEntryId">
                                    <Route render={props => <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                                            <RegulationProvider>
                                                <ExciseEntryForm placeContext={placeContext} placeId={placeId} {...props} exciseRegisterId={props.match.params.registerId} exciseEntryId={props.match.params.exciseEntryId} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseRegisters/:registerId/ExciseEntries/Correct/:exciseEntryId">
                                    <Route render={props => <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting}>
                                            <RegulationProvider>
                                                <ExciseEntryForm placeContext={placeContext} placeId={placeId} {...props} exciseRegisterId={props.match.params.registerId} exciseEntryId={props.match.params.exciseEntryId} isCorrecting={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseRegisters/:registerId/ExciseEntries/CorrectAsNew">
                                    <Route render={props => <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting}>
                                            <RegulationProvider>
                                                <ExciseEntryForm placeContext={placeContext} placeId={placeId} {...props} exciseRegisterId={props.match.params.registerId} exciseEntryId={props.match.params.exciseEntryId} isCorrectingAsNew={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseRegisters/:registerId/ExciseEntries/AssignAsAdditiveCorrection/:exciseEntryId">
                                    <Route render={props => <PlaceContext exciseRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting}>
                                            <RegulationProvider>
                                                <ExciseEntryForm placeContext={placeContext} placeId={placeId} {...props} exciseRegisterId={props.match.params.registerId} exciseEntryId={props.match.params.exciseEntryId} isCorrectingAsNew={true} isExpired={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                {/* Excise Register Entries Awaiting - routes */}
                                <Route path="/ExciseEntries/Awaiting" exact={true}>
                                    <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterEntryAwaitingDisplayAndAssigning}>
                                        <RegulationProvider>
                                            <AwaitingExciseEntry />
                                        </RegulationProvider>
                                    </Authorized>
                                </Route>
                                <Route path="/ExciseEntries/Awaiting/Preview/:entryId">
                                    <Route render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterEntryAwaitingDisplayAndAssigning}>
                                        <RegulationProvider>
                                            <ExciseEntryDetails {...props} exciseEntryId={props.match.params.entryId} isAwaiting />
                                        </RegulationProvider>
                                    </Authorized>} />
                                </Route>
                                {/* Import - routes */}
                                <Route path="/CsvImport" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterEntryImport}>
                                    <CsvImport />
                                </Authorized>} />
                                <Route path="/XlsxImport" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterEntryImport}>
                                    <XlsxImport />
                                </Authorized>} />
                                <Route path="/CsvImportEnergies" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryImport}>
                                    <CsvImportEnergies />
                                </Authorized>} />
                                <Route path="/XlsxImportEnergies" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryImport}>
                                    <XlsxImportEnergies />
                                </Authorized>} />
                                {/* Excise Register Report - routes */}
                                <Route path="/Reports/ExciseRegisters" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterReportDisplay}>
                                    <ExciseRegisterReport />
                                </Authorized>} />
                                <Route path="/Reports/ExciseStampRegisters" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseStampRegisterReportDisplay}>
                                    <ExciseStampRegisterReport />
                                </Authorized>} />
                                <Route path="/Reports/ExciseEnergyRegisters" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseEnergyRegisterReportDisplay}>
                                    <ExciseEnergyRegisterReport />
                                </Authorized>} />
                                <Route exact={true} path="/Reports/DailyReports" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseRegisterReportDisplay}>
                                    <DailyReports {...props} />
                                </Authorized>} />
                                {/* Excise Stamp Register  - routes */}
                                <Route exact={true} path="/ExciseStampRegisters" render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseStampRegisterDisplay}><ExciseStampRegisterList /></Authorized>} />
                                <Route path="/ExciseStampRegisters/Details/:registerId">
                                    <Route render={props => <PlaceContext stampRegisterId={props.match.params.registerId}>
                                        {
                                            placeContext => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterDisplay}>
                                                <ExciseStampRegisterDetails exciseStampRegisterId={props.match.params.registerId} {...props} />
                                            </Authorized>
                                        }
                                    </PlaceContext>} />
                                </Route>
                                {/* Excise Stamp Register Entries - routes */}
                                <Route path="/ExciseStampEntries/Preview/:entryId">
                                    <Route render={props => <PlaceContext stampEntryId={props.match.params.entryId}>
                                        {
                                            placeContext => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryDisplay}>
                                                <RegulationProvider>
                                                    <ExciseStampEntryFormDetails {...props} exciseEntryId={props.match.params.entryId} />
                                                </RegulationProvider>
                                            </Authorized>
                                        }
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseStampRegisters/:registerId/:registerType/Entries/Completed">
                                    <Route render={props => <PlaceContext stampRegisterId={props.match.params.registerId}>
                                        {
                                            placeContext => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryDisplay}>
                                                <CompletedExciseStampEntry placeContext={placeContext} exciseStampRegisterId={props.match.params.registerId} registerType={props.match.params.registerType} />
                                            </Authorized>
                                        }
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseStampRegisters/:registerId/:registerParagraph/Entries/Current">
                                    <Route render={props => <PlaceContext stampRegisterId={props.match.params.registerId}>
                                        {
                                            placeContext => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryDisplay}>
                                                <CurrentExciseStampEntry placeContext={placeContext} exciseStampRegisterId={props.match.params.registerId} registerType={props.match.params.registerParagraph} />
                                            </Authorized>
                                        }
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseStampRegisters/:registerId/:registerParagraph/ExciseStampEntries/Add">
                                    <Route render={props => <PlaceContext stampRegisterId={props.match.params.registerId}>
                                        {
                                            (placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                                                <RegulationProvider>
                                                    <ExciseStampEntryForm {...props} placeId={placeId} exciseStampRegisterId={props.match.params.registerId} registerParagraphType={props.match.params.registerParagraph} placeContext={placeContext} />
                                                </RegulationProvider>
                                            </Authorized>
                                        }
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseStampRegisters/:registerId/:registerParagraph/ExciseStampEntries/Edit/:exciseStampEntryId">
                                    <Route render={props => <PlaceContext stampRegisterId={props.match.params.registerId}>
                                        {
                                            (placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryEditing}>
                                                <RegulationProvider>
                                                    <ExciseStampEntryForm {...props} placeId={placeId} exciseStampRegisterId={props.match.params.registerId} registerParagraphType={props.match.params.registerParagraph} exciseStampEntryId={props.match.params.exciseStampEntryId} placeContext={placeContext} />
                                                </RegulationProvider>
                                            </Authorized>
                                        }
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseStampRegisters/:registerId/:registerParagraph/ExciseStampEntries/Correct/:exciseStampEntryId">
                                    <Route render={props => <PlaceContext stampRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryCorrecting}>
                                            <RegulationProvider>
                                                <ExciseStampEntryForm {...props} placeContext={placeContext} placeId={placeId} exciseStampRegisterId={props.match.params.registerId} registerParagraphType={props.match.params.registerParagraph} exciseStampEntryId={props.match.params.exciseStampEntryId} isCorrecting={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseStampRegisters/:registerId/:registerParagraph/ExciseStampEntries/CorrectAsNew">
                                    <Route render={props => <PlaceContext stampRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryCorrecting}>
                                            <RegulationProvider>
                                                <ExciseStampEntryForm {...props} placeContext={placeContext} placeId={placeId} exciseStampRegisterId={props.match.params.registerId} registerParagraphType={props.match.params.registerParagraph} exciseStampEntryId={props.match.params.exciseStampEntryId} isCorrectingAsNew={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                {/* Excise Energy Register  - routes */}
                                <Route exact={true} path="/ExciseEnergies/Registers"
                                    render={props => <Authorized showModal placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseEnergyRegisterDisplay}>
                                        <ExciseEnergyRegisterList />
                                    </Authorized>} />
                                <Route path="/ExciseEnergies/Registers/Details/:registerId">
                                    <Route render={props => <PlaceContext energyRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterDisplay}>
                                            <ExciseEnergyRegisterDetails exciseEnergyRegisterId={props.match.params.registerId} placeContext={placeContext} placeId={placeId} {...props} />
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseEnergies/Entries/Preview/:entryId">
                                    <Route render={props => <PlaceContext energyEntryId={props.match.params.entryId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay}>
                                            <RegulationProvider>
                                                <ExciseEnergyEntryForm placeContext={placeContext} placeId={placeId} {...props}
                                                    exciseEnergyEntryId={props.match.params.entryId}
                                                    isPreviewMode={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseEnergies/Registers/:registerId/Entries/Add">
                                    <Route render={props => <PlaceContext energyRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}>
                                            <RegulationProvider>
                                                <ExciseEnergyEntryForm placeContext={placeContext} placeId={placeId} {...props}
                                                    exciseEnergyRegisterId={props.match.params.registerId} isAddMode={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseEnergies/Registers/:registerId/Entries/Current">
                                    <Route render={props => <PlaceContext energyRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay}>
                                            <CurrentExciseEnergyEntry placeContext={placeContext} placeId={placeId} exciseEnergyRegisterId={props.match.params.registerId} />
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseEnergies/Registers/:registerId/Entries/Completed">
                                    <Route render={props => <PlaceContext energyRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay}>
                                            <CompletedExciseEnergyEntry placeContext={placeContext} placeId={placeId} exciseEnergyRegisterId={props.match.params.registerId} />
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseEnergies/Entries/Edit/:entryId">
                                    <Route render={props => <PlaceContext energyEntryId={props.match.params.entryId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryEditing}>
                                            <RegulationProvider>
                                                <ExciseEnergyEntryForm placeContext={placeContext} placeId={placeId} {...props}
                                                    exciseEnergyEntryId={props.match.params.entryId} isEditMode={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseEnergies/Entries/Correct/:entryId">
                                    <Route render={props => <PlaceContext energyEntryId={props.match.params.entryId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryCorrecting}>
                                            <RegulationProvider>
                                                <ExciseEnergyEntryForm placeContext={placeContext} placeId={placeId} {...props}
                                                    exciseEnergyEntryId={props.match.params.entryId} isCorrectMode={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>
                                <Route path="/ExciseEnergies/Registers/:registerId/Entries/CorrectAsNew">
                                    <Route render={props => <PlaceContext energyRegisterId={props.match.params.registerId}>
                                        {(placeContext, placeId) => <Authorized showModal placeContext={placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryCorrecting}>
                                            <RegulationProvider>
                                                <ExciseEnergyEntryForm placeContext={placeContext} placeId={placeId} {...props}
                                                    exciseEnergyRegisterId={props.match.params.registerId} isCorrectAsNewMode={true} />
                                            </RegulationProvider>
                                        </Authorized>}
                                    </PlaceContext>} />
                                </Route>

                                {/* Admin - routes */}
                                <Authorized showModal companyLevelPermission={CompanyLevelPermission.EwdAdministration}>
                                    <Route exact={true} path="/Admin/ExciseRegisters" render={props => <AdminExciseRegisterList />} />
                                    <Route path="/Admin/ExciseRegisters/Add" render={props => <ExciseRegisterCreate {...props} />} />
                                    <Route path="/Admin/ExciseRegisters/CreateInitialState/:registerId" render={props => <ExciseRegisterStateCreate {...props} exciseRegisterId={props.match.params.registerId} />} />
                                    <Route path="/Admin/ExciseRegisters/ExciseRegisterContainers/:registerId" render={props => <ExciseRegisterContainers {...props} exciseRegisterId={props.match.params.registerId} />} />
                                    <Route path="/Admin/ExciseRegisters/ExciseRegisterParagraphConfiguration/:registerId" render={props =>
                                        <RegulationProvider>
                                            <ExciseRegisterParagraphConfiguration {...props} exciseRegisterId={props.match.params.registerId} />
                                        </RegulationProvider>
                                    } />

                                    <Route exact={true} path="/Admin/ExciseStampRegisters" render={props => <ExciseStampRegisterAdminList />} />
                                    <Route path="/Admin/ExciseStampRegisters/Add" render={props => <ExciseStampRegisterCreate {...props} />} />
                                    <Route path="/Admin/ExciseStampRegisters/CreateInitialState/:registerId" render={props => <ExciseStampRegisterStateCreate {...props} exciseStampRegisterId={props.match.params.registerId} />} />

                                    <Route exact={true} path="/Admin/ExciseEnergies/Registers" render={props => <ExciseEnergyRegisterAdminList />} />
                                    <Route path="/Admin/ExciseEnergies/Registers/Add" render={props => <ExciseEnergyRegisterCreate {...props} />} />
                                    <Route path="/Admin/ExciseEnergies/Registers/Edit/:registerId" render={props => <ExciseEnergyRegisterCreate {...props} exciseEnergyRegisterId={props.match.params.registerId} />} />

                                    <Route exact={true} path="/Admin/Dictionaries" render={props => <EwdDictionaryList />} />
                                    <Route exact={true} path="/Admin/Dictionaries/:dictionaryId" render={props => <EwdDictionaryValueList dictionaryId={props.match.params.dictionaryId} />} />
                                    <Route path="/Admin/Dictionaries/:dictionaryId/DictionaryValues/Add" render={props => <EwdDictionaryValueCreate {...props} dictionaryId={props.match.params.dictionaryId} />} />
                                    <Route path="/Admin/Dictionaries/:dictionaryId/DictionaryValues/Edit/:dictionaryValueId" render={props => <EwdDictionaryValueEdit {...props} dictionaryId={props.match.params.dictionaryId} dictionaryValueId={props.match.params.dictionaryValueId} />} />

                                    <Route exact={true} path="/Admin/Places" render={props => <PlaceList />} />

                                    <Route path="/Admin/AuditLogs" render={props => <AuditLogList />} />

                                    <Route path="/Admin/CompanyUsers" render={props => <AdminCompanyUserList />} />
                                    <Route path="/Admin/UserPlacePermisions/:userId" render={props => <AdminUserPlacePermissionsForm userId={props.match.params.userId} {...props} />} />
                                    <Route exact={true} path="/Admin/Containers/:placeId/" render={props => <ContainersList placeId={props.match.params.placeId} {...props} />} />
                                    <Route path="/Admin/Containers/Add/:placeId/" render={props => <ContainerCreate placeId={props.match.params.placeId} {...props} />} />

                                    {/* CnToKwaMappingDictionary - routes */}
                                    <Route exact={true} path="/Admin/CnKwaDictionaries" render={props => <Authorized licencePermission={"CnToKwaMappingDictionary"}>
                                        <CnKwaDictionaryValueList />
                                    </Authorized>} />
                                    <Route path="/Admin/CnKwaDictionaries/Add" render={props => <Authorized licencePermission={"CnToKwaMappingDictionary"}>
                                        <CnKwaDictionaryValueCreate {...props} />
                                    </Authorized>} />
                                    <Route path="/Admin/CnKwaDictionaries/Edit/:dictionaryValueId" render={props => <Authorized licencePermission={"CnToKwaMappingDictionary"}>
                                        <CnKwaDictionaryValueEdit {...props} dictionaryValueId={props.match.params.dictionaryValueId} /></Authorized>} />
                                </Authorized>
                            </Switch>
                        </DictionaryProvider>
                    </RegisterProvider>}
            </AppLayout>
        </Authenticated >
    }
};

const randomKey = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export default withRouter(App);
