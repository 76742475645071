import React from 'react'
import { Button, Col, Row } from 'antd';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import { dateToString } from 'helper/GridHelper';
import { Moment } from 'moment';
import { ExciseEnergyRegisterStateDateDto } from 'services/GeneratedClient';
import FadingTooltip from 'layout/FadingTooltip';
import { CalendarOutlined } from '@ant-design/icons';

interface IProps { 
    exciseEnergyRegisterStateDate: ExciseEnergyRegisterStateDateDto | null,
    exciseEnergyEntryDate: Moment,
    changeDateHandler: (date: Moment) => void,
}

const ExciseEnergyRegisterStateDateInformation = ({ exciseEnergyRegisterStateDate, exciseEnergyEntryDate, changeDateHandler }: IProps) => {
    const changeDate = () => {
        changeDateHandler(exciseEnergyRegisterStateDate!.lastClosedDate
            ? exciseEnergyRegisterStateDate!.lastClosedDate.clone().add(1, 'day')
            : exciseEnergyRegisterStateDate!.oldestExciseEntryDate!.clone())
    }

    return(
        <Row>
            <Col span={24}>
                <Card title="Podsumowanie ewidencji">
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Pierwszy zamknięty dzień"}
                            value={exciseEnergyRegisterStateDate ? dateToString(exciseEnergyRegisterStateDate.firstClosedDate) : ""}
                        />
                        <DetailCell span={10}
                            label={"Ostatni zamknięty dzień"}
                            value={exciseEnergyRegisterStateDate ? dateToString(exciseEnergyRegisterStateDate.lastClosedDate) : ""}>
                            <FadingTooltip title="Przejdź do pierwszego dnia, który można zamknąć" placement="top">
                                <Button type="ghost" shape="circle" size='small' icon={<CalendarOutlined />}
                                    onClick={() => changeDate()}
                                />
                            </FadingTooltip>
                        </DetailCell>
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Status dnia"}
                            value={dateToString(exciseEnergyEntryDate)! <= dateToString(exciseEnergyRegisterStateDate?.lastClosedDate)! ? "Zamknięty" : "Otwarty"}
                        />
                    </RowWithBottomMargin>
                </Card>
            </Col>
        </Row>
    )};

export default ExciseEnergyRegisterStateDateInformation;