import * as Yup from 'yup';

export default class ExciseStampRegisterStateValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        return Yup.object().shape({
            date: Yup.date().required(),
            stampItemsQuantity: Yup.number().required(),
            stampItemsInRegisterQuantity: Yup.number().required(),
            stampItemsOutOfRegisterQuantity: Yup.number().required(),
            stampItemsAppliedQuantity: Yup.number().required(),
            stampItemsDamagedQuantity: Yup.number().required(),
            stampItemsLostQuantity: Yup.number().required(),
            stampItemsDestroyedQuantity: Yup.number().required(),
        });
    }
}
