import React from 'react';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import { EwdPlaceLevelPermission, ExciseStampRegisterClient, ExciseStampRegisterDto, ExciseStampRegisterStateClient, ExciseStampRegisterStateCloseDayResultDto, ExciseStampRegisterStateCloseDaysCmd, ExciseStampRegisterStateCloseDaysResponseDto, ExciseStampRegisterStateDetailsDto, ExciseStampRegisterStateDto } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { ajaxCatch } from 'helper/api';
import ExciseStampRegisterInformation from './ExciseStampRegisterInformation';
import { CenteredRow } from 'layout/CenteredRow';
import { Button, Col, Divider, notification, Popconfirm, Row } from 'antd';
import { Card } from 'components/StateDetailsComponents';
import { DatePicker } from 'components/DatePicker';
import moment, { Moment } from 'moment';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { showErrorListModal } from 'layout/Modals';
import ExciseStampRegisterStateInformation from './ExciseStampRegisterStateInformation';
import ExciseStampEntryDailyList from 'exciseStamps/exciseStampEntries/ExciseStampEntryDailyList';
import { RouteComponentProps } from 'react-router';
import { GridStore } from 'layout/table/paginated/GridStore';
import { dateToString } from 'helper/GridHelper';
import { Authorized } from 'authorization/Authorized';
import { AlertsStore } from 'alerts/AlertsStore';
import { RegisterDetailsStore } from 'registerDetails/RegisterDetailsStore';

interface IProps extends RouteComponentProps<any> {
    exciseStampRegisterId: number;
    dictionaryStore?: DictionaryStore;
    alertsStore?: AlertsStore;
    gridStore?: GridStore;
    registerDetailsStore?: RegisterDetailsStore;
}

interface IState {
    exciseStampRegister: ExciseStampRegisterDto | null;
    exciseStampRegisterState: ExciseStampRegisterStateDto | null;
    IsPossibleToCloseDay: boolean;
    IsLoading: boolean;
    selectedDate: Moment;
    closingDays: boolean;
    exciseStampRegisterStateDetails: ExciseStampRegisterStateDetailsDto | null;
}

@inject("dictionaryStore")
@inject("alertsStore")
@inject("gridStore")
@inject("registerDetailsStore")
export default class ExciseStampRegisterDetails extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedDate: moment().asUtc(),
            exciseStampRegisterState: null,
            IsPossibleToCloseDay: false,
            IsLoading: false,
            exciseStampRegister: null,
            closingDays: false,
            exciseStampRegisterStateDetails: null,
        }
    }

    componentDidMount() {
        this.props.registerDetailsStore?.checkSelectedRegister(`ExciseStampRegister${this.props.exciseStampRegisterId}`);
        this.setState({ selectedDate: this.getDate() }, () => this.getCloseDayState());
        this.getExciseStampRegisterStateDetails();
        this.getExciseStampRegister();
    }

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if (prevState.selectedDate !== this.state.selectedDate) {
            this.getCloseDayState();
            this.props.registerDetailsStore?.saveSelectedDate(this.state.selectedDate);
        }
        if (prevProps.exciseStampRegisterId !== this.props.exciseStampRegisterId) {
            this.getExciseStampRegister();
            this.getExciseStampRegisterStateDetails();
            this.setState({ selectedDate: this.getDate() }, () => this.getCloseDayState());
        }
    }

    getDate() {
        const date = (this.props.location.state?.firstUnclosedDate && this.props.gridStore?.getIsReloading())
            ? moment(this.props.location.state.firstUnclosedDate).asDate()
            : (this.props.registerDetailsStore?.getSelectedDate() ?? this.state.selectedDate);
        this.props.gridStore?.saveIsReloading(false);
        return date;
    }

    getExciseStampRegister = () => {
        ajaxCatch(() =>
            new ExciseStampRegisterClient().get(this.props.exciseStampRegisterId),
            (result: ExciseStampRegisterDto | null) => {
                this.setState({
                    exciseStampRegister: result,
                    closingDays: result?.closingDaysInProgress ?? false,
                })
            });
    }

    getExciseStampRegisterStateDetails = () => {
        ajaxCatch(() =>
            new ExciseStampRegisterStateClient().getExciseStampRegisterStateDetails(this.props.exciseStampRegisterId),
            (result: ExciseStampRegisterStateDetailsDto | null) => {
                this.setState({
                    exciseStampRegisterStateDetails: result
                })
            });
    }

    getCloseDayState = () => {
        this.setState({ IsLoading: true })
        ajaxCatch(() =>
            new ExciseStampRegisterStateClient().getCloseDayState(this.props.exciseStampRegisterId, this.state.selectedDate.asUtc()),
            (result: ExciseStampRegisterStateCloseDayResultDto | null) => {
                if (result) {
                    this.setState({
                        exciseStampRegisterState: result.exciseStampRegisterState ?? null,
                        IsPossibleToCloseDay: result.isPossibleToCloseDay
                    })
                }
            }).finally(() => {
                this.setState({ IsLoading: false })
            });
    }

    setDayToPrevious = () => {
        this.setState({
            selectedDate: moment(this.state.selectedDate.add(-1, 'days'))
        })
    }

    dateOnChange = (value: Moment | null, dateString: string) => {
        if (value) {
            this.setState({
                selectedDate: moment(value).asDate()
            })
        }
    }

    setDayToNext = () => {
        this.setState({
            selectedDate: moment(this.state.selectedDate.add(1, 'days'))
        })
    }

    closeDays = () => {
        this.setState({ closingDays: true });

        let cmd = new ExciseStampRegisterStateCloseDaysCmd({
            exciseStampRegisterId: this.props.exciseStampRegisterId,
            toDate: this.state.selectedDate.asUtc(),
            editorId: undefined,
        });

        ajaxCatch(() => new ExciseStampRegisterStateClient().closeDays(cmd),
            (result: ExciseStampRegisterStateCloseDaysResponseDto | null) => {
                if (result && result.errors && result.errors.length > 0) {
                    showErrorListModal(result.message!, result.errors);
                } else {
                    notification.success({
                        placement: "bottomRight",
                        message: result!.message!,
                    });
                    this.getCloseDayState();
                    this.getExciseStampRegisterStateDetails();
                    this.props.alertsStore?.fetchAndFilterCloseDayStampReminder();
                }
            }).finally(() => { this.setState({ closingDays: false }) });
    }

    isDisabledDate = (date: Moment) => {
        return this.state.exciseStampRegisterStateDetails ? this.state.exciseStampRegisterStateDetails?.firstClosedDate.asDate() > date.asDate() : false;
    }

    isPreviousDayDisabled = () => {
        return this.state.closingDays || dateToString(this.state.selectedDate) === dateToString(this.state.exciseStampRegisterStateDetails?.firstClosedDate)
    }

    isNextDayDisabled = () => {
        return this.state.closingDays;
    }

    getNumberOfDaysToClose = (): number => {
        let dateFrom = this.state.exciseStampRegisterStateDetails?.lastClosedDate.asUtc().asDate();
        let dateTo = this.state.selectedDate.asUtc().asDate();
        let numberOfDays = dateTo.diff(dateFrom, "days");
        return numberOfDays;
    }

    getCloseDaysPopconfirmMessage = () => {
        let dateFrom = dateToString(this.state.exciseStampRegisterStateDetails?.lastClosedDate.clone().add(1, "day"));
        let dateTo = dateToString(this.state.selectedDate);
        return <>
            <span>Zamykany dzień nie jest pierwszym otwartym.</span> <br />
            <span>System sprawdzi, czy w danym okresie znajdują się zdarzenia redagowane.</span> <br />
            <span>Czy na pewno chcesz zamknąć dni od {dateFrom} do {dateTo} włącznie?</span>
        </>;
    }

    getStateSummaryText = () => {
        return this.state.closingDays ? "Zamykanie dni w trakcie" : "Brak zainicjalizowanego stanu początkowego!"
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`SZCZEGÓŁY EWIDENCJI BANDEROL`} goBack={this.props.history.goBack} />
                {this.state.exciseStampRegister && this.props.dictionaryStore &&
                    <ExciseStampRegisterInformation exciseStampRegister={this.state.exciseStampRegister} dictionaryStore={this.props.dictionaryStore} />}
                {(this.state.exciseStampRegister && this.state.exciseStampRegisterStateDetails) ?
                    <ExciseStampRegisterStateInformation exciseStampRegisterState={this.state.exciseStampRegisterState} exciseStampRegisterStateDetails={this.state.exciseStampRegisterStateDetails} />
                    : <Row>
                        <Col span={12}>
                            <Card title="Podsumowanie dnia">
                                <p>{this.getStateSummaryText()}</p>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Podsumowanie całkowite">
                                <p>{this.getStateSummaryText()}</p>
                            </Card>
                        </Col>
                    </Row>}
                <Divider />
                <CenteredRow>
                    <Button onClick={this.setDayToPrevious} disabled={this.isPreviousDayDisabled()}>{'<'}</Button>
                    <DatePicker onChange={this.dateOnChange} value={moment(this.state.selectedDate.asDate())} disabledDate={this.isDisabledDate} format={"YYYY-MM-DD"} disabled={this.state.closingDays} />
                    <Button onClick={this.setDayToNext} disabled={this.isNextDayDisabled()}>{'>'}</Button>
                </CenteredRow>
                <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterEntryDisplay}
                    placeContext={this.state.exciseStampRegister?.placeName}>
                    {
                        this.state.exciseStampRegister &&
                        <ExciseStampEntryDailyList exciseStampRegisterId={this.props.exciseStampRegisterId} date={this.state.selectedDate}
                            placeContext={this.state.exciseStampRegister.placeName!}
                            registerType={this.state.exciseStampRegister.exciseStampRegisterType} />
                    }
                </Authorized>
                <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseStampRegisterDayClosing}
                    placeContext={this.state.exciseStampRegister?.placeName}>
                    <BottomActionButtons>
                        <Popconfirm placement="top"
                            disabled={this.getNumberOfDaysToClose() < 2 || this.state.closingDays}
                            title={this.getCloseDaysPopconfirmMessage()} okText="Tak" cancelText="Nie"
                            onConfirm={this.closeDays}>
                            <Button type="primary"
                                size={'large'}
                                disabled={!this.state.IsPossibleToCloseDay || this.state.IsLoading || this.state.closingDays}
                                loading={this.state.closingDays}
                                onClick={() => { this.getNumberOfDaysToClose() < 2 && this.closeDays() }}>
                                {this.getNumberOfDaysToClose() > 1 ? "Zamknij dni do wskazanej daty" : "Zamknij dzień"}
                            </Button>
                        </Popconfirm>
                    </BottomActionButtons>
                </Authorized>
            </>
        )
    }
};
