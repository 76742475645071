import React, { createRef } from 'react'
import { Button, Col, Row } from 'antd';
import { ExciseStampRegisterStateDetailsDto, ExciseStampRegisterStateDto } from 'services/GeneratedClient';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import { dateToString } from 'helper/GridHelper';
import FadingTooltip from 'layout/FadingTooltip';
import { FileSearchOutlined } from '@ant-design/icons';
import ExciseStampRegisterStatesOutOfRegisterModal from './ExciseStampRegisterStatesOutOfRegisterModal';

interface IProps {
    exciseStampRegisterState: ExciseStampRegisterStateDto | null,
    exciseStampRegisterStateDetails: ExciseStampRegisterStateDetailsDto | null
}

const ExciseRegisterStateInformation = ({ exciseStampRegisterState, exciseStampRegisterStateDetails }: IProps) => {
    const exciseStampRegisterStatesOutOfRegisterModalRef = createRef<ExciseStampRegisterStatesOutOfRegisterModal>();
    return (
        <Row>
            <Col span={12}>
                <Card title={<><span>Podsumowanie dnia</span> <FadingTooltip title="Pokaż rozliczenie wysyłek" placement="top">
                        <Button type="ghost" size='small' shape="circle" icon={<FileSearchOutlined />} onClick={() => exciseStampRegisterStatesOutOfRegisterModalRef.current?.show()}></Button>
                    </FadingTooltip></>}>
                    <ExciseStampRegisterStatesOutOfRegisterModal
                        ref={exciseStampRegisterStatesOutOfRegisterModalRef}
                        outOfRegisterStates={exciseStampRegisterState?.exciseStampRegisterStatesOutOfRegisterDto!}
                        headerName='Podsumowanie dnia - rozliczenie wysyłek'
                    />
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Status"}
                            value={exciseStampRegisterState ? "Zamknięty" : "Otwarty"} />
                        <DetailCell
                            label={"Stan banderol"}
                            value={exciseStampRegisterState ? exciseStampRegisterState.stampItemsQuantity.toString() : ""} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell 
                            label={"Stan banderol w siedzibie"}
                            value={exciseStampRegisterState ? exciseStampRegisterState.stampItemsInRegisterQuantity.toString() : ""} />
                        <DetailCell 
                            label={"Stan banderol poza siedzibą"}
                            value={exciseStampRegisterState ? exciseStampRegisterState.stampItemsOutOfRegisterQuantity.toString() : ""} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell 
                            label={"Stan banderol naniesionych"}
                            value={exciseStampRegisterState ? exciseStampRegisterState.stampItemsAppliedQuantity.toString() : ""} />
                        <DetailCell 
                            label={"Stan banderol uszkodzonych"}
                            value={exciseStampRegisterState ? exciseStampRegisterState.stampItemsDamagedQuantity.toString() : ""} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell 
                            label={"Stan banderol utraconych"}
                            value={exciseStampRegisterState ? exciseStampRegisterState.stampItemsLostQuantity.toString() : ""} />
                        <DetailCell 
                            label={"Stan banderol zniszczonych"}
                            value={exciseStampRegisterState ? exciseStampRegisterState.stampItemsDestroyedQuantity.toString() : ""} />
                    </RowWithBottomMargin>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Podsumowanie całkowite">
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Dzień rozpoczęcia ewidencji"}
                            value={exciseStampRegisterStateDetails ? dateToString(exciseStampRegisterStateDetails.firstClosedDate) : ""} />
                        <DetailCell
                            label={"Stan banderol w dniu rozpoczęcia ewidencji"}
                            value={exciseStampRegisterStateDetails ? exciseStampRegisterStateDetails.stampItemsQuantityInitial.toString() : ""} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Ostatni zamknięty dzień"}
                            value={exciseStampRegisterStateDetails ? dateToString(exciseStampRegisterStateDetails.lastClosedDate) : ""} />
                        <DetailCell
                            label={"Stan banderol w ostatnim zamkniętym dniu"}
                            value={exciseStampRegisterStateDetails ? exciseStampRegisterStateDetails.stampItemsQuantityLastDay.toString() : ""} />
                    </RowWithBottomMargin>
                </Card>
            </Col>
        </Row>
)}

export default ExciseRegisterStateInformation;