import * as Yup from 'yup';

export default class ExciseRegisterInventoryValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        return Yup.object().shape({
            containers: Yup.array()
                .of(
                Yup.object().shape({
                    amount: Yup.number().required(),
                })
                )
        });
    }
}
