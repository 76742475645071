import React, { createRef } from 'react';
import { EwdColumnProps } from '../../helper/GridHelper';
import { ImportMessageType, ImportRecord, } from '../../services/GeneratedClient';
import * as ImportMessageTypeMapper from '..//models/ImportMessageType';
import { PageHeader } from '../../layout/PageHeader';
import { MinusCircleOutlined, PlusCircleFilled, CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import TableWithSummaries from '../../layout/table/paginated/TableWithSummaries';
import EwdCard from '..//../components/EwdCard';

interface IImportMessages { message: string; }
interface IProps {
    importedRecords: ImportRecord[];
    globalResult: ImportMessageType;
}

interface IState {
    gridName: string;
}

export default class ImportResultList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "importResultList"
        }
    }

    private columns: EwdColumnProps<ImportRecord>[] = [
        {
            dataIndex: "ident",
            key: "ident",
            title: "Identyfikator",
        },
        {
            dataIndex: "messageType",
            key: "messageType",
            title: "Status",
            render: (text: any, record: ImportRecord) => {
                if (record.messageType) {
                    switch (record.messageType) {
                        case ImportMessageType.Error: return <CloseCircleOutlined style={{ fontSize: '24px', color: "red" }} />
                        case ImportMessageType.Success: return <CheckCircleOutlined style={{ fontSize: '24px', color: "green" }} />
                        case ImportMessageType.Warning: return <ExclamationCircleOutlined style={{ fontSize: '24px', color: "orange" }} />
                        case ImportMessageType.InProgress: return <ClockCircleOutlined style={{ fontSize: '24px', color: "grey" }} />
                        default: return <CloseCircleOutlined style={{ fontSize: '24px', color: "red" }} />
                    }
                }
            }
        },

    ];

    expandedRowRender = (data: string[] | undefined) => {
        const columns = [
            {
                dataIndex: "message",
                key: "Message",
                title: "Wiadomość",
                render: (text: any, record: IImportMessages) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        {record.message}
                    </div>
                ),
            },
        ]
        var messages = data!.map((val, index) => ({
            message: val,
            key: index
        } as IImportMessages));


        return (
            <EwdCard>
                <TableWithSummaries gridName={"importResultMessages"} columns={columns} dataSource={messages} pagination={false} />
            </EwdCard >
        )
    }

    private paginatedTable = createRef<TableWithSummaries<ImportRecord>>();

    public render() {
        return (
            <>
                <PageHeader title={`Rezultaty importu - ${this.props.globalResult && ImportMessageTypeMapper.map(this.props.globalResult)}`} />
                <TableWithSummaries<ImportRecord>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    dataSource={this.props.importedRecords}
                    rowKey={(r: ImportRecord) => r.ident!}
                    expandable={{
                        expandIconColumnIndex: 2,

                        expandedRowRender: record => this.expandedRowRender(record.errors),
                        rowExpandable: record => record.errors != null && record.errors.length > 0,
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <MinusCircleOutlined style={{ fontSize: '24px', color: "#009de1" }} onClick={e => onExpand(record, e)} />
                            ) : (
                                record.errors && <Tooltip placement="left" title="Dodatkowe informacje" >
                                    <PlusCircleFilled style={{ fontSize: '24px', color: "#009de1" }} onClick={e => onExpand(record, e)} />
                                </Tooltip>
                            )
                    }}
                />
            </>
        )
    }

};

export class ImportMessages {
    message!: string | undefined;
}

