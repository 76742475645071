import { Descriptions as AntdDescriptions, DescriptionsProps } from "antd";
import { DescriptionsItemProps } from "antd/lib/descriptions/Item";
import React from "react";

function Descriptions(props: DescriptionsProps): JSX.Element {
    return <AntdDescriptions {...props} size="small" bordered column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} />;
};

Descriptions.Item = (props: DescriptionsItemProps) => <AntdDescriptions.Item {...props} />

export default Descriptions