const getExciseRegisterReportGridName = (isIncome: boolean, isExpenditure: boolean, templateApplied?: boolean) => {
    let gridName = ""

    if (isIncome && isExpenditure) {
        gridName = `FullExciseRegisterReportList`;
    }
    else if (isIncome) {
        gridName = `IncomeExciseRegisterReportList`;
    }
    else if (isExpenditure) {
        gridName = `ExpenditureExciseRegisterReportList`;
    }
    else {
        gridName = `ExciseRegisterReportList`;
    }

    return gridName + (templateApplied ? "Template" : "");
};

export default getExciseRegisterReportGridName;

