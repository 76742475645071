import { ColumnProps } from "antd/lib/table";
import { EwdColumnProps } from "helper/GridHelper";
import { GridSettingsDto } from "services/GeneratedClient";

export default class ColumnHelper {
  public static findColumnByKey<T>(columns: EwdColumnProps<T>[], key: React.Key): EwdColumnProps<T> | undefined {
      for (let column of columns) {
          if (column.key === key) return column;

          if (column.children !== undefined) {
              let foundInChildren = this.findColumnByKey<T>(column.children!, key);

              if (foundInChildren !== undefined) return foundInChildren!;
          }
      }

      return undefined;
  }

  public static getFlattenKeys(columns: ColumnProps<any>[]): string[] {
      let visibleColumnsKeys: string[] = [];

      columns.forEach(column => {
          if (column.key != undefined) {
              visibleColumnsKeys.push(column.key.toString());
          }
      });

      return visibleColumnsKeys;
  }

  public static getVisibleColumns(gridSettings: GridSettingsDto | undefined, allColumns: ColumnProps<any>[]): ColumnProps<any>[] {
      if (!gridSettings) return allColumns;

      let visibleColumns: ColumnProps<any>[] = [];

      allColumns.forEach(column => {
          if (column.key === "actions" || gridSettings.visibleColumnKeys!.includes(column.key!.toString())) {
              visibleColumns.push(column);
          }
      });

      return visibleColumns;
  }

  public static getVisibleColumnsKeys(gridSettings: GridSettingsDto | undefined, allColumns: ColumnProps<any>[]): string[] {
      if (!gridSettings) return this.getFlattenKeys(allColumns);
      let filteredColumns = this.getVisibleColumns(gridSettings, allColumns);
      let visibleColumnsKeys: string[] = this.getFlattenKeys(filteredColumns);
      return visibleColumnsKeys;
    }
}