import { observable, autorun, action } from "mobx";
import { ajaxCatch } from "helper/api";
import { User } from "oidc-client";
import { UserDetailsDto, UserClient, UserCompanyModel, SaveGridSettingsCmd, GridSettingsDto } from "services/GeneratedClient";
import { SettingsStore } from '../settings/settingsStore';
import { PlatformClient } from '../services/PlatformClient';
import { Key } from "react";

export class AccountDetailsStore {
    @observable user: UserDetailsDto | undefined = undefined;
    @observable currentCompany: UserCompanyModel | undefined = undefined;
    @observable oidcUser: User | undefined = undefined;
    @observable gridSettings: { [key: string]: GridSettingsDto } | undefined = undefined;
    private settingsStore: SettingsStore;

    constructor(settingsStore: SettingsStore) {
        this.settingsStore = settingsStore;
        autorun(() => {
            if (this.oidcUser) {
                this.fetchAccountDetails();
                this.fetchGridSettings();
            }
        });
    }

    @action public fetchAccountDetails() {
        return ajaxCatch(
            () => new UserClient().getUserDetails(),
            action((user: UserDetailsDto | null) => {
                if (user) {
                    this.user = user;
                    if (user.companies) {
                        this.currentCompany = user.companies.filter(c => { return c.id.toString() === localStorage.getItem("companyId"); })[0] || user.companies[0];
                    }
                }
            })
        );
    }

    @action public fetchGridSettings() {
        return ajaxCatch(
            () => new UserClient().getGridSettings(),
            action((gridSettings: { [key: string]: GridSettingsDto } | null) => {
                if (gridSettings) {
                    this.gridSettings = gridSettings;
                }
            })
        );
    }

    @action changeCurrentCompany(id: number) {
        let company = this.user!.companies!.filter(x => x.id === id);
        let newCompany = company && company.length > 0 && company[0];
        if (newCompany) {
            localStorage.setItem("companyId", id.toString());
            this.currentCompany = newCompany;
        }

        new PlatformClient(this.settingsStore.settings!.authorityUrl!).changeCurrentCompany(id);
    }

    @action setOidcUser(oidcUser: User) {
        this.oidcUser = oidcUser;
    }

    @action saveVisibleColumns(gridName: string, visibleColumnKeys: Key[]) {
        ajaxCatch(() => new UserClient().saveGridSettings(new SaveGridSettingsCmd({
            gridName: gridName,
            visibleColumnKeys: visibleColumnKeys.map(x => { return x.toString() }),
            appUserId: undefined
        })), () => {
            this.fetchGridSettings();
        });
    }

    @action forceVisibleColumnsChange(gridName: string, visibleColumnKeys: string[]) {
        const grid = this.gridSettings![gridName];
        if (!grid) {
            this.gridSettings![gridName] = {
                gridName: gridName,
                visibleColumnKeys: visibleColumnKeys
            } as GridSettingsDto;
        } else {
            grid.visibleColumnKeys = visibleColumnKeys;
            this.saveVisibleColumns(gridName, visibleColumnKeys!);
        }
    }
}