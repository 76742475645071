import React, { useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd';
import { ajaxCatch } from 'helper/api';
import { CenteredSpin } from 'layout/CenteredSpin';
import { DictionaryName, ExciseStampRegisterClient, ExciseStampRegisterDto } from 'services/GeneratedClient';
import { useDictionaryStore } from 'dictionary/dictionaryStore';
import { DetailCell, RowWithBottomMargin, Card } from 'components/StateDetailsComponents';

interface Props {
    exciseStampRegisterId: number
}

const ExciseStampRegisterBasicDetails = (props: Props) => {
    var dictionaryStore = useDictionaryStore();
    const [exciseStampRegister, setExciseStampRegister] = useState<ExciseStampRegisterDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        setIsLoading(true);
        fetchExciseStampRegister();
    }, [props.exciseStampRegisterId]);

    function fetchExciseStampRegister() {
        ajaxCatch(() =>
            new ExciseStampRegisterClient().get(props.exciseStampRegisterId),
            (result: ExciseStampRegisterDto | null) => {
                setExciseStampRegister(result!)
            }).finally(() => setIsLoading(false));
    }

    return (
        <RowWithBottomMargin>
            <Col span={24}>
                <Card className='m-0' title="Dane ewidencji banderol">
                    { isLoading ? <CenteredSpin size="large" /> : 
                    <>
                        <Row>
                            <DetailCell
                                label={"Id"}
                                value={exciseStampRegister?.id.toString()} />
                            <DetailCell
                                label={"Nazwa"}
                                value={exciseStampRegister?.name} />
                            <DetailCell
                                label={"Typ rejestru"}
                                value={dictionaryStore?.getDisplay(DictionaryName.ExciseStampRegisterType, exciseStampRegister?.exciseStampRegisterType)} />
                            <DetailCell
                                label={"Nazwa miejsca"}
                                value={exciseStampRegister?.place?.name} />
                            <DetailCell 
                                label={"Rodzaj rejestru"}
                                value={exciseStampRegister?.exciseStampType} />
                        </Row>
                    </>}
                </Card>
            </Col>
        </RowWithBottomMargin>
    )};

export default ExciseStampRegisterBasicDetails;
