import { ajaxCatch } from "helper/api";
import { observable, action } from "mobx";
import { AllRegistersDto, ContainerDto, ExciseCustomRate, GoodsDictionaryDto, PlaceDto, RegisterClient, TraderDto } from "../services/GeneratedClient";

export class RegisterStore {
    @observable allPlaces!: PlaceDto[];
    @observable allSubjectPlaces!: PlaceDto[];
    @observable traders!: TraderDto[];
    @observable goodDictionaries!: GoodsDictionaryDto[];
    @observable companyId: number | undefined = undefined;
    @observable containersAll!: ContainerDto[];
    @observable containersActive!: ContainerDto[];
    @observable exciseEnergyRates!: ExciseCustomRate[];

    get places(): PlaceDto[] { return this.allPlaces.filter(place => place.isActive); }

    constructor() {

    }

    @action setAllRegisters(allRegisters: AllRegistersDto, companyId: number | undefined) {
        this.companyId = companyId;
        this.allPlaces = allRegisters.places !== undefined ? allRegisters.places.filter(p => p.companyId === companyId) : [];
        this.allSubjectPlaces = allRegisters.places !== undefined ? allRegisters.places.filter(p => p.companyId === companyId && p.placeExciseNumber !== null && p.placeExciseNumber !== undefined && p.isActive) : [];
        this.traders = allRegisters.traders !== undefined ? allRegisters.traders.filter(p => p.companyId === companyId && p.isActive) : [];
        this.goodDictionaries = allRegisters.goodsDictionaries !== undefined ? allRegisters.goodsDictionaries : [];
        this.containersAll = allRegisters.containers !== undefined ? allRegisters.containers : [];
        this.containersActive = allRegisters.containers !== undefined ? allRegisters.containers.filter(p => p.isActive) : [];
        this.exciseEnergyRates = allRegisters.exciseEnergyRates !== undefined ? allRegisters.exciseEnergyRates : [];
    }
    // TODO: zrobić odświeżanie pojedynczych rejestrów
    @action refreshRegisters() {
        ajaxCatch(() =>
            new RegisterClient().getAll(),
            (registers: AllRegistersDto | null) => {
                this.setAllRegisters(registers!, this.companyId);
            });
    }
    @action setCompany(companyId?: number) {
        this.companyId = companyId;
    }

    public getContainerById(id: number): ContainerDto {

        let values = this.containersAll!.filter(a => a.id === id);

        if (values.length !== 1) {
            throw `Nie odnaleziono id ${id} w słowniku zbiorników`;
        }

        return values[0];
    }

    public getContainerDisplayById(id?: number): string {
        if (!id) {
            return ``;
        }
        try {
            return this.getContainerById(id).name || "";
        }
        catch (error) {
            console.log(error);
            return id.toString();
        }
    }
}
