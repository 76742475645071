import { observable, action } from "mobx";
import moment, { Moment } from "moment";

export class RegisterDetailsStore {
    @observable selectedDate: Moment;
    @observable registerForSelectedDate: string;

    constructor() {
        this.selectedDate = moment().asUtc();
        this.registerForSelectedDate = "";
    }

    @action saveSelectedDate(date: Moment) {
        this.selectedDate = date;
    }

    getSelectedDate() {
        return this.selectedDate;
    }

    clearSelectedDate() {
        this.saveSelectedDate(moment().asUtc());
    }

    saveRegisterForSelectedDate(name: string) {
        this.registerForSelectedDate = name;
    }

    getRegisterForSelectedDate() {
        return this.registerForSelectedDate;
    }

    @action checkSelectedRegister(registerTypeRegisterId: string) {
        if (registerTypeRegisterId !== this.getRegisterForSelectedDate())
        {
            this.clearSelectedDate();
            this.saveRegisterForSelectedDate(registerTypeRegisterId);
        }
    }
}