import React, { ReactNode } from "react";

export interface IPageHeaderProps {
    title: string;
    right?: ReactNode;
}
export class PageHeader extends React.PureComponent<IPageHeaderProps> {
    constructor(props: IPageHeaderProps) {
        super(props);
    };
    render() {
        return <div className="page-title">
            {this.props.title}
            <div style={{ float: "right" }}>
                {this.props.right}
            </div>
        </div>
    }
}