import { ajaxCatch } from "helper/api";
import React, { ReactElement } from "react";
import { PlaceContextClient, PlaceContextDto } from "services/GeneratedClient";

interface IProps {
    exciseRegisterId?: number,
    exciseEntryId?: number
    stampRegisterId?: number,
    stampEntryId?: number,
    energyRegisterId?: number,
    energyEntryId?: number,
    children(placeContext: string, placeId: number): ReactElement;
}

export interface IState {
    placeContext: PlaceContextDto;
}

export default class PlaceContext extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
    }

    componentDidMount() {
        ajaxCatch(() =>
            new PlaceContextClient().getContext(
                this.props.exciseRegisterId,
                this.props.exciseEntryId,
                this.props.stampRegisterId,
                this.props.stampEntryId,
                this.props.energyRegisterId,
                this.props.energyEntryId), (result: PlaceContextDto | null) => {

                    this.setState({
                        placeContext: result!
                    })
                });
    }

    public render() {
        return (<>
            {
                this.state && this.state.placeContext && this.props.children(this.state.placeContext.name!, this.state.placeContext.placeId!)
            }
        </>);
    }
}
