import React from 'react';
import Tabs, { TabPane } from 'layout/tabs/Tabs'
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import CurrentExciseEntryList from './CurrentExciseEntryList';
import { Button, Divider } from 'antd';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Link } from 'react-router-dom';
import { Authorized } from 'authorization/Authorized';
import { EwdPlaceLevelPermission } from 'services/GeneratedClient';
import { IPlaceContextProps } from 'authorization/interfaces/IPlaceContextProps';
import ExciseRegisterBasicDetails from 'exciseRegisters/ExciseRegisterBasicDetails';

interface IProps extends IPlaceContextProps {
    exciseRegisterId: number;
}

type OptionValue = 'all' | 'income' | 'expenditure' | 'transshipment';

interface IOption {
    label: string;
    isIncome?: boolean;
    isExpenditure?: boolean;
    isTransshipment?: boolean;
    value: OptionValue;
}

export default class CurrentExciseEntry extends React.PureComponent<IProps, {}>{
    private options: (IOption)[] = [
        { label: 'Przychód', value: 'income', isIncome: true },
        { label: 'Rozchód', value: 'expenditure', isExpenditure: true },
        { label: 'Przeładunek', value: 'transshipment', isTransshipment: true },
        { label: 'Wszystko', value: 'all', isIncome: true, isExpenditure: true, isTransshipment: true }
    ]

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`REJESTR ZDARZEŃ`} goBack={"/ExciseRegisters"} />
                <ExciseRegisterBasicDetails exciseRegisterId={this.props.exciseRegisterId} />
                <Divider />
                <Tabs type="card" defaultActiveKey={"all"}>
                    {this.options.map(option => {
                        return <TabPane tab={option.label} key={option.value} >
                            <CurrentExciseEntryList exciseRegisterId={this.props.exciseRegisterId}
                                isIncome={option.isIncome || false}
                                isExpenditure={option.isExpenditure || false}
                                isTransshipment={option.isTransshipment || false}
                            />
                        </TabPane>
                    })}
                </Tabs>
                <BottomActionButtons>
                    <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}
                        placeContext={this.props.placeContext}>
                        <Link to={`/ExciseRegisters/${this.props.exciseRegisterId}/ExciseEntries/Add`}>
                            <Button>Dodaj zdarzenie</Button>
                        </Link>
                    </Authorized>
                    {/* <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryImport}
                        placeContext={this.props.placeContext}>
                        <Link to={`/ExciseRegisters/${this.props.exciseRegisterId}/ExciseEntries/Import`}>
                            <Button>Importuj zdarzenie</Button>
                        </Link>
                    </Authorized> */}
                </BottomActionButtons>
            </>
        )
    }
};