import React from "react";
import { Input, Button } from "antd";
import { CenteredRow } from "layout/CenteredRow";
import { inject, observer } from "mobx-react";
import { GridStore } from "layout/table/paginated/GridStore";
import { EwdColumnProps } from "helper/GridHelper";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";


interface IProps<T> {
    column: EwdColumnProps<T>;
    handleSearch: () => void;
    gridStore?: GridStore;
    gridName: string;
}

interface IState {
    value?: string | undefined;
}

@inject("gridStore")
@observer
export default class GridFilterInput<T> extends React.Component<IProps<T>, IState> {
    constructor(props: IProps<T>) {
        super(props);
        this.state = {
            value: this.props.gridStore!.getColumnFilter(this.props.gridName, this.props.column.key!)
        }
    }

    public render() {
        return <>
            <CenteredRow>
                <Button type="primary" icon={<SearchOutlined />} size="small" onClick={() => this.search()} disabled={!this.state.value}>
                    Wyszukaj
                </Button>
                <Button size="small" style={{ marginLeft: 4 }} icon={<DeleteOutlined />} onClick={() => this.clear()}>
                    Wyczyść
                </Button>
            </CenteredRow>

            <Input autoFocus
                ref={input => this.inputRef = input}
                onChange={e => this.setState({ value: e.target.value })}
                value={this.state.value}
                onPressEnter={() => this.state.value && this.search()}
                size="small"
                placeholder={this.props.column.filter === 'equals' ? "Wpisz całą frazę" : "Wpisz część frazy"}
                style={{ width: 200, marginTop: 8, display: 'block' }}
            />
        </>
    }

    private inputRef: any;

    componentDidMount() {
    }

    componentDidUpdate() {
        var x = window.scrollX, y = window.scrollY;
        this.inputRef.focus();
        window.scrollTo(x, y);
    }

    private clear() {
        this.setState({ value: undefined });
        this.props.gridStore!.clearColumnFilter(this.props.gridName, this.props.column.key!)

        this.props.handleSearch();
    }

    private search() {
        this.props.gridStore!.saveColumnFilter(this.props.gridName, this.props.column.key!, this.state.value);
        this.props.handleSearch();
    }
}