import { SorterResult } from "antd/lib/table/interface";
import { observable, action, isObservableArray } from "mobx";
import { MobXProviderContext } from "mobx-react";
import React from "react";
import { AntPagination } from "./SieveModel";

export class GridStore {

    @observable filters: Map<string, Map<React.Key, any>>;
    @observable paginations: Map<string, AntPagination>;
    @observable sorters: Map<string, any>;
    @observable lastUsedRowsIds: Map<string, string>;
    @observable gridNameForLastUsedRowId: string;
    @observable isReloading: boolean;

    constructor() {
        this.filters = new Map<string, Map<React.Key, any>>();
        this.paginations = new Map<string, AntPagination>();
        this.sorters = new Map<string, any>();
        this.lastUsedRowsIds = new Map<string, string>();
        this.gridNameForLastUsedRowId = "";
        this.isReloading = false;
    }

    @action saveColumnFilter(gridName: string, columnKey: React.Key, value: any) {
        let grid = this.filters.get(gridName);
        this.clearGridPagination(gridName);
        columnKey = columnKey.toString().replace('_', '.');
        if (!grid) {
            let gridFilters = new Map<React.Key, any>();
            gridFilters.set(columnKey, value);
            grid = this.filters.set(gridName, gridFilters);
        }
        else {
            grid.set(columnKey, value);
        }

    }

    getColumnFilter(gridName: string, columnKey: React.Key): any {
        let grid = this.filters.get(gridName);
        if (grid) return grid.get(columnKey);
        return undefined;
    }

    @action clearColumnFilter(gridName: string, columnKey: React.Key) {
        let grid = this.filters.get(gridName);

        if (grid) {
            grid.set(columnKey, undefined);
        }
    }

    getGridFilters(gridName: string): Map<React.Key, any> | undefined {
        //Globalne rzutowanie z ObservableArray na Array
        if (this.filters.get(gridName)?.entries()) {
            var filters = new Map<React.Key, any>(this.filters?.get(gridName)?.entries()!)
            Array.from(filters.entries()).forEach(entry => {
                if (isObservableArray(entry[1]))
                    filters?.set(entry[0], observable(entry[1]).slice());

                if (entry[0].toString().includes(".")) {
                    filters?.set(entry[0].toString().replace('.', '_'), filters.get(entry[0]));
                    filters?.delete(entry[0]);
                }
            })

            return filters;
        }

        return undefined;
    }

    @action resetGrid(gridName: string) {
        this.filters.delete(gridName);
        this.clearGridPagination(gridName);
        this.clearGridSorter(gridName);
    }

    @action saveGridPagination(gridName: string, pagination: AntPagination) {
        this.paginations.set(gridName, pagination);
    }

    getGridPagination(gridName: string): AntPagination {
        let saved = this.paginations.get(gridName);
        if (saved) return saved;

        return new AntPagination();
    }

    @action clearGridPagination(gridName: string): void {
        this.paginations.delete(gridName);
    }

    @action saveGridSorter(gridName: string, antSorter: any) {
        this.sorters.set(gridName, antSorter);
    }

    getGridSorter(gridName: string): SorterResult<any> {
        return this.sorters.get(gridName);
    }

    clearGridSorter(gridName: string) {
        this.sorters.delete(gridName);
    }

    @action saveLastUsedRowId(gridName: string, rowId: string) {
        this.lastUsedRowsIds.set(gridName, rowId);
    }

    getLastUsedRowId(gridName: string) {
        return this.lastUsedRowsIds.get(gridName);
    }

    @action clearLastUsedRowId(gridName: string) {
        this.lastUsedRowsIds.delete(gridName);
    }

    @action clearWithoutLastUsedRowId(gridName: string) {
        let tmpRowId = this.lastUsedRowsIds.get(gridName);
        this.lastUsedRowsIds.clear();
        if(tmpRowId) {
            this.lastUsedRowsIds.set(gridName, tmpRowId);
        }
    }

    @action saveGridNameForLastUsedRowId(gridName: string) {
        this.gridNameForLastUsedRowId = gridName;
    }

    getGridNameForLastUsedRowId() {
        return this.gridNameForLastUsedRowId;
    }

    getIsReloading() {
        return this.isReloading;
    }

    @action saveIsReloading(result: boolean) {
        this.isReloading = result;
    }
}


export function useGridStore(): GridStore {
    const { gridStore } = React.useContext(MobXProviderContext);
    return gridStore;
}
