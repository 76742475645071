import { Button } from 'antd';
import { Authorized } from 'authorization/Authorized';
import { ajaxCatch } from 'helper/api';
import React from 'react';
import { Link } from 'react-router-dom';
import { ExciseEnergyRegisterStateClient } from 'services/GeneratedClient';

interface ICorrectAsNewNavigationButtonProps {
    exciseEnergyRegisterId: number;
}

interface IState {
    hasAnyStateDate: boolean;
}

export default class CorrectAsNewNavigationButton extends React.PureComponent<ICorrectAsNewNavigationButtonProps, IState> {
    constructor(props: ICorrectAsNewNavigationButtonProps) {
        super(props);

        this.state = {
            hasAnyStateDate: false
        }
    }

    public componentDidMount() {
        this.fetchHasInitialState();
    }

    private fetchHasInitialState() {
        ajaxCatch(() =>
            new ExciseEnergyRegisterStateClient().hasAnyStateDate(this.props.exciseEnergyRegisterId),
            (result: boolean) => {
                this.setState({ hasAnyStateDate: result });
            });
    }

    public render() {
        return <>
            {this.state.hasAnyStateDate &&
                <Link to={`/ExciseEnergies/Registers/${this.props.exciseEnergyRegisterId}/Entries/CorrectAsNew`} >
                    <Button type="primary" size={'large'}>Dodaj zdarzenie korygujące</Button>
                </Link>
            }
        </>
    }
}
