import React, { createRef } from 'react';
import { dateTimeRenderer, EwdColumnProps } from 'helper/GridHelper';
import { AuditLogAreaName, AuditLogClient, AuditLogDto, ChangedValue, DictionaryName } from 'services/GeneratedClient';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from 'layout/FadingTooltip';
import { MinusCircleOutlined, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import TableWithSummaries from 'layout/table/paginated/TableWithSummaries';
import EwdCard from 'components/EwdCard';
import AuditLogRegisterDetailsButton from './AuditLogRegisterDetailsButton';
import AuditLogEntityDetailsButton from './AuditLogEntityDetailsButton';
import { GridStore } from 'layout/table/paginated/GridStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class AuditLogList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "auditLogList"
        }
    }

    componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<AuditLogDto>[] = [
        {
            key: "actions",
            title: "Czynności",
            width: 20,
        },
        {
            dataIndex: "dateCreated",
            key: "DateCreated",
            title: "Data",
            render: (text: any, record: AuditLogDto) => dateTimeRenderer(record.dateCreated),
            filter: "dateRange",
            sorter: true,
            width: 150,
        },
        {
            dataIndex: ["user", "login"],
            key: "AuditLogUserLogin",
            title: "Login użytkownika",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: ["user", "name"],
            key: "AuditLogUserName",
            title: "Imię użytkownika",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: ["user", "surname"],
            key: "AuditLogUserSurname",
            title: "Nazwisko użytkownika",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "description",
            key: "Description",
            title: "Opis",
            filter: "contains",
        },
        {
            dataIndex: "registerId",
            key: "RegisterId",
            title: "Id ewidencji",
            sorter: true,
            filter: "equals",
            render: (text: any, record: AuditLogDto) => (
                <>
                    {record.registerId}
                    <AuditLogRegisterDetailsButton
                        recordId={record.id}
                        gridName={this.state.gridName}
                        auditLogAreaName={record.auditLogAreaNameE}
                        registerId={record.registerId}
                    />
                </>),
        },
        {
            dataIndex: "entryId",
            key: "EntryId",
            title: "Id zdarzenia",
            sorter: true,
            filter: "equals",
            render: (text: any, record: AuditLogDto) => (
                <>
                    {record.entryId}
                    {record.entryExists && <AuditLogEntityDetailsButton
                        recordId={record.id}
                        gridName={this.state.gridName}
                        auditLogAreaName={record.auditLogAreaNameE}
                        entityId={record.entryId}
                    />}
                </>),
        },
        {
            dataIndex: "auditLogAreaName",
            key: "AuditLogAreaName",
            title: "Obszar",
            sorter: true,
            filter: "dictionary",
            filterDictionary: DictionaryName.AuditLogAreaName,
            render: (text: string, record: AuditLogDto) => {
                return this.props.dictionaryStore && this.props.dictionaryStore.getDisplay(DictionaryName.AuditLogAreaName, text)
            }
        },
    ];

    expandedRowRender = (data: ChangedValue[] | undefined) => {
        const columns = [
            {
                dataIndex: "name",
                key: "Name",
                title: "Nazwa zmienionego pola",
            },
            {
                dataIndex: "oldValue",
                key: "OldValue",
                title: "Poprzednia wartość",
            },
            {
                dataIndex: "newValue",
                key: "NewValue",
                title: "Nowa wartość",
            },
        ]

        return (
            <EwdCard>
                <TableWithSummaries gridName={"auditLogAdditionalDataList"} columns={columns} dataSource={data} pagination={false} />
            </EwdCard >
        )
    }

    private paginatedTable = createRef<PaginatedTable<AuditLogDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`LISTA CZYNNOŚCI`} />
                <PaginatedTable<AuditLogDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: AuditLogDto) => r.id!.toString()}
                    expandable={{
                        expandIconColumnIndex: 1,
                        expandedRowRender: record => this.expandedRowRender(record.additionalDataList),
                        rowExpandable: record => record.additionalDataList != null && record.additionalDataList.length > 0,
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <MinusCircleOutlined style={{ fontSize: '24px', color: "#009de1" }} onClick={e => onExpand(record, e)} />
                            ) : (
                                record.additionalDataList && <Tooltip placement="left" title="Dodatkowe informacje" >
                                    <PlusCircleFilled style={{ fontSize: '24px', color: "#009de1" }} onClick={e => onExpand(record, e)} />
                                </Tooltip>
                            )
                    }}
                    getPagedResult={(sieve: SieveModel) => {
                        return new AuditLogClient().getAuditLogs(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </>
        )
    }
};