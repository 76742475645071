import { Button, Col, Modal, Select } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { PageHeader } from "layout/PageHeader";
import PrimaryCard from "../layout/PrimaryCard";
import { DictionaryClient, EwdDictionaryValueDto } from "services/GeneratedClient";
import { FCenteredRow, FFieldLabel, FInput } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import EwdDictionaryValueValidation from "./EwdDictionaryValueValidation";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
    dictionaryId: number;
    dictionaryValueId: number;
}

interface IState {
    initialValues: EwdDictionaryValueDto;
    name: string | null;
    isLoading: boolean;
}

export default class EwdDictionaryValueEdit extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/Dictionaries/${this.props.dictionaryId}`;
    private validation: EwdDictionaryValueValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            initialValues: new EwdDictionaryValueDto(),
            name: "",
            isLoading: false,
        };

        this.validation = new EwdDictionaryValueValidation();
    }

    public componentDidMount() {
        ajaxCatch(() =>
            new DictionaryClient().getEwdDictionaryValue(this.props.dictionaryValueId),
            (result: EwdDictionaryValueDto | null) => {
                this.setState({
                    initialValues: result!
                })
            });

        ajaxCatch(() =>
            new DictionaryClient().getEwdDictionaryName(this.props.dictionaryId),
            (name: string | null) => {
                this.setState({ name: name })
            });
    }

    public send(values: EwdDictionaryValueDto, actions: FormikHelpers<EwdDictionaryValueDto>) {
        this.setState({ isLoading: true })
        values.dictionaryId = this.props.dictionaryId;

        Modal.confirm({
            centered: true,
            maskClosable: true,
            title: 'Zapisanie nowej wartości spowoduje nadpisanie wartości we wszystkich utworzonych już zdarzeniach akcyzowych, mających przypisaną tę pozycję słownika!',
            okText: 'Potwierdź zapis',
            cancelText: 'Anuluj',
            onOk: () => {
                ajaxByUser("Zaktualizowano wartość słownika", () =>
                    new DictionaryClient().update(values)
                        .then((resp: any) => {
                            actions.setSubmitting(false);
                            this.props.history.push(this.goBackUrl);
                        }).finally(() => this.setState({ isLoading: false })));
            },
            onCancel: () => this.setState({ isLoading: false })
        });
    }

    public render() {
        return (
            <>
                <PageHeader title={`EDYCJA WARTOŚCI SŁOWNIKA - ${this.state.name}`} />
                <Formik<EwdDictionaryValueDto> initialValues={this.state.initialValues} enableReinitialize
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<EwdDictionaryValueDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Kod w słowniku" sm={24} />
                                    <Field component={FInput} name="code" disabled={true} />
                                </Col>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Wartość wyświetlana" sm={24} />
                                    <Field component={FInput} name="displayValue" />
                                </Col>
                            </FCenteredRow>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                    props.submitForm();
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}