import React from "react";
import { DictionaryDto, DictionaryClient } from "../services/GeneratedClient";
import { ajaxCatch } from "../helper/api";
import { inject, observer } from "mobx-react";
import { DictionaryStore } from "./dictionaryStore";
import { AccountDetailsStore } from "account/AccountDetailsStore";

interface IProps {
    dictionaryStore?: DictionaryStore;
    accountDetailsStore?: AccountDetailsStore;
}

interface IState {
    dictionariesLoaded: boolean;
}

@inject("dictionaryStore")
@inject("accountDetailsStore")
@observer
export default class DictionaryProvider extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            dictionariesLoaded: false
        };
    }
    public componentDidMount() {
        this.fetchDictionaries()
    }

    private fetchDictionaries() {
        ajaxCatch(() =>
            new DictionaryClient().getAll(),
            (dictionaries: DictionaryDto[] | null) => {
                this.props.dictionaryStore!.setDictionaries(dictionaries!);
                this.setState({
                    dictionariesLoaded: true
                })
            });
    }

    public render() {
        if (this.state.dictionariesLoaded && this.props.children) return this.props.children;
        return null;
    }
}