import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { DictionaryClient, EwdDictionaryValueDto } from 'services/GeneratedClient';
import { Link } from 'react-router-dom';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import FadingTooltip from "layout/FadingTooltip";
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';
import { Button, Popconfirm } from 'antd';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import { GridStore } from 'layout/table/paginated/GridStore';
import { inject } from 'mobx-react';

interface IProps {
    gridStore?: GridStore;
    dictionaryId: number;
}

interface IState {
    gridName: string;
    name: string | null;
}

@inject("gridStore")
export default class EwdDictionaryValueList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "ewdDictionaryValueList",
            name: "",
        }
    }

    componentDidMount() {
        ajaxCatch(() =>
            new DictionaryClient().getEwdDictionaryName(this.props.dictionaryId),
            (name: string | null) => {
                this.setState({ name: name })
            });
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<EwdDictionaryValueDto>[] = [
        {
            key: "actions",
            render: (text: string, record: EwdDictionaryValueDto) => (
                <>
                    {!record.isDisabled && <FadingTooltip title="Edytuj">
                        <Link to={`/Admin/Dictionaries/${this.props.dictionaryId}/DictionaryValues/Edit/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<EditOutlined />} />
                        </Link>
                    </FadingTooltip>}
                    <FadingTooltip title={!record.isDisabled ? "Wyłącz" : "Włącz"}>
                        <Popconfirm placement="bottomRight" title={<text>Czy na pewno chcesz {!record.isDisabled ? "wyłączyć" : "włączyć"} wartość {record.displayValue}?</text>} okText="Tak" cancelText="Nie"
                            onConfirm={() => {
                                ajaxByUser(!record.isDisabled ? "Wyłączono wartość słownika" : "Włączono wartość słownika", () =>
                                    new DictionaryClient().switchIsDisabled(record.id!).then(() => {
                                        this.refresh();
                                    }));
                            }}>
                            <TableButton icon={!record.isDisabled ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                        </Popconfirm>
                    </FadingTooltip>
                </>
            ),
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "displayValue",
            key: "DisplayValue",
            title: "Nazwa wyświetlana",
            filter: "contains",
        },
        {
            dataIndex: "code",
            key: "Code",
            title: "Kod",
            filter: "contains",
        },
        {
            dataIndex: "isVisible",
            key: "IsVisible",
            title: "Czy włączony",
            render: (text: any, record: EwdDictionaryValueDto) => !record.isDisabled ? "TAK" : "NIE",
        },
    ];

    private refresh() {
        this.paginatedTable.current?.refresh();
    }

    private paginatedTable = createRef<PaginatedTable<EwdDictionaryValueDto>>();

    public render() {
        return (
            <>
                <PageHeaderReturn title={`SŁOWNIK - ${this.state.name}`} goBack={`/Admin/Dictionaries`} />
                <PaginatedTable<EwdDictionaryValueDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: EwdDictionaryValueDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new DictionaryClient().getEwdDictionaryValues(this.props.dictionaryId, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
                <BottomActionButtons>
                    <Link to={`/Admin/Dictionaries/${this.props.dictionaryId}/DictionaryValues/Add`}>
                        <Button>Dodaj nową wartość</Button>
                    </Link>
                </BottomActionButtons>
            </>
        )
    }
};