import React from 'react';
import { Button, Modal, ModalProps, Table } from 'antd';
import { ExciseRegisterContainerStateDetailsDto } from 'services/GeneratedClient';


interface IProps {
    headerName: string;
    containersState: ExciseRegisterContainerStateDetailsDto[] | undefined;
    hasRegisterControlOfNegativeStates: boolean;
}

interface IState {
    visible: boolean;
}

export default class ExciseRegisterContainersDayStateModal extends React.PureComponent<IProps & ModalProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            visible: false,
        }
    }

    public show() {
        this.setState({
            visible: true
        })
    }

    public hide() {
        this.setState({
            visible: false
        })
    }


    public render() {
        return (
            <Modal {...this.props}
                title={this.props.headerName}
                visible={this.state.visible}
                onCancel={() => this.hide()}
                footer={[
                    <Button key="back" onClick={() => this.hide()}>
                        Zamknij
                    </Button>
                ]}
                width={"600px"}>
                <Table

                    dataSource={this.props.containersState!}
                    rowKey={(row) => row.containerName!}
                    size="small"
                    pagination={false}
                    style={{ width: 600 }}
                    columns={[

                        {
                            dataIndex: "containerName",
                            key: "ContainerName",
                            title: "Zbiornik",
                        },
                        {
                            dataIndex: "incomeInExciseUnit",
                            key: "IncomeInExciseUnit",
                            title: "Przychód w jednostce rozliczenia akcyzy",
                        },
                        {
                            dataIndex: "expenditureInExciseUnit",
                            key: "ExpenditureInExciseUnit",
                            title: "Rozchód w jednostce rozliczenia akcyzy",
                        },
                        {
                            dataIndex: "incomeInInventoryUnit",
                            key: "IncomeInInventoryUnit",
                            title: "Przychód w jednostce technicznej",
                        },
                        {
                            dataIndex: "expenditureInInventoryUnit",
                            key: "ExpenditureInInventoryUnit",
                            title: "Rozchód w jednostce technicznej",
                        },
                        {
                            dataIndex: "storedAmountInExciseUnit",
                            key: "StoredAmountInExciseUnit",
                            title: `${this.props.hasRegisterControlOfNegativeStates
                                ? "Ilość magazynowana"
                                : "Rozchód narastająco"} w jednostce rozliczenia akcyzy`,
                            render: (value: number) => this.props.hasRegisterControlOfNegativeStates
                                ? value    
                                : Math.abs(value)
                        },
                    ]}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Suma</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{(this.props.containersState?.map(x => x.incomeInExciseUnit).reduce((a, c) => (a! + c! ?? 0)).toFixed(3))}</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>{(this.props.containersState?.map(x => x.expenditureInExciseUnit).reduce((a, c) => (a! + c! ?? 0)).toFixed(3))}</Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>{(this.props.containersState?.map(x => x.incomeInInventoryUnit).reduce((a, c) => (a! + c! ?? 0)).toFixed(3))}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>{(this.props.containersState?.map(x => x.expenditureInInventoryUnit).reduce((a, c) => (a! + c! ?? 0)).toFixed(3))}</Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>{(this.props.containersState?.map(x => this.props.hasRegisterControlOfNegativeStates
                                    ? x.storedAmountInExciseUnit
                                    : Math.abs(x.storedAmountInExciseUnit)
                                ).reduce((a, c) => (a! + c! ?? 0)).toFixed(3))}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </Modal>
        )
    }
};