import React from 'react';
import AntdButton, { ButtonProps } from 'antd/lib/button';
import { useGridStore } from '../paginated/GridStore';

interface IProps extends ButtonProps {
    gridName?: string,
    recordId?: number  
}

const Button = (allProps: IProps) => {
    const { gridName, recordId, ...props } = allProps
    let gridStore = useGridStore();
    const gridNameBeginning = "corrective_";

    return <AntdButton 
        onClick={
            () => {
                if (gridName?.startsWith(gridNameBeginning)) {
                    gridStore.clearLastUsedRowId(gridName.replace(gridNameBeginning, ""));
                }
                else if (gridName?.startsWith("completed")) {
                    gridStore.clearLastUsedRowId(`${gridNameBeginning + gridName}`)
                }
                gridName && recordId && gridStore.saveLastUsedRowId(gridName, recordId.toString());
            }
        }
        shape={props.shape ? props.shape : "circle"}
        size={props.size ? props.size : "small"}
        type={props.type ? props.type : "primary"}
        {...props} 
    />
}

export default Button;
