import React from 'react';
import Tabs, { TabPane } from 'layout/tabs/Tabs'
import CompletedExciseEntryList from './CompletedExciseEntryList';
import { CenteredRow } from 'layout/CenteredRow';
import { MonthPicker } from 'components/DatePicker';
import moment, { Moment } from 'moment';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { Authorized } from 'authorization/Authorized';
import { EwdPlaceLevelPermission } from 'services/GeneratedClient';
import { Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import CorrectAsNewNavigationButton from './buttons/CorrectAsNewNavigationButton';
import ExciseRegisterBasicDetails from 'exciseRegisters/ExciseRegisterBasicDetails';

interface IProps {
    exciseRegisterId: number;
    placeContext: string;
}

interface IState {
    dateFrom: Moment;
    dateTo: Moment;
}

type OptionValue = 'all' | 'income' | 'expenditure' | 'transshipment';

interface IOption {
    label: string;
    isIncome?: boolean;
    isExpenditure?: boolean;
    isTransshipment?: boolean;
    value: OptionValue;
}

export default class CompletedExciseEntry extends React.PureComponent<IProps, IState>{
    private options: (IOption)[] = [
        { label: 'Przychód', value: 'income', isIncome: true },
        { label: 'Rozchód', value: 'expenditure', isExpenditure: true },
        { label: 'Przeładunek', value: 'transshipment', isTransshipment: true },
        { label: 'Wszystko', value: 'all', isIncome: true, isExpenditure: true, isTransshipment: true }
    ]

    onChange = (value: Moment | null, dateString: string) => {
        if (value) {
            this.setState({
                dateFrom: moment(value).startOf("month").asUtc(),
                dateTo: moment(value).endOf("month").asUtc()
            })
        }
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            dateFrom: moment().startOf("month").asUtc(),
            dateTo: moment().endOf("month").asUtc()
        }
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`EWIDENCJA AKCYZOWA`} goBack={"/ExciseRegisters"} />
                <ExciseRegisterBasicDetails exciseRegisterId={this.props.exciseRegisterId} />
                <Divider />
                <CenteredRow><MonthPicker onChange={this.onChange} value={this.state.dateFrom} /></CenteredRow>
                <Tabs type="card" defaultActiveKey={"all"}>
                    {this.options.map(option => {
                        return <TabPane tab={option.label} key={option.value} >
                            <CompletedExciseEntryList exciseRegisterId={this.props.exciseRegisterId}
                                isIncome={option.isIncome || false}
                                isExpenditure={option.isExpenditure || false}
                                isTransshipment={option.isTransshipment || false}
                                dateFrom={this.state.dateFrom}
                                dateTo={this.state.dateTo} />
                        </TabPane>
                    })}
                </Tabs>
                <BottomActionButtons>
                    <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting} placeContext={this.props.placeContext}>
                        <CorrectAsNewNavigationButton exciseRegisterId={this.props.exciseRegisterId} />
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};