import { ClientBase } from "./ClientBase";

export class PlatformClient extends ClientBase {
    private authorityUrl : string;

    constructor(authorityUrl : string) {
        super();
        this.authorityUrl = authorityUrl;
    }

    public changeCurrentCompany(id: number) {
        let options = {method: "POST", headers: {}};
        this.transformOptions(options).then((options) => {
            options = {...options, credentials: "include", mode: 'cors'}
            fetch(`${this.authorityUrl}/Company/ChangeCompany?choosenCompanyId=${id}`, options).catch(() => {});
        });
    }
}