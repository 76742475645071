import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { ajaxByUser } from 'helper/api';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import React from 'react';
import "./ConfirmActionTableButton.less";

interface ITableButtonProps<T> {
    tooltip: string;
    question: string;
    confirmMessage: string;
    icon: React.ReactNode;
    request: () => Promise<T>;
    onSuccess?: (result? :T) => void;
    onFailure?: () => void;
}

interface IState {
    loading: boolean;
}

export default class ConfirmActionTableButton<T> extends React.PureComponent<ITableButtonProps<T>, IState> {
    constructor(props: ITableButtonProps<T>) {
        super(props);

        this.state = {
            loading: false
        }
    }

    delete = () => {
        this.setState({ loading: true });
        ajaxByUser(this.props.confirmMessage, () => this.props.request()
            .then((resp: T) => {
                this.props.onSuccess && this.props.onSuccess(resp);
            })
            .catch((ex?: any) => {
                this.props.onFailure && this.props.onFailure();
                throw ex;
            })
            .finally(() => {
                this.setState({ loading: false });
            })

        );        
    }

    public render() {
        return <>
            {!this.state.loading
            ? <Popconfirm placement="right"
                          overlayClassName="question-delete"
                          title={this.props.question}
                          icon={<QuestionCircleOutlined />}
                          onConfirm={this.delete}
                          okText="Tak"
                          cancelText="Nie"
            >
                <FadingTooltip title={this.props.tooltip} placement="top">
                    <TableButton icon={this.props.icon} />
                </FadingTooltip>
            </Popconfirm>
            : <FadingTooltip title="Proszę czekać" placement="top">
                <TableButton icon={<LoadingOutlined />} />
            </FadingTooltip>}
        </>
        
    }
}
