import React, { createRef } from 'react';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import { EwdPlaceLevelPermission, ExciseEnergyRegisterClient, ExciseEnergyRegisterDto, ExciseEnergyRegisterStateClient, ExciseEnergyRegisterStateDateCloseDayResultDto, ExciseEnergyRegisterStateDateCloseDaysCmd, ExciseEnergyRegisterStateDateCloseDaysResponseDto, ExciseEnergyRegisterStateDateDto } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { ajaxCatch } from 'helper/api';
import ExciseEnergyRegisterInformation from './ExciseEnergyRegisterInformation';
import { RouteComponentProps } from 'react-router';
import { Button, Col, Divider, notification, Popconfirm, Row } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { DatePicker } from 'components/DatePicker';
import ExciseEnergyRegisterStateDateInformation from './ExciseEnergyRegisterStateDateInformation';
import moment, { Moment } from 'moment';
import { dateToString } from 'helper/GridHelper';
import ExciseEnergyEntryDailyList from 'exciseEnergies/entries/ExciseEnergyEntryDailyList';
import { showErrorListModal } from 'layout/Modals';
import { Card } from 'components/StateDetailsComponents';
import { Authorized } from 'authorization/Authorized';
import { GridStore } from 'layout/table/paginated/GridStore';
import { AlertsStore } from 'alerts/AlertsStore';
import { IPlaceContextProps } from 'authorization/interfaces/IPlaceContextProps';
import { RegisterDetailsStore } from 'registerDetails/RegisterDetailsStore';

interface IProps extends RouteComponentProps<any>, IPlaceContextProps {
    exciseEnergyRegisterId: number;
    dictionaryStore?: DictionaryStore;
    alertsStore?: AlertsStore;
    gridStore?: GridStore;
    registerDetailsStore?: RegisterDetailsStore;
}

interface IState {
    exciseEnergyRegister: ExciseEnergyRegisterDto | null;
    exciseEnergyRegisterStateDate: ExciseEnergyRegisterStateDateDto | null;
    selectedDate: Moment;
    isPossibleToCloseDay: boolean;
    closingDays: boolean;
}

@inject("dictionaryStore")
@inject("alertsStore")
@inject("gridStore")
@inject("registerDetailsStore")
export default class ExciseEnergyRegisterDetails extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            exciseEnergyRegister: null,
            exciseEnergyRegisterStateDate: null,
            selectedDate: moment().asUtc(),
            isPossibleToCloseDay: false,
            closingDays: false,
        }
    }

    componentDidMount() {
        this.getExciseEnergyRegister();
        this.getExciseEnergyRegisterStateDate();
        this.props.registerDetailsStore?.checkSelectedRegister(`ExciseEnergyRegister${this.props.exciseEnergyRegisterId}`);
        this.setState({ selectedDate: this.getDate() }, () => this.getExciseEnergyRegisterStateDateCloseDay());
    }

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if (prevState.selectedDate !== this.state.selectedDate) {
            this.getExciseEnergyRegisterStateDateCloseDay();
            this.props.registerDetailsStore?.saveSelectedDate(this.state.selectedDate);
        }
        if (prevProps.exciseEnergyRegisterId !== this.props.exciseEnergyRegisterId) {
            this.getExciseEnergyRegister();
            this.getExciseEnergyRegisterStateDate();
            this.setState({ selectedDate: this.getDate() }, () => this.getExciseEnergyRegisterStateDateCloseDay());
        }
    }

    getDate() {
        const date = (this.props.location.state?.firstUnclosedDate && this.props.gridStore?.getIsReloading())
            ? moment(this.props.location.state.firstUnclosedDate).asDate()
            : (this.props.registerDetailsStore?.getSelectedDate() ?? this.state.selectedDate);
        this.props.gridStore?.saveIsReloading(false);
        return date;
    }

    getExciseEnergyRegister = () => {
        ajaxCatch(() =>
            new ExciseEnergyRegisterClient().get(this.props.exciseEnergyRegisterId),
            (result: ExciseEnergyRegisterDto | null) => {
                this.setState({
                    exciseEnergyRegister: result,
                    closingDays: result?.closingDaysInProgress ?? false,
                })
            });
    }

    getExciseEnergyRegisterStateDate = () => {
        ajaxCatch(() =>
            new ExciseEnergyRegisterStateClient().getExciseEnergyRegisterStateDate(this.props.exciseEnergyRegisterId),
            (result: ExciseEnergyRegisterStateDateDto | null) => {
                this.setState({
                    exciseEnergyRegisterStateDate: result
                })
            }
        );
    }

    getExciseEnergyRegisterStateDateCloseDay = () => {
        ajaxCatch(() =>
            new ExciseEnergyRegisterStateClient().getCloseDayStateDate(this.props.exciseEnergyRegisterId, this.state.selectedDate.asUtc().asDate()),
            (result: ExciseEnergyRegisterStateDateCloseDayResultDto | null) => {
                if (result) {
                    this.setState({
                        isPossibleToCloseDay: result.isPossibleToCloseDay
                    })
                }
            }
        );
    }

    closeDays = () => {
        this.setState({ closingDays: true });

        let cmd = new ExciseEnergyRegisterStateDateCloseDaysCmd({
            exciseEnergyRegisterId: this.props.exciseEnergyRegisterId,
            toDate: this.state.selectedDate.asDate().asUtc(),
            editorId: undefined,
        });

        ajaxCatch(() => new ExciseEnergyRegisterStateClient().closeDays(cmd),
            (result: ExciseEnergyRegisterStateDateCloseDaysResponseDto | null) => {
                if (result && result.errors && result.errors.length > 0) {
                    showErrorListModal(result.message!, result.errors);
                } else {
                    notification.success({
                        placement: "bottomRight",
                        message: result!.message!,
                    });
                    this.getExciseEnergyRegisterStateDate();
                    this.getExciseEnergyRegisterStateDateCloseDay();
                    this.props.alertsStore?.fetchAndFilterCloseDayEnergyReminder();
                    this.refresh();
                }
            }).finally(() => { this.setState({ closingDays: false }) });
    };

    dateOnChange = (value: Moment | null, dateString: string) => {
        if (value) {
            this.setState({
                selectedDate: moment(value)
            })
        }
    }

    setDayToPrevious = () => {
        this.setState({
            selectedDate: moment(this.state.selectedDate.add(-1, 'days'))
        })
    }

    setDayToNext = () => {
        this.setState({
            selectedDate: moment(this.state.selectedDate.add(1, 'days'))
        })
    }

    isDisabledDate = (date: Moment) => {
        return this.state.exciseEnergyRegisterStateDate ? this.state.exciseEnergyRegisterStateDate?.firstClosedDate! > date : false;
    }

    isPreviousDayDisabled = () => {
        return this.state.closingDays || dateToString(this.state.selectedDate) === dateToString(this.state.exciseEnergyRegisterStateDate?.firstClosedDate)
    }

    isNextDayDisabled = () => {
        return this.state.closingDays;
    }

    changeDateHandler = (newDate: Moment) => {
        this.setState({ selectedDate: newDate })
    }

    refresh() {
        this.exciseEnergyEntryDailyList.current?.refreshGrid();
    }

    getNumberOfDaysToClose = (): number => {
        if (!this.state.exciseEnergyRegisterStateDate?.lastClosedDate) {
            return 0;
        }
        let dateFrom = this.state.exciseEnergyRegisterStateDate.lastClosedDate.asUtc().asDate();
        let dateTo = this.state.selectedDate.asUtc().asDate();
        let numberOfDays = dateTo.diff(dateFrom, "days");
        return numberOfDays;
    }

    getCloseDaysPopconfirmMessage = () => {
        if (!this.state.exciseEnergyRegisterStateDate?.lastClosedDate) {
            return <></>;
        }
        let dateFrom = dateToString(this.state.exciseEnergyRegisterStateDate.lastClosedDate.clone().add(1, "day"));
        let dateTo = dateToString(this.state.selectedDate);
        return <>
            <span>Zamykany dzień nie jest pierwszym otwartym.</span> <br />
            <span>System sprawdzi, czy w danym okresie znajdują się zdarzenia redagowane.</span> <br />
            <span>Czy na pewno chcesz zamknąć dni od {dateFrom} do {dateTo} włącznie?</span>
        </>;
    }

    exciseEnergyEntryDailyList = createRef<ExciseEnergyEntryDailyList>();

    getStateSummaryText = () => {
        return this.state.closingDays ? "Zamykanie dni w trakcie" : "Brak zdarzeń dla danej ewidencji!"
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`SZCZEGÓŁY EWIDENCJI ENERGII ELEKTRYCZNEJ`} goBack={this.props.history.goBack} />
                {this.state.exciseEnergyRegister && this.props.dictionaryStore &&
                    <ExciseEnergyRegisterInformation
                        exciseEnergyRegister={this.state.exciseEnergyRegister}
                        dictionaryStore={this.props.dictionaryStore}
                    />
                }
                {this.state.exciseEnergyRegisterStateDate ?
                    <ExciseEnergyRegisterStateDateInformation
                        exciseEnergyRegisterStateDate={this.state.exciseEnergyRegisterStateDate}
                        exciseEnergyEntryDate={this.state.selectedDate}
                        changeDateHandler={this.changeDateHandler}
                    />
                    : <Row>
                        <Col span={24}>
                            <Card title="Podsumowanie ewidencji">
                                <p>{this.getStateSummaryText()}</p>
                            </Card>
                        </Col>
                    </Row>}
                <Divider />
                <CenteredRow>
                    <Button onClick={this.setDayToPrevious} disabled={this.isPreviousDayDisabled()}>{'<'}</Button>
                    <DatePicker
                        onChange={this.dateOnChange}
                        value={moment(this.state.selectedDate)}
                        disabledDate={this.isDisabledDate}
                        format={"YYYY-MM-DD"}
                        disabled={this.state.closingDays}
                    />
                    <Button onClick={this.setDayToNext} disabled={this.isNextDayDisabled()}>{'>'}</Button>
                </CenteredRow>
                <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay}
                    placeContext={this.props.placeContext}>
                    {
                        this.state.exciseEnergyRegister &&
                        <ExciseEnergyEntryDailyList
                            exciseEnergyRegisterId={this.props.exciseEnergyRegisterId}
                            date={this.state.selectedDate}
                            ref={this.exciseEnergyEntryDailyList}
                        />
                    }
                </Authorized>
                <BottomActionButtons>
                    <Authorized requiresSubscription placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterDayClosing} placeContext={this.props.placeContext}>
                        <Popconfirm placement="top"
                            disabled={this.getNumberOfDaysToClose() < 2 || this.state.closingDays}
                            title={this.getCloseDaysPopconfirmMessage()} okText="Tak" cancelText="Nie"
                            onConfirm={this.closeDays}>
                            <Button type="primary" size={'large'}
                                disabled={!this.state.isPossibleToCloseDay || this.state.closingDays}
                                loading={this.state.closingDays} onClick={() => { this.getNumberOfDaysToClose() < 2 && this.closeDays() }}>
                                {this.getNumberOfDaysToClose() > 1 ? "Zamknij dni do wskazanej daty" : "Zamknij dzień"}
                            </Button>
                        </Popconfirm>
                    </Authorized>
                </BottomActionButtons>
            </>
        )
    }
};
