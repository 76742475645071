import React from "react";
import { MobXProviderContext } from "mobx-react";
import { observable, action } from "mobx";
import { EwdRegisterName, ExciseRegisterClient, RegulationSchemaDto } from "../services/GeneratedClient";

type DisplayProvider = 'code' | 'description' | 'codeAndDescription'

export class RegulationStore {
    @observable regulations: RegulationSchemaDto[] | undefined = undefined;

    constructor() {
    }

    @action setRegulations(regulations: RegulationSchemaDto[]) {
        this.regulations = regulations;
    }

    public getByParagraph(paragraph: string, ewdRegisterName: EwdRegisterName): RegulationSchemaDto {
        const values = this.regulations!.filter(a => a.paragraph === paragraph && a.ewdRegisterName === ewdRegisterName);

        if (values.length !== 1) {
            throw `Nie odnaleziono paragrafu ${paragraph}`;
        }

        return values[0];
    }

    public getDisplay(paragraph: string, ewdRegisterName: EwdRegisterName, type: DisplayProvider = 'codeAndDescription'): string {
        const dictValue = this.getByParagraph(paragraph, ewdRegisterName);
        let display = ""
        if (dictValue) {
            switch (type) {
                case "code":
                    display = dictValue.paragraph || "";
                    break;
                case "description":
                    display = dictValue.display || "";
                case "codeAndDescription":
                    if (!dictValue.paragraph || !dictValue.display) {
                        display = `${dictValue.paragraph}${dictValue.display}`;
                    }
                    else {
                        display = `${dictValue.paragraph} - ${dictValue.display}`;
                    }
            }
        }
        return display;
    }

    public fetchExciseRegisterParagraphConfiguration(exciseRegisterId: number) {
        return new ExciseRegisterClient().getExciseRegisterRegulationConfiguration(exciseRegisterId);
    }

    public fetchExciseRegisterParagraphConfigurationForEntry(exciseRegisterId: number) {
        return new ExciseRegisterClient().getExciseRegisterRegulationConfigurationForEntry(exciseRegisterId);
    }

    public fetchExciseRegisterParagraphConfigurationForEntryCorrection(exciseRegisterId: number) {
        return new ExciseRegisterClient().getExciseRegisterRegulationConfigurationForEntryCorrection(exciseRegisterId);
    }
}

export function useRegulationStore(): RegulationStore {
    const { regulationStore } = React.useContext(MobXProviderContext);
    return regulationStore;
}