import { Button, Col, Collapse, ColProps, notification, Popconfirm, Select } from "antd";
import { inject, observer } from "mobx-react";
import { CalculatorOutlined, CloseOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import PrimaryCard from "../layout/PrimaryCard";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { RegisterStore } from "register/RegisterStore";
import ExciseEntryFormValidation from "./ExciseEntryFormValidation";
import { DictionaryName, ExciseEntryClient, ExciseEntryFormDto, CalculateExciseAmountCmd, ProductModel, CalculateExciseAmountResponse, DictionaryClient, EwdDictionaryDto, EwdDictionaryName, CreateExciseEntryCmd, ConfirmExciseEntryCmd, SaveExciseEntryDraftCmd, CorrectExciseEntryCmd, CalculatorResult, EwdRegisterName, EwdPlaceLevelPermission, ExciseRegisterDto, ExciseRegisterClient, CorrectionAsNewInitialDataResult, CorrectAsNewExciseEntryCmd } from "services/GeneratedClient";
import { FCenteredRow, FFieldLabel as FormikLabel, FSelect, FDictionarySelect, FPanel, FRow, IFFieldLabelProps, FInput, FInputNumber, FDatePicker, FDictionarySelectWithTooltip } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import { RegulationStore } from "regulations/RegulationStore";
import * as Yup from 'yup';
import { showErrorListModal, showFormIsInvalidModal, showWarning } from "layout/Modals";
import { PageHeader } from "layout/PageHeader";
import { Authorized } from "authorization/Authorized";
import { filterExciseProductCodeDictionary, filterRegulationsWithDefinedDirection } from "dictionary/DictionaryUtils";
import { SelectValue } from "antd/lib/select";
import { goodFormFieldNames, directions, defaultContainers } from "helper/FieldsDictionary";
import { IPlaceContextProps } from "authorization/interfaces/IPlaceContextProps";
import { Moment } from "moment";
import { CenteredSpin } from '../layout/CenteredSpin';
import ExciseRegisterBasicDetails from "exciseRegisters/ExciseRegisterBasicDetails";
import { GridStore } from "layout/table/paginated/GridStore";
import { FormChangeStore } from "formChange/FormChangeStore";
import { LinkWithConfirmation } from "formChange/LinkWithConfirmation";
import { isUnitConverterRequired } from "./ExciseEntryUtils";

const Option = Select.Option;
let YesOrNo = [
    {
        value: "true",
        label: "Tak"
    },
    {
        value: "false",
        label: "Nie"
    }
];

interface IProps extends RouteComponentProps<any>, IPlaceContextProps {
    dictionaryStore?: DictionaryStore;
    registerStore?: RegisterStore;
    regulationStore?: RegulationStore;
    gridStore?: GridStore;
    formChangeStore?: FormChangeStore;
    exciseRegisterId: number;
    exciseEntryId?: number;
    isCorrecting?: boolean;
    isCorrectingAsNew?: boolean;
    isExpired?: boolean;
    placeId: number;
}

interface IState {
    initialValues: ExciseEntryFormDto;
    paragraph?: string;
    exciseRegisterCnCode?: string;
    exciseRegisterCloseDate?: Moment;
    schema: Yup.ObjectSchema;
    ewdDictionaries: EwdDictionaryDto[] | null;
    exciseAmount: number | undefined;
    isWaitingForResult: boolean;
    isEditing: boolean;
    isCorrecting: boolean;
    isCorrectingAsNew: boolean;
    assigningAsCorrection: boolean;
    isLoading: boolean;
    direction?: string;
    lastClosedDay?: Moment;
    initialStateDay?: Moment;
    gridName?: string;
    allowedParagraphs: string[];
}

@inject("dictionaryStore")
@inject("registerStore")
@inject("regulationStore")
@inject("gridStore")
@inject("formChangeStore")
@observer
export default class ExciseEntryForm extends React.PureComponent<IProps, IState> {
    private goBackUrl: string;
    private validation: ExciseEntryFormValidation;

    constructor(props: any) {
        super(props);

        this.validation = new ExciseEntryFormValidation();

        this.goBackUrl = props.isCorrecting || props.isCorrectingAsNew ? `/ExciseRegisters/${this.props.exciseRegisterId}/Entries/Completed` : `/ExciseRegisters/${this.props.exciseRegisterId}/Entries/Current`;

        this.state = {
            initialValues: new ExciseEntryFormDto(),
            schema: this.validation.schema,
            ewdDictionaries: null,
            exciseAmount: undefined,
            isWaitingForResult: false,
            isEditing: this.props.exciseEntryId ? true : false,
            isCorrecting: this.props.isCorrecting ?? false,
            isCorrectingAsNew: this.props.isCorrectingAsNew ?? false,
            assigningAsCorrection: this.props.isExpired ?? false,
            isLoading: false,
            gridName: this.props.gridStore?.getGridNameForLastUsedRowId(),
            allowedParagraphs: []
        };
    }

    public async componentDidMount() {
        this.setState({ isLoading: true });

        await this.setAllowedParagraphs();

        if (this.state.isEditing)
            await this.fetchExciseEntry();
        if (this.state.isCorrectingAsNew)
            await this.fetchCorrectionAsNewInitialData();

        await this.fetchEwdDictionaries();
        await this.fetchExciseRegister();

        this.setDefaultParagraph();

        this.setState({ isLoading: false });
    }

    private setDefaultParagraph() {
        if (this.state.allowedParagraphs.length != 1 &&
            this.state.allowedParagraphs.length != 2) {
            return;
        }

        let initialValues = this.state.initialValues
        let paragraph = this.state.paragraph;
        let direction = this.state.direction;

        if (this.state.allowedParagraphs.length == 1) {
            paragraph = this.state.allowedParagraphs[0];
            direction = this.props.regulationStore!.regulations!.filter(x => x.paragraph === paragraph)[0]?.direction;
        }
        else if (this.state.allowedParagraphs.length == 2 && direction) {
            const configuredRegulations = this.props.regulationStore!.regulations!.filter(x => x.paragraph && this.state.allowedParagraphs.includes(x.paragraph));

            if (configuredRegulations.length != 2 ||
                !configuredRegulations.some(x => x.direction == directions.incoming) ||
                !configuredRegulations.some(x => x.direction == directions.outgoing)) {
                return;
            }

            paragraph = configuredRegulations.find(x => x.direction == direction)?.paragraph;
        }

        initialValues.paragraphOfRegulation = paragraph;
        initialValues.direction = direction;

        this.setState({
            initialValues: initialValues,
            direction: direction,
            paragraph: paragraph,
        });

        this.setInitialContainersAccordingToDirection(direction!, this.props.placeId);
    }

    private setAllowedParagraphs(): Promise<void> | undefined {
        if (this.state.isCorrecting || this.state.isCorrectingAsNew)
            return this.props.regulationStore?.fetchExciseRegisterParagraphConfigurationForEntryCorrection(this.props.exciseRegisterId)
                .then(async data => { this.setState({ allowedParagraphs: data != null ? data! : [] }); })
        else
            return this.props.regulationStore?.fetchExciseRegisterParagraphConfigurationForEntry(this.props.exciseRegisterId)
                .then(async data => { this.setState({ allowedParagraphs: data != null ? data! : [] }); })
    }

    private fetchExciseEntry(): Promise<void | ExciseEntryFormDto | null> {
        return ajaxCatch(() =>
            new ExciseEntryClient().getExciseEntryForm(this.props.exciseEntryId!),
            (result: ExciseEntryFormDto | null) => {
                result = this.setInitialContainersIfDirectionIsSet(result);
                this.setState({
                    initialValues: result!,
                    paragraph: result?.paragraphOfRegulation,
                    schema: result!.paragraphOfRegulation ? this.validation.getValidationSchema(this.props.regulationStore?.getByParagraph(result!.paragraphOfRegulation, EwdRegisterName.ExciseRegister), {
                        isCorrection: this.props.isCorrecting
                    }) : this.validation.schema,
                    direction: result!.direction
                })
            });
    }

    private fetchExciseRegister(): Promise<void | ExciseRegisterDto | null> {
        return ajaxCatch(() =>
            new ExciseRegisterClient().get(this.props.exciseRegisterId),
            (result: ExciseRegisterDto | null) => {
                let initialValues = this.state.initialValues
                initialValues.cnCode = initialValues.cnCode && result!.cnCode && initialValues.cnCode.startsWith(result!.cnCode)
                    ? initialValues.cnCode
                    : result!.cnCode;
                this.setState({
                    initialValues: initialValues,
                    exciseRegisterCnCode: result!.cnCode,
                    exciseRegisterCloseDate: result!.closeDate?.clone().asUtc()
                })
            });
    }

    private fetchEwdDictionaries(): Promise<void | EwdDictionaryDto[] | null> {
        return ajaxCatch(() =>
            new DictionaryClient().getEwdDictionariesAndValues(),
            (dictionaries: EwdDictionaryDto[] | null) => {
                this.setState({
                    ewdDictionaries: dictionaries
                });
            });
    }

    private fetchCorrectionAsNewInitialData(): Promise<void | CorrectionAsNewInitialDataResult | null> {
        return ajaxCatch(() =>
            new ExciseEntryClient().getCorrectionAsNewInitialData(this.props.exciseRegisterId!),
            (result: CorrectionAsNewInitialDataResult | null) => {
                let initialValues = this.state.initialValues;
                initialValues.nextCorrectionOrdinalNumber = result?.nextCorrectionOrdinalNumber!;
                initialValues.ordinalNumber = result?.nextOrdinalNumber!;
                this.setState({
                    initialValues: initialValues,
                    initialStateDay: result?.initialStateDay,
                    lastClosedDay: result?.lastClosedDay
                });
            });
    }

    public send(values: ExciseEntryFormDto, actions: FormikHelpers<ExciseEntryFormDto>) {
        if (!this.state.initialValues.id) {
            let cmd = new CreateExciseEntryCmd({
                exciseEntryFormDto: values,
                registerId: this.props.exciseRegisterId,
                editorId: undefined,
            });
            ajaxByUser("Dodano zdarzenie", () =>
                new ExciseEntryClient().create(cmd)
                    .then((resp: any) => {
                        actions.setSubmitting(false);
                        this.props.history.push(this.goBackUrl);
                        this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, resp.toString());
                    }).finally(() => this.setState({ isLoading: false })));
        } else {
            let cmd = new ConfirmExciseEntryCmd({
                exciseEntryFormDto: values,
                registerId: this.props.exciseRegisterId,
                editorId: undefined,
            });
            ajaxByUser("Zredagowano zdarzenie", () =>
                new ExciseEntryClient().confirm(cmd)
                    .then((resp: any) => {
                        actions.setSubmitting(false);
                        this.props.history.push(this.goBackUrl);
                    }).finally(() => this.setState({ isLoading: false })));
        }
    }

    public saveToEditState(values: ExciseEntryFormDto) {
        let cmd = new SaveExciseEntryDraftCmd({
            exciseEntryFormDto: values,
            registerId: this.props.exciseRegisterId,
            editorId: undefined,
        });
        ajaxByUser("Zapisano zdarzenie", () =>
            new ExciseEntryClient().saveDraft(cmd)
                .then((resp: any) => {
                    this.props.history.push(this.goBackUrl);
                    if (!this.props.exciseEntryId) {
                        this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, resp.toString());
                    }
                }));
    }

    public correct(values: ExciseEntryFormDto, actions: FormikHelpers<ExciseEntryFormDto>) {
        let cmd = new CorrectExciseEntryCmd({
            exciseEntryFormDto: values,
            registerId: this.props.exciseRegisterId,
            editorId: undefined
        });
        ajaxCatch(() =>
            new ExciseEntryClient().correct(cmd)
                .then((result: CalculatorResult | null) => {
                    if (result && result.errors && result.errors.length > 0) {
                        actions.setSubmitting(false);
                        showErrorListModal("Problem w trakcie dokonywania korekty", result.errors);
                    } else {
                        actions.setSubmitting(false);
                        this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, result!.id.toString());
                        this.props.history.push(this.goBackUrl);
                        notification.success({
                            message: "Zapisano korektę zdarzenia",
                            placement: "bottomRight",
                        });
                    }
                }).finally(() => this.setState({ isLoading: false })));
    }

    public correctAsNew(values: ExciseEntryFormDto, actions: FormikHelpers<ExciseEntryFormDto>) {
        values.id = undefined;
        let cmd = new CorrectAsNewExciseEntryCmd({
            exciseEntryFormDto: values,
            registerId: this.props.exciseRegisterId,
            editorId: undefined,
            expiredEntryId: this.props.exciseEntryId
        });
        ajaxCatch(() =>
            new ExciseEntryClient().correctAsNew(cmd)
                .then((result: CalculatorResult | null) => {
                    if (result && result.errors && result.errors.length > 0) {
                        actions.setSubmitting(false);
                        showErrorListModal("Problem w trakcie dodawania zdarzenia korygującego", result.errors);
                    } else {
                        actions.setSubmitting(false);
                        this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, result!.id.toString());
                        this.props.history.push(this.goBackUrl);
                        notification.success({
                            message: this.state.assigningAsCorrection ? "Przypisano zdarzenie do rejestru jako korektę dodającą" : "Zapisano zdarzenie korygujące",
                            placement: "bottomRight",
                        });
                    }
                }).finally(() => this.setState({ isLoading: false })));
    }

    public async calculateExciseAmount(values: ExciseEntryFormDto): Promise<any[]> {
        let quantity;
        let quantityInAdditionalUnit;

        if (values.direction === directions.outgoing) {
            quantity = values.expenditureInExciseUnit;
            quantityInAdditionalUnit = values.expenditureInInventoryUnit;
        } else if (values.direction === directions.incoming) {
            quantity = values.incomeInExciseUnit;
            quantityInAdditionalUnit = values.incomeInInventoryUnit;
        }
        else if (values.direction === directions.transshipment) {
            quantity = values.amountTransshipmentInExciseUnit;
            quantityInAdditionalUnit = values.amountTransshipmentInTechnicalUnit;
        }

        let cmd = new CalculateExciseAmountCmd({
            direction: values.direction,
            productModel: new ProductModel({
                exciseProductCode: values.exciseProductCode,
                cnCode: values.cnCode,
                quantity: quantity,
                netWeight: values.netWeight,
                quantityInAdditionalUnit: quantityInAdditionalUnit,
                notColoredAndMarkedOils: values.notColouredAndMarkedFuelOils,
                density: values.unitConverter,
                fiscalMarkUsedFlag: values.fiscalMark,
                alcoholicStrength: values.alcoholicStrength,
                biofuelContentQuality: values.biofuelContentQuality,
                degreePlato: values.degreePlato,
                fuelType: values.fuelType,
                maxRetailPrice: values.maxRetailPrice,
                sizeOfProducer: values.sizeOfProducer,
                key: undefined
            })
        });

        let exciseAmount;
        let fuelExciseAmount;
        await ajaxCatch(() =>
            new ExciseEntryClient().calculateExciseAmount(cmd)
                .then((resp: CalculateExciseAmountResponse | null) => {
                    if (resp && resp.exciseAmount) {
                        exciseAmount = resp.exciseAmount;
                        fuelExciseAmount = resp.fuelExciseAmount;
                    }
                    if (resp && resp.responsMessage)
                        showWarning(resp.responsMessage)
                }));

        return [exciseAmount, fuelExciseAmount];
    }

    FFieldLabel = (props: IFFieldLabelProps & ColProps & { name: string }) => {
        const fieldShouldBeOptional = () => {
            if (!this.state.paragraph || !this.props.regulationStore) {
                return false;
            }
            if (props.name == "unitConverter") {
                return !isUnitConverterRequired(this.state.paragraph, this.state.exciseRegisterCnCode);
            }
            return !this.props.regulationStore.getByParagraph(this.state.paragraph, EwdRegisterName.ExciseRegister).requiredFields?.includes(props.name)
        }

        return <FormikLabel {...props} optional={props.optional !== undefined ? props.optional : fieldShouldBeOptional()} />
    }

    private checkIfFieldShouldBeDisabled(fieldName: string): boolean {
        let fieldsAlwaysDisabled = ["date"]
        let nonCorrectableFields = ["paragraphOfRegulation", "direction", "cnCode", "date",
            "ordinalNumber", "sourceIdentifier", "exciseProductCode", "containerIdFrom", "containerIdTo"]
        let lockedFields = this.state.initialValues.lockedFields;
        let isFieldLocked = (lockedFields && lockedFields.some(a => a.toLowerCase() == fieldName.toLowerCase())) ?? false;

        if (fieldName == "containerIdTo" && (this.state.direction == directions.outgoing ||
            this.props.registerStore?.containersActive.filter(x => x.placeId == this.props.placeId && x.exciseRegisterIds?.some(y => y == this.props.exciseRegisterId)).length === 1)) {
            return true;
        }
        else if (fieldName == "containerIdFrom" && (this.state.direction == directions.incoming ||
            this.props.registerStore?.containersActive.filter(x => x.placeId == this.props.placeId && x.exciseRegisterIds?.some(y => y == this.props.exciseRegisterId)).length === 1)) {
            return true;
        }

        if (this.state.isEditing && (fieldsAlwaysDisabled.some(a => a === fieldName) || (!this.state.isCorrecting && isFieldLocked))) {
            return true;
        }

        if (this.state.isCorrecting) {
            if (nonCorrectableFields.some(a => a == fieldName)) {
                return true;
            }
        } else if (this.state.initialValues && this.state.initialValues.isFromPentaTaxModule) {

            if (fieldName === "containerIdTo" || fieldName == "containerIdFrom" || fieldName == "productCode") {
                return false;
            }

            if (isFieldLocked) {
                return true;
            }
        }

        if (fieldName === "cnCode" && this.state.exciseRegisterCnCode && this.state.exciseRegisterCnCode!.length == 8)
            return true

        return false;
    }

    private checkIfContainersOptional(fieldName: string, direction: string): boolean | undefined {
        if (fieldName == "containerIdFrom" && (direction == directions.outgoing || direction == directions.transshipment)) {
            return false;
        }
        else if (fieldName == "containerIdTo" && (direction == directions.incoming || direction == directions.transshipment)) {
            return false;
        }
        return undefined;
    }

    private setContainersAccordingToDirection(direction: string, placeId: number, props: FormikProps<ExciseEntryFormDto>) {
        this.setState({
            direction: direction,
        });

        const notSpecifiedContainerId = this.props.registerStore?.containersAll.filter(x => x.placeId == placeId && x.name == defaultContainers.notSpecified)[0].id!;
        const containtersForRegister = this.props.registerStore?.containersActive.filter(x => x.placeId == placeId && x.exciseRegisterIds?.some(y => y == this.props.exciseRegisterId)) ?? [];

        if (containtersForRegister.length === 1) {
            props.setFieldValue("containerIdFrom", notSpecifiedContainerId);
            props.setFieldValue("containerIdTo", notSpecifiedContainerId);
        }
        else {
            switch (direction) {
                case directions.outgoing:
                    props.setFieldValue("containerIdFrom", undefined);
                    props.setFieldValue("containerIdTo", notSpecifiedContainerId);
                    break;
                case directions.incoming:
                    props.setFieldValue("containerIdFrom", notSpecifiedContainerId);
                    props.setFieldValue("containerIdTo", undefined);
                    break;
                case directions.transshipment:
                    props.setFieldValue("containerIdFrom", undefined);
                    props.setFieldValue("containerIdTo", undefined);
                    break;
            }
        }
    }

    private setInitialContainersAccordingToDirection(direction: string, placeId: number) {
        let initialValues = this.state.initialValues

        const notSpecifiedContainerId = this.props.registerStore?.containersAll.filter(x => x.placeId == placeId && x.name == defaultContainers.notSpecified)[0].id!;
        const containtersForRegister = this.props.registerStore?.containersActive.filter(x => x.placeId == placeId && x.exciseRegisterIds?.some(y => y == this.props.exciseRegisterId)) ?? [];

        if (containtersForRegister.length === 1) {
            initialValues.containerIdFrom = notSpecifiedContainerId;
            initialValues.containerIdTo = notSpecifiedContainerId;
        }
        else {
            switch (direction) {
                case directions.outgoing:
                    initialValues.containerIdTo = notSpecifiedContainerId;
                    break;
                case directions.incoming:
                    initialValues.containerIdFrom = notSpecifiedContainerId;
                    break;
            }
        }

        this.setState({
            initialValues: initialValues,
        });
    }

    private setInitialContainersIfDirectionIsSet(exciseEntry: ExciseEntryFormDto | null) {
        if (exciseEntry!.direction) {
            let notSpecifiedContainerId = this.props.registerStore?.containersAll.filter(x => x.placeId == this.props.placeId && x.name == defaultContainers.notSpecified)[0].id!;
            switch (exciseEntry!.direction) {
                case directions.outgoing:
                    exciseEntry!.containerIdTo = notSpecifiedContainerId;
                    break;
                case directions.incoming:
                    exciseEntry!.containerIdFrom = notSpecifiedContainerId;
                    break;
            }
        }
        return exciseEntry;
    }

    private getPageHeaderTitle(): string {
        if (this.state.isCorrecting) {
            return "KOREKTA ZDARZENIA AKCYZOWEGO";
        }
        else if (this.state.assigningAsCorrection) {
            return "PRZYPISANIE ZDARZENIA PRZETERMINOWANEGO JAKO KOREKTĘ DODAJĄCĄ"
        }
        else if (this.state.isCorrectingAsNew) {
            return "TWORZENIE ZDARZENIA AKCYZOWEGO, KORYGUJĄCEGO";
        }
        if (this.state.isEditing) {
            return "REDAGOWANIE ZDARZENIA AKCYZOWEGO";
        }
        return "TWORZENIE ZDARZENIA AKCYZOWEGO";
    }

    public render() {
        const { placeId, exciseRegisterId } = this.props;
        return (
            <>
                <PageHeader title={this.getPageHeaderTitle()} />
                {this.state.isLoading ? <CenteredSpin size="large" /> :
                    <>
                        <ExciseRegisterBasicDetails exciseRegisterId={this.props.exciseRegisterId} />
                        <Formik<ExciseEntryFormDto> initialValues={this.state.initialValues} enableReinitialize
                            onSubmit={(values, actions) => {
                                if (this.state.isCorrecting) {
                                    this.correct(values, actions);
                                } else if (this.state.isCorrectingAsNew || this.state.assigningAsCorrection) {
                                    this.correctAsNew(values, actions);
                                } else {
                                    this.send(values, actions);
                                }
                            }}
                            validateOnBlur={true} validateOnChange={false} validateOnMount={true}
                            validationSchema={this.state.schema}>
                            {(props: FormikProps<ExciseEntryFormDto>) => {
                                this.props.formChangeStore?.setChangeDetectionStatus(props.dirty);
                                return <PrimaryCard>
                                    <Collapse defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']}>

                                        <FPanel key='0' header={`Informacje ogólne`}>
                                            <FRow>
                                                <this.FFieldLabel sm={6} label="Paragraf" name="paragraphOfRegulation" optional={false} />
                                                <Col sm={props.values.paragraphOfRegulation ? 6 : 18}>
                                                    <Field component={FDictionarySelectWithTooltip}
                                                        dictionaryValues={filterRegulationsWithDefinedDirection(this.props.dictionaryStore!, this.props.regulationStore!, this.state.allowedParagraphs, this.state.initialValues.isFromPentaTaxModule ? this.state.initialValues.direction : undefined)}
                                                        name="paragraphOfRegulation"
                                                        disabled={this.checkIfFieldShouldBeDisabled("paragraphOfRegulation")}
                                                        onChange={(v: any, o: any) => this.handleParagraphChange(props, v)} />
                                                </Col>
                                                {(props.values.paragraphOfRegulation || props.values.direction) &&
                                                    <>
                                                        <this.FFieldLabel sm={6} label="Kierunek zdarzenia" name="direction" optional={false} />
                                                        <Col sm={6}>
                                                            <Field component={FDictionarySelect}
                                                                name="direction"
                                                                disabled={true}
                                                                dictionaryName={DictionaryName.ExciseEntryDirection} />
                                                        </Col>
                                                    </>}
                                            </FRow>
                                            {(this.state.isCorrecting || this.state.isCorrectingAsNew) && this.state.initialValues.ordinalNumber &&
                                                <FRow>
                                                    <this.FFieldLabel sm={6} label="Lp." name="ordinalNumber" optional={false} />
                                                    <Col sm={6}>
                                                        <Field component={FInput} name="ordinalNumber" disabled={true} />
                                                    </Col>
                                                </FRow>}
                                        </FPanel>

                                        {(this.state.isCorrecting || this.state.isCorrectingAsNew) &&
                                            <FPanel key='1' header={`Informacje o korekcie`}>
                                                <FRow>
                                                    <this.FFieldLabel sm={6} label="Powód korekty" name="correctionReason" optional={false} />
                                                    <Col sm={6}>
                                                        <Field component={FInput} name="correctionReason" />
                                                    </Col>
                                                    <this.FFieldLabel sm={6} label="Lp. korekty" name="nextCorrectionOrdinalNumber" optional={false} />
                                                    <Col sm={6}>
                                                        <Field component={FInput} name="nextCorrectionOrdinalNumber" disabled={true} />
                                                    </Col>
                                                </FRow>
                                            </FPanel>}

                                        {props.values.paragraphOfRegulation &&
                                            <>
                                                <FPanel key='2' header={`Wyrób`}>
                                                    {!this.state.isCorrecting && <FRow>
                                                        <this.FFieldLabel sm={6} label="Wyszukaj wyrób z listy" name="product" />
                                                        <Col sm={18}>
                                                            <Select showSearch optionFilterProp="children" onSelect={this.setProductFields(props)} >
                                                                {this.props.registerStore!.goodDictionaries.filter(x => !x.cnCode || x.cnCode.includes(this.state.exciseRegisterCnCode!)).map(x =>
                                                                    <Option key={x.id!} value={x.id}>
                                                                        {x.name}
                                                                        {x.cnCode !== undefined ? ` (CN: ${x.cnCode})` : null}
                                                                        {x.productCode !== undefined ? ` (${x.productCode})` : null}
                                                                    </Option>)}
                                                            </Select>
                                                        </Col>
                                                    </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel sm={6} label="Nazwa wyrobu" name="productName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="productName" disabled={this.checkIfFieldShouldBeDisabled("productName")} />
                                                        </Col>
                                                        <this.FFieldLabel sm={6} label="Kod towarowy" name="productCode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="productCode" disabled={this.checkIfFieldShouldBeDisabled("productCode")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod wyrobu akcyzowego" name="exciseProductCode" />
                                                        <Col sm={6}>
                                                            <Field component={FDictionarySelectWithTooltip}
                                                                name="exciseProductCode"
                                                                disabled={this.checkIfFieldShouldBeDisabled("exciseProductCode")}
                                                                dictionaryValues={filterExciseProductCodeDictionary(this.props.dictionaryStore!, props.values.cnCode)} />
                                                        </Col>
                                                        <this.FFieldLabel label="Kod CN wyrobu" name="cnCode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput}
                                                                name="cnCode"
                                                                maxLength={8}
                                                                disabled={this.checkIfFieldShouldBeDisabled("cnCode")}
                                                                onChange={this.handleCnCodeChange(props)} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Informacja o znakach akcyzy" name="fiscalMark" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} isBoolean name="fiscalMark" disabled={this.checkIfFieldShouldBeDisabled("fiscalMark")} >
                                                                {YesOrNo.map((o, i) => {
                                                                    return (<Select.Option key={i} value={o.value}>{o.label}</Select.Option>);
                                                                })}
                                                            </Field>
                                                        </Col>
                                                        <this.FFieldLabel label="Informacja o oznakowaniu i barwieniu" name="notColouredAndMarkedFuelOils" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} isBoolean name="notColouredAndMarkedFuelOils" disabled={this.checkIfFieldShouldBeDisabled("notColouredAndMarkedFuelOils")} >
                                                                {YesOrNo.map((o, i) => {
                                                                    return (<Select.Option key={i} value={o.value}>{o.label}</Select.Option>);
                                                                })}
                                                            </Field>
                                                        </Col>
                                                    </FRow>
                                                    {props.values.direction !== directions.outgoing &&
                                                        <FRow>
                                                            <this.FFieldLabel label="Przychód w jednostce rozliczenia akcyzy" name="incomeInExciseUnit" />
                                                            <Col sm={6}>
                                                                <Field component={FInputNumber} precision={3} name="incomeInExciseUnit" disabled={this.checkIfFieldShouldBeDisabled("incomeInExciseUnit")} />
                                                            </Col>
                                                            <this.FFieldLabel label="Przychód w jednostce alternatywnej" name="incomeInInventoryUnit" />
                                                            <Col sm={6}>
                                                                <Field component={FInputNumber} precision={3} name="incomeInInventoryUnit" disabled={this.checkIfFieldShouldBeDisabled("incomeInInventoryUnit")} />
                                                            </Col>
                                                        </FRow>}
                                                    {props.values.direction !== directions.incoming &&
                                                        <FRow>
                                                            <this.FFieldLabel label="Rozchód w jednostce rozliczenia akcyzy" name="expenditureInExciseUnit" />
                                                            <Col sm={6}>
                                                                <Field component={FInputNumber} precision={3} name="expenditureInExciseUnit" disabled={this.checkIfFieldShouldBeDisabled("expenditureInExciseUnit")} />
                                                            </Col>
                                                            <this.FFieldLabel label="Rozchód w jednostce alternatywnej" name="expenditureInInventoryUnit" />
                                                            <Col sm={6}>
                                                                <Field component={FInputNumber} precision={3} name="expenditureInInventoryUnit" disabled={this.checkIfFieldShouldBeDisabled("expenditureInInventoryUnit")} />
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Przelicznik jednostek (ilość w jednostce technicznej / ilość w jednostce akcyzowej)" name="unitConverter" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} precision={6} name="unitConverter" disabled={this.checkIfFieldShouldBeDisabled("unitConverter")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Kod Nomenklatury Scalonej (CN) suszu tytoniowego otrzymanego w wyniku przetwarzania" name="cnCodeOfDriedTabacco" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="cnCodeOfDriedTabacco" disabled={this.checkIfFieldShouldBeDisabled("cnCodeOfDriedTabacco")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Masa netto" name="netWeight" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} name="netWeight" disabled={this.checkIfFieldShouldBeDisabled("netWeight")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Zawartość alkoholu" name="alcoholicStrength" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} name="alcoholicStrength" disabled={this.checkIfFieldShouldBeDisabled("alcoholicStrength")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Stopień Plato" name="degreePlato" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} name="degreePlato" disabled={this.checkIfFieldShouldBeDisabled("degreePlato")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Rodzaj paliwa" name="fuelType" />
                                                        <Col sm={6}>
                                                            <Field component={FDictionarySelect}
                                                                name="fuelType"
                                                                disabled={this.checkIfFieldShouldBeDisabled("fuelType")}
                                                                dictionaryName={DictionaryName.FuelTypeType} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Maksymalna cena detaliczna za 20 szt. lub za kilogram" name="maxRetailPrice" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} name="maxRetailPrice" disabled={this.checkIfFieldShouldBeDisabled("maxRetailPrice")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Wielkość producenta" name="sizeOfProducer" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} name="sizeOfProducer" disabled={this.checkIfFieldShouldBeDisabled("sizeOfProducer")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Biokomponenty oraz paliwo spełniają wymagania jakościowe" name="biofuelContentQuality" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} isBoolean name="biofuelContentQuality" disabled={this.checkIfFieldShouldBeDisabled("biofuelContentQuality")} >
                                                                {YesOrNo.map((o, i) => {
                                                                    return (<Option key={i} value={o.value}>{o.label}</Option>);
                                                                })}
                                                            </Field>
                                                        </Col>
                                                        <this.FFieldLabel label="Wartość opałowa" name="calorificValue" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} precision={7} name="calorificValue" disabled={this.checkIfFieldShouldBeDisabled("calorificValue")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={3} header={"Informacje dodatkowe"}>
                                                    <FRow>
                                                        <this.FFieldLabel label="Data" name="date" />
                                                        <Col sm={6}>
                                                            <Field sm={6} component={FDatePicker} name="date" disabled={this.checkIfFieldShouldBeDisabled("date")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Data wystawienia dokumentu" name="documentDate" />
                                                        <Col sm={6}>
                                                            <Field sm={6} component={FDatePicker} name="documentDate" disabled={this.checkIfFieldShouldBeDisabled("documentDate")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Numer dokumentu dostawy lub przewozowego lub zgłoszenia" name="documentNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="documentNumber" disabled={this.checkIfFieldShouldBeDisabled("documentNumber")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Numer pozycji dokumentu dostawy lub przewozowego lub zgłoszenia" name="productNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="productNumber" disabled={this.checkIfFieldShouldBeDisabled("productNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Data faktury" name="invoiceDate" />
                                                        <Col sm={6}>
                                                            <Field sm={6} component={FDatePicker} name="invoiceDate" disabled={this.checkIfFieldShouldBeDisabled("invoiceDate")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Numer faktury" name="invoiceNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="invoiceNumber" disabled={this.checkIfFieldShouldBeDisabled("invoiceNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kwota akcyzy" name="exciseAmount" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} name="exciseAmount" disabled={this.checkIfFieldShouldBeDisabled("exciseAmount")} />
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Button type="default" icon={<CalculatorOutlined />} size="large" loading={this.state.isWaitingForResult} disabled={this.checkIfFieldShouldBeDisabled("exciseAmount")} onClick={() => {
                                                                let values = props.values;
                                                                this.setState({ isWaitingForResult: true });
                                                                this.calculateExciseAmount(values).then((result) => {
                                                                    props.setFieldValue("exciseAmount", result[0]);
                                                                    props.setFieldValue("fuelExciseAmount", result[1]);
                                                                    props.setFieldTouched("exciseAmount");
                                                                    props.setFieldTouched("fuelExciseAmount");
                                                                    this.setState({ isWaitingForResult: false });
                                                                });
                                                            }}>Wylicz kwotę akzyzy</Button>
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kwota akcyzy paliwowej" name="fuelExciseAmount" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} name="fuelExciseAmount" disabled={this.checkIfFieldShouldBeDisabled("fuelExciseAmount")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Miejsce w składzie" name="taxWarehousePlace" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="taxWarehousePlace" disabled={this.checkIfFieldShouldBeDisabled("taxWarehousePlace")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel optional={this.checkIfContainersOptional("containerIdFrom", this.state.direction!)} label="Numer zbiornika wydającego" name="containerIdFrom" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} optionFilterProp="children" name="containerIdFrom" disabled={this.checkIfFieldShouldBeDisabled("containerIdFrom")} >
                                                                {placeId && this.props.registerStore!.containersAll.filter(x => x.placeId == placeId && x.exciseRegisterIds?.some(y => y == exciseRegisterId)).map((x, y) =>
                                                                    <Option disabled={!x.isActive} key={x.id!} value={x.id!}> {x.name} </Option>)}
                                                            </Field>
                                                        </Col>
                                                        <this.FFieldLabel optional={this.checkIfContainersOptional("containerIdTo", this.state.direction!)} label="Numer zbiornika przyjmującego" name="containerIdTo" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} optionFilterProp="children" name="containerIdTo" disabled={this.checkIfFieldShouldBeDisabled("containerIdTo")} >
                                                                {placeId && this.props.registerStore!.containersAll.filter(x => x.placeId == placeId && x.exciseRegisterIds?.some(y => y == exciseRegisterId)).map((x, y) =>
                                                                    <Option disabled={!x.isActive} key={x.id!} value={x.id!}> {x.name} </Option>)}
                                                            </Field>
                                                        </Col>
                                                        <this.FFieldLabel label="Sposób nabycia" name="methodOfAcquisition" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} name="methodOfAcquisition" disabled={this.checkIfFieldShouldBeDisabled("methodOfAcquisition")} >
                                                                {this.state.ewdDictionaries && this.state.ewdDictionaries.filter(a => a.name == EwdDictionaryName.MethodOfAcquisition)[0].values?.map((o, i) => {
                                                                    return (<Option key={i} value={o.code!}>{o.displayValue}</Option>);
                                                                })}
                                                            </Field>
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Sposób wyprowadzenia" name="methodOfDerivation" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="methodOfDerivation" disabled={this.checkIfFieldShouldBeDisabled("methodOfDerivation")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Sposób zużycia wyrobów akcyzowych" name="methodOfConsumptionOfGoods" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} name="methodOfConsumptionOfGoods" disabled={this.checkIfFieldShouldBeDisabled("methodOfConsumptionOfGoods")} >
                                                                {this.state.ewdDictionaries && this.state.ewdDictionaries.filter(a => a.name == EwdDictionaryName.MethodOfConsumptionOfGoods)[0].values?.map((o, i) => {
                                                                    return (<Option key={i} value={o.code!}>{o.displayValue}</Option>);
                                                                })}
                                                            </Field>
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Sposób przetworzenia" name="methodOfProcessingOfGoods" />
                                                        <Col sm={6}>
                                                            <Field component={FSelect} name="methodOfProcessingOfGoods" disabled={this.checkIfFieldShouldBeDisabled("methodOfProcessingOfGoods")} >
                                                                {this.state.ewdDictionaries && this.state.ewdDictionaries.filter(a => a.name == EwdDictionaryName.MethodOfProcessingOfGoods)[0].values?.map((o, i) => {
                                                                    return (<Option key={i} value={o.code!}>{o.displayValue}</Option>);
                                                                })}
                                                            </Field>
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ilość przeładowana w jednostce rozliczenia akcyzy" name="amountTransshipmentInExciseUnit" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} precision={3} name="amountTransshipmentInExciseUnit" disabled={this.checkIfFieldShouldBeDisabled("amountTransshipmentInExciseUnit")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Ilość przeładowana w jednostce technicznej" name="amountTransshipmentInTechnicalUnit" />
                                                        <Col sm={6}>
                                                            <Field component={FInputNumber} precision={3} name="amountTransshipmentInTechnicalUnit" disabled={this.checkIfFieldShouldBeDisabled("amountTransshipmentInTechnicalUnit")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Przeładowano z (identyfikator środka transportu)" name="transshippedFrom" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transshippedFrom" disabled={this.checkIfFieldShouldBeDisabled("transshippedFrom")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Przeładowano na (identyfikator środka transportu)" name="transshippedTo" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transshippedTo" disabled={this.checkIfFieldShouldBeDisabled("transshippedTo")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Numer koncesji na obrót paliwami ciekłymi z zagranicą" name="concessionNumberForFuels" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="concessionNumberForFuels" disabled={this.checkIfFieldShouldBeDisabled("concessionNumberForFuels")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Typ koncesji" name="concessionType" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="concessionType" disabled={this.checkIfFieldShouldBeDisabled("concessionType")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Data wydania koncesji" name="concessionIssueDate" />
                                                        <Col sm={6}>
                                                            <Field component={FDatePicker} name="concessionIssueDate" disabled={this.checkIfFieldShouldBeDisabled("concessionIssueDate")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Data ważności koncesji" name="concessionExpiryDate" />
                                                        <Col sm={6}>
                                                            <Field component={FDatePicker} name="concessionExpiryDate" disabled={this.checkIfFieldShouldBeDisabled("concessionExpiryDate")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Data sprzedaży" name="saleDate" />
                                                        <Col sm={6}>
                                                            <Field component={FDatePicker} name="saleDate" disabled={this.checkIfFieldShouldBeDisabled("saleDate")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Data wydania wyrobów węglowych finalnemu nabywcy" name="finalCarbonicBuyerIssueDate" />
                                                        <Col sm={6}>
                                                            <Field sm={6} component={FDatePicker} name="finalCarbonicBuyerIssueDate" disabled={this.checkIfFieldShouldBeDisabled("finalCarbonicBuyerIssueDate")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Sposób użycia" toolTip="W ramach zwolnień, o których mowa w art. 31a ust. 1, lub albo do celow nie objetych zwolnieniem." name="useMethod" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="useMethod" disabled={this.checkIfFieldShouldBeDisabled("useMethod")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Adres miejsca wykonania czynności" name="activitiesPlaceAddress" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="activitiesPlaceAddress" disabled={this.checkIfFieldShouldBeDisabled("activitiesPlaceAddress")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Status wyrobów niezwolnionych" name="notExemptProductStatus" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="notExemptProductStatus" disabled={this.checkIfFieldShouldBeDisabled("notExemptProductStatus")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Opis przeznaczenia" name="useDescription" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="useDescription" disabled={this.checkIfFieldShouldBeDisabled("useDescription")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Typ dokumentu lokalnego logistycznego" name="typeOfLocalDocument" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="typeOfLocalDocument" disabled={this.checkIfFieldShouldBeDisabled("typeOfLocalDocument")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Numer lokalnego dokumentu logistycznego" name="localDocumentNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="localDocumentNumber" disabled={this.checkIfFieldShouldBeDisabled("localDocumentNumber")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Numer pozycji na dokumencie lokalnym" name="localProductNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="localProductNumber" disabled={this.checkIfFieldShouldBeDisabled("localProductNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Numer zgłoszenia celnego" name="customsDeclarationNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="customsDeclarationNumber" disabled={this.checkIfFieldShouldBeDisabled("customsDeclarationNumber")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Data zgłoszenia celnego" name="customsDeclarationDate" />
                                                        <Col sm={6}>
                                                            <Field sm={6} component={FDatePicker} name="customsDeclarationDate" disabled={this.checkIfFieldShouldBeDisabled("customsDeclarationDate")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Miejsce (urząd) importu" name="customsOffice" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="customsOffice" disabled={this.checkIfFieldShouldBeDisabled("customsOffice")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={4} header={"Odbiorca"}>
                                                    {!this.checkIfFieldShouldBeDisabled("recipientName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="recipient" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "recipient")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="recipientIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="recipientIdentifier" disabled={this.checkIfFieldShouldBeDisabled("recipientIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="recipientName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="recipientName" disabled={this.checkIfFieldShouldBeDisabled("recipientName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="recipientStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="recipientStreetName" disabled={this.checkIfFieldShouldBeDisabled("recipientStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="recipientStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="recipientStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("recipientStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="recipientPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="recipientPostcode" disabled={this.checkIfFieldShouldBeDisabled("recipientPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="recipientCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="recipientCity" disabled={this.checkIfFieldShouldBeDisabled("recipientCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={5} header={"Dostawca"}>
                                                    {!this.checkIfFieldShouldBeDisabled("supplierName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="supplier" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "supplier")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="supplierIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="supplierIdentifier" disabled={this.checkIfFieldShouldBeDisabled("supplierIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="supplierName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="supplierName" disabled={this.checkIfFieldShouldBeDisabled("supplierName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="supplierStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="supplierStreetName" disabled={this.checkIfFieldShouldBeDisabled("supplierStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="supplierStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="supplierStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("supplierStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="supplierPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="supplierPostcode" disabled={this.checkIfFieldShouldBeDisabled("supplierPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="supplierCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="supplierCity" disabled={this.checkIfFieldShouldBeDisabled("supplierCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={6} header={"Przewoźnik"}>
                                                    {!this.checkIfFieldShouldBeDisabled("transporterName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="transporter" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "transporter")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="transporterIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transporterIdentifier" disabled={this.checkIfFieldShouldBeDisabled("transporterIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="transporterName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transporterName" disabled={this.checkIfFieldShouldBeDisabled("transporterName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="transporterStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transporterStreetName" disabled={this.checkIfFieldShouldBeDisabled("transporterStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="transporterStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transporterStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("transporterStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="transporterPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transporterPostcode" disabled={this.checkIfFieldShouldBeDisabled("transporterPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="transporterCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transporterCity" disabled={this.checkIfFieldShouldBeDisabled("transporterCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={7} header={"Spedytor"}>
                                                    {!this.checkIfFieldShouldBeDisabled("transportArrangerName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="transportArranger" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "transportArranger")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="transportArrangerIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transportArrangerIdentifier" disabled={this.checkIfFieldShouldBeDisabled("transportArrangerIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="transportArrangerName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transportArrangerName" disabled={this.checkIfFieldShouldBeDisabled("transportArrangerName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="transportArrangerStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transportArrangerStreetName" disabled={this.checkIfFieldShouldBeDisabled("transportArrangerStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="transportArrangerStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transportArrangerStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("transportArrangerStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="transportArrangerPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transportArrangerPostcode" disabled={this.checkIfFieldShouldBeDisabled("transportArrangerPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="transportArrangerCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="transportArrangerCity" disabled={this.checkIfFieldShouldBeDisabled("transportArrangerCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={8} header={"Właściciel"}>
                                                    {!this.checkIfFieldShouldBeDisabled("ownerName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="owner" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "owner")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="ownerIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="ownerIdentifier" disabled={this.checkIfFieldShouldBeDisabled("ownerIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="ownerName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="ownerName" disabled={this.checkIfFieldShouldBeDisabled("ownerName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="ownerStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="ownerStreetName" disabled={this.checkIfFieldShouldBeDisabled("ownerStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="ownerStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="ownerStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("ownerStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="ownerPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="ownerPostcode" disabled={this.checkIfFieldShouldBeDisabled("ownerPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="ownerCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="ownerCity" disabled={this.checkIfFieldShouldBeDisabled("ownerCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={9} header={"Magazynujący tytoń poza składem"}>
                                                    {!this.checkIfFieldShouldBeDisabled("storerOfTobaccoName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="storerOfTobacco" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "storerOfTobacco")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="storerOfTobaccoIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="storerOfTobaccoIdentifier" disabled={this.checkIfFieldShouldBeDisabled("storerOfTobaccoIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="storerOfTobaccoName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="storerOfTobaccoName" disabled={this.checkIfFieldShouldBeDisabled("storerOfTobaccoName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="storerOfTobaccoStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="storerOfTobaccoStreetName" disabled={this.checkIfFieldShouldBeDisabled("storerOfTobaccoStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="storerOfTobaccoStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="storerOfTobaccoStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("storerOfTobaccoStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="storerOfTobaccoPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="storerOfTobaccoPostcode" disabled={this.checkIfFieldShouldBeDisabled("storerOfTobaccoPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="storerOfTobaccoCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="storerOfTobaccoCity" disabled={this.checkIfFieldShouldBeDisabled("storerOfTobaccoCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={10} header={"Finalny nabywca"}>
                                                    {!this.checkIfFieldShouldBeDisabled("finalBuyerName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="finalBuyer" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "finalBuyer")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="finalBuyerIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="finalBuyerIdentifier" disabled={this.checkIfFieldShouldBeDisabled("finalBuyerIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="finalBuyerName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="finalBuyerName" disabled={this.checkIfFieldShouldBeDisabled("finalBuyerName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="finalBuyerStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="finalBuyerStreetName" disabled={this.checkIfFieldShouldBeDisabled("finalBuyerStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="finalBuyerStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="finalBuyerStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("finalBuyerStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="finalBuyerPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="finalBuyerPostcode" disabled={this.checkIfFieldShouldBeDisabled("finalBuyerPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="finalBuyerCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="finalBuyerCity" disabled={this.checkIfFieldShouldBeDisabled("finalBuyerCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={11} header={"Importer"}>
                                                    {!this.checkIfFieldShouldBeDisabled("importerName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="importer" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "importer")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="importerIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="importerIdentifier" disabled={this.checkIfFieldShouldBeDisabled("importerIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="importerName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="importerName" disabled={this.checkIfFieldShouldBeDisabled("importerName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="importerStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="importerStreetName" disabled={this.checkIfFieldShouldBeDisabled("importerStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="importerStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="importerStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("importerStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="importerPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="importerPostcode" disabled={this.checkIfFieldShouldBeDisabled("importerPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="importerCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="importerCity" disabled={this.checkIfFieldShouldBeDisabled("importerCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={12} header={"Sprzedawca"}>
                                                    {!this.checkIfFieldShouldBeDisabled("sellerName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="seller" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "seller")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="sellerIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="sellerIdentifier" disabled={this.checkIfFieldShouldBeDisabled("sellerIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="sellerName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="sellerName" disabled={this.checkIfFieldShouldBeDisabled("sellerName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="sellerStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="sellerStreetName" disabled={this.checkIfFieldShouldBeDisabled("sellerStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="sellerStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="sellerStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("sellerStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="sellerPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="sellerPostcode" disabled={this.checkIfFieldShouldBeDisabled("sellerPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="sellerCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="sellerCity" disabled={this.checkIfFieldShouldBeDisabled("sellerCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>

                                                <FPanel key={13} header={"Nabywca"}>
                                                    {!this.checkIfFieldShouldBeDisabled("buyerName") &&
                                                        <FRow>
                                                            <this.FFieldLabel sm={6} label="Wyszukaj kontrahenta z listy" name="buyer" />
                                                            <Col sm={18}>
                                                                <Select showSearch optionFilterProp="children" onSelect={this.setFormSubject(props, "buyer")}>
                                                                    {this.props.registerStore!.traders.map(x =>
                                                                        <Option key={x.id!} value={x.id} >{x.name} {x.vatNumber}</Option>)}
                                                                </Select>
                                                            </Col>
                                                        </FRow>}
                                                    <FRow>
                                                        <this.FFieldLabel label="Identyfikator (np. NIP)" name="buyerIdentifier" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="buyerIdentifier" disabled={this.checkIfFieldShouldBeDisabled("buyerIdentifier")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nazwa" name="buyerName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="buyerName" disabled={this.checkIfFieldShouldBeDisabled("buyerName")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Ulica" name="buyerStreetName" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="buyerStreetName" disabled={this.checkIfFieldShouldBeDisabled("buyerStreetName")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Nr domu" name="buyerStreetNumber" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="buyerStreetNumber" disabled={this.checkIfFieldShouldBeDisabled("buyerStreetNumber")} />
                                                        </Col>
                                                    </FRow>
                                                    <FRow>
                                                        <this.FFieldLabel label="Kod pocztowy" name="buyerPostcode" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="buyerPostcode" disabled={this.checkIfFieldShouldBeDisabled("buyerPostcode")} />
                                                        </Col>
                                                        <this.FFieldLabel label="Miejscowość" name="buyerCity" />
                                                        <Col sm={6}>
                                                            <Field component={FInput} name="buyerCity" disabled={this.checkIfFieldShouldBeDisabled("buyerCity")} />
                                                        </Col>
                                                    </FRow>
                                                </FPanel>
                                            </>}

                                    </Collapse>

                                    {props.values.paragraphOfRegulation &&
                                        <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                            <LinkWithConfirmation to="" actionOnClick={this.props.history.goBack}>
                                                <Button icon={<CloseOutlined />} size="large">Anuluj</Button>
                                            </LinkWithConfirmation>
                                            <Popconfirm title="Czy na pewno chcesz wyczyścić formularz?" okText="Tak" cancelText="Nie" onConfirm={() => props.resetForm()}>
                                                <Button type="default" icon={<DeleteOutlined />} size="large">Wyczyść</Button>
                                            </Popconfirm>
                                            {(!this.state.isCorrecting && !this.state.isCorrectingAsNew) && <Authorized requiresSubscription placeContext={this.props.placeContext}
                                                placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}>
                                                <Button type="primary" icon={<SaveOutlined />} size="large" onClick={() => {
                                                    this.saveToEditState(props.values);
                                                }}>Zapisz</Button>
                                                <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                                    this.validateAndSubmitForm(props);
                                                }}>Zapisz ze statusem "Gotowe"</Button>
                                            </Authorized>}
                                            {(this.state.isCorrecting || this.state.isCorrectingAsNew) && !this.state.assigningAsCorrection && <Authorized placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting}>
                                                <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                                    this.validateAndSubmitForm(props);
                                                }}>Zapisz korektę</Button>
                                            </Authorized>}
                                            {(this.state.assigningAsCorrection) && <Authorized placeContext={this.props.placeContext} placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryCorrecting}>
                                                <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                                    this.validateAndSubmitForm(props);
                                                }}>Przypisz zdarzenie jako korektę dodającą</Button>
                                            </Authorized>}
                                        </FCenteredRow>}
                                </PrimaryCard>
                            }}
                        </Formik>
                    </>}
            </>
        )
    }

    validateAndSubmitForm = (props: FormikProps<ExciseEntryFormDto>) => {
        props.setFormikState({
            isSubmitting: true,
            values: props.values,
            errors: props.errors,
            touched: props.touched,
            isValidating: props.isValidating,
            submitCount: props.submitCount + 1
        });

        props.validateForm(props.values)
            .then((errors: FormikErrors<ExciseEntryFormDto>) => {
                if (Object.keys(errors).length) {
                    props.setSubmitting(false);
                    console.log("Błędy walidacji klienckiej: ", errors, "wartości", props.values);
                    showFormIsInvalidModal();
                } else {
                    props.submitForm();
                }
            })
    }

    setProductFields = (props: FormikProps<ExciseEntryFormDto>) => (id: SelectValue): void => {
        var product = this.props.registerStore!.goodDictionaries.filter(x => x.id == id)[0];
        var direction = props.values.direction !== undefined ? props.values.direction : null;
        var exciseCodes = filterExciseProductCodeDictionary(this.props.dictionaryStore!, props.values.cnCode);

        this.setProductFieldValue(props, goodFormFieldNames.productName, product.name);
        this.setProductFieldValue(props, goodFormFieldNames.productCode, product.productCode);
        this.setProductFieldValue(props, goodFormFieldNames.exciseProductCode, exciseCodes.some(x =>
            x.displayValue && product.category && x.displayValue.includes(product.category)) ? product.category : undefined);
        this.setProductFieldValue(props, goodFormFieldNames.fiscalMark, product.fiscalMarkUsedFlag &&
            (product.fiscalMarkUsedFlag ? YesOrNo[0].label : YesOrNo[1].label));
        this.setProductFieldValue(props, goodFormFieldNames.notColouredAndMarkedFuelOils, product.notColouredAndMarkedFuelOils &&
            (product.notColouredAndMarkedFuelOils ? YesOrNo[0].label : YesOrNo[1].label));
        this.setProductFieldValue(props, goodFormFieldNames.incomeInExciseUnit, direction === directions.incoming ? product.amountOfGoods : undefined);
        this.setProductFieldValue(props, goodFormFieldNames.expenditureInExciseUnit, direction === directions.outgoing ? product.amountOfGoods : undefined);
        this.setProductFieldValue(props, goodFormFieldNames.unitConverter, product.density);
        this.setProductFieldValue(props, goodFormFieldNames.netWeight, product.netWeightOfGoods);
        this.setProductFieldValue(props, goodFormFieldNames.alcoholicStrength, product.alcoholicStrength);
        this.setProductFieldValue(props, goodFormFieldNames.degreePlato, product.degreePlato);
        this.setProductFieldValue(props, goodFormFieldNames.fuelType, product.fuelType);
        this.setProductFieldValue(props, goodFormFieldNames.maxRetailPrice, product.maxRetailPrice);
        this.setProductFieldValue(props, goodFormFieldNames.sizeOfProducer, product.sizeOfProducer);
        this.setProductFieldValue(props, goodFormFieldNames.biofuelContentQuality, product.biofuelContentMeetsQualityRequirements &&
            (product.biofuelContentMeetsQualityRequirements ? YesOrNo[0].label : YesOrNo[1].label));
    }

    setProductFieldValue = (props: FormikProps<ExciseEntryFormDto>, fieldName: string, value: any) => {
        if (this.state.initialValues.isFromPentaTaxModule && this.checkIfFieldShouldBeDisabled(fieldName))
            return;
        if (value === undefined || value == 0 || value?.lenght == 0)
            return;
        props.setFieldValue(fieldName, value);
    }

    setFormSubject = (props: FormikProps<ExciseEntryFormDto>, subject: string) => (id: SelectValue): void => {
        var trader = this.props.registerStore!.traders.filter(x => x.id == id)[0];

        props.setFieldValue(subject + `Identifier`, trader.vatNumber);
        props.setFieldValue(subject + `Name`, trader.name);
        props.setFieldValue(subject + `StreetName`, trader.street);
        props.setFieldValue(subject + `StreetNumber`, trader.streetNumber);
        props.setFieldValue(subject + `Postcode`, trader.postalCode);
        props.setFieldValue(subject + `City`, trader.city);
    }

    handleCnCodeChange = (props: FormikProps<ExciseEntryFormDto>) => {
        // jeżeli undefined
        if (!props.values.cnCode) {
            props.setFieldValue('cnCode', this.state.exciseRegisterCnCode);
            return;
        }
        // jeżeli długość mniejsza niż kod CN ewidencji
        if (props.values.cnCode != this.state.exciseRegisterCnCode &&
            (props.values.cnCode.length < this.state.exciseRegisterCnCode!.length)) {
            props.setFieldValue('cnCode', this.state.exciseRegisterCnCode);
            return;
        }
        // jeżeli kod CN nie zaczyne się kodem CN z ewidencji
        if (props.values.cnCode.length > this.state.exciseRegisterCnCode!.length &&
            !props.values.cnCode.startsWith(this.state.exciseRegisterCnCode!)) {
            props.setFieldValue('cnCode', this.state.exciseRegisterCnCode + props.values.cnCode.substring(this.state.exciseRegisterCnCode!.length));
            return;
        }
    }

    handleParagraphChange = (props: FormikProps<ExciseEntryFormDto>, paragraph: string) => {
        let direction = this.props.regulationStore!.regulations!.filter(x => x.paragraph === paragraph)[0]?.direction;
        if (!direction) {
            return;
        }

        props.setFieldValue('paragraphOfRegulation', paragraph)
        props.setFieldValue('direction', direction)

        if (direction == directions.incoming) {
            props.setFieldValue(goodFormFieldNames.expenditureInExciseUnit, undefined);
            props.setFieldValue(goodFormFieldNames.expenditureInInventoryUnit, undefined);
        } else if (direction == directions.outgoing) {
            props.setFieldValue(goodFormFieldNames.incomeInExciseUnit, undefined);
            props.setFieldValue(goodFormFieldNames.incomeInInventoryUnit, undefined);
        }

        this.setState({
            paragraph: paragraph,
            schema: this.validation.getValidationSchema(this.props.regulationStore?.getByParagraph(paragraph, EwdRegisterName.ExciseRegister), {
                isPlaceExists: !!this.props.placeId,
                isCorrection: this.state.isCorrecting || this.state.isCorrectingAsNew,
                initialStateDay: this.state.initialStateDay,
                lastClosedDay: this.state.lastClosedDay,
                exciseRegisterCloseDate: this.state.exciseRegisterCloseDate
            })
        });
        this.setContainersAccordingToDirection(direction!, this.props.placeId, props);
    }
}
