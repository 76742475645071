import React from 'react'
import { Col } from 'antd';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { DictionaryName, ExciseRegisterDto } from 'services/GeneratedClient';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import { PlaceDetails } from 'places/PlaceDetails';

const ExciseRegisterInformation = ({ exciseRegister, dictionaryStore }: { exciseRegister: ExciseRegisterDto, dictionaryStore: DictionaryStore }) =>
    <>
        <RowWithBottomMargin>
            <Col span={24}>
                <Card title="Szczegóły rejestru ewidencji">
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Identyfikator"}
                            value={exciseRegister.id.toString()} />
                        <DetailCell
                            label={"Nazwa ewidencji"}
                            value={exciseRegister.name} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Kod CN wyrobu"}
                            value={exciseRegister.cnCode} />
                        <DetailCell
                            label={"Status"}
                            value={dictionaryStore.getDisplay(DictionaryName.ExciseRegisterStatus, exciseRegister.status)} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Jednostka miary akcyzowej"}
                            value={dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, exciseRegister.exciseUnitOfMeasure)} />
                        <DetailCell
                            label={"Jednostka miary techniczna"}
                            value={dictionaryStore.getDisplay(DictionaryName.UnitOfMeasure, exciseRegister.inventoryUnitOfMeasure)} />
                    </RowWithBottomMargin>
                </Card>
            </Col>
        </RowWithBottomMargin>
        <RowWithBottomMargin>
            <Col span={24}>
                <PlaceDetails placeId={exciseRegister.placeId} horizontal />
            </Col>
        </RowWithBottomMargin>
    </>


export default ExciseRegisterInformation;