import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer } from 'helper/GridHelper';
import { ActionType, CompanyLevelPermission, DictionaryName, EwdPlaceLevelPermission, ExciseEntryClient, ExciseEntryDto } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from "layout/FadingTooltip";
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { Link } from 'react-router-dom';
import TableButton from 'layout/table/tableButton/TableButton';
import DeleteEntryButton from './DeleteEntryButton';
import ChangeToReadyButton from './ChangeToReadyButton';
import ChangeToEditedButton from './ChangeToEditedButton';
import { Authorized } from 'authorization/Authorized';
import { GridStore } from 'layout/table/paginated/GridStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
    exciseRegisterId: number;
    isIncome: boolean;
    isExpenditure: boolean;
    isTransshipment: boolean;
}

interface IState {
    gridName: string;
    expandedRowKeys?: React.Key[]
}

interface ExciseEntryColumnProps {
    income: boolean;
    expenditure: boolean;
    transshipment: boolean;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class CurrentExciseEntryList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: this.getGridName(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment),
        }
    }

    componentDidMount() {
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    getGridName = (isIncome: boolean, isExpenditure: boolean, isTransshipment: boolean) => `currentExcise${isIncome ? "Income" : ""}${isExpenditure ? "Expenditure" : ""}${isTransshipment ? "Transshipment" : ""}EntriesList`
    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        const gridName = this.getGridName(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment);
        if (gridName != prevState.gridName) {
            this.setState({ gridName });
        }
    }

    refreshGrid = () => {
        this.paginatedTable.current?.refresh();
    }

    private columns: (ExciseEntryColumnProps & EwdColumnProps<ExciseEntryDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseEntryDto) => (
                <>
                    {record.id && <>
                        <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryDisplay} placeContext={record.placeContext}>
                            <FadingTooltip title="Podgląd" placement="top">
                                <Link to={`/ExciseEntries/Preview/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>
                        {record.actions?.includes(ActionType.Editing) && <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}
                            placeContext={record.placeContext}>
                            <FadingTooltip title="Redaguj" placement="top">
                                <Link to={`/ExciseRegisters/${this.props.exciseRegisterId}/ExciseEntries/Edit/${record.id}`} >
                                    <TableButton gridName={this.state.gridName} recordId={record.id} icon={<EditOutlined />} />
                                </Link>
                            </FadingTooltip>
                        </Authorized>}
                        {record.actions?.includes(ActionType.Confirming) && <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}
                            placeContext={record.placeContext}>
                            <ChangeToReadyButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ActionType.SwitchingToEdited) && <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}
                            placeContext={record.placeContext}>
                            <ChangeToEditedButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                        {record.actions?.includes(ActionType.Deleting) && <Authorized placeLevelPermission={EwdPlaceLevelPermission.ExciseRegisterEntryEditing}
                            placeContext={record.placeContext}>
                            <DeleteEntryButton
                                recordId={record.id}
                                onSuccess={this.refreshGrid}
                                onFailure={this.refreshGrid}
                            />
                        </Authorized>}
                    </>}
                </>
            ),
            title: "Czynności",
            income: true,
            expenditure: true,
            transshipment: true,
            width: 140,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            income: true,
            expenditure: true,
            transshipment: true,
            width: 80,
            filter: "equals",
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (text: any, record: ExciseEntryDto) => dateRenderer(record.date),
            income: true,
            expenditure: true,
            transshipment: true,
            width: 90,
        },
        {
            dataIndex: "sourceIdentifier",
            key: "SourceIdentifier",
            title: "Identyfikator źródłowy",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            title: "Paragraf",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (text: any, record: ExciseEntryDto) => {
                return this.props.dictionaryStore!.getDisplay(DictionaryName.ExciseEntryStatus, record.status);
            },
            title: "Status",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "supplierIdentifier",
            key: "SupplierIdentifier",
            title: "Dostawca identyfikator",
            income: true,
            expenditure: false,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "supplierName",
            key: "SupplierName",
            title: "Dostawca",
            income: true,
            expenditure: false,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "recipientIdentifier",
            key: "RecipientIdentifier",
            title: "Odbiorca identyfikator",
            income: false,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "recipientName",
            key: "RecipientName",
            title: "Odbiorca",
            income: false,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "documentNumber",
            key: "DocumentNumber",
            title: "Nr dokumentu",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "productNumber",
            key: "ProductNumber",
            title: "Nr pozycji dokumentu",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "invoiceNumber",
            key: "InvoiceNumber",
            title: "Nr faktury",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "invoicePosition",
            key: "InvoicePosition",
            title: "Nr pozycji faktury",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "typeOfLocalDocument",
            key: "TypeOfLocalDocument",
            title: "Rodzaj dokumentu lokalnego",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "localDocumentNumber",
            key: "LocalDocumentNumber",
            title: "Numer dokumentu lokalnego",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "localProductNumber",
            key: "LocalProductNumber",
            title: "Numer pozycji dokumentu lokalnego",
            income: true,
            expenditure: true,
            transshipment: true,
        },
        {
            dataIndex: "productCode",
            key: "ProductCode",
            title: "Kod towaru",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "productName",
            key: "ProductName",
            title: "Nazwa towaru",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "exciseProductCode",
            key: "ExciseProductCode",
            title: "Kod wyrobu akcyzowego",
            income: true,
            expenditure: true,
            transshipment: true,
            filter: "contains",
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
            income: true,
            expenditure: false,
            transshipment: false,
        },
        {
            dataIndex: "incomeInInventoryUnit",
            key: "IncomeInInventoryUnit",
            title: "Przychód w jednostce technicznej",
            income: true,
            expenditure: false,
            transshipment: false,
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "expenditureInInventoryUnit",
            key: "ExpenditureInInventoryUnit",
            title: "Rozchód w jednostce technicznej",
            income: false,
            expenditure: true,
            transshipment: false,
        },
        {
            dataIndex: "transshippedFrom",
            key: "TransshippedFrom",
            title: "Przeładowano z",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "transshippedTo",
            key: "TransshippedTo",
            title: "Przeładowano na",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "amountTransshipmentInExciseUnit",
            key: "AmountTransshipmentInExciseUnit",
            title: "Ilość przeładowana w jednostce rozliczenia akcyzy",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "amountTransshipmentInTechnicalUnit",
            key: "AmountTransshipmentInTechnicalUnit",
            title: "Ilość przeładowana w jednostce technicznej",
            income: false,
            expenditure: false,
            transshipment: true,
        },
        {
            dataIndex: "unitConverter",
            key: "UnitConverter",
            title: "Przelicznik jednostek",
            income: true,
            expenditure: true,
            transshipment: true,
        },
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseEntryDto>>();
    public render() {
        return (
            <>
                <PaginatedTable<ExciseEntryDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns.filter(x => (this.props.isIncome && x.income) || (this.props.isExpenditure && x.expenditure) || (this.props.isTransshipment && x.transshipment))}
                    columnsSelector={true}
                    getRowKey={(r: ExciseEntryDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseEntryClient().getCurrent(this.props.isIncome, this.props.isExpenditure, this.props.isTransshipment, this.props.exciseRegisterId, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }}
                    scroll={{ y: 'calc(100vh - 470px)' }}
                />
            </>
        )
    }
};
