import React from "react";
import { Upload, message, Button, PageHeader, Row, Col, Space, Spin, notification } from "antd";
import { DownloadOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { CenteredRow } from "../layout/CenteredRow";
import { ajaxCatch, downloadFile } from "../helper/api";
import { FileUploadClient } from "./FileUploadClient";
import { ImportClient, ImportDataType, ImportFileExtension, ImportMessageType, ImportResultForm } from "../services/GeneratedClient";
import ImportResultList from "./models/ImportResultList";



const Dragger = Upload.Dragger;
interface IProps {
    extension: ImportFileExtension;
    dataType: ImportDataType;
}
interface IState {
    uploading: boolean;
    file: any | undefined;
    result: ImportResultForm | undefined;
    downloadingTemplate: boolean;
    downloadingDescription: boolean;
}
export default class ImportFile extends React.PureComponent<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            uploading: false,
            result: undefined,
            downloadingTemplate: false,
            downloadingDescription: false,
            file: undefined,
        }
    }

    handleUpload = () => {
        const { file } = this.state;
        const { extension, dataType } = this.props;

        this.setState({
            uploading: true,
            result: undefined
        });

        ajaxCatch(() =>
            new FileUploadClient().
                uploadFiles(
                    file,
                    dataType,
                    extension)!

                .then((resp: ImportResultForm) => {
                    this.setState({
                        file: undefined,
                        uploading: false,
                        result: resp
                    });
                    if (resp && resp.messageType == ImportMessageType.InProgress) {
                        notification.info({
                            placement: "bottomRight",
                            message: 'Dane są w trakcie przetwarzania, o rezultacie importu poinformujemy mailowo',
                        });
                    }
                    else if (resp && resp.messageType != ImportMessageType.Error) {
                        notification.success({
                            placement: "bottomRight",
                            message: 'Poprawnie zaimportowano zdarzenia',
                        });
                    }

                }));
    }
    private _handleTemplateDownload = () => {
        this.setState({
            downloadingTemplate: true,
        });
        const { extension, dataType } = this.props;

        new ImportClient().downloadTemplate(extension, dataType)
            .then((response) => {
                this.setState({
                    downloadingTemplate: false,
                });
                const file = new Blob(
                    [response.data],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                downloadFile(file, response.fileName!);
            })
            .catch((ex) => {
                this.setState({
                    downloadingTemplate: false,
                });
                console.log(ex);
                message.error('Wystąpił niespodziewany błąd podczas pobierania szablonu.');
            });
    }
    public get handleTemplateDownload() {
        return this._handleTemplateDownload;
    }
    public set handleTemplateDownload(value) {
        this._handleTemplateDownload = value;
    }

    private _handleDescriptionDowload = () => {
        this.setState({
            downloadingDescription: true,
        });
        const { dataType } = this.props;

        new ImportClient().downloadDescription(dataType)
            .then((response) => {
                this.setState({
                    downloadingDescription: false,
                });
                const file = new Blob(
                    [response.data],
                    { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

                downloadFile(file, response.fileName!);
            })
            .catch((ex) => {
                this.setState({
                    downloadingDescription: false,
                });
                console.log(ex);
                message.error('Wystąpił niespodziewany błąd podczas pobierania opisu.');
            });
    }
    public get handleDescriptionDowload() {
        return this._handleDescriptionDowload;
    }
    public set handleDescriptionDowload(value) {
        this._handleDescriptionDowload = value;
    }

    public render() {
        const { uploading, file, downloadingTemplate, downloadingDescription } = this.state;
        const { extension, dataType } = this.props;
        let pageHeader = `Import ewidencji ${dataType == ImportDataType.ExciseEnergy ? 'energii elektrycznej ' : ''}${ImportFileExtension[extension]}`

        const draggerProps = {
            accept: `.${ImportFileExtension[extension]}`,
            name: 'file',
            maxCount: 1,
            onRemove: (file: any) => {
                this.setState((state) => {
                    return {
                        file: file,
                    };
                });
            },
            beforeUpload: (file: any) => {
                let fileExtension = file.name.split(".").pop();
                const isCorrectFormat = fileExtension.toUpperCase() === `${ImportFileExtension[extension]}`
                if (!isCorrectFormat) {
                    notification.error({
                        placement: "bottomRight",
                        message: `${file.name} nie jest plikiem w formacie ${ImportFileExtension[extension]}`,
                    });
                    return false;
                }
                this.setState(state => ({
                    file: file,
                }));
                return false;
            },
            file,
        };

        return (
            <>
                <PageHeader title={`${pageHeader}`} />
                <CenteredRow style={{ display: "block" }}>
                    <Col span={24}>
                        <Dragger {...draggerProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Wciśnij lub przeciągnij tu plik {ImportFileExtension[extension]}
                            </p>
                        </Dragger>
                    </Col>
                </CenteredRow>
                <CenteredRow>
                    <Col>
                        <Space>
                            {uploading && (<Spin></Spin>)}
                            <Button

                                type="primary"
                                onClick={this.handleUpload}
                                disabled={!this.state.file}
                                loading={uploading}
                                style={{}}
                            >
                                <UploadOutlined />
                                {uploading ? "Importowanie" : "Importuj"}
                            </Button>
                            <Button
                                type="primary"
                                onClick={this.handleTemplateDownload}
                                loading={downloadingTemplate}
                            >
                                <DownloadOutlined />
                                {downloadingTemplate ? 'Pobieranie' : 'Pobierz szablon'}
                            </Button>
                            <Button
                                type="primary"
                                onClick={this.handleDescriptionDowload}
                                loading={downloadingDescription}
                            >
                                <DownloadOutlined />
                                {downloadingDescription ? 'Pobieranie' : 'Pobierz opis szablonu'}
                            </Button>
                        </Space>

                    </Col>
                </CenteredRow>
                {/* </Space> */}
                <Row align="middle"></Row>
                {
                    this.state.result && <CenteredRow>
                        <Col>

                            <ImportResultList
                                key={"importResult"}
                                importedRecords={this.state.result.records!}
                                globalResult={this.state.result.messageType}
                            />

                        </Col>
                    </CenteredRow>
                }
            </>
        );
    }
}
