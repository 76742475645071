import * as React from "react";
import {
    UploadOutlined,
    DesktopOutlined,
    CompassOutlined,
    FileDoneOutlined,
    HourglassOutlined,
    PieChartOutlined,
    UnorderedListOutlined,
    UserOutlined,
    AuditOutlined,
    KeyOutlined,
    FileExcelOutlined,
    FileTextOutlined,
    TableOutlined,
} from "@ant-design/icons";
import { Layout, Menu, BackTop, Tooltip } from "antd";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import TopBar from "./TopBar";
import PermissionValidator from "../authorization/PermissionValidator";
import { inject, observer } from "mobx-react";
import { AccountDetailsStore } from "account/AccountDetailsStore";
import {
    CompanyLevelPermission, EwdPlaceLevelPermission,
} from "services/GeneratedClient";
import { LinkWithConfirmation } from "formChange/LinkWithConfirmation";
import { FormChangeStore } from "formChange/FormChangeStore";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

interface IProps extends RouteComponentProps {
    platformUrl: string | undefined;
    accountDetailsStore?: AccountDetailsStore;
    formChangeStore?: FormChangeStore;
}
type TOwnProps = RouteComponentProps;

@inject("accountDetailsStore")
@inject("formChangeStore")
@observer
class AppLayout extends React.Component<IProps & TOwnProps> {
    state = {
        collapsed: false,
    };

    onCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    constructor(props: IProps & TOwnProps) {
        super(props);
    }

    componentDidUpdate(): void {
        this.props.formChangeStore?.setChangeDetectionStatus(false);
    }

    render() {
        let userCompany = this.props.accountDetailsStore!.currentCompany;
        let permissionValidator = new PermissionValidator(userCompany!);
        let moduleActive = permissionValidator.isModuleActive();

        const homePageKey = "/";
        return (
            <Layout>
                <Sider
                    style={{ minHeight: '100vh', left: 0 }}
                    width="220px"
                    collapsible={true}
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                >
                    <div className="logo">
                        <a href={this.props.platformUrl}>
                            <img
                                src="pentaTAX_gg.png"
                                style={{
                                    marginLeft: "auto",
                                    paddingTop: "15px",
                                    marginRight: "auto",
                                    marginBottom: "20px",
                                    display: "block",
                                    width: "80%",
                                }}
                                alt=""
                            />
                        </a>
                        <img src="ewidencje_akcyzowe_tekst.svg"
                            style={{
                                maxWidth: "140px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginBottom: "5px",
                                display: "block"
                            }} />
                    </div>

                    <Menu
                        theme="dark"
                        defaultOpenKeys={this.getDefaultOpenKeys()}
                        selectedKeys={[
                            this.props.location.pathname.toLowerCase() === "/"
                                ? homePageKey
                                : this.props.location.pathname.toLowerCase(),
                        ]}
                        mode="inline"
                    >
                        {(permissionValidator.isAdmin() ||
                            permissionValidator.hasCompanyLevelPermission(
                                CompanyLevelPermission.EwdAdministration
                            ) ||
                            permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                EwdPlaceLevelPermission.ExciseRegisterDisplay
                            )) && (
                                <Menu.Item key="/exciseregisters">
                                    <LinkWithConfirmation to="/ExciseRegisters">
                                        <PieChartOutlined />
                                        <span>Ewidencje akcyzowe</span>
                                    </LinkWithConfirmation>
                                </Menu.Item>
                            )}

                        {(permissionValidator.isAdmin() ||
                            permissionValidator.hasCompanyLevelPermission(
                                CompanyLevelPermission.EwdAdministration
                            ) ||
                            permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                EwdPlaceLevelPermission.ExciseStampRegisterDisplay
                            )) && (
                                <Menu.Item key="/excisestampregisters">
                                    <LinkWithConfirmation to="/ExciseStampRegisters">
                                        <PieChartOutlined />
                                        <span>Ewidencje banderol</span>
                                    </LinkWithConfirmation>
                                </Menu.Item>
                            )}

                        {(permissionValidator.isAdmin() ||
                            permissionValidator.hasCompanyLevelPermission(
                                CompanyLevelPermission.EwdAdministration
                            ) ||
                            permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                EwdPlaceLevelPermission.ExciseEnergyRegisterDisplay
                            )) &&
                            <Menu.Item key="/exciseenergies/registers">
                                <Tooltip title="Ewidencje energii elektrycznej" placement="right">
                                    <LinkWithConfirmation to="/ExciseEnergies/Registers">
                                        <PieChartOutlined />
                                        <span>Ewidencje energii elektrycznej</span>
                                    </LinkWithConfirmation>
                                </Tooltip>
                            </Menu.Item>}

                        {moduleActive &&
                            (permissionValidator.isAdmin() ||
                                permissionValidator.hasCompanyLevelPermission(
                                    CompanyLevelPermission.EwdAdministration
                                ) ||
                                permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                    EwdPlaceLevelPermission.ExciseRegisterEntryImport
                                )) && (
                                <Menu.SubMenu
                                    key="imports"
                                    title={
                                        <>
                                            <UploadOutlined />
                                            <span>Importy</span>{" "}
                                        </>
                                    }
                                >
                                    <Menu.Item key="/csvimport">
                                        <LinkWithConfirmation to="/CsvImport">
                                            <FileTextOutlined />
                                            <span>Import CSV ewidencji</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    <Menu.Item key="/xlsximport">
                                        <LinkWithConfirmation to="/XlsxImport">
                                            <FileExcelOutlined />
                                            <span>Import XLSX ewidencji</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    <Menu.Item key="/csvimportenergies">
                                        <LinkWithConfirmation to="/CsvImportEnergies">
                                            <FileTextOutlined />
                                            <span>Import CSV energii elektrycznej</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    <Menu.Item key="/xlsximportenergies">
                                        <LinkWithConfirmation to="/XlsxImportEnergies">
                                            <FileExcelOutlined />
                                            <span>Import XLSX energii elektrycznej</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                </Menu.SubMenu>

                            )}
                        {(permissionValidator.isAdmin() ||
                            permissionValidator.hasCompanyLevelPermission(
                                CompanyLevelPermission.EwdAdministration
                            ) ||
                            permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                EwdPlaceLevelPermission.ExciseRegisterEntryAwaitingDisplayAndAssigning
                            )) && (
                                <Menu.Item key="/exciseentries/awaiting">
                                    <LinkWithConfirmation to="/ExciseEntries/Awaiting">
                                        <HourglassOutlined />
                                        <span>Poczekalnia</span>
                                    </LinkWithConfirmation>
                                </Menu.Item>
                            )}

                        {(permissionValidator.isAdmin() ||
                            permissionValidator.hasCompanyLevelPermission(
                                CompanyLevelPermission.EwdAdministration
                            ) ||
                            permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                EwdPlaceLevelPermission.ExciseRegisterReportDisplay
                            ) ||
                            permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                EwdPlaceLevelPermission.ExciseStampRegisterReportDisplay
                            ) ||
                            permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                EwdPlaceLevelPermission.ExciseEnergyRegisterReportDisplay
                            )) && (
                                <Menu.SubMenu
                                    key="reports"
                                    title={
                                        <>
                                            <FileDoneOutlined />
                                            <span>Raporty</span>{" "}
                                        </>
                                    }
                                >
                                    {permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                        EwdPlaceLevelPermission.ExciseRegisterReportDisplay) &&
                                        <Menu.Item key="/reports/exciseregisters">
                                            <LinkWithConfirmation to="/Reports/ExciseRegisters">
                                                <FileDoneOutlined />
                                                <span>Ewidencje akcyzowe</span>
                                            </LinkWithConfirmation>
                                        </Menu.Item>}
                                    {permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                        EwdPlaceLevelPermission.ExciseStampRegisterReportDisplay) &&
                                        <Menu.Item key="/reports/excisestampregisters">
                                            <LinkWithConfirmation to="/Reports/ExciseStampRegisters">
                                                <FileDoneOutlined />
                                                <span>Ewidencje banderol</span>
                                            </LinkWithConfirmation>
                                        </Menu.Item>}
                                    {permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                        EwdPlaceLevelPermission.ExciseEnergyRegisterReportDisplay) &&
                                        <Menu.Item key="/reports/exciseenergyregisters">
                                            <Tooltip title="Ewidencje energii elektrycznej" placement="right">
                                                <LinkWithConfirmation to="/Reports/ExciseEnergyRegisters">
                                                    <FileDoneOutlined />
                                                    <span>Ewidencje energii elektrycznej</span>
                                                </LinkWithConfirmation>
                                            </Tooltip>
                                        </Menu.Item>}
                                    {permissionValidator.hasPlaceLevelPermissionInAnyPlace(
                                        EwdPlaceLevelPermission.ExciseRegisterReportDisplay) &&
                                        <Menu.Item key="/reports/dailyreports">
                                            <Tooltip title="Wydruki jednodniowe" placement="right">
                                                <LinkWithConfirmation to="/Reports/DailyReports">
                                                    <TableOutlined />
                                                    <span>Wydruki jednodniowe</span>
                                                </LinkWithConfirmation>
                                            </Tooltip>
                                        </Menu.Item>
                                    }
                                </Menu.SubMenu>
                            )}

                        {(permissionValidator.isAdmin() ||
                            permissionValidator.hasCompanyLevelPermission(
                                CompanyLevelPermission.EwdAdministration
                            )) && (
                                <Menu.SubMenu
                                    key="administration"
                                    title={
                                        <>
                                            <UserOutlined />
                                            <span>Administracja</span>{" "}
                                        </>
                                    }
                                >
                                    <Menu.Item key="/admin/places">
                                        <LinkWithConfirmation to="/Admin/Places">
                                            <CompassOutlined />
                                            <span>Miejsca</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/exciseregisters">
                                        <LinkWithConfirmation to="/Admin/ExciseRegisters">
                                            <DesktopOutlined />
                                            <span>Ewidencje akcyzowe</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/excisestampregisters">
                                        <LinkWithConfirmation to="/Admin/ExciseStampRegisters">
                                            <DesktopOutlined />
                                            <span>Ewidencje banderol</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>

                                    <Menu.Item key="/admin/exciseenergies/registers">
                                        <Tooltip title="Ewidencje energii elektrycznej" placement="right">
                                            <LinkWithConfirmation to="/Admin/ExciseEnergies/Registers">
                                                <DesktopOutlined />
                                                <span>Ewidencje energii elektrycznej</span>
                                            </LinkWithConfirmation>
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item key="/admin/dictionaries">
                                        <LinkWithConfirmation to="/Admin/Dictionaries">
                                            <UnorderedListOutlined />
                                            <span>Słowniki</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/auditlogs">
                                        <LinkWithConfirmation to="/Admin/AuditLogs">
                                            <AuditOutlined />
                                            <span>Lista czynności</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/companyusers">
                                        <LinkWithConfirmation to="/Admin/CompanyUsers">
                                            <KeyOutlined />
                                            <span>Uprawnienia</span>
                                        </LinkWithConfirmation>
                                    </Menu.Item>
                                    {
                                        permissionValidator.hasLicencePermission("CnToKwaMappingDictionary") &&
                                        <Menu.Item key="/admin/cnkwadictionaries">
                                            <LinkWithConfirmation to="/Admin/CnKwaDictionaries">
                                                <KeyOutlined />
                                                <span>{"Słownik CN->KWA"}</span>
                                            </LinkWithConfirmation>
                                        </Menu.Item>
                                    }
                                </Menu.SubMenu>
                            )}
                    </Menu>
                </Sider>

                <Layout style={{ minHeight: "100vh" }}>
                    <BackTop />

                    <TopBar onCompanyChange={() => {
                        this.props.history.push('/');
                    }} />
                    <Content style={{ overflow: "initial" }}>
                        <div style={{ padding: 0, minHeight: 360 }}>
                            {this.props.children}
                        </div>
                    </Content>
                    <Footer style={{ textAlign: "center" }}>
                        Pentacomp Systemy Informatyczne S.A. ©2018 -{" "}
                        {new Date().getFullYear()}
                    </Footer>
                </Layout>
            </Layout>
        );
    }

    private getDefaultOpenKeys(): string[] | undefined {
        if (this.props.location.pathname.toLowerCase().startsWith("/admin"))
            return ["administration"];
        else if (
            this.props.location.pathname.toLowerCase().startsWith("/report")
        )
            return ["reports"];
        return undefined;
    }
}

export default withRouter(AppLayout);
