import React from "react";
import { inject, observer } from "mobx-react";
import { AccountDetailsStore } from "../account/AccountDetailsStore";
import { CompanyLevelPermission, EwdPlaceLevelPermission } from "services/GeneratedClient";
import { Route } from "react-router";
import UnauthorizedView from './UnathorizedView';
import PermissionValidator from "./PermissionValidator";
import FadingTooltip from "layout/FadingTooltip";
import { showErrorModal } from "layout/Modals";

interface IProps {
    placeContext?: string;
    placeLevelPermission?: EwdPlaceLevelPermission;
    placeLevelPermissionInAnyPlace?: EwdPlaceLevelPermission;
    companyLevelPermission?: CompanyLevelPermission;
    licencePermission?: string;
    admin?: boolean;
    showDisabled?: boolean;
    any?: boolean;
    requiresSubscription?: boolean;
    accountDetailsStore?: AccountDetailsStore;
    showModal?: boolean;
}

@inject("accountDetailsStore")
@observer
export class Authorized extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        let authorized = this.isAuthorized();

        return <>
            {!authorized && this.props.showModal &&
                showErrorModal("Nie posiadasz uprawnień do modułu ewidencji. Skontaktuj się z administratorem swojej firmy w celu nadania stosownych uprawnień.")
            }
            {authorized && this.props.children}
            {!authorized && this.props.showDisabled &&
                <FadingTooltip title="Nie posiadasz wystarczających uprawnień">
                    <span style={{ cursor: "not-allowed" }}>
                        <span style={{ pointerEvents: "none" }}>
                            {this.props.children}
                        </span>
                    </span>
                </FadingTooltip>
            }
            {!authorized && !this.props.showDisabled && this.props.children && React.Children.map(this.props.children, (child) => {
                if (React.isValidElement(child) && child.type === Route) {
                    return <Route {...child.props} component={UnauthorizedView} />
                } else {
                    return undefined
                }
            })}
        </>
    }

    private isAuthorized() {
        let userCompany = this.props.accountDetailsStore!.currentCompany;

        if (!userCompany) return false;
        if (this.props.any && userCompany) return true;

        if (this.props.requiresSubscription && !userCompany.isModuleActive)
            return false;

        let permissionValidator = new PermissionValidator(userCompany);

        if (this.props.licencePermission !== undefined) {
            return permissionValidator.hasLicencePermission(this.props.licencePermission)
        }

        if(this.props.admin !== undefined) {
            if (permissionValidator.isAdmin()) return true;
        }

        if (this.props.companyLevelPermission !== undefined && permissionValidator.hasCompanyLevelPermission(this.props.companyLevelPermission))
            return true;

        if (this.props.placeLevelPermission !== undefined && permissionValidator.hasPlaceLevelPermission(this.props.placeLevelPermission, this.props.placeContext!))
            return true;

        if (this.props.placeLevelPermissionInAnyPlace !== undefined) {
            if (permissionValidator.hasPlaceLevelPermissionInAnyPlace(this.props.placeLevelPermissionInAnyPlace))
                return true;
        }

        if(permissionValidator.isAdmin() || permissionValidator.hasCompanyLevelPermission(CompanyLevelPermission.EwdAdministration)) 
            return true;

        return false;
    }

}