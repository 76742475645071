import React, { PropsWithChildren } from 'react';
import { FrownOutlined } from '@ant-design/icons';

const Span = ({ fontSize, children }: PropsWithChildren<{ fontSize?: string | undefined }>) =>
    <span style={{ fontSize: fontSize || "48px", display: "block", marginBottom: "16px" }} >{children}</span>

const ErrorPageInternal = ({ statusCode, description }: { statusCode: string, description: string }) =>
    <div style={{ textAlign: "center", marginTop: "32px" }}>
        <Span><FrownOutlined style={{ fontSize: "128px" }} /></Span>
        <Span fontSize="72px"><b>{statusCode}</b></Span>
        <Span fontSize="32px">{description}</Span>
    </div>

const ClientError = ({ statusCode }: { statusCode: string }) =>
    <ErrorPageInternal statusCode={statusCode} description="Żądany zasób nie został znalezioniony lub nie masz do niego dostępu" />;
const ServerError = ({ statusCode }: { statusCode: string }) =>
    <ErrorPageInternal statusCode={statusCode} description="Wystąpił niespodziewany błąd. Skontaktuj się z administratorem." />;

export const getErrorPage = (statusCode: number) => {

    if (statusCode > 400 && statusCode < 500) {
        return <ClientError statusCode={statusCode.toString()} />
    } else {
        return <ServerError statusCode={statusCode.toString()} />
    }
}

export const ErrorPage = ({ statusCode }: { statusCode: number }) => getErrorPage(statusCode);



