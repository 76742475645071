import * as React from 'react';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';

interface IProps {
    size?: SpinSize;
    children?: React.ReactNode;
}

export const CenteredSpin = (props = { size: "large" as SpinSize } as IProps) =>
    <div style={{display: "flex", padding: 64, justifyContent: "center", alignContent: "center"}}>
        <Spin size={props.size} children={props.children} />
    </div>
