import { Button, Col, Select } from "antd";
import { inject, observer } from "mobx-react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { PageHeader } from "layout/PageHeader";
import PrimaryCard from "../layout/PrimaryCard";
import { CreateExciseRegisterCmd, DictionaryName, ExciseRegisterClient, ExciseRegisterDto } from "services/GeneratedClient";
import { FCenteredRow, FDictionaryAutoCompleteWithTooltip, FDictionarySelect, FFieldLabel, FInput, FSelect } from "forms/FormikFormItems";
import { ajaxByUser } from "helper/api";
import ExciseRegisterValidation from "./ExciseRegisterValidation";
import { RegisterStore } from "register/RegisterStore";
import { GridStore } from "layout/table/paginated/GridStore";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
    dictionaryStore?: DictionaryStore;
    registerStore?: RegisterStore;
    gridStore?: GridStore;
}

interface IState {
    isLoading: boolean;
    gridName?: string;
}

@inject("dictionaryStore")
@inject("registerStore")
@inject("gridStore")
@observer
export default class ExciseRegisterCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/ExciseRegisters`;
    private validation: ExciseRegisterValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            gridName: this.props.gridStore?.getGridNameForLastUsedRowId(),
        };

        this.validation = new ExciseRegisterValidation();
    }

    public send(values: ExciseRegisterDto, actions: FormikHelpers<ExciseRegisterDto>) {
        values.controlOfNegativeStates = true;
        this.setState({ isLoading: true })
        let cmd = new CreateExciseRegisterCmd({
            exciseRegisterDto: values
        });
        ajaxByUser("Dodano ewidencję", () =>
            new ExciseRegisterClient().create(cmd)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, resp.toString());
                    this.props.history.push(this.goBackUrl);
                }).finally(() => this.setState({ isLoading: false })));
    }

    public render() {
        return (
            <>
                <PageHeader title={`TWORZENIE EWIDENCJI AKCYZOWEJ`} />
                <Formik<ExciseRegisterDto> initialValues={new ExciseRegisterDto()}
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<ExciseRegisterDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Nazwa" sm={24} />
                                    <Field component={FInput} name="name" />
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Miejsce" sm={24} />
                                    <Field component={FSelect} name="placeId">
                                        {this.props.registerStore?.allSubjectPlaces?.map((r, index) =>
                                            <Option key={index} value={r.id?.toString() || ""}>{`${r.name} (${r.streetName} ${r.streetNumber} ${r.city})`}</Option>
                                        )}
                                    </Field>
                                </Col>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Kod CN wyrobu" sm={24} />
                                    <Field component={FDictionaryAutoCompleteWithTooltip}
                                        name="cnCode"
                                        dictionaryName={DictionaryName.CnCode} />
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Jednostka miary akcyzowej" sm={24} />
                                    <Field component={FDictionarySelect}
                                        name="exciseUnitOfMeasure"
                                        dictionaryName={DictionaryName.UnitOfMeasure} />
                                </Col>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Jednostka miary techniczna" sm={24} />
                                    <Field component={FDictionarySelect}
                                        name="inventoryUnitOfMeasure"
                                        dictionaryName={DictionaryName.UnitOfMeasure} />
                                </Col>
                            </FCenteredRow>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                    props.submitForm();
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}
