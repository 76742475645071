import React, { createRef } from 'react';
import { EwdColumnProps, dateRenderer } from 'helper/GridHelper';
import {  CompanyLevelPermission, CorrectiveExciseStampEntryHeaderDto, DictionaryName, EwdPlaceLevelPermission, ExciseStampActionType, ExciseStampEntryClient, ExciseStampEntryHeaderDto, ExciseStampRegisterStateClient } from 'services/GeneratedClient';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from "layout/FadingTooltip";
import { DiffOutlined, DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { Moment } from 'moment';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { Authorized } from 'authorization/Authorized';

interface IProps {
    dictionaryStore?: DictionaryStore;
    exciseStampRegisterId: number;
    registerType: string;
    dateFrom: Moment;
    dateTo: Moment;
}

interface IState {
    gridName: string;
    expandedRowKeys?: React.Key[]
}


@inject("dictionaryStore")
export default class CompletedExciseStampEntryList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "completedExciseStampEntryGrid"
        }
    }


    componentDidMount() {

    }
    

    componentDidUpdate = (prevProps:IProps, prevState:IState) => {
        if(prevProps.dateFrom !== this.props.dateFrom || prevProps.dateTo !== this.props.dateTo) {
            this.paginatedTable.current?.refresh();
        }
    }

//#region Corrective Table Columns

expandableConfig(): ExpandableConfig<ExciseStampEntryHeaderDto> {
    return {
        rowExpandable: (record: ExciseStampEntryHeaderDto) => record.correctedEntries?.length !== 0,
        expandedRowRender: (record: ExciseStampEntryHeaderDto) => {
            const columns: (EwdColumnProps<CorrectiveExciseStampEntryHeaderDto>)[] = [
                {
                    key: "actions",
                    render: (text: string, record: CorrectiveExciseStampEntryHeaderDto) => (
                        <>
                            {record.id && <>
                                <FadingTooltip title="Podgląd" placement="top">
                                    <Link to={`/ExciseStampEntries/Preview/${record.id}`} >
                                        <TableButton gridName={gridName} recordId={record.id} icon={<SearchOutlined />} />
                                    </Link>
                                </FadingTooltip></>}
                        </>
                    ),
                },
                {
                    dataIndex: "id",
                    key: "Id",
                    title: "Id",
                },
                {
                    dataIndex: "entryDate",
                    key: "EntryDate",
                    title: "Data wpisu",
                    render: (text: any, record: CorrectiveExciseStampEntryHeaderDto) => dateRenderer(record.entryDate),
                    width: 90,
                },
                {
                    dataIndex: "correctionOrdinalNumber",
                    key: "CorrectionOrdinalNumber",
                    title: "Lp. Korekty",
                },
                {
                    dataIndex: "correctionReason",
                    key: "CorrectionReason",
                    title: "Przyczyna korekty",
                },
                {
                    dataIndex: "sourceIdentifier",
                    key: "SourceIdentifier",
                    title: "Identyfikator źródłowy",
                },
                {
                    dataIndex: "sendIdentifier",
                    key: "SendIdentifier",
                    title: "Identyfikator wysyłki",
                },
                {
                    dataIndex: "paragraphType",
                    key: "ParagraphType",
                    title: "Rodzaj paragrafu"
                },
                {
                    dataIndex: "status",
                    key: "Status",
                    render: (text: any, record: CorrectiveExciseStampEntryHeaderDto) => {
                        return this.props.dictionaryStore!.getDisplay(DictionaryName.ExciseStampEntryStatus, record.status);
                    },
                    title: "Status"
                },
                {
                    dataIndex: "itemsQuantity",
                    key: "ItemsQuantity",
                    title: "Wpływ na stan banderol",
                    render: (quantity: number, record: CorrectiveExciseStampEntryHeaderDto) => {
                        if (quantity >= 0) return quantity 
                        else return <span style={{ color: 'red' }}>{quantity}</span>
                    }
                },
                {
                    dataIndex: "itemsInRegisterQuantity",
                    key: "ItemsInRegisterQuantity",
                    title: "Wpływ na stan banderol w siedzibie",
                    render: (quantity: number, record: CorrectiveExciseStampEntryHeaderDto) => {
                        if (quantity >= 0) return quantity 
                        else return <span style={{ color: 'red' }}>{quantity}</span>
                    }
                },
                {
                    dataIndex: "itemsOutOfRegisterQuantity",
                    key: "ItemsOutOfRegisterQuantity",
                    title: "Wpływ na stan banderol poza siedzibą",
                    render: (quantity: number, record: CorrectiveExciseStampEntryHeaderDto) => {
                        if (quantity >= 0) return quantity 
                        else return <span style={{ color: 'red' }}>{quantity}</span>
                    }
                },
                {
                    dataIndex: "itemsAppliedQuantity",
                    key: "ItemsAppliedQuantity",
                    title: "Wpływ na stan banderol naniesionych",
                    render: (quantity: number, record: CorrectiveExciseStampEntryHeaderDto) => {
                        if (quantity >= 0) return quantity 
                        else return <span style={{ color: 'red' }}>{quantity}</span>
                    }
                },
                {
                    dataIndex: "itemsDamagedQuantity",
                    key: "ItemsDamagedQuantity",
                    title: "Wpływ na stan banderol uszkodzonych",
                    render: (quantity: number, record: CorrectiveExciseStampEntryHeaderDto) => {
                        if (quantity >= 0) return quantity 
                        else return <span style={{ color: 'red' }}>{quantity}</span>
                    }
                },
                {
                    dataIndex: "itemsLostQuantity",
                    key: "ItemsLostQuantity",
                    title: "Wpływ na stan banderol utraconych",
                    render: (quantity: number, record: CorrectiveExciseStampEntryHeaderDto) => {
                        if (quantity >= 0) return quantity 
                        else return <span style={{ color: 'red' }}>{quantity}</span>
                    }
                },
                {
                    dataIndex: "itemsDestroyedQuantity",
                    key: "ItemsDestroyedQuantity",
                    title: "Wpływ na stan banderol zniszczonych",
                    render: (quantity: number, record: CorrectiveExciseStampEntryHeaderDto) => {
                        if (quantity >= 0) return quantity 
                        else return <span style={{ color: 'red' }}>{quantity}</span>
                    }
                },
            ]

            const data: CorrectiveExciseStampEntryHeaderDto[] | undefined = record.correctedEntries;
            const gridName = "corrective_completedExciseStampEntryGrid"

            return <PaginatedTable<CorrectiveExciseStampEntryHeaderDto>
                gridName={gridName}
                pagination={false}
                columns={columns}
                columnsSelector={true}
                dataSource={data}
                getRowKey={(r: CorrectiveExciseStampEntryHeaderDto) => r.id!.toString()}
                getPagedResult={() => new Promise((resolve, reject) => { resolve({ results: data }) })}
            />
        },
        expandedRowKeys: this.state.expandedRowKeys,
        expandIconColumnIndex: -1
    }
};

//#endregion Corrective Table Columns


//#region Main Table Columns

    private columns: (EwdColumnProps<ExciseStampEntryHeaderDto>)[] = [
        {
            key: "actions",
            render: (text: string, record: ExciseStampEntryHeaderDto) => (
                <>
                    {record.id && <>
                        <FadingTooltip title="Podgląd" placement="top">
                            <Link to={`/ExciseStampEntries/Preview/${record.id}`} >
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                            </Link>
                        </FadingTooltip></>}
                    {record.actions?.includes(ExciseStampActionType.Correcting) && !record.isSupplyEntry &&
                    <Authorized placeLevelPermissionInAnyPlace={EwdPlaceLevelPermission.ExciseStampRegisterEntryCorrecting}>
                        <FadingTooltip title="Korekta" placement="top">
                            <Link to={`/ExciseStampRegisters/${this.props.exciseStampRegisterId}/${this.props.registerType}/ExciseStampEntries/Correct/${record.id}`} >
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<DiffOutlined />} />
                            </Link>
                        </FadingTooltip>
                    </Authorized>}
                    {record.correctedEntries?.length !== 0 && <>
                        {this.state.expandedRowKeys && this.state.expandedRowKeys.includes(record.id!.toString())
                            ? <FadingTooltip title="Ukryj korekty">
                                <TableButton type="default" icon={<DownOutlined />} onClick={() => {
                                    const remove = this.state.expandedRowKeys!.indexOf(record.id!.toString());
                                    this.setState({
                                        expandedRowKeys: (this.state.expandedRowKeys || []).filter((_, i) => i !== remove)
                                    })
                                }}
                                />
                            </FadingTooltip>
                            : <FadingTooltip title="Pokaż korekty">
                                <TableButton type="default" icon={<RightOutlined />} onClick={() => {
                                    this.setState({
                                        expandedRowKeys: [...(this.state.expandedRowKeys || []), record.id!.toString()]
                                    })
                                }}
                                />
                            </FadingTooltip>
                        }
                    </>}
                </>
            ),
            title: "Czynności",
            width: 140,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            width: 80,
        },
        {
            dataIndex: "ordinalNumber",
            key: "OrdinalNumber",
            title: "Lp.",
            filter: "equals",
        },
        {
            dataIndex: "correctionOrdinalNumber",
            key: "CorrectionOrdinalNumber",
            title: "Lp. Korekty",
            filter: "equals",
        },
        {
            dataIndex: "entryDate",
            key: "EntryDate",
            title: "Data wpisu",
            render: (text: any, record: ExciseStampEntryHeaderDto) => dateRenderer(record.entryDate),
            width: 90,
        },
        {
            dataIndex: "sourceIdentifier",
            key: "SourceIdentifier",
            title: "Identyfikator źródłowy",
        },
        {
            dataIndex: "paragraphType",
            key: "ParagraphType",
            title: "Rodzaj paragrafu"
        },
        {
            dataIndex: "status",
            key: "Status",
            render: (text: any, record: ExciseStampEntryHeaderDto) => {
                return this.props.dictionaryStore!.getDisplay(DictionaryName.ExciseStampEntryStatus, record.status);
            },
            title: "Status"
        },
        {
            dataIndex: "itemsQuantity",
            key: "ItemsQuantity",
            title: "Wpływ na stan banderol",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsInRegisterQuantity",
            key: "ItemsInRegisterQuantity",
            title: "Wpływ na stan banderol w siedzibie",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsOutOfRegisterQuantity",
            key: "ItemsOutOfRegisterQuantity",
            title: "Wpływ na stan banderol poza siedzibą",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsAppliedQuantity",
            key: "ItemsAppliedQuantity",
            title: "Wpływ na stan banderol naniesionych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsDamagedQuantity",
            key: "ItemsDamagedQuantity",
            title: "Wpływ na stan banderol uszkodzonych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsLostQuantity",
            key: "ItemsLostQuantity",
            title: "Wpływ na stan banderol utraconych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "itemsDestroyedQuantity",
            key: "ItemsDestroyedQuantity",
            title: "Wpływ na stan banderol zniszczonych",
            render: (quantity: number, record: ExciseStampEntryHeaderDto) => {
                if (quantity >= 0) return quantity 
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
    ];

//#endregion Main Table Columns

    private paginatedTable = createRef<PaginatedTable<ExciseStampEntryHeaderDto>>();
    public render() {
        return (
            <>
                <PaginatedTable<ExciseStampEntryHeaderDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    expandable={this.expandableConfig()}
                    topSummary={{label: "Stan na początek okresu", getData: () => { return new ExciseStampRegisterStateClient().getInitialState(this.props.exciseStampRegisterId, this.props.dateFrom) }}}
                    botSummary={{label: "Podsumowanie", getData: () => { return new ExciseStampRegisterStateClient().getSummarizingState(this.props.exciseStampRegisterId, this.props.dateFrom, this.props.dateTo) }}}
                    getRowKey={(r: ExciseStampEntryHeaderDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseStampEntryClient().getClosed(this.props.exciseStampRegisterId, this.props.dateFrom, this.props.dateTo, sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }}
                    scroll={{ y: 'calc(100vh - 550px)' }}
                    />
            </>
        )
    }
};