import { Button, Col, Select } from "antd";
import { inject, observer } from "mobx-react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { PageHeader } from "layout/PageHeader";
import { SaveExciseStampRegisterCmd, DictionaryName, ExciseStampRegisterClient, ExciseStampRegisterDto, ExciseStampRegisterType } from "services/GeneratedClient";
import { FCenteredRow, FDictionarySelect, FDictionarySelectWithTooltip, FFieldLabel, FInput, FSelect } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import ExciseStampRegisterValidation from "./ExciseStampRegisterValidation";
import { RegisterStore } from "register/RegisterStore";
import PrimaryCard from "layout/PrimaryCard";
import { GridStore } from "layout/table/paginated/GridStore";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
    dictionaryStore?: DictionaryStore;
    registerStore?: RegisterStore;
    gridStore?: GridStore;
    exciseStampRegisterId?: number;
}

interface IState {
    editState: boolean;
    initialValues: ExciseStampRegisterDto;
    isLoading: boolean;
    gridName?: string;
}

@inject("dictionaryStore")
@inject("registerStore")
@inject("gridStore")
@observer
export default class ExciseStampRegisterCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/ExciseStampRegisters`;
    private validation: ExciseStampRegisterValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            editState: this.props.exciseStampRegisterId ? true : false,
            initialValues: new ExciseStampRegisterDto(),
            isLoading: false,
            gridName: this.props.gridStore?.getGridNameForLastUsedRowId(),
        };

        this.validation = new ExciseStampRegisterValidation();
    }

    componentDidMount() {
        if (this.state.editState) {
            this.fetchExciseStampRegister();
        }
    }

    private fetchExciseStampRegister() {
        ajaxCatch(() =>
            new ExciseStampRegisterClient().get(this.props.exciseStampRegisterId!),
            (result: ExciseStampRegisterDto | null) => {
                this.setState({
                    initialValues: result!,
                })
            });
    }

    public send(values: ExciseStampRegisterDto, actions: FormikHelpers<ExciseStampRegisterDto>) {
        this.setState({ isLoading: true });
        let cmd = new SaveExciseStampRegisterCmd({
            exciseStampRegisterDto: values
        });

        ajaxByUser(this.state.editState ? "Zaktualizowano ewidencję" : "Dodano ewidencję", () =>
            new ExciseStampRegisterClient().save(cmd)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    if (!this.state.editState) {
                        this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, resp.toString());
                    }
                    this.props.history.push(this.goBackUrl);
                }).finally(() => this.setState({ isLoading: false })));
    }

    public render() {
        return (
            <>
                <PageHeader title={this.state.editState ? 'EDYCJA EWIDENCJI BANDEROL' : 'TWORZENIE EWIDENCJI BANDEROL'} />
                <Formik<ExciseStampRegisterDto> initialValues={this.state.initialValues} enableReinitialize
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<ExciseStampRegisterDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Nazwa" sm={24} />
                                    <Field component={FInput} name="name" />
                                </Col>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Miejsce" sm={24} />
                                    <Field component={FSelect} name="placeId">
                                        {this.props.registerStore?.allSubjectPlaces?.map((r, index) =>
                                            <Option key={index} value={r.id?.toString() || ""}>{`${r.name} (${r.streetName} ${r.streetNumber} ${r.city})`}</Option>
                                        )}
                                    </Field>
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Typ" />
                                    <Field component={FDictionarySelect}
                                        name="exciseStampRegisterType"
                                        dictionaryName={DictionaryName.ExciseStampRegisterType} />
                                </Col>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Rodzaj" sm={24} />
                                    <Field component={FDictionarySelectWithTooltip}
                                        name="exciseStampType"
                                        dictionaryName={DictionaryName.ExciseStampType}
                                        onChange={(v: any, o: any) => {
                                            props.setFieldValue("ownerDetails", undefined)
                                        }} />
                                </Col>
                                {props.values.exciseStampRegisterType == ExciseStampRegisterType[ExciseStampRegisterType.Paragraph25] && <Col md={6} xs={24}>
                                    <FFieldLabel label="Dane właściela" sm={24} />
                                    <Field component={FInput} name="ownerDetails" />
                                </Col>}
                            </FCenteredRow>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" icon={<SaveOutlined />} size="large" loading={this.state.isLoading} onClick={() => {
                                    props.submitForm();
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}
