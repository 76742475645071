import * as Yup from 'yup';

export default class EwdDictionaryValueValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        return Yup.object().shape({
            code: Yup.string().required(),
            displayValue: Yup.string().required(),
        });
    }
}