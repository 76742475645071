import { defaultContainers } from 'helper/FieldsDictionary';
import * as Yup from 'yup';

export default class ContainerValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        return Yup.object().shape({
            name: Yup.string().required()
      });
    }
}