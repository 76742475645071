import React, { createRef } from 'react'
import { Row, Col, Button } from 'antd';
import { ExciseRegisterStateDetailsDto, ExciseRegisterStateDto } from 'services/GeneratedClient';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import ExciseRegisterContainersStateModal, { ContainerState } from './ExciseRegisterContainersStateModal';
import { DropboxOutlined } from '@ant-design/icons';
import ExciseRegisterContainersDayStateModal from './ExciseRegisterContainersDayStateModal';
import FadingTooltip from 'layout/FadingTooltip';
import { dateToString } from 'helper/GridHelper';

interface IProps {
    exciseRegisterState: ExciseRegisterStateDto | null,
    exciseRegisterStateDetails: ExciseRegisterStateDetailsDto | null,
    IsPossibleToCloseDay: boolean,
    hasRegisterControlOfNegativeStates: boolean,
}

const ExciseRegisterStateInformation = ({ exciseRegisterState, exciseRegisterStateDetails, IsPossibleToCloseDay, hasRegisterControlOfNegativeStates }: IProps) => {
    const exciseRegisterContainersInitialStateModalModalRef = createRef<ExciseRegisterContainersStateModal>();
    const exciseRegisterContainersStateLastDayModalModalRef = createRef<ExciseRegisterContainersStateModal>();
    const exciseRegisterContainersStateLastInventoryModalModalRef = createRef<ExciseRegisterContainersStateModal>();
    const exciseRegisterContainersDayStateModalModalRef = createRef<ExciseRegisterContainersDayStateModal>();
    const exciseRegisterContainersDayInventoryStateModalModalRef = createRef<ExciseRegisterContainersStateModal>();

    return (
        <>
            <Row>
                <Col span={12}>
                    <Card title={<><span>Podsumowanie dnia</span> <FadingTooltip title="Pokaż w zbiornikach" placement="top">
                        <Button type="ghost" size='small' shape="circle" icon={<DropboxOutlined />} onClick={() => exciseRegisterContainersDayStateModalModalRef.current?.show()}></Button>
                    </FadingTooltip></>}>
                        <ExciseRegisterContainersDayStateModal
                            ref={exciseRegisterContainersDayStateModalModalRef}
                            containersState={exciseRegisterState?.exciseRegisterContainerStateDetailsDto!}
                            headerName='Podsumowanie dnia - zbiorniki'
                            hasRegisterControlOfNegativeStates={hasRegisterControlOfNegativeStates}
                        />
                        <RowWithBottomMargin>
                            <DetailCell
                                label={"Status"}
                                value={exciseRegisterState && !IsPossibleToCloseDay ? "Zamknięty" : "Otwarty"} />
                            <DetailCell
                                label={`${hasRegisterControlOfNegativeStates
                                    ? "Ilość magazynowana"
                                    : "Rozchód narastająco"} w jednostce rozliczenia akcyzy na koniec dnia`}
                                value={exciseRegisterState && !IsPossibleToCloseDay
                                    ? (hasRegisterControlOfNegativeStates
                                        ? exciseRegisterState.storedAmountInExciseUnit
                                        : Math.abs(exciseRegisterState.storedAmountInExciseUnit)).toString()
                                    : ""} />
                        </RowWithBottomMargin>
                        <RowWithBottomMargin>
                            <DetailCell
                                label={"Przychód w jednostce rozliczenia akcyzy"}
                                value={exciseRegisterState && !IsPossibleToCloseDay ? exciseRegisterState.incomeInExciseUnit.toString() : ""} />
                            <DetailCell
                                label={"Rozchód w jednostce rozliczenia akcyzy"}
                                value={exciseRegisterState && !IsPossibleToCloseDay ? exciseRegisterState.expenditureInExciseUnit.toString() : ""} />
                        </RowWithBottomMargin>
                        <RowWithBottomMargin>
                            <DetailCell
                                label={"Przychód w jednostce technicznej"}
                                value={exciseRegisterState && !IsPossibleToCloseDay ? exciseRegisterState.incomeInInventoryUnit.toString() : ""} />
                            <DetailCell
                                label={"Rozchód w jednostce technicznej"}
                                value={exciseRegisterState && !IsPossibleToCloseDay ? exciseRegisterState.expenditureInInventoryUnit.toString() : ""} />
                        </RowWithBottomMargin>
                        {
                            exciseRegisterState && hasRegisterControlOfNegativeStates &&  exciseRegisterState.storedAmountInExciseUnitInventory != undefined &&
                            (<RowWithBottomMargin>
                                <DetailCell
                                    label={"Stan z inwentaryzacji w bieżącym dniu"}
                                    value={exciseRegisterState ? exciseRegisterState.storedAmountInExciseUnitInventory.toString() : ""} >
                                    <FadingTooltip title="Pokaż w zbiornikach" placement="top">
                                        <Button type="ghost" shape="circle" size='small' icon={<DropboxOutlined />} onClick={() => exciseRegisterContainersDayInventoryStateModalModalRef.current?.show()}></Button>
                                    </FadingTooltip>
                                    <ExciseRegisterContainersStateModal
                                        ref={exciseRegisterContainersDayInventoryStateModalModalRef}
                                        containersState={exciseRegisterState?.exciseRegisterInventoryContainerStateDetailsDto!.map(x => new ContainerState(x.containerName!, x.storedAmountInExciseUnit))}
                                        headerName='Ilość magazynowana w jednostce rozliczenia akcyzy z inwentaryzacji w bieżącym dniu'
                                    />
                                </DetailCell>

                            </RowWithBottomMargin>)
                        }
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Podsumowanie całkowite">
                        <RowWithBottomMargin>
                            <DetailCell span={10}
                                label={"Dzień rozpoczęcia ewidencji"}
                                value={exciseRegisterStateDetails ? dateToString(exciseRegisterStateDetails.firstClosedDate) : ""} >
                                <FadingTooltip title="Pokaż w zbiornikach" placement="top">
                                    <Button type="ghost" shape="circle" size='small' icon={<DropboxOutlined />} onClick={() => exciseRegisterContainersInitialStateModalModalRef.current?.show()} />
                                </FadingTooltip>
                                <ExciseRegisterContainersStateModal
                                    ref={exciseRegisterContainersInitialStateModalModalRef}
                                    containersState={exciseRegisterStateDetails?.initialContainersState?.map(x =>
                                        new ContainerState(x.containerName!, hasRegisterControlOfNegativeStates ? x.storedAmountInExciseUnit : Math.abs(x.storedAmountInExciseUnit)))}
                                    headerName={`${hasRegisterControlOfNegativeStates
                                        ? "Ilość magazynowana"
                                        : "Rozchód narastająco"} w jednostce rozliczenia akcyzy w dniu rozpoczęcia ewidencji`}
                                />
                            </DetailCell>
                            <DetailCell
                                label={`${hasRegisterControlOfNegativeStates
                                    ? "Ilość magazynowana"
                                    : "Rozchód narastająco"} w jednostce rozliczenia akcyzy w dniu rozpoczęcia ewidencji`}
                                value={exciseRegisterStateDetails
                                    ? (hasRegisterControlOfNegativeStates
                                        ? exciseRegisterStateDetails?.storedAmountInExciseUnitInitial
                                        : Math.abs(exciseRegisterStateDetails?.storedAmountInExciseUnitInitial)).toString()
                                    : ""} />
                        </RowWithBottomMargin>
                        {hasRegisterControlOfNegativeStates && <RowWithBottomMargin>
                            <DetailCell span={10}
                                label={"Dzień ostatniej inwentaryzacji"}
                                value={exciseRegisterStateDetails?.lastInventoryDate ? dateToString(exciseRegisterStateDetails.lastInventoryDate) : ""} >
                                <FadingTooltip title="Pokaż w zbiornikach" placement="top">
                                    <Button type="ghost" shape="circle" size='small' icon={<DropboxOutlined />} onClick={() => exciseRegisterContainersStateLastInventoryModalModalRef.current?.show()} />
                                </FadingTooltip>
                                <ExciseRegisterContainersStateModal
                                    ref={exciseRegisterContainersStateLastInventoryModalModalRef}
                                    containersState={exciseRegisterStateDetails?.lastInventoryContainersState?.map(x => new ContainerState(x.containerName!, x.storedAmountInExciseUnit))}
                                    headerName='Ilość magazynowana w jednostce rozliczenia akcyzy w dniu ostatniej inwentaryzacji'
                                />
                            </DetailCell>
                            <DetailCell
                                label={"Ilość magazynowana w jednostce rozliczenia akcyzy w dniu ostatniej inwentaryzacji"}
                                value={exciseRegisterStateDetails?.storedAmountInExciseUnitLastInventory != undefined ? exciseRegisterStateDetails.storedAmountInExciseUnitLastInventory.toString() : ""} />
                        </RowWithBottomMargin>}
                        <RowWithBottomMargin>
                            <DetailCell span={10}
                                label={"Ostatni zamknięty dzień"}
                                value={exciseRegisterStateDetails ? dateToString(exciseRegisterStateDetails.lastClosedDate) : ""} >
                                <FadingTooltip title="Pokaż w zbiornikach" placement="top">
                                    <Button type="ghost" shape="circle" size='small' icon={<DropboxOutlined />} onClick={() => exciseRegisterContainersStateLastDayModalModalRef.current?.show()} />
                                </FadingTooltip>
                                <ExciseRegisterContainersStateModal
                                    ref={exciseRegisterContainersStateLastDayModalModalRef}
                                    containersState={exciseRegisterStateDetails?.lastClosedDayContainersState?.map(x =>
                                        new ContainerState(x.containerName!, hasRegisterControlOfNegativeStates ? x.storedAmountInExciseUnit : Math.abs(x.storedAmountInExciseUnit)))}
                                    headerName={<>{hasRegisterControlOfNegativeStates
                                        ? "Ilość magazynowana"
                                        : "Rozchód narastająco"} w jednostce rozliczenia akcyzy w ostatnim<br />zamkniętym dniu</>}
                                />
                            </DetailCell>
                            <DetailCell
                                label={`${hasRegisterControlOfNegativeStates 
                                    ? "Ilość magazynowana"
                                    : "Rozchód narastająco"} w jednostce rozliczenia akcyzy w ostatnim zamkniętym dniu`}
                                value={exciseRegisterStateDetails
                                    ? (hasRegisterControlOfNegativeStates
                                        ? exciseRegisterStateDetails?.storedAmountInExciseUnitLastClosedDay
                                        : Math.abs(exciseRegisterStateDetails?.storedAmountInExciseUnitLastClosedDay)).toString()
                                    : ""} />
                        </RowWithBottomMargin>
                    </Card>
                </Col>
            </Row>
        </>
    )
}




export default ExciseRegisterStateInformation;