import { dateToString } from "helper/GridHelper";
import { observable, action } from "mobx";
import { ajaxCatch } from "../helper/api";
import { NotClosedDayDto, NotClosedDayStampDto, NotClosedDayEnergyDto, ExciseRegisterStateClient, ExciseStampRegisterStateClient, ExciseEnergyRegisterStateClient, AnnouncementDto, AnnouncementClient } from "../services/GeneratedClient";
import { Modal } from "antd";
import React from "react";

export class AlertsStore {
    @observable closeDayAlerts: NotClosedDayDto[] | undefined = undefined;
    @observable closeDayStampAlerts: NotClosedDayStampDto[] | undefined = undefined;
    @observable closeDayEnergyAlerts: NotClosedDayEnergyDto[] | undefined = undefined;
    @observable announcements: AnnouncementDto[] | undefined = undefined;

    constructor() {
    }

    @action public fetchAndFilterCloseDayReminder() {
        let closeDayAlerts: string[];
        let closeDayAlertsString = sessionStorage.getItem('closeDayAlerts');
        if (closeDayAlertsString) {
            closeDayAlerts = closeDayAlertsString.split(";");
        };
        ajaxCatch(() =>
            new ExciseRegisterStateClient().getClosingDayReminder(),
            action((result: NotClosedDayDto[] | null) => {
                if (result) {
                    this.closeDayAlerts = result.filter(
                        a => !closeDayAlerts?.includes(`${a.exciseRegisterId}_${dateToString(a.lastClosedDay)}`)
                    )
                }
            }));
    }

    @action public setCloseDayReminder(newTab: NotClosedDayDto[]) {
        this.closeDayAlerts = newTab;
    }

    @action public fetchAndFilterCloseDayEnergyReminder() {
        let closeDayEnergyAlerts: string[];
        let closeDayEnergyAlertsString = sessionStorage.getItem('closeDayEnergyAlerts');
        if (closeDayEnergyAlertsString) {
            closeDayEnergyAlerts = closeDayEnergyAlertsString.split(";");
        };
        ajaxCatch(() =>
            new ExciseEnergyRegisterStateClient().getClosingDayEnergyReminder(),
            action((result: NotClosedDayEnergyDto[] | null) => {
                if (result) {
                    this.closeDayEnergyAlerts = result.filter(
                        a => !closeDayEnergyAlerts?.includes(`${a.exciseRegisterId}_${dateToString(a.lastClosedDay)}`)
                    )
                }
            }));
    }

    @action public setCloseDayEnergyReminder(newTab: NotClosedDayEnergyDto[]) {
        this.closeDayEnergyAlerts = newTab;
    }

    @action public fetchAndFilterCloseDayStampReminder() {
        let closeDayStampAlerts: string[];
        let closeDayStampAlertsString = sessionStorage.getItem('closeDayStampAlerts');
        if (closeDayStampAlertsString) {
            closeDayStampAlerts = closeDayStampAlertsString.split(";");
        };
        ajaxCatch(() =>
            new ExciseStampRegisterStateClient().getClosingDayStampReminder(),
            action((result: NotClosedDayStampDto[] | null) => {
                if (result) {
                    this.closeDayStampAlerts = result.filter(
                        a => !closeDayStampAlerts?.includes(`${a.exciseRegisterId}_${dateToString(a.lastClosedDay)}`)
                    )
                }
            }));
    }

    @action public setCloseDayStampReminder(newTab: NotClosedDayStampDto[]) {
        this.closeDayStampAlerts = newTab;
    }

    @action public fetchAndFilterAnnouncements() {
        var announcements: string[];
        var announcementsString = sessionStorage.getItem('ewd-announcements');
        if (announcementsString) {
            announcements = announcementsString.split(";");
        };
        ajaxCatch(() =>
            new AnnouncementClient().getAnnouncements(),
            action((result: AnnouncementDto[] | null) => {
                if (result) {
                    this.announcements = result.filter(a => !announcements?.includes(a.id.toString()))
                }
                this.showErrorAnnouncementsModal(result);
            }));
    }

    @action public setAnnouncements(newTab: AnnouncementDto[]) {
        this.announcements = newTab;
    }

    showErrorAnnouncementsModal(announcements: AnnouncementDto[] | null) {
        if (!announcements) {
            return;
        }

        const sessionStorageResourceName = 'ewd-seen-error-announcements';
        const seenErrorAlertsResource = sessionStorage.getItem(sessionStorageResourceName);
        const seenAlertIds = seenErrorAlertsResource ? seenErrorAlertsResource.split(';') : [];
        const newErrorAnnouncements = announcements.filter(a => a.isError);

        if (newErrorAnnouncements.length == 0) {
            return;
        }

        const errorAnnouncementsToBeDisplayed: AnnouncementDto[] = [];
        newErrorAnnouncements.forEach(a => {
            if (!seenAlertIds.includes(a.id.toString())) {
                errorAnnouncementsToBeDisplayed.push(a);
                seenAlertIds.push(a.id.toString());
            }
        });

        if (errorAnnouncementsToBeDisplayed.length > 0) {
            Modal.error({
                centered: true,
                width: 800,
                title: "Uwaga!",
                content: errorAnnouncementsToBeDisplayed.map((announcement, index) => {
                    return <p key={index}>{announcement.content}</p>
                })
            });
            sessionStorage.setItem(sessionStorageResourceName, seenAlertIds.join(';'));
        }
    }
}
