import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { DictionaryName, EwdPlaceLevelPermission, ExciseEnergyRegisterClient, ExciseEnergyRegisterListDto, } from 'services/GeneratedClient';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from 'layout/FadingTooltip';
import TableButton from 'layout/table/tableButton/TableButton';
import { Link } from 'react-router-dom';
import { BellOutlined, ReadOutlined, ReconciliationOutlined, SearchOutlined } from '@ant-design/icons';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { Authorized } from 'authorization/Authorized';
import { GridStore } from 'layout/table/paginated/GridStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class ExciseEnergyRegisterList extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "exciseEnergyRegisterGrid"
        }
    }

    public componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<ExciseEnergyRegisterListDto>[] = [
        {
            key: "actions",
            width: 120,
            render: (text: string, record: ExciseEnergyRegisterListDto) => (
                <>
                    <Authorized placeContext={record.placeName} placeLevelPermission={EwdPlaceLevelPermission.ExciseEnergyRegisterEntryDisplay}>
                        <FadingTooltip title="Zdarzenia ewidencji">
                            <Link to={`/ExciseEnergies/Registers/${record.id}/Entries/Completed`}>
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ReadOutlined />} />
                            </Link>
                        </FadingTooltip>
                        <FadingTooltip title="Zdarzenia bieżące">
                            <Link to={`/ExciseEnergies/Registers/${record.id}/Entries/Current`}>
                                <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ReconciliationOutlined />} />
                            </Link>
                        </FadingTooltip>
                    </Authorized>
                    <FadingTooltip title="Szczegóły ewidencji">
                        <Link to={`/ExciseEnergies/Registers/Details/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<SearchOutlined />} />
                        </Link>
                    </FadingTooltip>
                </>
            )
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "name",
            key: "Name",
            title: "Nazwa",
            sorter: true,
            filter: "contains",
        },
        {
            dataIndex: "placeName",
            key: "Place.Name",
            title: "Miejsce",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
        }
    ];

    private paginatedTable = createRef<PaginatedTable<ExciseEnergyRegisterListDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`EWIDENCJE ENERGII ELEKTRYCZNEJ`} />
                <PaginatedTable<ExciseEnergyRegisterListDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: ExciseEnergyRegisterListDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new ExciseEnergyRegisterClient().getAll(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </>
        )
    }
};