import { Button } from 'antd';
import { Authorized } from 'authorization/Authorized';
import { ajaxCatch } from 'helper/api';
import React from 'react';
import { Link } from 'react-router-dom';
import { ExciseStampRegisterStateClient } from 'services/GeneratedClient';

interface ICorrectAsNewNavigationButtonProps {
    exciseStampRegisterId: number;
    registerType: string;
}

interface IState {
    hasInitialState: boolean;
}

export default class CorrectAsNewNavigationButton extends React.PureComponent<ICorrectAsNewNavigationButtonProps, IState> {
    constructor(props: ICorrectAsNewNavigationButtonProps) {
        super(props);

        this.state = {
            hasInitialState: false
        }
    }

    public componentDidMount() {
        this.fetchHasInitialState();
    }

    private fetchHasInitialState() {
        ajaxCatch(() =>
            new ExciseStampRegisterStateClient().hasInitialState(this.props.exciseStampRegisterId),
            (result: boolean | null) => {
                this.setState({
                    hasInitialState: result ?? false
                })
            });
    }

    public render() {
        return <>
            {
                this.state.hasInitialState &&
                <Link to={`/ExciseStampRegisters/${this.props.exciseStampRegisterId}/${this.props.registerType}/ExciseStampEntries/CorrectAsNew`} >
                    <Button type="primary" size={'large'}>Dodaj zdarzenie korygujące</Button>
                </Link>
            }
        </>
    }
}
