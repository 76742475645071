import { tuple } from 'antd/lib/_util/type';
import { inject } from 'mobx-react';
import { ExciseStampRegisterType } from 'services/GeneratedClient';
import * as Yup from 'yup';

export default class ExciseStampRegisterValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        return Yup.object().shape({
            name: Yup.string().required(),
            placeId: Yup.string().required(),

            exciseStampRegisterType: Yup.string().required(),
            exciseStampType: Yup.string().required(),

            ownerDetails: Yup.string().notRequired()
                .when("exciseStampRegisterType", {
                    is: ExciseStampRegisterType[ExciseStampRegisterType.Paragraph25],
                    then: Yup.string().required(),
                    message: "Pole wymagane w wybranym typie rejestru."
            })
        });
    }
}