import React from "react";
import { Tooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";

interface IProps {

}

interface IState {

}

export default class FadingTooltip extends React.Component<IProps & TooltipProps, IState> {
    public render() {
        return <Tooltip placement="left" {...this.props} mouseLeaveDelay={0.0} mouseEnterDelay={0.7}>
            {this.props.children}
        </Tooltip>
    }
}