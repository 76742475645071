import { Button, Col, Select } from "antd";
import { inject, observer } from "mobx-react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import { DictionaryStore } from "dictionary/dictionaryStore";
import { PageHeader } from "layout/PageHeader";
import PrimaryCard from "layout/PrimaryCard";
import { SaveExciseEnergyRegisterCmd, DictionaryName, ExciseEnergyRegisterClient, ExciseEnergyRegisterDto } from "services/GeneratedClient";
import { FCenteredRow, FDictionaryAutoCompleteWithTooltip, FDictionarySelect, FDictionarySelectWithTooltip, FFieldLabel, FInput, FSelect } from "forms/FormikFormItems";
import { ajaxByUser, ajaxCatch } from "helper/api";
import { RegisterStore } from "register/RegisterStore";
import ExciseEnergyRegisterCreateValidation from "./ExciseEnergyRegisterCreateValidation";
import { showFormIsInvalidModal } from "layout/Modals";
import { GridStore } from "layout/table/paginated/GridStore";
import { filterCNCodeDictionary } from "dictionary/DictionaryUtils";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
    dictionaryStore?: DictionaryStore;
    registerStore?: RegisterStore;
    gridStore?: GridStore;
    exciseEnergyRegisterId?: number;
}

interface IState {
    editState: boolean;
    initialValues: ExciseEnergyRegisterDto;
    gridName?: string;
}

@inject("dictionaryStore")
@inject("registerStore")
@inject("gridStore")
@observer
export default class ExciseEnergyRegisterCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/ExciseEnergies/Registers`;
    private validation: ExciseEnergyRegisterCreateValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            editState: this.props.exciseEnergyRegisterId ? true : false,
            initialValues: this.setInitialValues(),
            gridName: this.props.gridStore?.getGridNameForLastUsedRowId(),
        };

        this.validation = new ExciseEnergyRegisterCreateValidation();
    }

    componentDidMount() {
        if (this.state.editState) {
            this.fetchExciseEnergyRegister();
        }
    }

    private setInitialValues() {
        let initialValue = new ExciseEnergyRegisterDto();
        initialValue.cnCode = "27160000";
        return initialValue;
    }

    private fetchExciseEnergyRegister() {
        ajaxCatch(() =>
            new ExciseEnergyRegisterClient().get(this.props.exciseEnergyRegisterId!),
            (result: ExciseEnergyRegisterDto | null) => {
                this.setState({
                    initialValues: result!,
                });
            });
    }

    public send(values: ExciseEnergyRegisterDto, actions: FormikHelpers<ExciseEnergyRegisterDto>) {
        let cmd = new SaveExciseEnergyRegisterCmd({
            exciseEnergyRegisterDto: values
        });

        ajaxByUser(this.state.editState ? "Zaktualizowano ewidencję" : "Dodano ewidencję", () =>
            new ExciseEnergyRegisterClient().save(cmd)
                .then((resp: any) => {
                    actions.setSubmitting(false);
                    if (!this.state.editState) {
                        this.props.gridStore?.saveLastUsedRowId(this.state.gridName!, resp.toString());
                    }
                    this.props.history.push(this.goBackUrl);
                }));
    }

    public validateAndSubmitForm = (props: FormikProps<ExciseEnergyRegisterDto>) => {
        props.setFormikState({
            isSubmitting: true,
            values: props.values,
            errors: props.errors,
            touched: props.touched,
            isValidating: props.isValidating,
            submitCount: props.submitCount + 1
        });

        props.validateForm(props.values)
            .then((errors: FormikErrors<ExciseEnergyRegisterDto>) => {
                if (Object.keys(errors).length) {
                    props.setSubmitting(false);
                    console.log("Błędy walidacji klienckiej: ", errors, "wartości", props.values);
                    showFormIsInvalidModal();
                } else {
                    props.submitForm();
                }
            })
    }

    public render() {
        return (
            <>
                <PageHeader title={this.state.editState ? 'EDYCJA EWIDENCJI ENERGII ELEKTRYCZNEJ' : 'TWORZENIE EWIDENCJI ENERGII ELEKTRYCZNEJ'} />
                <Formik<ExciseEnergyRegisterDto> initialValues={this.state.initialValues} enableReinitialize
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<ExciseEnergyRegisterDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Nazwa" sm={24} />
                                    <Field component={FInput} name="name" />
                                </Col>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Miejsce" sm={24} />
                                    <Field component={FSelect} name="placeId">
                                        {this.props.registerStore?.allSubjectPlaces?.map((r, index) =>
                                            <Option key={index} value={r.id}>{`${r.name} (${r.streetName} ${r.streetNumber} ${r.city})`}</Option>
                                        )}
                                    </Field>
                                </Col>
                            </FCenteredRow>
                            <FCenteredRow>
                                <Col md={9} xs={24}>
                                    <FFieldLabel label="Kod CN wyrobu" sm={24} />
                                    <Field name="cnCode" component={FInput} disabled />
                                </Col>
                            </FCenteredRow>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>

                                <Button type="primary" icon={<SaveOutlined />} size="large" onClick={() => {
                                    this.validateAndSubmitForm(props);
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}
