import React, { PropsWithChildren } from 'react'
import { Col, Card as AntCard } from 'antd';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import { DictionaryName, ExciseStampRegisterDto } from 'services/GeneratedClient';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import { PlaceDetails } from 'places/PlaceDetails';

const ExciseStampRegisterInformation = ({ exciseStampRegister, dictionaryStore }: { exciseStampRegister: ExciseStampRegisterDto, dictionaryStore: DictionaryStore }) =>
    <>
        <RowWithBottomMargin>
            <Col span={24}>
                <Card title="Szczegóły rejestru ewidencji">
                    <RowWithBottomMargin>
                        <DetailCell
                            label={"Typ rejestru"}
                            value={dictionaryStore?.getDisplay(DictionaryName.ExciseStampRegisterType, exciseStampRegister.exciseStampRegisterType)} />
                        <DetailCell
                            label={"Nazwa"}
                            value={exciseStampRegister.name} />
                    </RowWithBottomMargin>
                    <RowWithBottomMargin>
                        <DetailCell 
                            label={"Rodzaj rejestru"}
                            value={exciseStampRegister.exciseStampType} />
                        <DetailCell
                            label={"Dane właściciela"}
                            value={exciseStampRegister.ownerDetails} />
                    </RowWithBottomMargin>
                </Card>
            </Col>
        </RowWithBottomMargin>
        <RowWithBottomMargin>
            <Col span={24}>
                <PlaceDetails placeId={exciseStampRegister.placeId} horizontal />
            </Col>
        </RowWithBottomMargin>
    </>


export default ExciseStampRegisterInformation;