import { FilterFilled, FilterOutlined, FilterTwoTone } from '@ant-design/icons';
import { SorterResult } from 'antd/lib/table/interface';
import { EwdColumnProps } from 'helper/GridHelper';
import React, { Key, ReactNode } from 'react';
import { GridSettingsDto } from 'services/GeneratedClient';
import ColumnSelector from './ColumnSelector';
import GridClearFilters from './filters/GridClearFilters';
import { GridFilterCustomSelect } from './filters/GridFilterCustomSelect';
import { GridFilterDateRange } from './filters/GridFilterDateRange';
import { GridFilterDateTimeRange } from './filters/GridFilterDateTimeRange';
import { GridFilterDictionarySelect } from './filters/GridFilterDictionarySelect';
import GridFilterInput from './filters/GridFilterInput';
import "./columnExtender.less";

export class ColumnExtender<T> {
    private gridName: string;
    private columnsSelector: boolean;

    constructor(gridName: string, columnsSelector: boolean | undefined) {
        this.gridName = gridName;
        this.columnsSelector = columnsSelector ? columnsSelector : false;
    }

    public applyActionButtons(gridSettings: GridSettingsDto | undefined, columns: EwdColumnProps<T>[], anyFilters: boolean, search?: () => void) {
        if (columns === undefined || columns.length === 0) return [];

        columns.forEach(column => {
            if (column.key === 'actions') {
                column.title = (filters: any, sortOrder: any) => {
                    return <div className={`${this.columnsSelector === true && search !== undefined && columns.some(a => a.filter !== undefined || a.sorter !== undefined) ? "action-buttons" : ""}`}>
                        {this.columnsSelector === true && <ColumnSelector allColumns={columns} gridName={this.gridName} gridSettings={gridSettings} />}

                        {search !== undefined && columns.some(a => a.filter !== undefined || a.sorter !== undefined) &&
                            <GridClearFilters anyFilters={anyFilters} gridName={this.gridName} handleSearch={() => search()} />}
                    </div>
                }
            }
        });
    }

    public applyFiltersAndSorter(columns: EwdColumnProps<T>[], antSorter: SorterResult<T>, search: () => void, isColumnFiltered: (columnKey: Key) => boolean): EwdColumnProps<T>[] {
        if (columns === undefined || columns.length === 0) return [];

        let columnsWithFiltersProps: EwdColumnProps<T>[] = [];

        columns.forEach(column => {
            let columnWithFilterProps: EwdColumnProps<T>;

            if (column.filter !== undefined) {
                columnWithFilterProps = { ...column, ...this.getColumnFilterProps(column, search, isColumnFiltered) };
            }
            else {
                columnWithFilterProps = { ...column };
            }

            columnWithFilterProps.sortOrder = (antSorter && column.key === antSorter.columnKey) ? antSorter.order : undefined;
            columnsWithFiltersProps.push(columnWithFilterProps);
        });

        return columnsWithFiltersProps;
    }

    private getColumnFilterProps = (column: EwdColumnProps<T>, search: () => void, isColumnFiltered: (columnKey: Key) => boolean) => ({
        filterDropdown: (): ReactNode => {
            return <div style={{ padding: 8 }} className="filter-dropdown">
                {this.getFilterDropdownInput(column, search)}
            </div>
        },
        filterIcon: () => (!isColumnFiltered(column.key!) ? <FilterOutlined /> :
            <FilterFilled className="column-filter-active" />),
    });


    private getFilterDropdownInput(column: EwdColumnProps<T>, search: () => void): ReactNode {
        if (column.filter === 'contains' || column.filter === 'equals') {
            return <GridFilterInput
                column={column}
                handleSearch={() => { search() }}
                gridName={this.gridName}
            />
        }
        else if (column.filter === 'dictionary') {
            if (column.filterDictionary === undefined) throw "Typ słownika jest wymagany w przypadku użycia filtra słownikowego";

            return <GridFilterDictionarySelect
                column={column}
                handleSearch={() => { search() }}
                gridName={this.gridName}
            />
        }
        else if (column.filter === 'dateRange') {
            return <GridFilterDateRange
                column={column}
                handleSearch={() => { search() }}
                gridName={this.gridName}
            />
        }
        else if (column.filter === 'dateTimeRange') {
            return <GridFilterDateTimeRange
                column={column}
                handleSearch={() => { search() }}
                gridName={this.gridName}
                DateTimeFormat={column.filterDateTimeFormat ? column.filterDateTimeFormat : "YYYY-MM-DD HH:mm:ss"}
                TimeFormat={column.filterTimeFormat ? column.filterTimeFormat : "HH:mm:ss"}
            />
        }
        else if (column.filter === 'customSelect') {
            if (column.filterCustomSelectValues === undefined) throw "Lista wyboru jest wymagana w przypadku użycia niestandardowego filtra";

            return <GridFilterCustomSelect
                column={column}
                handleSearch={() => { search() }}
                gridName={this.gridName}
                filterCustomSelectValues={column.filterCustomSelectValues}
            />
        }
        else {
            throw `Filtr ${column.filter} nie jest obsługiwany`
        }
    }
}