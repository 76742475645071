import React from 'react';
import { Modal } from 'antd';
import { GlobalUserManager } from 'authentication/UserManager';
import { UserClient } from 'services/GeneratedClient';

export default class AutoLogoutWhenUserLoggedInUsingAnotherClientComponent extends React.Component<{}, {}> {
    userClient: UserClient;
    constructor(props: any) {
        super(props);

        this.userClient = new UserClient();
    }

    public render() {
        return <></>
    }

    componentDidMount() {
        setInterval(() => {
            this.userClient.isUserLoggedInUsingAnotherClient()
                .then((loggedIn) => {
                    if (loggedIn) {
                        Modal.warn({
                            centered: true,
                            maskClosable: false,
                            title: <p>Zalogowano na innym urządzeniu lub przeglądarce. Zostaniesz automatycznie wylogowany.</p>
                        });

                        setTimeout(() => {
                            GlobalUserManager.UserManager().signoutRedirect();
                        }, 5000)
                    }
                })
        }, 30000)
    }
}
