import React from "react";
import { RegisterClient, AllRegistersDto } from "../services/GeneratedClient";
import { ajaxCatch } from "../helper/api";
import { inject, observer } from "mobx-react";
import { RegisterStore } from "./RegisterStore";
import { AccountDetailsStore } from "account/AccountDetailsStore";

interface IProps {
    registerStore?: RegisterStore;
    accountDetailsStore?: AccountDetailsStore;
}

interface IState {
    loaded: boolean;
}

@inject("registerStore")
@inject("accountDetailsStore")
@observer
export default class RegisterProvider extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loaded: false
        };
    }

    public componentDidMount() {
        this.fetchDictionaries()
    }

    private fetchDictionaries() {
        this.props.registerStore!.setCompany(this.props.accountDetailsStore?.currentCompany?.id);
        this.props.registerStore!.refreshRegisters();

        this.setState({
            loaded: true
        });
    }

    componentDidUpdate() {
        if (this.props.accountDetailsStore!.currentCompany?.id !== this.props.registerStore!.companyId) {
            if (this.state.loaded) {
                this.setState({ loaded: false });
                this.fetchDictionaries()
            }
        }
    }

    public render() {
        if (this.state.loaded && this.props.children) return this.props.children;
        return null;
    }
}