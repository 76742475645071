import React from "react";
import { Moment } from "moment";
import { ColumnProps } from "antd/lib/table";
import { DictionaryName } from "services/GeneratedClient";

// formaty dat zgodne z Ant Design (stały format)

export const dateTimeRenderer = (date: Moment | undefined) => {
  return date && <React.Fragment><span>{dateTimeToString(date)}</span></React.Fragment>;
};

export const dateRenderer = (date: Moment | undefined) => {
  return date && <React.Fragment><span>{dateToString(date)}</span></React.Fragment>;
};

export const timeRenderer = (date: Moment | undefined) => {
  return date && <React.Fragment><span>{timeToString(date)}</span></React.Fragment>;
};

export const dateTimeToString = (date: Moment | undefined) => {
  return date && date.local().format("YYYY-MM-DD HH:mm:ss");
};

export const dateToString = (date: Moment | undefined) => {
  return date && date.local().format("YYYY-MM-DD").toString();
};

export const timeToString = (date: Moment | undefined) => {
  return date && date.local().format("HH:mm:ss").toString();
};

export const newLineRenderer = (text: string) => {
  return text.split("\n").map((val, index) => {
    return <p key={index}>{val.trim()}</p>
  })
}

export interface IBooleanValueProvider {
  valueIfTrue: string,
  valueIfFalse: string,
}

export const yesNoBooleanValueProvider = {
  valueIfTrue: "Tak",
  valueIfFalse: "Nie",
} as IBooleanValueProvider

export const defaultBooleanValueProvider = {
  valueIfTrue: "Prawda",
  valueIfFalse: "Fałsz",
} as IBooleanValueProvider

export const booleanRenderer = (value: boolean | undefined, valueProvider: IBooleanValueProvider = defaultBooleanValueProvider) => {
  if (value === undefined) {
    return undefined;
  }
  return value ? valueProvider.valueIfTrue : valueProvider.valueIfFalse;
}

export type filterType =
  'contains' | 'equals' | 'dictionary' | 'dateRange' | 'dateTimeRange' | 'custom' | 'customSelect';

export interface EwdColumnProps<T> extends ColumnProps<T> {
  filterDictionary?: DictionaryName;
  filter?: filterType;
  filterCustomSelectValues?: CustomSelectValue[];
  filterDateTimeFormat?: string;
  filterTimeFormat?: string;
}

export interface CustomSelectValue {
  code: string;
  displayName: string;
}