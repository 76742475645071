import { dateRenderer, dateTimeRenderer, EwdColumnProps } from "helper/GridHelper";
import { ExciseStampRegisterReportDto } from "services/GeneratedClient";
import React from 'react';

interface IProps {
}

const getExciseStampRegisterReportColumns = (props: IProps): (EwdColumnProps<ExciseStampRegisterReportDto>)[] => {
    return [
        {
            key: "actions",
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id"
        },
        {
            dataIndex: "ordinalNumbers",
            key: "OrdinalNumbers",
            title: "Lp. | Lp. korekty"
        },
        {
            dataIndex: "paragraphType",
            key: "ParagraphType",
            title: "Paragraf"
        },
        {
            dataIndex: "sourceIdentifier",
            key: "SourceIdentifier",
            title: "Identyfikator źródła"
        },
        {
            dataIndex: "batchIdentifier",
            key: "BatchIdentifier",
            title: "Identyfikator partii"
        },
        {
            dataIndex: "sendIdentifier",
            key: "SendIdentifier",
            title: "Identyfikator wysyłki"
        },
        {
            dataIndex: "foreignEntityDetails",
            key: "ForeignEntityDetails",
            title: "Nazwa i adres podmiotu zagranicznego"
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (text: any, record: ExciseStampRegisterReportDto) => dateRenderer(record.date),
            width: 90,
        },
        {
            dataIndex: "actionDate",
            key: "ActionDate",
            title: "Data wprowadzenia / edycji",
            render: (text: any, record: ExciseStampRegisterReportDto) => dateTimeRenderer(record.actionDate),
            width: 90,
        },
        {
            dataIndex: "stampItemsQuantity",
            key: "StampItemsQuantity",
            title: "Wpływ na stan banderol",
            render: (quantity: number, record: ExciseStampRegisterReportDto) => {
                if (quantity >= 0) return record.stampItemsQuantity
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "totalStampItemsQuantity",
            key: "TotalStampItemsQuantity",
            title: "Stan banderol",
        },
        {
            dataIndex: "stampItemsInRegisterQuantity",
            key: "StampItemsInRegisterQuantity",
            title: "Stan banderol w siedzibie",
        },
        {
            dataIndex: "stampItemsOutOfRegisterQuantity",
            key: "StampItemsOutOfRegisterQuantity",
            title: "Stan banderol poza siedzibą",
        },
        {
            dataIndex: "stampItemsAppliedQuantity",
            key: "StampItemsAppliedQuantity",
            title: "Stan banderol naniesionych",
        },
        {
            dataIndex: "stampItemsDamagedQuantity",
            key: "StampItemsDamagedQuantity",
            title: "Stan banderol uszkodzonych",
        },
        {
            dataIndex: "stampItemsLostQuantity",
            key: "StampItemsLostQuantity",
            title: "Stan banderol utraconych",
        },
        {
            dataIndex: "stampItemsDestroyedQuantity",
            key: "StampItemsDestroyedQuantity",
            title: "Stan banderol zniszczonych",
        },
        {
            dataIndex: "quantityForSendId",
            key: "QuantityForSendId",
            title: "Stan banderol dla danej wysyłki",
        },
        {
            dataIndex: "impactOnStampItemsInRegisterQuantity",
            key: "ImpactOnStampItemsInRegisterQuantity",
            title: "Wpływ na stan banderol w siedzibie",
            render: (quantity: number, record: ExciseStampRegisterReportDto) => {
                if (quantity >= 0) return record.stampItemsQuantity
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "impactOnStampItemsOutOfRegisterQuantity",
            key: "ImpactOnStampItemsOutOfRegisterQuantity",
            title: "Wpływ na stan banderol poza siedzibą",
            render: (quantity: number, record: ExciseStampRegisterReportDto) => {
                if (quantity >= 0) return record.stampItemsQuantity
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "impactOnStampItemsAppliedQuantity",
            key: "ImpactOnStampItemsAppliedQuantity",
            title: "Wpływ na stan banderol naniesionych",
            render: (quantity: number, record: ExciseStampRegisterReportDto) => {
                if (quantity >= 0) return record.stampItemsQuantity
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "impactOnStampItemsDamagedQuantity",
            key: "ImpactOnStampItemsDamagedQuantity",
            title: "Wpływ na stan banderol uszkodzonych",
            render: (quantity: number, record: ExciseStampRegisterReportDto) => {
                if (quantity >= 0) return record.stampItemsQuantity
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "impactOnStampItemsLostQuantity",
            key: "ImpactOnStampItemsLostQuantity",
            title: "Wpływ na stan banderol utraconych",
            render: (quantity: number, record: ExciseStampRegisterReportDto) => {
                if (quantity >= 0) return record.stampItemsQuantity
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "impactOnStampItemsDestroyedQuantity",
            key: "ImpactOnStampItemsDestroyedQuantity",
            title: "Wpływ na stan banderol zniszczonych",
            render: (quantity: number, record: ExciseStampRegisterReportDto) => {
                if (quantity >= 0) return record.stampItemsQuantity
                else return <span style={{ color: 'red' }}>{quantity}</span>
            }
        },
        {
            dataIndex: "issuedQuantity",
            key: "IssuedQuantity",
            title: "Liczba banderol wydanych",
        },
        {
            dataIndex: "issedForMarkQuantity",
            key: "IssedForMarkQuantity",
            title: "Liczba banderol podatkowych wydanych do oznaczania",
        },
        {
            dataIndex: "transferredQuantity",
            key: "TransferredQuantity",
            title: "Liczba banderol podatkowych przekazanych",
        },
        {
            dataIndex: "returnedQuantity",
            key: "ReturnedQuantity",
            title: "Liczba zwróconych banderol podatkowych",
        },
        {
            dataIndex: "sentQuantity",
            key: "SentQuantity",
            title: "Liczba wysłanych banderol podatkowych",
        },
        {
            dataIndex: "receivedQuantity",
            key: "ReceivedQuantity",
            title: "Liczba otrzymanych banderol podatkowych",
        },
        {
            dataIndex: "receivedForMarkingQuantity",
            key: "ReceivedForMarkingQuantity",
            title: "Liczba otrzymanych banderol podatkowych do procesu oznaczania",
        },
        {
            dataIndex: "issuedForReplacedQuantity",
            key: "IssuedForReplacedQuantity",
            title: "Liczba banderol wydanych za banderole podatkowe zwrócone niewykorzystane i nieuszkodzone",
        },
        {
            dataIndex: "issuedForAcceptedLossQuantity",
            key: "IssuedForAcceptedLossQuantity",
            title: "Liczba banderol wydanych w ramach dopuszczalnej normy strat",
        },
        {
            dataIndex: "appliedQuantity",
            key: "AppliedQuantity",
            title: "Liczba banderol podatkowych naniesionych na opakowania jednostkowe wyrobów akcyzowych",
        },
        {
            dataIndex: "otherQuantity",
            key: "OtherQuantity",
            title: "Liczba banderol podatkowych powracających",
        },
        {
            dataIndex: "damagedInMarkingQuantity",
            key: "DamagedInMarkingQuantity",
            title: "Liczba banderol podatkowych uszkodzonych w procesie oznaczania wyrobów akcyzowych",
        },
        {
            dataIndex: "damagedOutMarkingQuantity",
            key: "DamagedOutMarkingQuantity",
            title: "Liczba banderol podatkowych uszkodzonych poza procesem oznaczania wyrobów akcyzowych",
        },
        {
            dataIndex: "returnedDamagedInMarkingQuantity",
            key: "ReturnedDamagedInMarkingQuantity",
            title: "Liczba zwróconych banderol podatkowych uszkodzonych w procesie oznaczania",
        },
        {
            dataIndex: "returnedDamagedOutMarkingQuantity",
            key: "ReturnedDamagedOutMarkingQuantity",
            title: "Liczba zwróconych banderol podatkowych uszkodzonych poza procesem oznaczania",
        },
        {
            dataIndex: "lostInMarkingQuantity",
            key: "LostInMarkingQuantity",
            title: "Liczba banderol podatkowych utraconych w procesie oznaczania wyrobów akcyzowych",
        },
        {
            dataIndex: "lostOutMarkingQuantity",
            key: "LostOutMarkingQuantity",
            title: "Liczba banderol podatkowych utraconych poza procesem oznaczania wyrobów akcyzowych",
        },
        {
            dataIndex: "destroyedInMarkingQuantity",
            key: "DestroyedInMarkingQuantity",
            title: "Liczba banderol podatkowych zniszczonych w procesie oznaczania wyrobów akcyzowych",
        },
        {
            dataIndex: "destroyedOutMarkingQuantity",
            key: "DestroyedOutMarkingQuantity",
            title: "Liczba banderol podatkowych zniszczonych poza procesem oznaczania wyrobów akcyzowych",
        },
        {
            dataIndex: "editorUserMail",
            key: "EditorUserMail",
            title: "Email użytkownika dodającego / edytującego",
        },
    ];
};

export default getExciseStampRegisterReportColumns;