import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";
import { downloadFile } from 'helper/api';
import { showSuccess } from "layout/Modals";
import React, { CSSProperties } from "react";
import { FileResponse } from "services/GeneratedClient";

interface IProps {
    style?: CSSProperties;
    getFile: () => Promise<FileResponse>;
}
interface IState {
    generating: boolean;
}
export class GeneratePdfButton extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            generating: false,
        }
    }

    render() {
        return <>
            <Tooltip placement="left" title="Generuj PDF">
                <Button shape="circle" type="default" icon={<FilePdfOutlined />} onClick={() => {
                    this.generatePdf()
                }} loading={this.state.generating} style={this.props.style} />
            </Tooltip>
        </>;
    }

    generatePdf(): any {
        this.setState({ generating: true });
        let response = this.props.getFile()
            .then((response) => {
                if (!response.fileName) {
                    showSuccess("Plik PDF wygenerowany.");
                    this.setState({ generating: false });
                } else {
                    this.setState({ generating: false });
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });

                    downloadFile(file, response.fileName!);
                }
            })
            .catch((resp) => {
                this.setState({ generating: false });
            });
    }
}
