import { Button, Col, Modal, Select } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import PrimaryCard from "../layout/PrimaryCard";
import CnKwaDictionaryValueValidation from "./CnKwaDictionaryValueValidation";
import { inject } from "mobx-react";
import { GridStore } from "layout/table/paginated/GridStore";
import { CnKwaDictionaryValueClient, CnKwaDictionaryValueDto } from "services/GeneratedClient";
import { PageHeader } from "layout/PageHeader";
import { FCenteredRow, FFieldLabel, FInput } from "forms/FormikFormItems";
import { ajaxByUser } from "helper/api";

const Option = Select.Option;

interface IProps extends RouteComponentProps<any> {
}

interface IState {
    isLoading: boolean;
}

@inject("gridStore")
export default class CnKwaDictionaryValueCreate extends React.PureComponent<IProps, IState> {
    private readonly goBackUrl = `/Admin/CnKwaDictionaries`;
    private validation: CnKwaDictionaryValueValidation;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false
        };

        this.validation = new CnKwaDictionaryValueValidation();
    }

    componentDidMount() {
    }

    public send(values: CnKwaDictionaryValueDto, actions: FormikHelpers<CnKwaDictionaryValueDto>) {
        this.setState({ isLoading: true })
                
        Modal.confirm({
            centered: true,
            maskClosable: true,
            title: `Zapisanie nowej wartości spowoduje uzupełnienie kodu wyrobu towarowego we wszystkich utworzonych już zdarzeniach akcyzowych dla kodu CN: ${values.cnCode}`,
            okText: 'Potwierdź zapis',
            cancelText: 'Anuluj',
            onOk: () => {
                ajaxByUser("Dodano wartość słownika CN->KWA", () =>
                    new CnKwaDictionaryValueClient().create(values)
                        .then((resp: any) => {
                            actions.setSubmitting(false);
                            this.setState({ isLoading: false });
                            this.props.history.push(this.goBackUrl);
                        })
                        .finally(() => this.setState({ isLoading: false })));
            },
            onCancel: () => this.setState({ isLoading: false })
        });
    }

    public render() {
        return (
            <>
                <PageHeader title={`DODANIE WARTOŚCI SŁOWNIKA CN->KWA`} />
                <Formik<CnKwaDictionaryValueDto> initialValues={new CnKwaDictionaryValueDto()}
                    onSubmit={(values, actions) => {
                        this.send(values, actions)
                    }}
                    validateOnBlur={true} validateOnChange={false} validateOnMount={false}
                    validationSchema={this.validation.schema}>
                    {(props: FormikProps<CnKwaDictionaryValueDto>) => (
                        <PrimaryCard>
                            <FCenteredRow>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Kod CN" sm={24} />
                                    <Field component={FInput} name="cnCode" />
                                </Col>
                                <Col md={6} xs={24}>
                                    <FFieldLabel label="Kod KWA" sm={24} />
                                    <Field component={FInput} name="kwaCode" />
                                </Col>
                            </FCenteredRow>

                            <FCenteredRow className="bottom-action-buttons" style={{ marginTop: "10px" }}>
                                <Button type="primary" icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" icon={<SaveOutlined />} loading={this.state.isLoading} size="large" onClick={() => {
                                    props.submitForm();
                                }}>Zapisz</Button>
                            </FCenteredRow>
                        </PrimaryCard>
                    )}
                </Formik>
            </>
        )
    }
}