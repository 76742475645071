import React, { useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd';
import { useDictionaryStore } from 'dictionary/dictionaryStore';
import { Card, DetailCell, RowWithBottomMargin } from 'components/StateDetailsComponents';
import { ajaxCatch } from 'helper/api';
import { CenteredSpin } from 'layout/CenteredSpin';
import { ExciseEnergyRegisterClient, ExciseEnergyRegisterDto } from 'services/GeneratedClient';

interface Props {
    exciseEnergyRegisterId: number
}

const ExciseEnergyRegisterBasicDetails = (props: Props) => {
    const [exciseEnergyRegister, setExciseEnergyRegister] = useState<ExciseEnergyRegisterDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        setIsLoading(true);
        fetchExciseEnergyRegister();
    }, [props.exciseEnergyRegisterId]);

    function fetchExciseEnergyRegister() {
        ajaxCatch(() =>
            new ExciseEnergyRegisterClient().get(props.exciseEnergyRegisterId),
            (result: ExciseEnergyRegisterDto | null) => {
                setExciseEnergyRegister(result!)
            }).finally(() => setIsLoading(false));
    }

    return (
        <RowWithBottomMargin>
            <Col span={24}>
                <Card className='m-0' title="Dane ewidencji energii elektrycznej">
                    { isLoading ? <CenteredSpin size="large" /> : 
                    <>
                        <Row>
                            <DetailCell
                                label={"Id ewidencji"}
                                value={exciseEnergyRegister?.id.toString()} />
                            <DetailCell
                                label={"Nazwa"}
                                value={exciseEnergyRegister?.name} />
                        </Row>
                        <Divider className='divider-thin' />
                        <Row>
                            <DetailCell
                                label={"Kod CN wyrobu"}
                                value={exciseEnergyRegister?.cnCode} />
                            <DetailCell
                                label={"Nazwa miejsca"}
                                value={exciseEnergyRegister?.place?.name} />
                        </Row>
                    </>}
                </Card>
            </Col>
        </RowWithBottomMargin>
    )};

export default ExciseEnergyRegisterBasicDetails;
