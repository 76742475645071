import { Key } from "react";
import { SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import SieveQueryBuilder from "./filters/SieveQueryBuilder";
import { EwdColumnProps } from "helper/GridHelper";
import ColumnHelper from "./ColumnHelper";

declare type TablePaginationPosition = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
export class AntPagination implements TablePaginationConfig {
  total?: number | undefined = undefined;
  pageSize?: number = 25
  current?: number = 1
  position?: TablePaginationPosition[];
  hideOnSinglePage?: boolean = true;
}

export class SieveModel {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  totalRowCount?: number;

  constructor(init?: Partial<SieveModel>) {
    Object.assign(this, init);
  }
}

export const mapFiltersToSieveFilters = (ewdFilters: Map<React.Key, any>, columns: EwdColumnProps<any>[]): string => {
  let queryBuilder = new SieveQueryBuilder();

  ewdFilters.forEach((filterValue: any, filterKey: Key) => {
    if (filterValue === undefined) return;

    //Todo -> znaleźć przyczynę potrzeby dodania zamiany z _ na . w tym miejscu, pomimo dodania w zapisie i odczycie filtrów
    filterKey = filterKey.toString().replace('_', '.');

    let column = ColumnHelper.findColumnByKey<any>(columns, filterKey);

    if (column === undefined) {
      console.warn("Nie odnaleziono kolumny (wskazanej w filtrze) o kluczu (key):", filterKey);
      return;
    }
    else if (column.filter === undefined) {
      console.warn("Nie zdefiniowano typu filtra dla kolumny (wskazanej w filtrze) o kluczu (key): ", filterKey, column, ewdFilters);
      return;
    }
    else {
      queryBuilder.default(filterKey.toString(), filterValue, column.filter);
    }
  });

  return queryBuilder.build();
}

export const mapAntSorterToSieve = (antSorter: SorterResult<any>): string => {
  let sieveSorts = "";
  if (antSorter && !(Object.keys(antSorter).length === 0 && antSorter.constructor === Object)) {
    if (antSorter.column)
      sieveSorts = (antSorter.order === "ascend" ? "" : "-") + antSorter.column.key;
    else
      sieveSorts = "";
  }

  return sieveSorts;
}


