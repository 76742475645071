import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { DictionaryClient, EwdDictionaryDto } from 'services/GeneratedClient';
import { Link } from 'react-router-dom';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import { inject } from 'mobx-react';
import { DictionaryStore } from 'dictionary/dictionaryStore';
import FadingTooltip from "layout/FadingTooltip";
import { ReadOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';
import { GridStore } from 'layout/table/paginated/GridStore';

interface IProps {
    dictionaryStore?: DictionaryStore;
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("dictionaryStore")
@inject("gridStore")
export default class EwdDictionaryList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "ewdDictionaryList"
        }
    }

    componentDidMount() {
        this.props.gridStore?.clearWithoutLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<EwdDictionaryDto>[] = [
        {
            key: "actions",
            render: (text: string, record: EwdDictionaryDto) => (
                <>
                    <FadingTooltip title="Wartości">
                        <Link to={`/Admin/Dictionaries/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<ReadOutlined />} />
                        </Link>
                    </FadingTooltip>
                </>
            ),
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "description",
            key: "DictionaryName",
            title: "Nazwa",
            filter: "contains",
        },
    ];

    private paginatedTable = createRef<PaginatedTable<EwdDictionaryDto>>();

    public render() {
        return (
            <>
                <PageHeader title={`SŁOWNIKI`} />
                <PaginatedTable<EwdDictionaryDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: EwdDictionaryDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new DictionaryClient().getEwdDictionaries(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
            </>
        )
    }
};