import { Button } from "antd";
import * as React from "react";
import { inject, observer } from "mobx-react";
import { GridStore } from "../GridStore";
import { CenteredRow } from "layout/CenteredRow";
import { EwdColumnProps } from "helper/GridHelper";
import { RangeValue } from 'rc-picker/lib/interface'
import { Moment } from 'moment'
import { DatePicker } from 'components/DatePicker';
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

interface IProps<T> {
    gridStore?: GridStore;

    column: EwdColumnProps<T>;
    handleSearch: () => void;
    gridName: string;
    DateTimeFormat: string;
    TimeFormat: string;
}

class State {
    value?: RangeValue<Moment>;
}

@inject("gridStore")
@observer
export class GridFilterDateTimeRange<T> extends React.Component<IProps<T>, State> {
    constructor(props: IProps<T>) {
        super(props);

        let savedValues = this.props.gridStore!.getColumnFilter(this.props.gridName, this.props.column.key!);
        if (savedValues) savedValues = savedValues.filter((a: any) => a !== undefined);

        this.state = {
            value: savedValues
        }
    }

    public render() {
        return <>
            <CenteredRow>
                <Button type="primary" icon={<SearchOutlined />} size="small" onClick={() => this.search()} disabled={!this.state.value}>
                    Wyszukaj
                </Button>
                <Button size="small" style={{ marginLeft: 4 }} icon={<DeleteOutlined />} onClick={() => this.clear()}>
                    Wyczyść
                </Button>
            </CenteredRow>

            <DatePicker.RangePicker
                format={this.props.DateTimeFormat}
                showTime={{format: this.props.TimeFormat}}
                autoFocus
                size="small"
                placeholder={["Od", "Do"]}
                style={{ width: 200, marginTop: 8, display: 'block' }}
                value={this.state.value}
                onChange={(dates: RangeValue<Moment>) => this.setState({ value: dates })}
            />
        </>
    }

    private clear() {
        this.setState({ value: undefined });
        this.props.gridStore!.clearColumnFilter(this.props.gridName, this.props.column.key!)

        this.props.handleSearch();
    }

    private search() {
        this.props.gridStore!.saveColumnFilter(this.props.gridName, this.props.column.key!, this.state.value);
        this.props.handleSearch();
    }
}