import React from 'react';
import { Button, Modal, ModalProps, Table } from 'antd';
import { ExciseStampRegisterStateOutOfRegisterDto } from 'services/GeneratedClient';


interface IProps {
    headerName: string;
    outOfRegisterStates: ExciseStampRegisterStateOutOfRegisterDto[] | undefined;
}

interface IState {
    visible: boolean;
}

export default class ExciseStampRegisterStatesOutOfRegisterModal extends React.PureComponent<IProps & ModalProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            visible: false,
        }
    }

    public show() {
        this.setState({
            visible: true
        })
    }

    public hide() {
        this.setState({
            visible: false
        })
    }
    private sumForSendIdentifier = "WSZYSTKIE";

    public render() {
        return (
            <Modal {...this.props}
                title={this.props.headerName}
                visible={this.state.visible}
                onCancel={() => this.hide()}
                footer={[
                    <Button key="back" onClick={() => this.hide()}>
                        Zamknij
                    </Button>
                ]}
                width={"600px"}>
                <Table
                    dataSource={this.props.outOfRegisterStates?.filter(x => x.sendIdentifier !== this.sumForSendIdentifier)}
                    rowKey={(row) => row.sendIdentifier!}
                    size="small"
                    pagination={false}
                    style={{ width: 600 }}
                    columns={[
                        {
                            dataIndex: "sendIdentifier",
                            key: "SendIdentifier",
                            title: "Identyfikacja wysyłki",
                        },
                        {
                            dataIndex: "stampItemsQuantity",
                            key: "StampItemsQuantity",
                            title: "Stan banderol",
                        },
                    ]}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Ogólny stan jeszcze nierozliczonych wysyłek</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{(this.props.outOfRegisterStates?.find(x => x.sendIdentifier === this.sumForSendIdentifier)?.stampItemsQuantity)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </Modal>
        )
    }
};