import * as Yup from 'yup';

export default class CnKwaDictionaryValueValidation {
    public schema = this.getValidationSchema();

    private getValidationSchema() {
        return Yup.object().shape({
            cnCode: Yup.string().required()
            .matches(/^[0-9]+$/, "Kod CN może składać się tylko z cyfr")
            .min(4)
            .max(8),
            kwaCode: Yup.string().required(),
        });
    }
}