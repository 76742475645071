import { Button } from 'antd';
import { Authorized } from 'authorization/Authorized';
import { ajaxCatch } from 'helper/api';
import React from 'react';
import { Link } from 'react-router-dom';
import { ExciseRegisterStateClient } from 'services/GeneratedClient';

interface ICorrectAsNewNavigationButtonProps {
    exciseRegisterId: number;
}

interface IState {
    hasInitialState: boolean;
}

export default class CorrectAsNewNavigationButton extends React.PureComponent<ICorrectAsNewNavigationButtonProps, IState> {
    constructor(props: ICorrectAsNewNavigationButtonProps) {
        super(props);

        this.state = {
            hasInitialState: false
        }
    }

    public componentDidMount() {
        this.fetchHasInitialState();
    }

    private fetchHasInitialState() {
        ajaxCatch(() =>
            new ExciseRegisterStateClient().hasInitialState(this.props.exciseRegisterId),
            (result: boolean | null) => {
                this.setState({
                    hasInitialState: result ?? false
                })
            });
    }

    public render() {
        return <>
            {
                this.state.hasInitialState &&
                <Link to={`/ExciseRegisters/${this.props.exciseRegisterId}/ExciseEntries/CorrectAsNew`} >
                    <Button type="primary" size={'large'}>Dodaj zdarzenie korygujące</Button>
                </Link>
            }
        </>
    }
}
