export const directions = {
    outgoing: "Outgoing",
    incoming: "Incoming",
    transshipment: "Transshipment"
}

// nazwy pol dla wyrobu
export const goodFormFieldNames = {
    productName: "productName",
    productCode: "productCode",
    exciseProductCode: "exciseProductCode",
    cnCode: "cnCode",
    fiscalMark: "fiscalMark",
    notColouredAndMarkedFuelOils: "notColouredAndMarkedFuelOils",
    incomeInExciseUnit: "incomeInExciseUnit",
    expenditureInExciseUnit: "expenditureInExciseUnit",
    incomeInInventoryUnit: "incomeInInventoryUnit",
    expenditureInInventoryUnit: "expenditureInInventoryUnit",
    unitConverter: "unitConverter",
    cnCodeOfDriedTabacco: "cnCodeOfDriedTabacco",
    netWeight: "netWeight",
    alcoholicStrength: "alcoholicStrength",
    degreePlato: "degreePlato",
    fuelType: "fuelType",
    maxRetailPrice: "maxRetailPrice",
    sizeOfProducer: "sizeOfProducer",
    biofuelContentQuality: "biofuelContentQuality"
}

export const defaultContainers = {
    notSpecified: "Nie określony"
}