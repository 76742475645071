import React from "react";
import { Route, Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { GlobalUserManager } from "./UserManager";
import { User } from "oidc-client";
import { inject, observer } from "mobx-react";
import { AccountDetailsStore } from "account/AccountDetailsStore";
import { CenteredSpin } from "layout/CenteredSpin";

interface IProps {
    accountDetailsStore?: AccountDetailsStore;

}
type IPropsWithRouter = IProps & RouteComponentProps;

const returnUrl = "returnUrl";

@inject("accountDetailsStore")
@observer
class AuthenticatedComponent extends React.Component<IPropsWithRouter, any> {
    constructor(props: IPropsWithRouter) {
        super(props);
    }

    componentDidMount() {
        if (this.props.location.pathname == '/authCallback' || this.props.location.pathname == '/silentRenew') {
            return;
        }
        GlobalUserManager.UserManager().getUser().then((getUser: User | null) => {
            if (!getUser || getUser.expired) {
                sessionStorage.setItem(returnUrl, this.props.location.pathname);
                GlobalUserManager.UserManager().signinRedirect();
            } else {
                this.markUserAsLoggedIn(getUser);
            }
        })
    }

    private markUserAsLoggedIn(redirectUser: User) {
        this.props.accountDetailsStore!.setOidcUser(redirectUser);
    }

    public render() {
        return <>
            <Route path="/authCallback" component={AuthCallbackView} />
            <Route path="/silentRenew" component={SilentRenewCallbackView} />
            {/* <Route path="/authError" component={AuthErrorView} /> */}
            {(!this.props.accountDetailsStore!.oidcUser) ? <CenteredSpin size='large'>Logowanie do aplikacji, proszę czekać</CenteredSpin> : this.props.children}
        </>
    }
}
const Authenticated = withRouter(AuthenticatedComponent);
export default Authenticated;

interface IAuthCallbackViewProps {
    accountDetailsStore?: AccountDetailsStore;
}
interface IAuthCallbackViewState {
    user: User | undefined;
}

@inject("accountDetailsStore")
class AuthCallbackView extends React.Component<IAuthCallbackViewProps, IAuthCallbackViewState> {
    constructor(props: IAuthCallbackViewProps) {
        super(props);

        this.state = {
            user: undefined
        }
    }

    private markUserAsLoggedIn(redirectUser: User) {
        this.setState({ user: redirectUser });
        this.props.accountDetailsStore!.setOidcUser(redirectUser);
    }

    componentDidMount() {
        GlobalUserManager.UserManager().signinRedirectCallback().then((user: User) => {
            this.markUserAsLoggedIn(user);

        })
    }

    public render() {
        var returnAddres = sessionStorage.getItem(returnUrl);

        if (returnAddres?.endsWith("silentRenew")) returnAddres = null;

        if (returnAddres) {
            if (this.state.user) {
                sessionStorage.removeItem(returnUrl)
                return <Redirect to={returnAddres} />;
            }
            return null;
        }
        return this.state.user ? <Redirect to="/" /> : <CenteredSpin size='large' />;
    }
}

class SilentRenewCallbackView extends React.Component<RouteComponentProps> {
    componentDidMount() {
        if (this.props.location.search && this.props.location.search.includes("error=login_required")) {
            GlobalUserManager.UserManager().signinRedirect();
        } else {
            GlobalUserManager.UserManager().signinSilentCallback();
        }
    }

    public render() {
        return <></>
    }
}
