import React, { Key } from "react";
import { ajaxByUser, ajaxCatch } from "helper/api";
import { UserClient, UserPlacePermissionKeysDto, UserPlacePermissionsClient, UserPlacePermissionsDto, UserShortenedDto } from "services/GeneratedClient";
import { Button, Tree, } from 'antd';
import { DataNode } from "antd/lib/tree";
import { PageHeaderReturn } from "layout/PageHeaderReturn";
import BottomActionButtons from "layout/ActionButtons/BottomActionButtons";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "react-router";
import EwdCard from "components/EwdCard";


interface IProps extends RouteComponentProps<any> {
    userId: number;
}

interface IState {
    initialTreeData: DataNode[],
    initialCheckedKeys: string[],
    checkedKeys: string[],
    user: UserShortenedDto | null,
    isLoading: boolean,
}

export default class AdminUserPlacePermissionsForm extends React.PureComponent<IProps, IState>
{
    private readonly goBackUrl = `/Admin/CompanyUsers`;

    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        ajaxCatch(() => new UserClient().getCompanyUser(this.props.userId),
            (data: UserShortenedDto | null) => {
                this.setState({
                    user: data
                });
            });

        ajaxCatch(() =>
            new UserPlacePermissionsClient().getUserPlacesPermissionsQuery(this.props.userId),
            (data: UserPlacePermissionsDto[] | null) => {
                var initialTreeData = this.generateInitialTreeData(data);
                var initialCheckedKeys: string[] = this.generateInitialCheckedKeys(data);

                this.setState({
                    initialTreeData: initialTreeData,
                    initialCheckedKeys: initialCheckedKeys,
                    checkedKeys: initialCheckedKeys,
                    isLoading: false
                });
            });
    }

    private generateInitialCheckedKeys(data: UserPlacePermissionsDto[] | null) {
        var initialCheckedKeys: string[] = [];
        data!.forEach(record => {
            record.permissions!.forEach(permission => {
                if (permission.checked) {
                    initialCheckedKeys.push(`${record.place!.id}-${permission.permissionName}`);
                }
            });
        });
        return initialCheckedKeys;
    }

    private generateInitialTreeData(result: UserPlacePermissionsDto[] | null) {
        return result!.map(x => ({
            title: `Nazwa miejsca: ${x.place!.name}`,
            key: `${x.place!.id}`,
            children: x.permissions?.map(y => ({
                title: y.displayName,
                key: `${x.place!.id}-${y.permissionName}`
            } as DataNode))
        } as DataNode));
    }

    private mapUserPlacePermissionKeys() {
        var userPlacePermissionKeys: UserPlacePermissionKeysDto[] = [];

        this.state.checkedKeys.forEach(key => {
            var values = key.split("-");
            if (values.length == 2) {
                userPlacePermissionKeys.push(new UserPlacePermissionKeysDto({
                    placeId: Number(values[0]),
                    permissionName: values[1]
                }));
            }
        });
        return userPlacePermissionKeys;
    }

    private onCheck(checked: { checked: Key[]; halfChecked: Key[]; } | Key[], info: any) {
        this.setState({
            checkedKeys: checked as string[]
        });
    };

    private onSubmit() {
        this.setState({
            isLoading: true
        });

        var userPlacePermissionKeys: UserPlacePermissionKeysDto[] = this.mapUserPlacePermissionKeys();
        ajaxByUser("Zapisano uprawnienia użytkownika", () => new UserPlacePermissionsClient().saveUserPlacePermissionsCmd(this.props.userId, userPlacePermissionKeys)
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            }));
    }

    public render() {
        return (
            <>
                <PageHeaderReturn title={`UPRAWNIENIA DO MIEJSCA`} goBack={this.goBackUrl} />
                <EwdCard title={this.state?.user?.isAdmin ? "Użytkownik jest administratorem i ma dostęp do wszystkich miejsc i uprawnień w ramach obecnej firmy" :
                    this.state?.user && `Uprawnienia użytkownika ${this.state.user.login} do miejsc w ramach obecnej firmy`}>
                    {this.state?.initialTreeData && this.state?.user && !this.state?.user.isAdmin &&
                        <>
                            <Tree
                                checkable showLine selectable={false}
                                defaultCheckedKeys={this.state.initialCheckedKeys}
                                treeData={this.state.initialTreeData}
                                onCheck={(checked, info) => this.onCheck(checked, info)}
                            />
                            <BottomActionButtons>
                                <Button icon={<CloseOutlined />} size="large" onClick={() => {
                                    this.props.history.push(this.goBackUrl);
                                }}>Anuluj</Button>
                                <Button type="primary" loading={this.state.isLoading} size={'large'} icon={<SaveOutlined />} onClick={() => this.onSubmit()}>Zapisz</Button>
                            </BottomActionButtons>
                        </>
                    }
                </EwdCard>
            </>
        )
    }
}