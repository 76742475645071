import React from "react";
import { MobXProviderContext } from "mobx-react";
import { observable, action } from "mobx";
import { DictionaryDto, DictionaryName, DictionaryValueDto, ExciseRegisterClient, ExciseStampRegisterType } from "../services/GeneratedClient";
import { ajaxCatch } from "helper/api";
import { string } from "prop-types";


export class DictionaryStore {
    @observable dictionaries: DictionaryDto[] | undefined = undefined;
    @observable companyId: number | undefined = undefined;

    constructor() {

    }


    @action setDictionaries(dictionaries: DictionaryDto[]) {
        this.dictionaries = dictionaries;
    }

    public getByCode(dictName: DictionaryName, value: string): DictionaryValueDto {
        let dictionaries = this.dictionaries!.filter(a => a.name === dictName);

        if (dictionaries.length !== 1) {
            throw `Nie odnaleziono słownika ${dictName}`;
        }

        let values = dictionaries[0].values!.filter(a => a.value === value);

        if (values.length !== 1) {
            throw `Nie odnaleziono kodu ${value} w słowniku ${dictName}`;
        }

        return values[0];
    }

    public getById(dictName: DictionaryName, value: number): DictionaryValueDto {
        let dictionaries = this.dictionaries!.filter(a => a.name === dictName);

        if (dictionaries.length !== 1) {
            throw `Nie odnaleziono słownika ${dictName}`;
        }

        let values = dictionaries[0].values!.filter(a => a.id === value);
        
        if (values.length !== 1) {
            throw `Nie odnaleziono id ${value} w słowniku ${dictName}`;
        }

        return values[0];
    }

    public getDisplay(dictName: DictionaryName, code?: string | number): string {
        if(!code) {
            return "";
        }
        try {
            if (typeof code === 'number') {
                let value = this.getById(dictName, code);
                return value.description!;
            }
            else {
                let value = this.getByCode(dictName, code.toString());
                return value.displayValue!;
            }
        }
        catch (error) {
            console.log(error);

            return code?.toString();
        }
    }

    public getDictionary(dictName: DictionaryName): DictionaryDto {
        let dictionaries = this.dictionaries!.filter(a => a.name === dictName);

        if (dictionaries.length !== 1) {
            throw `Nie odnaleziono słownika ${dictName}`;
        }
        return dictionaries[0];
    }


    public getDictionaryValues(dictName: DictionaryName): DictionaryValueDto[] {
        let values = this.getDictionary(dictName).values!;
        return values != null ? values : [];
    }

    public concatDictionaries(dictName1: DictionaryName, dictName2: DictionaryName): DictionaryValueDto[] {
        let dict1 = this.getDictionary(dictName1);
        let dict2 = this.getDictionary(dictName2);

        return dict1.values!.concat(dict2.values!);
    }

    public getThirdCountryOfOriginDictionaryValues(): DictionaryValueDto[] {
        let dict1 = this.getDictionary(DictionaryName.Country).values!;
        let dict2 = this.getDictionary(DictionaryName.MemberState).values!;

        var subtracted = dict1.filter(n => !dict2.some(x => x.value === n.value) && n.value !== "GR");

        return subtracted;
    }

    public getExciseStampParagraphDictionaryName(paragraphType: string): DictionaryName {
        switch(paragraphType) {
            case ExciseStampRegisterType[ExciseStampRegisterType.Paragraph24]:
                return DictionaryName.RegulationExciseStampPar24;
            case ExciseStampRegisterType[ExciseStampRegisterType.Paragraph25]:
                return DictionaryName.RegulationExciseStampPar25;
            default:
                return undefined as unknown as DictionaryName
        }
    }
}

export function useDictionaryStore(): DictionaryStore {
    const { dictionaryStore } = React.useContext(MobXProviderContext);
    return dictionaryStore;
}