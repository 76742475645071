import React, { createRef } from 'react';
import { EwdColumnProps } from 'helper/GridHelper';
import { DictionaryClient, CnKwaDictionaryValueDto, CnKwaDictionaryValueClient } from 'services/GeneratedClient';
import { Link } from 'react-router-dom';
import PaginatedTable from 'layout/table/paginated/PaginatedTable';
import { SieveModel } from 'layout/table/paginated/SieveModel';
import { PageHeader } from 'layout/PageHeader';
import FadingTooltip from "layout/FadingTooltip";
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import TableButton from 'layout/table/tableButton/TableButton';
import { Button, Popconfirm } from 'antd';
import BottomActionButtons from 'layout/ActionButtons/BottomActionButtons';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { PageHeaderReturn } from 'layout/PageHeaderReturn';
import { GridStore } from 'layout/table/paginated/GridStore';
import { inject } from 'mobx-react';

interface IProps {
    gridStore?: GridStore;
}

interface IState {
    gridName: string;
}

@inject("gridStore")
export default class CnKwaDictionaryValueList extends React.PureComponent<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            gridName: "CnKwaDictionaryValueList"
        }
    }

    componentDidMount() {
        this.props.gridStore?.saveGridNameForLastUsedRowId(this.state.gridName);
    }

    private columns: EwdColumnProps<CnKwaDictionaryValueDto>[] = [
        {
            key: "actions",
            render: (text: string, record: CnKwaDictionaryValueDto) => (
                <>
                    {!record.isDisabled && <FadingTooltip title="Edytuj">
                        <Link to={`/Admin/CnKwaDictionaries/Edit/${record.id}`}>
                            <TableButton gridName={this.state.gridName} recordId={record.id} icon={<EditOutlined />} />
                        </Link>
                    </FadingTooltip>}
                    <FadingTooltip title={!record.isDisabled ? "Wyłącz" : "Włącz"}>
                        <Popconfirm placement="bottomRight" title={<text>Czy na pewno chcesz {!record.isDisabled ? "wyłączyć" : "włączyć"} mapowanie dla kodu CN: {record.cnCode}?</text>} okText="Tak" cancelText="Nie"
                            onConfirm={() => {
                                ajaxByUser(!record.isDisabled ? "Wyłączono wartość słownika" : "Włączono wartość słownika", () =>
                                    new CnKwaDictionaryValueClient().switchIsDisabled(record.id!).then(() => {
                                        this.refresh();
                                    }));
                            }}>
                            <TableButton icon={!record.isDisabled ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                        </Popconfirm>
                    </FadingTooltip>
                </>
            ),
            title: "Czynności"
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            sorter: true,
            filter: "equals",
        },
        {
            dataIndex: "cnCode",
            key: "CnCode",
            title: "Kod CN",
            filter: "contains",
        },
        {
            dataIndex: "kwaCode",
            key: "KwaCode",
            title: "Kod Wyrobu Akcyzowego",
            filter: "contains",
        },
        {
            dataIndex: "isDisabled",
            key: "IsDisabled",
            title: "Czy włączony",
            render: (text: any, record: CnKwaDictionaryValueDto) => !record.isDisabled ? "TAK" : "NIE",
        },
    ];

    private refresh() {
        this.paginatedTable.current?.refresh();
    }

    private paginatedTable = createRef<PaginatedTable<CnKwaDictionaryValueDto>>();

    public render() {
        return (
            <>
                <PageHeader title="WARTOŚCI SŁOWNIKA CN->KWA" />
                <PaginatedTable<CnKwaDictionaryValueDto>
                    ref={this.paginatedTable}
                    gridName={this.state.gridName}
                    columns={this.columns}
                    columnsSelector={true}
                    getRowKey={(r: CnKwaDictionaryValueDto) => r.id!.toString()}
                    getPagedResult={(sieve: SieveModel) => {
                        return new CnKwaDictionaryValueClient().getCnKwaDictionaryValues(sieve.filters, sieve.sorts, sieve.page, sieve.pageSize);
                    }} />
                <BottomActionButtons>
                    <Link to={`/Admin/CnKwaDictionaries/Add`}>
                        <Button>Dodaj nową wartość</Button>
                    </Link>
                </BottomActionButtons>
            </>
        )
    }
};